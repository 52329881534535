import {Hooks, useFlexLayout} from 'react-table';

/* useFlexLayout hook with ability to fix column width */
export const useCustomFlexLayout = <D extends object>(hooks: Hooks<D>) => {
  useFlexLayout(hooks);
  hooks.getHeaderProps.push((props, {column}) => {
    return [props].concat(
      column.fixedWidth && props.style?.flex
        ? {
            style: {
              ...props.style,
              flexGrow: 0,
            },
          }
        : [],
    );
  });
  hooks.getCellProps.push((props, {cell}) => {
    return [props].concat(
      cell.column.fixedWidth && props.style?.flex
        ? {
            style: {
              ...props.style,
              flexGrow: 0,
            },
          }
        : [],
    );
  });
};

useCustomFlexLayout.pluginName = 'useFixedColumn';
