export enum ObserverAction {
  create = 'create',
  update = 'update',
  remove = 'remove',
  move = 'move',
  archive = 'archive',
  restore = 'restore',
}

export enum ObserverJobStatus {
  queue,
  running,
  success,
  error,
}

export enum ObserverActionSource {
  taskDetails,
  gantt,
  affected,
  sync,
  bulk,
}
