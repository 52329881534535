import cn from 'classnames';

import SpriteSVG from 'assets/images/svg-sprite.svg';

import s from './Icon.module.scss';

type IconProps = {
  className?: string;
  color?: 'fill' | 'stroke' | 'colored';
  iconName: string;
};

const Icon = ({className, color = 'fill', iconName}: IconProps) => {
  return (
    <svg className={cn(s.icon, s[`icon_color_${color}`], className)} width="24" height="24">
      <use xlinkHref={`${SpriteSVG}#${iconName}`} />
    </svg>
  );
};

export default Icon;
