import {GanttStatic} from 'dhtmlx-gantt';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {useBulkActions} from 'modules/Tasks/components/ActionsBar/hooks/useBulkActions';
import {useTasksActions} from 'modules/Tasks/hooks/useTasksActions';
import {TasksBulkTypes} from 'modules/Tasks/utils/constants';

export function useTasksBulkActions({gantt, projectId}: {gantt: GanttStatic; projectId: string}) {
  const {t} = useTranslation('gantt');
  const {selected, bulkType, setBulkType, openBulkModal, clearSelection, closeBulkModal} =
    useBulkActions<TasksBulkTypes>({gantt, projectId});
  const copyTasks = () => {
    const copiedIds = gantt.copyPlugin.copy();
    // values must be at root level for i18next-parser to extract correctly
    toast.success(t('floatMenu_successCopy', {defaultValue: '{{count}} item copied!', count: copiedIds.length}));
  };

  const setSubcontractor = () => openBulkModal(TasksBulkTypes.subcontractor);
  const setLocation = () => openBulkModal(TasksBulkTypes.location);
  const setType = () => openBulkModal(TasksBulkTypes.type);
  const setColor = () => openBulkModal(TasksBulkTypes.color);
  const setAssignee = () => openBulkModal(TasksBulkTypes.responsible);
  const setWatchers = () => openBulkModal(TasksBulkTypes.watcher);
  const setStatus = () => openBulkModal(TasksBulkTypes.status);

  const {indentSelectedTasks, outdentSelectedTasks, deleteSelectedTasks} = useTasksActions(gantt);
  return {
    bulkType,
    selected,
    actions: {
      indentSelectedTasks,
      outdentSelectedTasks,
      deleteSelectedTasks,
      copyTasks,
      clearSelection,
      closeBulkModal,
      setSubcontractor,
      setLocation,
      setStatus,
      setType,
      setColor,
      setAssignee,
      setWatchers,
      setBulkType,
    },
  };
}
