import {CompanyModel, CompanyOrgs} from 'shared/models/company';
import {CompanyCreateRequest} from 'shared/models/onboardingForms';
import {ReportTimelineResult} from 'shared/models/reports';
import {InvitedWorker} from 'shared/models/worker';

import ApiAxios from './axios';

const MAX_ORGS = 1000;

class CompanyService {
  private getWorkerCompanyBasePath(workerId: string) {
    return `/workers/${workerId}/companies`;
  }
  private getCompanyBasePath(companyId: string) {
    return `/companies/${companyId}`;
  }

  createCompany(companyRequest: CompanyCreateRequest, workerId: string) {
    return ApiAxios.post<CompanyModel>(this.getWorkerCompanyBasePath(workerId), {
      ...companyRequest,
      type: 'company',
    }).then((res) => {
      return res.data;
    });
  }

  updateCompany(model: CompanyModel, workerId: string) {
    return ApiAxios.post<CompanyModel>(`${this.getWorkerCompanyBasePath(workerId)}/${model.id}`, model).then(
      (res) => res.data,
    );
  }

  uploadCompanyLogo(file: File, companyId: string) {
    const payload = new FormData();
    payload.append('logo_pic', file);
    return ApiAxios.post<{url: string}>(`${this.getCompanyBasePath(companyId)}/logo`, payload, {
      headers: {'Content-Type': 'multipart/form-data'},
    }).then((res) => res.data);
  }

  getCompanyOrgs(companyId: string) {
    return ApiAxios.get<CompanyOrgs[]>(`${this.getCompanyBasePath(companyId)}/companyorgs`, {
      params: {
        limit: MAX_ORGS,
        offset: 0,
      },
    }).then((res) => res.data);
  }

  getCompany(companyId: string) {
    return ApiAxios.get<CompanyModel>(`${this.getCompanyBasePath(companyId)}`).then((res) => res.data);
  }

  getInvitedWorkerByCode(inviteCode: string) {
    return ApiAxios.get<InvitedWorker>('/companyinvite', {params: {invite_code: inviteCode}}).then((res) => res.data);
  }

  async getLaborTimeline(companyId: string) {
    return ApiAxios.get<ReportTimelineResult>(`${this.getCompanyBasePath(companyId)}/reports/timeline`).then(
      (res) => res.data,
    );
  }
}

export default new CompanyService();
