import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect} from 'react';

import {useLabelCategories} from 'shared/hooks/useLabelCategories';

export function useGanttLabelCategories(gantt: GanttStatic) {
  const categoryByLabel = useLabelCategories();
  useEffect(() => {
    gantt.labelCategories = categoryByLabel;
  }, [gantt, categoryByLabel]);
}
