import {SerializedError} from '@reduxjs/toolkit';
import axios from 'axios';
import {t} from 'i18next';

import {getHumanizedFbError, isFirebaseAuthError} from 'shared/constants/firebase';

export function serializeError(error: unknown): SerializedError {
  if (axios.isAxiosError(error) && error.response) {
    return {
      code: error.code,
      message: error.response.data?.message || error.response.data?.detail,
      name: 'Axios error',
      stack: error.stack,
    };
  }
  if (isFirebaseAuthError(error)) {
    return {
      code: error.code,
      message: getHumanizedFbError(error, t),
      name: 'Auth error',
      stack: '',
    };
  } else if (typeof error === 'object' && error !== null) {
    return ['name', 'message', 'stack', 'code'].reduce(
      (prev, cur) => ({...prev, [cur]: error[cur]}),
      {} as SerializedError,
    );
  } else {
    return {message: String(error)};
  }
}
