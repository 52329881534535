import {Gantt as GanttEnterprise, GanttStatic} from 'dhtmlx-gantt';

import {GanttNames} from 'shared/constants/gantt';

class Gantt {
  private instances = new Map<string, GanttStatic>();
  public list(names?: string | string[]) {
    if (!names) {
      return Array.from(this.instances.values());
    }
    return []
      .concat(names)
      .filter((name) => this.instances.has(name))
      .map((name) => this.instances.get(name));
  }
  public getInstance(name = GanttNames.gantt) {
    if (this.instances.has(name)) {
      return this.instances.get(name);
    }
    const inst = GanttEnterprise.getGanttInstance();
    Object.assign(inst.config, {columns: []}); // inst.dRender = debounce(() => inst.render.call(inst), 50);
    inst.dRender = inst.render;
    inst.name = name;
    this.instances.set(name, inst);
    return inst;
  }
  public hasInstance(name = GanttNames.gantt) {
    return this.instances.has(name);
  }
}

export default new Gantt();
