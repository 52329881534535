import {useCallback} from 'react';

import {useRootSelector} from 'store';
import {getActiveCompanyWorkersSelector} from 'store/profile';

export function useIsCompanyAdmin() {
  const workers = useRootSelector(getActiveCompanyWorkersSelector);
  return useCallback(
    (companyId: string) =>
      workers?.some((workers) => workers.companyId === companyId && workers.roles.includes('company_admin')),
    [workers],
  );
}
