import {GanttStatic} from 'dhtmlx-gantt';

import {TaskGanttModel} from 'shared/models/task/task';

export const withGantt = (gantt: GanttStatic, cb: (gantt: GanttStatic) => void, silent = true) => {
  if (gantt) {
    if (silent) {
      gantt.silent(() => cb(gantt));
      gantt.dRender();
    } else {
      cb(gantt);
    }
  }
};

interface ActivityIndexByOutlineNumber {
  gantt: GanttStatic;
  taskId?: string;
  parent: string;
  outlineKey: string;
  index?: number;
}

/* Find right activity index base on outline sort key
 *
 *  we need iter through the list of children from bottom to top(from lowest outline number to bigger)
 *  and find index where activity should be
 *  we need exclude from children list self, because we may be in wrong position
 *  and also placeholder task
 *
 *  - if we found child with same outline number or bigger, move task to one of position
 *    1. child position if task moved to another parent
 *       or current task position is after new position we want to move
 *    2. child position - 1 in other cases
 *  - overwise move task to the bottom of the list*/
export const findActivityIndexByOutlineNumber = ({
  gantt,
  taskId,
  parent,
  outlineKey,
  index,
}: ActivityIndexByOutlineNumber) => {
  // if move to first index
  if (index === 0) return index;
  const children: TaskGanttModel[] = gantt
    .getChildren(parent)
    .map((taskId) => gantt.getTask(taskId))
    .filter((child) => child.type !== gantt.config.types.placeholder && child.id !== taskId);
  const childrenCopy = [...children];
  const currentParent = gantt.getParent(taskId);
  const currentIndex = gantt.getTaskIndex(taskId);
  if (childrenCopy?.length) {
    while (childrenCopy.length) {
      const child = childrenCopy[0];
      if (child.outline_sort_key) {
        const compare = outlineKey?.localeCompare(child.outline_sort_key);
        const childIndex = gantt.getTaskIndex(child.id);
        if (compare <= 0) {
          return currentParent !== parent || currentIndex - childIndex > 0 ? childIndex : childIndex - 1 || 0;
        }
      }
      childrenCopy.shift();
    }
    return children.length;
  }
  return 0;
};
