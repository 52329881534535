import {useTranslation} from 'react-i18next';

import GanttComponent from 'modules/Tasks/components/Gantt/GanttView';
import {configureGanttBase} from 'modules/Tasks/components/Gantt/utils/config';
import {createIssuesDataProcessor} from 'modules/Tasks/components/Gantt/utils/dataProcessors';
import {getGanttTableLayout} from 'modules/Tasks/components/Gantt/utils/layout';
import {loadIssues} from 'modules/Tasks/components/Gantt/utils/load';
import {configureZoomLevels} from 'modules/Tasks/components/Gantt/utils/zoomLevels';
import {getColumns, getColumnsLabels} from 'modules/Tasks/Views/Issues/utils';
import EmptyGridBubble from 'shared/components/EmptyGridBubble';
import {TasksViewMode} from 'shared/constants/common';
import {GanttNames} from 'shared/constants/gantt';
import {useUnmount} from 'shared/hooks/core/useUnmount';
import Gantt from 'shared/models/Gantt';

import SidebarPanel, {SidebarType} from '../../components/SidebarPanel/SidebarPanel';
import {GanttViewCommonProps} from '../types';

export const GanttIssuesView = ({
  projectId,
  isActiveFilter,
  queryParams,
  lookaheadColors,
  hasAdminRole,
}: GanttViewCommonProps) => {
  const {t} = useTranslation(['gantt', 'lookahead', 'filters']);

  useUnmount(() => {
    Gantt.getInstance(GanttNames.issues).needReload = true;
  });

  function getNoData() {
    const getText = () => {
      if (isActiveFilter) {
        return t('filters:not_found.issues', `No results found. Please try again or reset filters.`);
      }
      return t(
        'gantt:no_data.issues',
        'To add issues, select activities in the Gantt or Lookahead view and select “Add Issue” from the pop up menu.',
      );
    };

    return <EmptyGridBubble text={getText()} t={t} />;
  }

  return (
    <GanttComponent
      load={loadIssues}
      viewMode={TasksViewMode.lookahead}
      name={GanttNames.issues}
      projectId={projectId}
      noDataElement={getNoData()}
      isActiveFilter={isActiveFilter}
      queryParams={queryParams}
      lookaheadColors={lookaheadColors}
      persistGridWidth={false}
      createDataProcessor={createIssuesDataProcessor}
      frozenColumns={1}
      viewPanel
      configure={({gantt, cacheHelper}) => {
        configureGanttBase({gantt, t, projectId, cacheHelper, hasAdminRole});
        configureZoomLevels(gantt, projectId);
        gantt.getColumnLabels = getColumnsLabels;
        gantt.config.grid_width = undefined;
        gantt.config.grid_resize = false;
        gantt.config.show_unscheduled = true;
        gantt.config.grid_elastic_columns = true;
        gantt.config.order_branch = false;
        gantt.config.layout = getGanttTableLayout(undefined);
        gantt.config.columns = getColumns(gantt, t);
        gantt.config.placeholder_task = false;
      }}
      dataCy="table-container"
      getPanelActivity={({taskId, onClose, updateActivityDetailsTask, gantt}) => (
        <SidebarPanel
          sidebarType={SidebarType.issue}
          taskId={taskId}
          onClose={onClose}
          updateActivityDetailsTask={updateActivityDetailsTask}
          gantt={gantt}
        />
      )}
    />
  );
};
