import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import StatusElement from 'shared/components/StatusElement';
import {getPrettyTaskStatuses, taskStatusClassNameOverride} from 'shared/constants/statuses';

import s from './Wbs.module.scss';
import TaskStatusIcon from 'shared/components/TaskStatusIcon';
import {TaskStatusType} from 'shared/models/task/taskStatus';

type WbsProps = {
  className?: string;
  id?: string;
  title: string;
  status: TaskStatusType;
};

const Wbs = ({className, id, title, status}: WbsProps) => {
  const {t} = useTranslation(['gantt']);
  return (
    <div className={cn(s.wbs, className)}>
      <div className={s.wbs__id}>{id}</div>
      <div className={s.wbs__title}>{title}</div>
      <StatusElement
        label={getPrettyTaskStatuses(t)[status]}
        size="xs"
        className={taskStatusClassNameOverride[status]}
        icon={<TaskStatusIcon name={status as TaskStatusType} size={12} className="status-element__icon" />}
      />
    </div>
  );
};

export default Wbs;
