import {FC, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useHistory} from 'react-router';

import {GanttNames} from 'shared/constants/gantt';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {generatePathWithQueryParams} from 'shared/helpers/common';
import {useRootSelector} from 'store';
import {selectAllProjectEntities} from 'store/projects';

import s from './WorkerTasks.module.scss';
import Loader from 'shared/components/Loader';
import TaskCard from 'shared/components/TaskCard';
import {TasksResponseType} from 'shared/models/task/task';

type Props = {
  tasks: TasksResponseType;
  isFetching: boolean;
};

const WorkerTasks: FC<Props> = ({tasks, isFetching}: Props) => {
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const offset = useRef(0);
  const projects = useRootSelector(selectAllProjectEntities);
  const [pagedData, setPagedData] = useState<TasksResponseType>([]);
  const {t} = useTranslation('worker');

  const getNextData = (tasks: TasksResponseType) => {
    if (tasks.length) {
      offset.current = offset.current + 20;
      setPagedData(tasks.slice(0, offset.current));
    }
  };

  useEffect(() => {
    if (tasks?.length) {
      getNextData(tasks);
    }
  }, [tasks]);

  return (
    <InfiniteScroll
      dataLength={pagedData.length}
      hasMore={(!tasks && isFetching) || pagedData.length < tasks?.length}
      next={() => getNextData(tasks)}
      loader={
        !tasks && isFetching ? (
          <TaskCard className="profile-tasks__item" loading={true} />
        ) : (
          <div className="loader-container">
            <Loader style={{backgroundColor: 'transparent'}} />
          </div>
        )
      }
      scrollableTarget="slide-panel__content--aside"
      className={`${s.workerTasks} ${s.workerTasks__scrollBody}`}
    >
      <section className="profile-tasks">
        <header className="info-header info-header--size-xl">
          <h2 className={`info-header__title ${s.workerTasks__box}`}>{t('tasks.title', 'Activities')}</h2>
        </header>
        <div className={`profile-tasks__grid ${s.workerTasks__box}`}>
          {pagedData?.map((task) => (
            <TaskCard
              onClick={() =>
                history.push(
                  generatePathWithQueryParams(routes.tasks, {projectId: task.projectId}, {view: GanttNames.gantt}),
                  {
                    taskId: task.id,
                  },
                )
              }
              className="profile-tasks__item"
              task={task}
              project={projects[task.projectId]}
              loading={isFetching}
              key={task.id}
              hideEmpty
            />
          ))}
        </div>
      </section>
    </InfiniteScroll>
  );
};
export default WorkerTasks;
