import {useInfiniteQuery} from 'react-query';

import ProjectsApi from 'api/projects';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

export const useGetSubcontractors = (projectId: string) => {
  const {
    isLoading,
    data: projectSubs,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(
    QUERY_CACHE_KEYS.projectSubcontractorsWithPaginate(projectId),
    () => ProjectsApi.getOrgs(projectId),
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    },
  );

  return {isLoading, projectSubs, hasNextPage, fetchNextPage, refetchSubs: refetch};
};
