import cn from 'classnames';
import dayjs from 'dayjs';
import {useTranslation} from 'react-i18next';

import {ActionModel} from 'modules/Tasks/components/SidebarPanel/utils/types';
import CoreNativeDatePicker from 'shared/components/CoreForm/CoreNativeDatePicker';
import Select from 'shared/components/CoreForm/Select/Select';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import {CompanyWorker} from 'shared/models/worker';

import s from './ActionsForm.module.scss';

type ActionsFormProps = {
  className?: string;
  data: ActionModel;
  onChange: (data: Partial<ActionModel>) => void;
  workers: CompanyWorker[];
  disabled?: boolean;
};

const ActionsForm = ({className, disabled, data, onChange, workers}: ActionsFormProps) => {
  const {t} = useTranslation(['task']);
  return (
    <div className={cn(s.actionsForm, className)}>
      <div className={`${s.actionsForm__item} ${s.actionsForm__item_asignee}`}>
        <FormControl label={t('task:activity_actions.action.responsible.label', 'Assignee')}>
          <Select
            isDisabled={disabled}
            className="react-select"
            placeholder={t('task:activity_actions.action.responsible.placeholder', 'Unassigned')}
            classNamePrefix="react-select"
            menuPlacement="auto"
            value={data.responsible}
            options={
              workers?.map((w) => ({
                label: w.workerFull.fullName || w.workerFull.email || w.workerFull.mobileNumber,
                value: w.workerId,
              })) || []
            }
            onChange={(value) => onChange({responsible: value})}
          />
        </FormControl>
      </div>
      <div className={`${s.actionsForm__item} ${s.actionsForm__item_date}`}>
        <FormControl label={t('task:activity_actions.action.date.label', 'Date')}>
          <CoreNativeDatePicker
            disabled={disabled}
            minDate={data.minDate}
            value={data.endDate}
            onChange={(value) => onChange({endDate: dayjs(value?.target?.value).toDate()})}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default ActionsForm;
