import dayjs from 'dayjs';
import {MutableRefObject, useEffect} from 'react';

export type UseScrollAfterLoadingProps = {
  progressReportRef: MutableRefObject<HTMLElement | null>;
  isLoading: boolean;
  status: 'idle' | 'loading' | 'error' | 'success';
};

export const useScrollToCurrentDayAfterLoad = ({progressReportRef, isLoading, status}: UseScrollAfterLoadingProps) => {
  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get('schedEndFirst');
  const dateFormat = 'M/D/YY';
  const selectedDay = paramValue
    ? dayjs(parseInt(paramValue, 10)).format(dateFormat) // convert milliseconds to date
    : dayjs().format(dateFormat); // current date in 'M/D/YY' format

  useEffect(() => {
    const observer = new MutationObserver(() => {
      if (progressReportRef.current && !isLoading && status === 'success') {
        const spanNodes = progressReportRef.current.getElementsByTagName('span');
        for (let i = 0; i < spanNodes.length; i++) {
          if (spanNodes[i].textContent === selectedDay) {
            spanNodes[i].scrollIntoView({behavior: 'smooth', block: 'start'});
            // Disconnect the observer after scrolling
            observer.disconnect();
            break;
          }
        }
      }
    });

    if (progressReportRef.current) {
      // Configuration of the observer (in this case observe child additions/change/removals)
      const config = {attributes: true, childList: true, subtree: true};

      // Start the observer on the current ref
      observer.observe(progressReportRef.current, config);
    }

    // Cleanup on unmount
    return () => {
      observer.disconnect();
    };
  }, [isLoading, status, progressReportRef, selectedDay]);
};
