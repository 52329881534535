export interface CompanyModel {
  alias?: string | null;
  bannerText?: string | null;
  businessUrl?: string | null;
  companyAddress?: string;
  companyName?: string;
  companyPhone?: string | null;
  companySize?: string;
  id?: string;
  languagePref?: 'en_US' | 'es_US' | 'pt_BR';
  locale?: 'US' | 'BR';
  logoUrl?: string | null;
  pitchText?: string | null;
  projectImages?: string[];
  type?: 'company' | 'coreslight';
  proStartTime?: string;
  currentSeatCount?: number;
  subscriptionLevel?: string;
  status?: CompanyStatus;
}

export type CompanyOrgs = {
  companyId?: string;
  group?: CompanyOrgsGroup;
  groupId?: string;
  id?: string;
  abbrev?: string;
  lookaheadColor?: string;
  contactWorkerId?: string;
  nbWorkers?: number;
};

export type CompanyOrgsGroup = {
  adhoc?: boolean;
  direct?: boolean;
  id?: string;
  name?: string;
  notifybot?: boolean;
};

export enum CompanyStatus {
  Active = 'active',
  Disabled = 'disabled',
  Unclaimed = 'unclaimed',
}
