import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import equal from 'fast-deep-equal';
import {camelizeKeys} from 'humps';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {useHistory, useLocation} from 'react-router';
import {toast} from 'react-toastify';

import GanttService from 'api/gantt';
import TasksApi from 'api/tasks';
import WorkersApi from 'api/workers';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {transformData} from 'modules/Tasks/components/Gantt/utils/functions';
import Action from 'modules/Tasks/components/SidebarPanel/components/ActionsList/Action/Action';
import PanelSection from 'modules/Tasks/components/SidebarPanel/components/PanelSection/PanelSection';
import {ActionModel} from 'modules/Tasks/components/SidebarPanel/utils/types';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {safeParseDate, toShortIso} from 'shared/helpers/dates';
import {getTaskChangedFieldsOnly} from 'shared/helpers/task';
import {filterDuplicatedCompanyWorkers} from 'shared/helpers/worker';
import {useUnmount} from 'shared/hooks/core/useUnmount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {TaskObjectType, GroupMemberRole} from 'shared/models/task/const';
import {TaskAssignees} from 'shared/models/task/member';
import {TaskDetailsModelDTO, TaskGanttModel} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {useRootSelector} from 'store';

import s from './ActionsList.module.scss';
import {calculateDate} from './utils';

type ActionsListProps = {
  className?: string;
  parent: TaskDetailsModelDTO;
  projectId: string;
  onOpenAction: (id: string) => void;
  disabled?: boolean;
};

type ActionEditableFields = Pick<TaskDetailsModelDTO, 'endDate' | 'status' | 'name'>;

const ActionsList = ({className, parent, projectId, onOpenAction, disabled}: ActionsListProps) => {
  const taskId = parent?.id;
  const location = useLocation<{newActionFocus: boolean}>();
  const taskFromState = useRootSelector((state) => state.tasks.currentTask);
  const [creating, setCreating] = useState(false);
  const isNewActionFocus = !!location?.state?.newActionFocus && !!parent;
  const {viewMode} = useFilterContext();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.tasks.sidePanel;
  const {t} = useTranslation(['task']);
  const project = useProjectSelector(projectId);
  const [taskToFocus, setTaskToFocus] = useState<string>();
  const history = useHistory<{newActionFocus: boolean}>();

  const {data: workers} = useQuery(
    ['workers', taskId],
    () =>
      WorkersApi.getAllProjectWorkers(projectId, {
        filterParams: {
          blendedStatus: ['active', 'invited'],
        },
        offset: 0,
        limit: 1000,
        sortOrder: 'ASC',
      }),
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    },
  );

  const {
    data: subtasks,
    refetch: refetchSubtasks,
    remove,
  } = useQuery(
    ['lookaheadTaskActions', taskId],
    async () => {
      const res = await GanttService.getSubTasks(projectId, taskId).then((res) =>
        // TODO: shared component cannot import anything from feature folder
        transformData({
          data: res.data,
          projectId: projectId,
          timezoneOffset: project.timezoneOffset,
          inclusiveEndDate: false,
        }),
      );
      return isNewActionFocus ? prepareSubtasks(res.tasks) : res.tasks;
    },
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    },
  );

  useUnmount(() => {
    remove();
  });

  const prepareSubtasks = (tasks = [] as TaskGanttModel[]) => {
    const filteredActions = tasks.filter((s) => s.object_type === TaskObjectType.action);
    if (isNewActionFocus) {
      return filteredActions.map((action, index) =>
        index === filteredActions.length - 1 ? {...action, focusToTask: true} : action,
      );
    }
    return filteredActions;
  };

  const addAction = async () => {
    mixpanel.track(mixpanelEvents.action);
    function isGanttTask(task: TaskGanttModel | TaskDetailsModelDTO): task is TaskGanttModel {
      return '$has_child' in task;
    }
    const {start, end} = calculateDate({
      startDate: isGanttTask(parent) ? parent.start_date : parent.startDate,
      endDate: isGanttTask(parent) ? parent.end_date : parent.endDate,
    });
    setCreating(true);
    const taskModel = {
      name: t('task:activity_actions.new_name', 'New Action'),
      parentId: String(taskId),
      projectId: project.id,
      objectType: TaskObjectType.action,
      status: TaskStatusType.assigned,
      startDate: toShortIso(start),
      endDate: toShortIso(end),
    };
    try {
      const created = await TasksApi.createTask(taskModel);
      setTaskToFocus(created.id);
      await refetchSubtasks();
      toast.success(t('task:activity_actions.notifications.created', 'Action created'));
    } catch (err) {
      Sentry.captureException(err);
      if (isAxiosError(err)) {
        toast.error(extractAxiosError(err));
      }
    } finally {
      setCreating(false);
      if (isNewActionFocus) {
        history.replace({
          ...history.location,
          state: {
            ...history.location.state,
            newActionFocus: false,
          },
        });
      }
    }
  };

  const subtasksIds = useMemo(() => {
    return subtasks?.map((s) => s.id) || [];
  }, [subtasks]);

  const actions = useMemo(() => {
    return (
      subtasks?.map(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        ({id, responsible, status, name, start_date, end_date}) =>
          ({
            id,
            responsible: responsible?.[0] && (responsible[0] as any).member_id,
            endDate: safeParseDate(end_date),
            minDate: safeParseDate(start_date),
            status,
            name,
          } as ActionModel),
      ) || []
    );
  }, [subtasks]);

  useEffect(() => {
    if (subtasksIds.includes(taskFromState?.id) && !isNewActionFocus) {
      refetchSubtasks();
    }
    if (isNewActionFocus && !creating) {
      addAction();
    }
  }, [taskFromState, isNewActionFocus]);

  async function updateTask(model: Partial<TaskDetailsModelDTO>) {
    return await TasksApi.updateTask(model);
  }

  const onUpdate = async (data: ActionModel) => {
    try {
      const currentTask: TaskGanttModel = subtasks.find((subtask) => subtask.id === data.id);
      let needRefetch = false;
      const updateFields: ActionEditableFields = {
        endDate: toShortIso(data.endDate), // TODO: more accurate compare dates
        name: data.name.trim(),
        status: data.status,
      };
      const originalFields: ActionEditableFields = {
        endDate: toShortIso(currentTask.end_date),
        name: currentTask.name,
        status: currentTask.status,
      };
      if (!equal(updateFields, originalFields)) {
        const originalTaskData = {
          id: currentTask.id,
          projectId: currentTask.projectId,
          ...originalFields,
        };
        const changedFieldsOnly = getTaskChangedFieldsOnly(originalTaskData, updateFields);
        await updateTask(changedFieldsOnly);
        needRefetch = true;
      }
      const camelizeResponsible = camelizeKeys(currentTask.responsible) as TaskAssignees[];
      if (
        data.responsible &&
        (!currentTask || !camelizeResponsible.find((resp) => resp.memberId === data.responsible))
      ) {
        await TasksApi.addAssignee(data.id, {
          memberId: data.responsible,
          memberRole: GroupMemberRole.responsible,
        });
        needRefetch = true;
      }
      needRefetch && (await refetchSubtasks());
      // TODO: update grids
    } catch (e) {
      toast.error(extractAxiosError(e) || 'Unexpected error');
    }
  };

  const deleteAction = async (taskId: string) => {
    await TasksApi.deleteTask(taskId);
    await refetchSubtasks();
  };

  return (
    <PanelSection
      className={s.panelActivityInfo__item}
      title={t('task:activity_actions.title', 'Actions')}
      width="narrow"
      headerAction={
        !disabled && (
          <CtrlButton view="link" onClick={addAction} disabled={disabled || creating}>
            {creating
              ? t('task:activity_actions.creating', 'Creating...')
              : t('task:activity_actions.create', 'Create Action')}
          </CtrlButton>
        )
      }
      // moreAction={<CtrlButton view="link">{t('lookahead:activity_details.actions.showMore', 'Show more')}</CtrlButton>} TODO
      description={!actions.length && t('task:activity_actions.empty_desc', 'No actions here. You can create new.')}
    >
      <div className={cn(s.actionsList, className)}>
        {actions?.map((action) => (
          <Action
            disabled={disabled}
            focus={taskToFocus === action.id}
            key={action.id}
            data={action}
            onChange={onUpdate}
            className={s.actionsList__item}
            workers={filterDuplicatedCompanyWorkers(workers || [])}
            onDelete={() => deleteAction(action.id)}
            onOpen={() => onOpenAction(action.id)}
          />
        ))}
      </div>
    </PanelSection>
  );
};

export default ActionsList;
