import {useMemo} from 'react';

import {useCompany} from 'shared/hooks/useCompany';

export function useCompanyLocale() {
  const company = useCompany();

  return useMemo(() => {
    return company?.locale?.toUpperCase() || 'US';
  }, [company]);
}
