import anchorme from 'anchorme';
import cn from 'classnames';
import {memo} from 'react';
import {useTranslation} from 'react-i18next';

import s from './InfoLinksList.module.scss';

type InfoLinksListProps = {
  className?: string;
  text: string;
};

const InfoLinksList = ({className, text}: InfoLinksListProps) => {
  const {t} = useTranslation(['task']);
  const parsed = anchorme.list(text).filter((parsedValue) => parsedValue.isURL && parsedValue.host);
  const linksList =
    parsed &&
    parsed.map((link, index) => {
      return (
        <div className={s.infoLinksList__item} key={`${link.string}_${index}`}>
          <a className={s.infoLinksList__link} href={link.string} target="_blank" rel="noreferrer">
            {`${link.protocol ? '' : 'https://'}${link.string}`}
          </a>
        </div>
      );
    });

  if (!linksList.length) return null;

  return (
    <div className={cn(s.infoLinksList, className)}>
      <div className={s.infoLinksList__label}>{t('task:task_form.links.label', 'Links')}</div>
      {<div className={s.infoLinksList__body}>{linksList}</div>}
      <div className={s.infoLinksList__note}>Links from description will be added here automacally</div>
    </div>
  );
};

export default memo(InfoLinksList);
