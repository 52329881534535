import {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';
import Icon from 'shared/components/Icon';

import {toTitleCase} from 'shared/helpers/common';
import {WorkerBlendedStatus} from 'shared/models/worker';

type ProfileStatusProps = {
  status: WorkerBlendedStatus | 'new';
  className?: string;
};

const statusClasses = {
  new: 'new-worker',
};

const statusIcons = {
  invited: 'hourglass_quarter',
  active: 'checkmark-outlined',
};

const ProfileStatus: FC<ProfileStatusProps> = ({status, className}) => {
  const {t} = useTranslation(['common', 'worker']);
  return (
    <div className={`profile-status profile-status--${statusClasses[status] || status} ${className}`}>
      {statusIcons[status] ? (
        <Icon colorFill name={statusIcons[status]} size={24} className="profile-status__icon" />
      ) : null}
      <span className="profile-status__name">
        {toTitleCase(status === 'new' ? t('worker:form.title_new') : t(`common:worker_status.${status}`) || 'Unknown')}
      </span>
    </div>
  );
};

export default memo(ProfileStatus);
