import {TFunction} from 'react-i18next';
import {mixed, object, string} from 'yup';

const IMAGE_SIZE = 20480 * 1024;
const IMAGE_SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/bmp'];
export function getProfileDefaultSchema(t: TFunction) {
  return object({
    fullName: string().required(t('common:validation.required', 'This field is required')),
    trade: string().optional(),
  });
}

export const getImageValidationSchema = (t: TFunction) =>
  mixed()
    .test('fileSize', t('common:validation.image_size'), function (value) {
      if (!!value) {
        return value.size <= IMAGE_SIZE;
      }
      return true;
    })
    .test('fileFormat', t('common:validation.image_type'), (value) => {
      if (!!value) {
        return IMAGE_SUPPORTED_FORMATS.includes(value.type);
      }
      return true;
    });
