import ApiAxios from './axios';

class GroupsService {
  private static instance: GroupsService | null = null;

  public static getInstance(): GroupsService {
    if (!GroupsService.instance) {
      GroupsService.instance = new GroupsService();
    }
    return GroupsService.instance;
  }

  async getGroupByGroupId(groupId: string) {
    const res = await ApiAxios.get<{name: string}>(`/groups/${groupId}`);
    return res.data;
  }
}

export default GroupsService.getInstance();
