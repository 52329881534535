import {useMemo} from 'react';
import {useStore} from 'react-redux';

import {withGantt} from 'shared/helpers/gantt';
import {toGanttTaskModel} from 'shared/mapping/task';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {RootState, useRootDispatch} from 'store';
import {taskActions} from 'store/tasks';

import {updateCollapseIcon} from '../../../modules/Tasks/utils/functions';

import {addTask, createCacheHandler, moveTask, updateGanttTask} from './helpers';
import {ToggleArchiveAction} from './types';

export function useTasksCacheHelper() {
  const dispatch = useRootDispatch();
  const rootStore = useStore<RootState>();

  return useMemo(() => {
    const add = createCacheHandler<
      {
        task: TaskDetailsModelDTO;
        asPristine?: boolean;
        index?: number;
      }[]
    >({
      gantt: (inst, items) => {
        inst.batchUpdate(() => {
          items
            .sort((a, b) => a.task.outlineSortKey.localeCompare(b.task.outlineSortKey))
            .forEach(({task, asPristine = false, index}) => addTask(inst, task, asPristine, index));
        });
      },
    });

    const update = createCacheHandler<Partial<TaskDetailsModelDTO>[]>({
      gantt: (inst, tasks) => {
        inst.batchUpdate(() => {
          tasks
            .sort((a, b) => a.outlineSortKey.localeCompare(b.outlineSortKey))
            .forEach((task) => updateGanttTask(inst, task));
        });
      },
      currentTask: (tasks) => {
        const rootState = rootStore.getState();
        tasks.forEach((task) => {
          if (rootState.tasks.currentTask?.id === task.id) {
            dispatch(taskActions.updateTask(task));
          }
        });
      },
    });

    const remove = createCacheHandler<string[]>({
      gantt: (inst, ids) => {
        inst.batchUpdate(() => {
          ids.forEach((id) => {
            if (inst.isTaskExists(id)) {
              const parent = inst.getParent(id) as string;
              inst.deleteTask(id);
              parent && updateCollapseIcon(inst, parent, -1);
            }
          });
        });
      },
    });

    const removeWithChildren = createCacheHandler<string>({
      gantt: (inst, taskId) => {
        withGantt(
          inst,
          (gantt) => {
            if (gantt.isTaskExists(taskId)) {
              const parent = gantt.getParent(taskId) as string;
              gantt.deleteTask(taskId);
              parent && updateCollapseIcon(gantt, parent, -1);
            }
          },
          true,
        );
      },
    });

    const toggleArchived = createCacheHandler<{tasks: TaskDetailsModelDTO[]; action: ToggleArchiveAction}>({
      gantt: (inst, {tasks}) => {
        inst.batchUpdate(() => tasks.forEach((task) => updateGanttTask(inst, task)));
      },
    });

    const restore = createCacheHandler<TaskDetailsModelDTO[]>({
      gantt: (inst, tasks) => {
        inst.batchUpdate(() => {
          tasks.forEach((task) => {
            const ganttTask = toGanttTaskModel(task);
            inst.silent(() => {
              inst.addTask(ganttTask);
            });
          });
        });
      },
    });

    const move = createCacheHandler<
      {
        task: TaskDetailsModelDTO;
        index: number;
        parent: string;
      }[]
    >({
      gantt: (inst, items) => {
        items
          .sort((a, b) => a.task.outlineSortKey.localeCompare(b.task.outlineSortKey))
          .forEach(({task, index, parent}) => {
            moveTask(inst, task, parent, index);
          });
      },
    });

    return {add, update, remove, removeWithChildren, toggleArchived, restore, move};
  }, [rootStore, dispatch]);
}
