import {AxiosResponse, CancelTokenSource} from 'axios';
import {decamelize, decamelizeKeys} from 'humps';

import {AsyncCommitResult} from 'shared/components/TasksImport/utils/types';
import {CompletePercentageFunc, pollResults} from 'shared/helpers/api';
import {isAxiosError} from 'shared/helpers/axios';
import {prepareWorkerFiltersForUrl} from 'shared/helpers/worker';
import {CompanyOrgs, CompanyOrgsGroup} from 'shared/models/company';
import {Feedback} from 'shared/models/feedback';
import {
  ProjectModel,
  ProjectWorkflow,
  ActivityIdRowNumber,
  ActivitiesIdRowMap,
  ProjectCollabRequest,
  ProjectCollabInfo,
  ProjectCustomFieldDef,
} from 'shared/models/project';
import {
  TaskOutlineBasicModel,
  TaskOutlineModelDTO,
  FeedbackProjectModelDTO,
  WakeCapCrews,
  WakeCapZones,
} from 'shared/models/task/task';
import {TaskDependencyDto} from 'shared/models/TaskDependency';
import {CompanyWorker, CompanyWorkerRequestParams} from 'shared/models/worker';

import ApiAxios from './axios';

export class ProjectsService {
  private getBasePath(companyId: string) {
    return `/companies/${companyId}/projects`;
  }

  private getProjectsPath(projectId: string) {
    return `/projects/${projectId}`;
  }

  getProjects(companyId: string) {
    return ApiAxios.get<ProjectModel[]>(this.getBasePath(companyId)).then((res) => res.data);
  }

  getProject(projectId: string) {
    return ApiAxios.get<ProjectModel>(this.getProjectsPath(projectId)).then((res) => res.data);
  }

  createProject(companyId: string, project: ProjectModel) {
    return ApiAxios.post<ProjectModel>(this.getBasePath(companyId), project).then((res) => res.data);
  }

  updateProject(project: Partial<ProjectModel>) {
    return ApiAxios.post<ProjectModel>(this.getProjectsPath(project.id), project).then((res) => res.data);
  }

  deleteProject(projectId: string) {
    return ApiAxios.delete<ProjectModel>(this.getProjectsPath(projectId)).then((res) => res.data);
  }

  getProjectTaskTypes(projectId: string) {
    return ApiAxios.get<string[]>(`${this.getProjectsPath(projectId)}/task_types`).then((res) => res.data);
  }

  getOutline(projectId: string) {
    return ApiAxios.get<TaskOutlineBasicModel[]>(`${this.getProjectsPath(projectId)}/outline`);
  }

  getOutlineTasks(companyId: string, taskIds: string[], cancelToken?: CancelTokenSource) {
    return ApiAxios.get<TaskOutlineModelDTO[]>(`/companies/${companyId}/outline_tasks`, {
      params: {taskids: taskIds.join(',')},
      cancelToken: cancelToken?.token,
    });
  }

  getProjectWorkers<RT extends 'data' | 'response' = 'data'>(
    projectId: string,
    {offset, limit, filterParams, sortOrder, sortField}: CompanyWorkerRequestParams,
    returnType?: RT,
  ): RT extends 'data' ? Promise<CompanyWorker[]> : Promise<AxiosResponse<CompanyWorker[]>>;
  getProjectWorkers(
    projectId: string,
    {offset, limit, filterParams, sortOrder, sortField}: CompanyWorkerRequestParams,
    returnType: 'data' | 'response' = 'data',
  ) {
    const preparedSort = {
      sortField: sortField ? decamelize(sortField) : undefined,
      sortOrder: sortOrder ? sortOrder : undefined,
    };
    return ApiAxios.get<CompanyWorker[]>(`${this.getProjectsPath(projectId)}/companyworkers`, {
      params: {
        offset,
        limit,
        filterParams: JSON.stringify(decamelizeKeys(prepareWorkerFiltersForUrl(filterParams))),
        ...preparedSort,
      },
    }).then((res) => (returnType === 'data' ? res.data : res));
  }

  getOrgs(projectId: string) {
    return ApiAxios.get<CompanyOrgs[]>(`${this.getProjectsPath(projectId)}/companyorgs`).then((res) =>
      res.data
        ? res.data.sort((companyA, companyB) => {
            return companyA?.group?.name.localeCompare(companyB?.group?.name);
          })
        : res.data,
    );
  }

  createOrg(projectId: string, payload?: Partial<CompanyOrgs | CompanyOrgsGroup> | string) {
    return ApiAxios.post<CompanyOrgs>(
      `${this.getProjectsPath(projectId)}/companyorgs`,
      typeof payload === 'string' ? {name: payload} : payload,
    )
      .then((res) => res.data)
      .catch((e) => {
        if (isAxiosError<{companyorg: CompanyOrgs}>(e)) {
          const {
            response: {
              data: {companyorg = null},
              status,
            },
          } = e;
          if (status === 409 && companyorg) {
            return companyorg;
          }
        }
        throw e;
      });
  }

  updateOrg(projectId: string, orgId: string, payload: Partial<CompanyOrgs | CompanyOrgsGroup>) {
    return ApiAxios.post<CompanyOrgs>(`${this.getProjectsPath(projectId)}/companyorgs/${orgId}`, payload).then(
      (res) => res.data,
    );
  }

  deleteOrg(projectId: string, orgId: string) {
    return ApiAxios.delete(`${this.getProjectsPath(projectId)}/companyorgs/${orgId}`).then((res) => res.data);
  }

  getProjectSub(projectId: string, subId: string) {
    return ApiAxios.get<CompanyOrgs>(`${this.getProjectsPath(projectId)}/companyorgs/${subId}`).then((res) => res.data);
  }

  getWorkflow(projectId: string) {
    return ApiAxios.get<ProjectWorkflow>(`${this.getProjectsPath(projectId)}/workflow`).then((res) => res.data);
  }

  getRowNumberMap(projectId: string) {
    return ApiAxios.get<{data: ActivityIdRowNumber[]}>(`${this.getProjectsPath(projectId)}/rownumber_map`).then(
      (res) =>
        res.data?.data?.reduce((acc, {id, rownum}) => {
          return Object.assign(acc, {[id]: rownum, [rownum]: id});
        }, {} as ActivitiesIdRowMap) || {},
    );
  }

  getTaskDependencies(projectId: string, tasksIds: string[]) {
    return ApiAxios.post<{dependencies: TaskDependencyDto[]}>(
      `${this.getProjectsPath(projectId)}/get_dependencies`,
      tasksIds.length ? {taskIds: tasksIds} : {},
    ).then((res) => res.data);
  }

  sendCollabRequest(projectId: string, payload: ProjectCollabRequest) {
    return ApiAxios.post(`${this.getProjectsPath(projectId)}/collaborate`, payload).then((res) => res.data);
  }

  getMostRecentCollabUsers(projectId: string) {
    return ApiAxios.get<ProjectCollabInfo>(`${this.getProjectsPath(projectId)}/collaborate/most_recent`).then(
      (res) => res.data,
    );
  }

  getCollaborationInfo(projectId: string, collabId: string) {
    return ApiAxios.get<ProjectCollabInfo>(`${this.getProjectsPath(projectId)}/collaboration/${collabId}`).then(
      (res) => res.data,
    );
  }

  cloneProject(projectId: string, name: string) {
    return ApiAxios.post<ProjectModel>(`${this.getProjectsPath(projectId)}/clone/start`, {name}, {}).then(
      (res) => res.data,
    );
  }

  cloneProjectStatus(projectId: string, asyncUploadId: string) {
    return ApiAxios.get<AsyncCommitResult<Record<string, string | number | boolean>>>(
      `${this.getProjectsPath(projectId)}/clone/status/${asyncUploadId}`,
      {},
    );
  }

  async pollExtractResult(projectId: string, asyncUploadId: string, onProgress: CompletePercentageFunc) {
    return pollResults({
      importId: asyncUploadId,
      maxWaitSec: 100,
      completePercentage: onProgress,
      fetcher: () => this.cloneProjectStatus(projectId, asyncUploadId),
    });
  }

  createCustomFieldDef(projectId: string, payload: ProjectCustomFieldDef) {
    return ApiAxios.post<ProjectCustomFieldDef>(`${this.getProjectsPath(projectId)}/custom_field_def`, payload).then(
      (res) => res.data,
    );
  }

  updateCustomFieldDef(projectId: string, payload: Omit<ProjectCustomFieldDef, 'fieldType'>) {
    const {internalFieldName, fieldData, fieldName} = payload;
    return ApiAxios.post<ProjectCustomFieldDef>(
      `${this.getProjectsPath(projectId)}/custom_field_def/${internalFieldName}`,
      {fieldData, fieldName},
    ).then((res) => res.data);
  }

  deleteCustomFieldDef(projectId: string, internalFieldName: string) {
    return ApiAxios.delete(`${this.getProjectsPath(projectId)}/custom_field_def/${internalFieldName}`).then(
      (res) => res.data,
    );
  }

  async postProjectFeedback(projectId: string, payload: Feedback[]) {
    const res = await ApiAxios.post<FeedbackProjectModelDTO[]>(`${this.getProjectsPath(projectId)}/feedback`, payload);
    return res.data;
  }

  async deleteProjectFeedback(projectId: string, id: string) {
    const res = await ApiAxios.delete<void>(`${this.getProjectsPath(projectId)}/feedback/${id}`);
    return res.data;
  }

  async getAllProjectsForWorker(workerId: string) {
    const res = await ApiAxios.get(`/workers/${workerId}/projects`);
    return res.data;
  }

  async getWakeCapCrews(projectId: string) {
    const res = await ApiAxios.get<WakeCapCrews[]>(`${this.getProjectsPath(projectId)}/wakecap_crews`);
    return res.data;
  }

  async getWakeCapZones(projectId: string) {
    const res = await ApiAxios.get<WakeCapZones[]>(`${this.getProjectsPath(projectId)}/wakecap_zones`);
    return res.data;
  }
}

export default new ProjectsService();
