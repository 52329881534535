import {createAction, createAsyncThunk} from '@reduxjs/toolkit';

import ProjectsApi from 'api/projects';
import {deleteNullOrEmptyFields} from 'shared/helpers/common';
import {ProjectModel} from 'shared/models/project';
import {SnapshotPayload} from 'shared/models/task/task';
import {serializeError} from 'store/utils/serializeError';

type CreateProjectPayload = {
  companyId: string;
  model: ProjectModel;
};

export const getAllProjects = createAsyncThunk('projects/getAll', async (companyId: string) => {
  return await ProjectsApi.getProjects(companyId);
});

export const createProject = createAsyncThunk(
  'projects/createProject',
  async ({companyId, model}: CreateProjectPayload) => {
    const clearedPayload = Object.assign({}, model);
    deleteNullOrEmptyFields(clearedPayload);
    return await ProjectsApi.createProject(companyId, clearedPayload);
  },
  {serializeError},
);

export const updateCurrentProject = createAction<SnapshotPayload>('projects/updateCurrentProject');

export const updateProject = createAsyncThunk(
  'projects/updateProject',
  async (model: ProjectModel) => {
    return ProjectsApi.updateProject({...model, country: model.country === null ? '' : model.country});
  },
  {serializeError},
);

export const deleteProject = createAsyncThunk(
  'projects/deleteProject',
  async (projectId: string) => {
    return ProjectsApi.deleteProject(projectId);
  },
  {serializeError},
);

export const getProject = createAsyncThunk(
  'projects/getProject',
  async (projectId: string) => {
    return await ProjectsApi.getProject(projectId);
  },
  {serializeError},
);

export const getAllProjectsForWorker = createAsyncThunk(
  'projects/getAllProjectsForWorker',
  async (workerId: string) => {
    return await ProjectsApi.getAllProjectsForWorker(workerId);
  },
);
