import {GanttStatic} from 'dhtmlx-gantt';
import {useCallback, useEffect, useState} from 'react';

export function useActiveTask(gantt: GanttStatic) {
  const [activityDetailsTask, _setActivityDetailsTask] = useState<string>(null);

  useEffect(() => {
    if (activityDetailsTask) {
      const task = gantt.isTaskExists(activityDetailsTask) && gantt.getTask(activityDetailsTask);
      if (task) {
        task.openedEditPanel = true;
        gantt.refreshTask(task.id);
        return () => {
          if (gantt.isTaskExists(task.id)) {
            task.openedEditPanel = false;
            gantt.refreshTask(task.id);
          }
        };
      }
    }
  }, [activityDetailsTask, gantt]);

  const setActivityDetailsTask = useCallback(
    (value: string) => {
      const task = gantt.isTaskExists(value) && gantt.getTask(value);
      _setActivityDetailsTask(task && !task.readonly ? value : null);
    },
    [gantt],
  );

  return [activityDetailsTask, setActivityDetailsTask] as const;
}
