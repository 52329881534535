import mixpanel from 'mixpanel-browser';
import {useCallback, useEffect, useMemo, useRef} from 'react';
import {useLocation} from 'react-router';

import {useAuth} from 'shared/components/AuthUserProvider';
import {mixpanelEvents} from 'shared/constants/mixpanelEvents';
import {useProfile} from 'shared/hooks/useProfile';
import {parser} from 'shared/utils/UAParser';

import {useCompany} from '../useCompany';

const prfx = `C4_`;

export type MixpanelServiceType = ReturnType<typeof useMixpanel>;

type EventMeta = {
  [key: string]: unknown;
};

export interface MixpanelTrackFn {
  (event: string, meta?: EventMeta): void;
}

interface MixpanelDefaultMeta {
  companyName?: string;
  companyid?: string;
  userId?: string;
  userFullName?: string;
  [key: string]: unknown;
}

interface MixpanelBufferEvent {
  event: string;
  meta: EventMeta;
}

export interface UseMixpanelParams {
  waitAuth?: boolean;
  waitCompany?: boolean;
  // short for withAuth:false, waitCompany:false
  publicPage?: boolean;
  extraMeta?: EventMeta;
}
function addTimestamp(meta: object) {
  return Object.assign(meta, {timestamp: new Date().toISOString()});
}

const defaultParams: UseMixpanelParams = {
  waitAuth: true,
  waitCompany: true,
  publicPage: false,
  extraMeta: {},
};

export const useMixpanel = ({extraMeta, waitAuth, waitCompany, publicPage}: UseMixpanelParams = defaultParams) => {
  const company = useCompany();
  const profile = useProfile();
  const {isAuthenticated} = useAuth();
  const location = useLocation();
  const defaultMeta = useRef<MixpanelDefaultMeta>({});
  const waitingAuth = !publicPage && waitAuth && (!isAuthenticated() || !profile);
  const waitingCompany = !publicPage && waitCompany && !company;
  const isLoading = waitingCompany || waitingAuth;
  const eventsBuffer = useRef<MixpanelBufferEvent[]>([]);
  const needDeferEvents = useRef(isLoading);
  const deviceInfo = parser.getResult();

  const searchParams = new URLSearchParams(location.search);
  const viewMode = searchParams.get('view');

  const track: MixpanelTrackFn = useCallback((event: string, meta: EventMeta = {}) => {
    const resultMeta = Object.assign(defaultMeta.current, meta);
    if (mixpanel.config?.token && event?.length) {
      const normalizedEvent = event.startsWith(prfx) ? event : `${prfx}${event}`;
      if (needDeferEvents.current) {
        eventsBuffer.current.push({event: normalizedEvent, meta: resultMeta});
      } else {
        mixpanel.track(normalizedEvent, addTimestamp(resultMeta));
      }
    }
  }, []);

  const trackWithAction = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      action: (...args: any[]) => any,
      event: string,
      meta: EventMeta = {},
      condition = true,
    ) => {
      if (condition) {
        track(event, Object.assign(meta, defaultMeta.current));
      }
      return action();
    },
    [track],
  );

  useEffect(() => {
    Object.assign(
      defaultMeta.current,
      !!company && {companyName: company.companyName, companyId: company.id},
      !!profile?.id && {userId: profile.id, userFullName: profile?.fullName || profile?.email},
      {deviceInfo},
      !!viewMode && {viewMode},
    );
  }, [company, deviceInfo, profile, viewMode]);

  useEffect(() => {
    Object.assign(defaultMeta.current, extraMeta);
  }, [extraMeta]);

  function precessBuffer() {
    while (eventsBuffer.current.length) {
      const {meta, event} = eventsBuffer.current.shift();
      track(event, meta);
    }
  }

  useEffect(() => {
    if (needDeferEvents.current !== isLoading) {
      needDeferEvents.current = isLoading;
      if (!isLoading && eventsBuffer.current.length) {
        precessBuffer();
      }
    }
  }, [isLoading]);

  return useMemo(() => ({track, trackWithAction, events: mixpanelEvents}), [track, trackWithAction, mixpanelEvents]);
};
