import {FormikProps} from 'formik/dist/types';
import {TFunction} from 'i18next';
import * as yup from 'yup';

import {InviteBy, WorkerFormValues} from 'shared/models/worker';

export function getValidationSchema(t: TFunction) {
  return yup.object({
    fullName: yup.string().required(t('common:validation.required', 'This field is required')),
    phoneCode: yup.string().when('inviteBy', {
      is: InviteBy.Phone,
      then: yup.string().required(t('common:validation.required')),
    }),
    phoneNumber: yup.string().when('inviteBy', {
      is: InviteBy.Phone,
      then: yup.string().required(t('common:validation.required')),
    }),
    trade: yup.string().optional(),
    sendEmail: yup.boolean().optional(),
    sendSms: yup.boolean().optional(),
    inviteBy: yup.string().optional(),
    workerEmail: yup.string().when('inviteBy', {
      is: InviteBy.Email,
      then: yup
        .string()
        .trim()
        .email(t('common:validation.email', 'Must be a valid email'))
        .required(t('common:validation.required')),
    }),
    orgs: yup
      .array(
        yup
          .string()
          .min(1, t('common:validation.min', 'Minimum {{min}} character', {min: 1}))
          .max(50, t('common:validation.max', 'Maximum {{max}} characters', {max: 50})),
      )
      .nullable(),
    roles: yup.array(yup.string()).required(),
  });
}

export function checkErrorVisibility(formik: FormikProps<WorkerFormValues>) {
  const fieldVisibleBy: {[P in keyof WorkerFormValues]?: InviteBy} = {
    workerEmail: InviteBy.Email,
    phoneNumber: InviteBy.Phone,
  };
  formik.validateForm();
  for (const [field, inviteBy] of Object.entries(fieldVisibleBy)) {
    if (formik.errors[field] && formik.values.inviteBy !== inviteBy) {
      formik.setFieldValue('inviteBy', inviteBy);
      formik.setFieldTouched(field);
      break;
    }
  }
}
