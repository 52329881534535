import {TaskModelRawDTO} from 'shared/models/task/task';

export function getIndexInParentFromOSK(tasks: TaskModelRawDTO[], osk: string) {
  function getParentSortKey(sortKey: string) {
    if (!sortKey) {
      return undefined;
    }
    return sortKey.split('.').slice(0, -1).join('.') || '0';
  }
  const parentOSK = getParentSortKey(osk);

  // Same hierarchy level, same parent
  const siblings = tasks.filter((task) => {
    if (!task.outline_sort_key) {
      return false;
    }
    const sibParentOSK = getParentSortKey(task.outline_sort_key);
    return parentOSK === sibParentOSK;
  });

  const index = siblings.findIndex((task) => task.outline_sort_key >= osk);

  return index;
}
