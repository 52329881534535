import {lazy} from 'react';
import {useTranslation} from 'react-i18next';

import GanttComponent from 'modules/Tasks/components/Gantt/GanttView';
import {configureGanttBase} from 'modules/Tasks/components/Gantt/utils/config';
import {createDataProcessor} from 'modules/Tasks/components/Gantt/utils/dataProcessors';
import {getGanttMapLayout} from 'modules/Tasks/components/Gantt/utils/layout';
import {loadActivityIds} from 'modules/Tasks/components/Gantt/utils/load';
import {configureZoomLevels} from 'modules/Tasks/components/Gantt/utils/zoomLevels';
import {TasksViewMode} from 'shared/constants/common';
import {GanttNames} from 'shared/constants/gantt';
import Gantt from 'shared/models/Gantt';

import './GanttVisual.scss';
import TaskContextMenu from '../../components/Gantt/components/TaskContextMenu/TaskContextMenu';
import SidebarPanel, {SidebarType} from '../../components/SidebarPanel/SidebarPanel';
import {GanttViewCommonProps} from '../types';

const Viewer = lazy(() => import('./DocumentViewer/DocumentViewer'));

export const GanttVisualView = function GanttVisualView({
  projectId,
  noDataElement,
  isActiveFilter,
  queryParams,
  lookaheadColors,
  hasAdminRole,
}: GanttViewCommonProps) {
  const {t} = useTranslation(['gantt', 'lookahead']);
  const gantt = Gantt.getInstance(GanttNames.ganttVisual);

  return (
    <>
      <Viewer projectId={projectId} visible={true} gantt={gantt} />
      <GanttComponent
        load={loadActivityIds}
        name={GanttNames.ganttVisual}
        viewMode={TasksViewMode.ganttVisual}
        projectId={projectId}
        noDataElement={noDataElement}
        isActiveFilter={isActiveFilter}
        queryParams={queryParams}
        lookaheadColors={lookaheadColors}
        contextMenu={TaskContextMenu}
        createDataProcessor={createDataProcessor}
        configure={({gantt, cacheHelper}) => {
          configureGanttBase({gantt, t, projectId, cacheHelper, hasAdminRole});
          configureZoomLevels(gantt, projectId);
          gantt.config.layout = getGanttMapLayout();
        }}
        dataCy="gantt-container"
        getPanelActivity={({taskId, onClose, updateActivityDetailsTask, gantt}) => (
          <SidebarPanel
            sidebarType={SidebarType.activity}
            taskId={taskId}
            onClose={onClose}
            updateActivityDetailsTask={updateActivityDetailsTask}
            gantt={gantt}
          />
        )}
      />
    </>
  );
};
