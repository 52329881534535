import {TaskLabelCategoryMap} from 'shared/constants/dailyStatus';
import {useAppConstants} from 'shared/hooks/useAppConstants';

export function useLabelCategories(): TaskLabelCategoryMap {
  const labelByCategory = useAppConstants()?.locales?.uS?.taskRiskLabelByCategory || {};

  const categoryByLabel = {};
  for (const [category, labels] of Object.entries(labelByCategory)) {
    for (const label of labels) {
      categoryByLabel[label] = category;
    }
  }
  return categoryByLabel;
}
