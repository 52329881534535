import cs from 'classnames';
import {FC, ReactNode} from 'react';

import {NavigationId} from 'modules/Home/Home';
import {OnboardingStep} from 'modules/SignUp';
import {useClassName} from 'shared/hooks/useClassName';
import {useLandingStyles} from 'shared/hooks/useLandingStyles';

import {Header} from '.';

type Props = {
  onboardingSteps?: OnboardingStep[];
  navigationList?: NavigationId[];
  currentStep?: number;
  children?: ReactNode;
  formClassName?: string;
  headerClear?: boolean;
};

export const OnboardingLayout: FC<Props> = ({
  navigationList,
  onboardingSteps,
  currentStep,
  formClassName,
  children,
  headerClear,
}) => {
  useLandingStyles();
  useClassName(document.body, 's-page', {clearInitialClasses: false});
  return (
    <>
      <Header navigationList={navigationList} clear={headerClear} />
      <div className="onboarding-z">
        <div className="box">
          <form className={cs('form-onboarding-z', formClassName)}>
            {!!onboardingSteps && (
              <ul className="onboarding-nav-z form-onboarding-z__nav">
                {onboardingSteps.map((step, index) => (
                  <li
                    className={cs('onboarding-nav-z__item', {
                      'is-active': currentStep === step.number,
                      'is-completed': currentStep > step.number,
                    })}
                    key={`name_${index}`}
                  >
                    <span className="onboarding-nav-z__number">{step.number}</span>
                    <span className="onboarding-nav-z__name">{step.name}</span>
                  </li>
                ))}
              </ul>
            )}
            {children}
          </form>
        </div>
      </div>
    </>
  );
};

export default OnboardingLayout;
