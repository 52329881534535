import {FC, ReactNode} from 'react';

type Props = {
  title: string;
  description?: string | ReactNode;
  children: ReactNode;
  footer?: ReactNode;
};

const ZetOnboardingContainer: FC<Props> = ({title, description, children, footer}) => {
  return (
    <>
      <div className="form-onboarding-z__container">
        <header className="form-onboarding-z__header">
          <h1 className="form-onboarding-z__title" dangerouslySetInnerHTML={{__html: title}} />
          {description && <div className="form-onboarding-z__description">{description}</div>}
        </header>
        <div className="form-onboarding-z__grid">{children}</div>
      </div>
      {footer}
    </>
  );
};
export default ZetOnboardingContainer;
