import env from 'shared/constants/env';
import {WeatherReport} from 'shared/models/weather';

class WeatherService {
  getWeather(city: string, state: string, days = 3): Promise<WeatherReport> {
    const WEATHER_URL = `https://api.weatherapi.com/v1/forecast.json?key=${env.weatherApiKey}&aqi=yes&alerts=yes&days=${days}`;
    const url = WEATHER_URL + `&q=${encodeURIComponent(city)},${encodeURIComponent(state)}`;
    return fetch(url).then((resp) => resp.json());
  }
}

export default new WeatherService();
