import cn from 'classnames';
import {ButtonHTMLAttributes, cloneElement, isValidElement, PropsWithChildren, ReactNode, useState} from 'react';

import Icon from 'shared/components/Icon';

import {toTitleCase} from '../../../helpers/common';

import s from './CtrlBtnOption.module.scss';

type NestedContenDirection = 'right' | 'top' | 'bottom' | 'left';
type NestedContentPlacement = NestedContenDirection | 'rightBottom' | 'rightTop' | 'leftBottom' | 'leftTop';

const ICONS_MAP: Record<NestedContentPlacement, `arrow_2_${NestedContenDirection}`> = {
  right: 'arrow_2_right',
  rightBottom: 'arrow_2_right',
  rightTop: 'arrow_2_right',
  left: 'arrow_2_left',
  leftBottom: 'arrow_2_left',
  leftTop: 'arrow_2_left',
  bottom: 'arrow_2_bottom',
  top: 'arrow_2_top',
};

interface CtrlBtnOptionProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  type?: 'submit' | 'reset' | 'button';
  title: string;
  icon?: ReactNode;
  iconRight?: ReactNode;
  countSelected?: number;
  openOnHover?: boolean;
  onClick?: () => void;
  nested?: ReactNode;
  below?: ReactNode;
  nestedContentPlacement?: NestedContentPlacement;
  size?: 'm' | 's';
  selected?: boolean;
  previewSelected?: string;
}

const CtrlBtnOption = ({
  className,
  type = 'button',
  icon,
  iconRight,
  countSelected,
  title,
  children,
  openOnHover = false,
  nested,
  below,
  size = 'm',
  nestedContentPlacement = 'right',
  selected,
  previewSelected,
  ...buttonProps
}: PropsWithChildren<CtrlBtnOptionProps>) => {
  const [showNested, setShowNested] = useState(false);
  const openNestedContent = () => {
    setShowNested(true);
  };

  const hideNestedContent = () => {
    setShowNested(false);
  };

  return (
    <div
      className={cn(s.container)}
      onMouseEnter={() => openOnHover && openNestedContent()}
      onMouseLeave={() => openOnHover && hideNestedContent()}
    >
      <button
        className={cn(
          s.ctrlBtnOption,
          s[`ctrlBtnOption_size${size.toUpperCase()}`],
          nestedContentPlacement === 'left' && s.ctrlBtnOption_sideLeft,
          selected && s.ctrlBtnOption_isSelected,
          className,
        )}
        type={type}
        {...buttonProps}
      >
        {isValidElement(icon) &&
          cloneElement(icon, {
            ...icon.props,
            className: cn(`${s.ctrlBtnOption__icon} ${s.ctrlBtnOption__icon_main}`, icon.props.className),
          })}
        <span className={s.ctrlBtnOption__text}>{title}</span>
        {previewSelected && <span className={s.ctrlBtnOption__previewSelected}>{previewSelected}</span>}
        {!!countSelected && <span className={s.ctrlBtnOption__selected}>{countSelected}</span>}
        {isValidElement(iconRight) &&
          cloneElement(iconRight, {
            ...iconRight.props,
            className: cn(`${s.ctrlBtnOption__icon} ${s.ctrlBtnOption__icon_minor}`, iconRight.props.className),
          })}
        {!!nested && (
          <Icon
            className={`${s.ctrlBtnOption__icon} ${s.ctrlBtnOption__icon_more}`}
            name={ICONS_MAP[nestedContentPlacement]}
            colorFill
          />
        )}
        {selected && (
          <Icon className={`${s.ctrlBtnOption__icon} ${s.ctrlBtnOption__icon_more}`} name="check" colorFill />
        )}
      </button>
      {children && (
        <div className={cn(s.ctrlBtnOption__preview, buttonProps.disabled && s.ctrlBtnOption__preview_isDisabled)}>
          {children}
        </div>
      )}
      {showNested && nested && (
        <div
          className={cn(
            s.nestedContent,
            showNested && s.nestedContent__isActive,
            s[`nestedContent__isActive--position${toTitleCase(nestedContentPlacement, true)}`],
          )}
        >
          {nested}
        </div>
      )}
    </div>
  );
};

export default CtrlBtnOption;
