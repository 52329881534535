import {ComponentProps, Ref, useCallback, useMemo, useState} from 'react';
import {TFunction} from 'react-i18next';
import Select, {Props} from 'react-select';

import {defaultReactSelectStyles} from 'shared/components/CoreForm/Select/styles';
import {filterDuplicatedCompanyWorkers} from 'shared/helpers/worker';
import {useProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {CompanyWorker} from 'shared/models/worker';

import CoreSelectField from '../Select';

import {CustomMenuList, CustomOption, CustomOptionForMulti, CustomSingleValue} from './components';
import css from './index.module.scss';

export type ProjectWorkerSelectOption = {
  label: string;
  value: CompanyWorker;
};

type AsyncProjectWorkerSelectProps = {
  onSelectChange?: (value: CompanyWorker[]) => void;
  placeholder: string;
  loadingPlaceholder: string;
  projectId: string;
  taskId?: string;
  t?: TFunction;
  withInviteButton?: boolean;
  value?: ProjectWorkerSelectOption[]; // CompanyWorker[];
  isCreatable?: boolean;
} & Omit<ComponentProps<typeof CoreSelectField>, 'value'>;

const AsyncProjectWorkerSelect = <IsMulti extends boolean = true>({
  onSelectChange,
  placeholder,
  loadingPlaceholder,
  value,
  projectId,
  selectRef,
  withInviteButton = false,
  isCreatable = false,
  ...props
}: AsyncProjectWorkerSelectProps &
  Omit<Props<ProjectWorkerSelectOption, IsMulti>, 'value'> & {selectRef?: Ref<Select>}) => {
  const [selected, setSelected] = useState<CompanyWorker | CompanyWorker[]>(null);
  const {projectWorkers, isLoading} = useProjectWorkers(projectId);

  const options = useMemo(() => {
    if (projectWorkers) {
      return filterDuplicatedCompanyWorkers(projectWorkers).map((cworker) => {
        return {
          label: cworker.workerFull.fullName || cworker.workerFull.email || cworker.workerFull.mobileNumber,
          value: cworker,
        } as ProjectWorkerSelectOption;
      });
    }
    return [];
  }, [projectWorkers]);

  const onChange = (selected: CompanyWorker | CompanyWorker[]) => {
    setSelected(selected);
    onSelectChange(Array.isArray(selected) ? selected : [selected]);
  };

  const getCustomComponents = useCallback(() => {
    const components = {
      Option: props.isMulti ? CustomOptionForMulti : CustomOption,
      SingleValue: CustomSingleValue,
    };
    if (withInviteButton) {
      Object.assign(components, {MenuList: CustomMenuList});
    }
    return components;
  }, [props.isMulti, withInviteButton]);

  const SelectType = isCreatable ? CoreSelectField.Creatable : CoreSelectField;

  return (
    <SelectType
      ref={selectRef as any}
      defaultInputValue=""
      isSearchable={true}
      isDisabled={isLoading}
      isLoading={isLoading}
      menuPlacement="auto"
      options={options}
      placeholder={isLoading ? loadingPlaceholder : placeholder}
      className={`react-select override-menu-height ${css['task-responsible-select']}`}
      classNamePrefix="react-select"
      onChange={onChange}
      styles={defaultReactSelectStyles}
      value={value || selected}
      components={getCustomComponents()}
      {...props}
    />
  );
};

export default AsyncProjectWorkerSelect;
