import {TFunction} from 'i18next';
import {CSSProperties, FC} from 'react';

import Button from '../Button';
import Icon from '../Icon';

type EmptyGridBubble = {
  text: string;
  cardText?: string;
  t: TFunction;
  buttonText?: string;
  buttonAction?: () => void;
  style?: CSSProperties;
};

const EmptyGridBubble: FC<EmptyGridBubble> = ({buttonAction, buttonText, text, cardText, style, t}) => {
  return (
    <section className="empty" style={style}>
      <div className="empty__container">
        <div className="empty__content">
          {!!cardText && (
            <section className="task-card task-card--view-glass empty__card">
              <div className="task-card__header">
                <Icon name="parenttask" colorFill className="task-card__icon" size={24} />
                <div className="task-card__id">{t('workers:empty.card_id', 'C4-001')}</div>
                <div className="status-element status-element--assigned task-card__status">
                  <span className="status-element__name">{t('workers:empty.assigned_status', 'Assigned')}</span>
                </div>
              </div>
              <h2 className="task-card__name">{cardText}</h2>
            </section>
          )}
          <h2 className="empty__title">{text}</h2>
          {!!(buttonText && buttonAction) ? (
            <div className="empty__actions">
              <Button
                className="empty__button"
                onClick={buttonAction}
                icon={<Icon name="add-circle-outlined" colorFill className="ctrl-btn__icon" size={24} />}
              >
                {buttonText}
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </section>
  );
};
export default EmptyGridBubble;
