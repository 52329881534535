import {GanttStatic} from 'dhtmlx-gantt';

export const OSK_SEGMENT_LENGTH = 10;

export enum TasksBulkTypes {
  responsible = 'responsible',
  watcher = 'watcher',
  subcontractor = 'subcontractor',
  type = 'type',
  location = 'location',
  color = 'color',
  delete = 'delete',
  status = 'status',
}

export enum IssuesBulkTypes {
  responsible = 'responsible',
  status = 'status',
}

export type BulkProps = {
  onClose: () => void;
  gantt: GanttStatic;
  projectId: string;
  className?: string;
};
