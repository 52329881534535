import {GanttStatic} from 'dhtmlx-gantt';
import {decamelizeKeys} from 'humps';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useParams} from 'react-router';
import {toast} from 'react-toastify';

import {SubcontractorFormValues} from 'modules/Subcontractors/utils/utils';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {CompanyOrgs} from 'shared/models/company';
import Gantt from 'shared/models/Gantt';

import {useCreateSubcontractor, useUpdateSubcontractor} from './mutations';

const updateSubcontractors = (gantt: GanttStatic, orgs: CompanyOrgs) => {
  gantt.batchUpdate(() => {
    gantt.eachTask((task: GanttTask) => {
      if (task.responsible_org?.id === orgs.id) {
        task.responsible_org = decamelizeKeys(orgs) as CompanyOrgs;
        gantt.refreshTask(task.id);
      }
    });
  });
};

const prepareValuesForUpdate = (values: SubcontractorFormValues) => {
  return {
    abbrev: values.abbrev,
    lookaheadColor: values.lookaheadColor,
    group: {
      name: values.name,
    },
    contactWorkerId: values.contactWorkerId,
  };
};

export const useHandleSubcontractors = (isNewSubcontractor: boolean) => {
  const history = useHistory();
  const routes = useLocalizedRoutes();
  const {projectId} = useParams<{projectId: string}>();
  const {t} = useTranslation('gantt');
  const ganttList = Gantt.list();

  const inviteSubcontractor = useCreateSubcontractor();
  const updateSubcontractor = useUpdateSubcontractor();

  return async (values: SubcontractorFormValues) => {
    if (!isNewSubcontractor) {
      try {
        const updatedSubcontractor = await updateSubcontractor.mutateAsync(prepareValuesForUpdate(values));
        ganttList.map((inst) => updateSubcontractors(inst, updatedSubcontractor));
        toast.success(t('toast.success.subcontractor_update', 'Company updated.'));
        return updatedSubcontractor;
      } catch (e) {
        if (isAxiosError(e)) {
          toast.error(extractAxiosError(e));
        }
      }
    } else {
      try {
        if (!values.abbrev) delete values.abbrev;
        if (!values.lookaheadColor) delete values.lookaheadColor;

        const invitedSubcontractor = await inviteSubcontractor.mutateAsync(values);
        toast.success(t('toast.success.subcontractor_create', 'Company created.'));
        history.push(generatePath(routes.subcontractor, {id: invitedSubcontractor.id, projectId}));
        return invitedSubcontractor;
      } catch (e) {
        if (isAxiosError(e)) {
          toast.error(extractAxiosError(e));
        }
      }
    }
  };
};
