import {cloneElement, FC, HTMLAttributes, ReactNode} from 'react';

type Props = {
  description: ReactNode;
};

const Description: FC<Props> = ({description}: Props) => {
  const element = <div className="confirmation__description"></div>;
  return typeof description == 'string'
    ? cloneElement<HTMLAttributes<HTMLDivElement>>(element, {
        dangerouslySetInnerHTML: {__html: description},
      })
    : cloneElement(element, null, description);
};
export default Description;
