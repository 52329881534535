import {RefObject, useState, useEffect} from 'react';

export function useCalculatePosition(ref: RefObject<HTMLElement>, isVisible: boolean, offset: number): number {
  const [position, setPosition] = useState<number>(0);

  // The ref and isVisible values are dependencies of our useEffect hook
  useEffect(() => {
    if (ref.current && isVisible) {
      // Get the box metrics relative to the viewport
      const rect = ref.current.getBoundingClientRect();

      // We add window.scrollY to take into account the scrolling
      const calculatedPosition = rect.top + window.scrollY - offset;
      setPosition(calculatedPosition);
    }
  }, [ref, isVisible, offset]);

  return position;
}
