import {useEffect} from 'react';

export function useKey({condition, key, actionFn}: {condition: boolean; key: string; actionFn: () => void}) {
  useEffect(() => {
    if (condition) {
      const executeActionOnKey = (event: {key: string}) => {
        if (event.key === key) {
          actionFn();
        }
      };

      window.addEventListener('keyup', executeActionOnKey);

      return () => {
        window.removeEventListener('keyup', executeActionOnKey);
      };
    }
  }, [condition, key, actionFn]);
}
