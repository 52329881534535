import {object, ref, string} from 'yup';
import * as Yup from 'yup';

export type InviteSignUpValues = {
  emailAddress: string;
  password: string;
  repeatPassword: string;
  countryCode: string;
  phoneNumber: string;
  verificationCode: string;
};

export const emailValidationSchema = object({
  emailAddress: string().email('Must be a valid email address').required('This field is required'),
  password: string()
    .min(6, 'Must be at least 6 characters long')
    .max(255, 'Must not exceed 255 characters long')
    .required('This field is required'),
  repeatPassword: string()
    .required('This field is required')
    .oneOf([ref('password'), ''], 'Passwords must match'),
});

export const phoneValidationSchema = object({
  verificationCode: Yup.string().required('This field is required'),
  phoneNumber: Yup.string().required('This field is required'),
});

export function getInitialValues() {
  return {
    countryCode: '+1',
    phoneNumber: '',
    verificationCode: '',
    emailAddress: '',
    password: '',
    repeatPassword: '',
  } as InviteSignUpValues;
}
