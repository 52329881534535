import {logoutUser} from 'store/actions';

import {createReducer} from '../../utils/createReducer';

import * as types from './types';

const initialState = {
  isLoggedIn: false,
  loginInProgress: false,
  loginError: null,
  isRequestingPhoneCode: false,
};

export default createReducer<types.AuthStore, types.LoginActions>(initialState, {
  [types.LOGIN_SUCCESS](state) {
    return {
      ...state,
      isLoggedIn: true,
      loginError: null,
      loginInProgress: false,
    };
  },
  [types.LOGIN_FAILURE](state, action) {
    return {
      ...state,
      isLoggedIn: false,
      loginError: action.payload,
      loginInProgress: false,
    };
  },
  [types.LOGIN_INPROGRESS](state) {
    return {
      ...state,
      isLoggedIn: false,
      loginInProgress: true,
      loginError: null,
    };
  },
  [logoutUser.type](state) {
    return {
      ...state,
      loginError: null,
      isLoggedIn: false,
      loginInProgress: false,
    };
  },
  [types.REQUESTING_PHONE_CODE_INPROGRESS](state) {
    return {...state, isRequestingPhoneCode: true};
  },
  [types.REQUESTING_PHONE_CODE_SUCCESS](state) {
    return {...state, isRequestingPhoneCode: false};
  },
  [types.REQUESTING_PHONE_CODE_FAILED](state) {
    return {...state, isRequestingPhoneCode: false};
  },
});
