import {useEffect} from 'react';

const ACTIVE_CLASS_NAME = 'popup-is-active';
export function usePopupScrollHandler(scrollableSelector = '.screen') {
  useEffect(() => {
    const scrolled = window.scrollY;
    const screen = document.querySelector<HTMLDivElement>(scrollableSelector);
    if (screen && !screen.classList.contains(ACTIVE_CLASS_NAME)) {
      screen.style.position = 'fixed';
      screen.style.top = `-${scrolled}px`;
      screen.style.width = '100%';
      screen.classList.add(ACTIVE_CLASS_NAME);
      return () => {
        if (screen) {
          screen.style.position = '';
          screen.style.top = '';
          screen.style.width = '';
          screen.classList.remove(ACTIVE_CLASS_NAME);
          if (scrolled) {
            window.scrollTo({top: scrolled});
          }
        }
      };
    }
  }, []);
}
