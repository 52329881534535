import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import box from '../Box/Box.module.scss';
import Slider from '../Slider/Slider';

import s from './Reviews.module.scss';

type ReviewsProps = {
  className?: string;
};

const Reviews = ({className}: ReviewsProps) => {
  const {t} = useTranslation(['landing2']);

  return (
    <section className={cn(s.reviews, className)}>
      <div className={box.box}>
        <div className={s.reviews__container}>
          <h2 className={s.reviews__title}>{t('landing2:reviews.title', 'Trusted by 2000+ companies')}</h2>
          <div className={s.reviews__body}>
            <Slider />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
