import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {getPrettyTaskStatuses} from 'shared/constants/statuses';
import {safeFormatDate} from 'shared/helpers/dates';
import {useToggle} from 'shared/hooks/core/useToggle';
import {TaskHistoryType} from 'shared/models/task/task';

import TaskStatusIcon from '../TaskStatusIcon';

type Props = {
  history: TaskHistoryType[];
};

const COUNT_DISPLAYING_ITEMS = 3;

const TaskStatusHistory: FC<Props> = ({history}: Props) => {
  const {isToggled, toggle} = useToggle();
  const {t} = useTranslation(['lookahead', 'gantt']);
  const statuses = useMemo(() => getPrettyTaskStatuses(t), []);
  return (
    <div className="app-task-history">
      <div className="app-task-history__list">
        {history.slice(0, isToggled ? history.length : COUNT_DISPLAYING_ITEMS).map((item, idx) => (
          <div className="app-task-history__item" key={idx}>
            <div className="app-task-history__item-header">
              <time className="app-task-history__item-date" dateTime="">
                {safeFormatDate(item.timeUpdated, 'll')}
              </time>
              <div className="app-task-history__item-user">{item.isSharedLogin ? item.guestName : item.workerName}</div>
            </div>
            <div className="app-task-history__item-body">
              <div className="app-task-history__item-status">{statuses[item.status]}</div>
            </div>
            <TaskStatusIcon colorFill className="app-task-history__item-icon" size={24} name={item.status} />
          </div>
        ))}
      </div>
      {history.length > COUNT_DISPLAYING_ITEMS && (
        <button className="ctrl-btn-more-app app-task-history__button-more" type="button" onClick={() => toggle()}>
          {isToggled
            ? t('lookahead:activity_details.buttons.show_less', 'Show less')
            : t('lookahead:activity_details.buttons.show_more', 'Show more')}
        </button>
      )}
    </div>
  );
};
export default TaskStatusHistory;
