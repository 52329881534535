import {CheckoutSessionRequestPayload, ProSubscription} from 'shared/models/checkout';

import ApiAxios from './axios';

export default {
  createCheckoutSession(companyId: string, payload: CheckoutSessionRequestPayload) {
    return ApiAxios.post<string>(`/companies/${companyId}/checkoutsession`, payload).then((res) => res.data);
  },
  generatePortalUrl(companyId: string, subscriptionId: string, returnUrl: string) {
    return ApiAxios.get<string>(
      `/companies/${companyId}/coresubscriptions/${subscriptionId}/portal_session?return_url=${returnUrl}`,
    ).then((res) => res.data);
  },
  completeCheckoutSession(sessionId: string) {
    return ApiAxios.post<ProSubscription>(`/checkoutsession/${sessionId}`).then((res) => res.data);
  },
  getSubscriptions(companyId: string) {
    return ApiAxios.get<ProSubscription[]>(`/companies/${companyId}/coresubscriptions`).then((res) => res.data);
  },
  changeSubscriptionPlan(companyId: string, subscriptionId: string, priceId: string, quantity: number) {
    return ApiAxios.post<ProSubscription[]>(
      `/companies/${companyId}/coresubscriptions/${subscriptionId}/changeplan?price_id=${priceId}&quantity=${quantity}`,
    ).then((res) => res.data);
  },
  getProPlan() {
    return ApiAxios.get(`/proplans`).then((res) => res.data[0]);
  },
  cancelSubscription(companyId: string, subscriptionId: string, reason = '') {
    return ApiAxios.delete(`/companies/${companyId}/coresubscriptions/${subscriptionId}?note=${encodeURI(reason)}`);
  },
};
