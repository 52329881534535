import {RefObject, useCallback, useEffect} from 'react';

import {debounce} from 'shared/helpers/debounce';
import {useIsTablet} from 'shared/hooks/useIsTablet';

export function useGanttTouchScrollListener(
  containerRef: RefObject<HTMLElement>,
  setIsScrolling: (isScrolling: boolean) => void,
) {
  const isTablet = useIsTablet();
  const registerGanttScrollListener = useCallback(() => {
    let xDown: number | null = null;
    let yDown: number | null = null;

    function handleTouchStart(evt: TouchEvent) {
      xDown = evt.touches[0].clientX;
      yDown = evt.touches[0].clientY;
    }

    function handleTouchMove(evt: TouchEvent) {
      if (!xDown || !yDown) {
        return;
      }

      const xUp = evt.touches[0].clientX;
      const yUp = evt.touches[0].clientY;

      const xDiff = xDown - xUp;
      const yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        // Horizontal swipe detection
        // No action needed for horizontal swipes
      } else {
        setIsScrolling(true);
      }
      xDown = null;
      yDown = null;
      setIsScrolling(false);
    }

    function handleTouchEnd() {
      setIsScrolling(false);
    }

    const debouncedTouchStart = debounce(handleTouchStart, 250);
    const debouncedTouchMove = debounce(handleTouchMove, 250);
    const options: AddEventListenerOptions = {passive: true};

    if (containerRef.current) {
      containerRef.current.addEventListener('touchstart', debouncedTouchStart, options);
      containerRef.current.addEventListener('touchmove', debouncedTouchMove, options);
      containerRef.current.addEventListener('touchend', handleTouchEnd, options);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('touchstart', debouncedTouchStart);
        containerRef.current.removeEventListener('touchmove', debouncedTouchMove);
      }
    };
  }, [containerRef, setIsScrolling]);

  useEffect(() => {
    if (isTablet) {
      const detachScrollListener = registerGanttScrollListener();

      return () => {
        detachScrollListener();
      };
    }
  }, [isTablet, registerGanttScrollListener]);
}
