import {TFunction} from 'react-i18next';
import {date, mixed, number, object, ref, string} from 'yup';

function parseNumber(value: string, fallback: number) {
  const parsed = parseFloat(String(value));
  return isNaN(parsed) ? fallback : parsed;
}

export const validationSchema = (t: TFunction) =>
  object().shape(
    {
      name: string()
        .max(200, t('task:validation.common.max_length', {length: 200}))
        .required(t('task:validation.common.required'))
        .test('onlySpaces', t('task:validation.common.required'), (value) => value?.replaceAll(' ', '').length !== 0),
      projectId: string().required(t('task:validation.common.required')),
      startDate: date().required(t('task:validation.common.required')).nullable(),
      endDate: date()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .test('end_date_after_start_date', t('task:validation.end_date'), (value, ctx) => {
          if (!(value instanceof Date)) {
            return true;
          }
          return value >= ctx.parent.startDate;
        }),
      description: string().max(2000, t('task:validation.common.max_length', {length: 2000})),
      location: string().max(200, t('task:validation.common.max_length', {length: 200})),
      responsibleParty: string()
        .max(200, t('task:validation.common.max_length', {length: 200}))
        .nullable(),
      status: string(),
      completionUnit: string().nullable(true),
      completionTarget: mixed()
        .nullable(true)
        .when('completionUnit', {
          is: (completionUnit) => completionUnit === '%',
          then: number()
            .transform((v) => parseNumber(v, 100))
            .typeError(t('task:validation.common.number'))
            .max(100, t('task:validation.completion_target.length')),
          otherwise: number()
            .transform((v) => parseNumber(v, 0))
            .typeError(t('task:validation.common.number'))
            .max(100000000, t('task:validation.completion_target.max')),
        }),
      completionAmount: number()
        .transform((v) => parseNumber(v, 0))
        .max(ref('completionTarget'), t('task:validation.completion_amount.out_of_range'))
        .typeError(t('task:validation.common.number'))
        .nullable(true),
      type: string()
        .max(100, t('task:validation.common.max_length', {length: 100}))
        .nullable(),
      duration: string()
        .nullable()
        .when('endDate', {
          is: (value) => !value,
          then: (schema) => schema.required('This field is required'),
        }),
      actualStart: date()
        .nullable()
        .when('actualEnd', {
          is: (value) => !!value,
          then: (schema) => schema.required(t('task:validation.actual_start')),
        }),
      projectedLabor: string()
        .nullable()
        .max(100, t('task:validation.common.max_length', {length: 100})),
      actualLabor: string()
        .nullable()
        .max(100, t('task:validation.common.max_length', {length: 100})),
      csiCode: string()
        .nullable()
        .max(12, t('task:validation.common.max_length', {length: 12})),
      costCode: string()
        .nullable()
        .max(12, t('task:validation.common.max_length', {length: 12})),
      phaseCode: string()
        .nullable()
        .max(12, t('task:validation.common.max_length', {length: 12})),
      customCode: string()
        .nullable()
        .max(12, t('task:validation.common.max_length', {length: 12})),
      estLaborHours: number()
        .nullable()
        .transform((v) => parseNumber(v, 0))
        .typeError(t('task:validation.common.number')),
    },
    [['completionTarget', 'completionUnit']],
  );
