import {FC, useCallback, useRef} from 'react';
import {toast} from 'react-toastify';

import SubscriptionApi from 'api/subscription';
import Popup from 'shared/components/Popup';
import env from 'shared/constants/env';
import {useMount} from 'shared/hooks/core/useMount';
import {useUnmount} from 'shared/hooks/core/useUnmount';

import {useSubscriptionContext} from '../CheckoutContext';
import {subscriptionActionsCreators, SubscriptionStep} from '../state';
import {SubscriptionEventTypes} from '../utils/constants';
import {CheckoutNavigationProps, CheckoutRedirectEvent} from '../utils/types';

type CheckoutStripeProps = CheckoutNavigationProps;

const isCheckoutRedirectEvent = (e: MessageEvent): e is MessageEvent<CheckoutRedirectEvent> => {
  return e.data && e.data.type === SubscriptionEventTypes.checkoutRedirect;
};

const CheckoutStripe: FC<CheckoutStripeProps> = ({close}) => {
  const {state, dispatch, setCheckoutPopup} = useSubscriptionContext();
  const closeCheckTimer = useRef<number>();
  const checkoutPopupRef = useRef<Window | null>();

  function createCheckoutPopupWindow() {
    const params = new URLSearchParams();
    params.set('sessionId', state.session);
    params.set('pbKey', env.stripePublishableKey);

    return window.open(`${window.origin}/checkout.html?${params.toString()}`, 'StripeCheckout', `width=800,height=600`);
  }

  const clear = useCallback((listener: (e: MessageEvent) => void) => {
    if (checkoutPopupRef.current) {
      if (closeCheckTimer.current) {
        clearInterval(closeCheckTimer.current);
        closeCheckTimer.current = null;
      }
      window.removeEventListener('message', listener);
      checkoutPopupRef.current.close();
      setCheckoutPopup((checkoutPopupRef.current = null));
    }
  }, []);

  const onMessage = useCallback(
    async (e: MessageEvent<unknown>) => {
      if (isCheckoutRedirectEvent(e) && e.source === checkoutPopupRef.current) {
        if ((e.data as CheckoutRedirectEvent).result === '1') {
          const sub = await SubscriptionApi.completeCheckoutSession(state.session);
          dispatch(subscriptionActionsCreators.setSubscription(sub));
          dispatch(subscriptionActionsCreators.setStep(SubscriptionStep.finished));
          clear(onMessage);
        } else if (e.data.result === '0') {
          clear(onMessage);
          close();
        }
      }
    },
    [dispatch, clear],
  );

  const startCheckoutProcess = () => {
    const checkoutPopup = (checkoutPopupRef.current = createCheckoutPopupWindow());
    setCheckoutPopup(checkoutPopup);
    try {
      checkoutPopup.focus();
    } catch (error) {
      toast.warning('Please allow pop-up window on this page to continue checkout');
    }

    closeCheckTimer.current = window.setInterval(() => {
      if (checkoutPopup.closed) {
        clear(onMessage);
        close();
      }
    }, 100);

    window.addEventListener('message', onMessage);
  };

  useMount(startCheckoutProcess);
  useUnmount(() => clear(onMessage));

  return (
    <>
      <Popup.Body>
        <div className="form-plan form-plan--payment">
          <div className="form-plan__payment">
            <picture className="picture form-plan__logo-stripe">
              <img className="picture__image" src="/images/logo/logo-stripe.svg" alt="Stripe" />
            </picture>
          </div>
        </div>
      </Popup.Body>
    </>
  );
};
export default CheckoutStripe;
