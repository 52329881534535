import cn from 'classnames';
import {CSSProperties, ReactNode, Ref, RefCallback, useEffect, useRef, UIEvent, createElement} from 'react';
import {HeaderGroup, Row, SortingRule, TableOptions, useResizeColumns, useSortBy, useTable} from 'react-table';
import {Virtuoso, VirtuosoHandle} from 'react-virtuoso';

import {useCustomFlexLayout} from 'shared/components/Table/hooks/useCustomFlexLayout';

import {TableRow, TableRowProps} from './components/Columns/TableRow';
import {TableSortIndicator} from './components/TableSortIndicator';
import s from './Table.module.scss';

interface CoreTableProps<D extends Record<string, unknown>> extends TableOptions<D> {
  onRowClick?: (row: Row<D>) => void;
  next?: () => void;
  onSort?: (type: SortingRule<D>[]) => void;
  cellRenderProps?: Record<string, unknown>;
  cellClassName?: string;
  hasMore?: boolean;
  isLoading?: boolean;
  skeletonLoader?: ReactNode;
  scrollableTarget?: ReactNode;
  tableClassName?: string;
  queryKey?: string;
  fetchFinished?: boolean;
  bodyStyle?: CSSProperties;
  fixedRowHeight?: number;
  headerHeight?: number;
  onScroll?: (e: Event) => void;
  vListRef?: Ref<VirtuosoHandle>;
  scrollerRef?: RefCallback<HTMLElement>;
  isScrolling?: (isScrolling: boolean) => void;
  scrollerClassName?: string;
  headerClassName?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
type HeaderGroupType<D extends {}> = HeaderGroup<D> & {title?: string};

const Table = <D extends Record<string, unknown>>({
  data,
  onRowClick,
  next,
  hasMore,
  isLoading = true,
  itemsRendered,
  skeletonLoader,
  cellRenderProps,
  cellClassName,
  scrollableTarget,
  onSort,
  tableClassName,
  fetchFinished,
  disableSortBy,
  row: rowComponent,
  fixedRowHeight,
  vListRef,
  scrollerRef,
  isScrolling,
  scrollerClassName,
  headerClassName,
  ...restProps
}: CoreTableProps<D>) => {
  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    getTableBodyProps,
    state: {sortBy},
  } = useTable<D>(
    {
      data,
      ...restProps,
      manualSortBy: true,
      disableSortBy,
      fixedRowHeight,
    },
    useSortBy,
    useCustomFlexLayout,
    useResizeColumns,
  );

  const header = useRef<HTMLDivElement>();

  useEffect(() => {
    typeof onSort === 'function' && onSort(sortBy);
  }, [sortBy]);

  const showSkeleton = isLoading && !data?.length;

  const loadMore = () => {
    if (hasMore) next();
  };

  const onScroll = (e: UIEvent<'div'>) => {
    if (e.target instanceof HTMLDivElement) {
      header.current.style.transform = `translateX(-${e.target.scrollLeft}px)`;
    }
  };

  return (
    <div className={cn(s.tableWorkers, tableClassName)} {...getTableProps()}>
      <div className={cn(s.tableWorkers__table, headerClassName)}>
        {headerGroups.map((headerGroup, i) => {
          return (
            <div
              key={i}
              className={cn(s.tableWorkers__line, s.tableWorkers__line_header)}
              ref={header}
              {...headerGroup.getHeaderGroupProps({style: {flexGrow: 0}})}
            >
              {headerGroup.headers.map((column: HeaderGroupType<D>, index) => (
                <div
                  key={index}
                  className={`${s.tableWorkers__cell} ${s.tableWorkers__cell_th}`}
                  {...column.getHeaderProps()}
                >
                  {column.canResize && <div className={s.tableWorkers__thResizer} {...column.getResizerProps()}></div>}
                  <span
                    {...column.getSortByToggleProps({
                      title: column.disableSortBy
                        ? undefined
                        : `Sort by ${typeof column.Header === 'string' ? column.Header : column.title}`,
                    })}
                  >
                    {column.render('Header')}
                  </span>
                  {column.isSorted && !disableSortBy && <TableSortIndicator column={column} />}
                </div>
              ))}
            </div>
          );
        })}
        {showSkeleton ? (
          skeletonLoader
        ) : (
          <Virtuoso
            ref={vListRef}
            {...getTableBodyProps()}
            className={scrollerClassName}
            data={rows}
            endReached={loadMore}
            fixedItemHeight={fixedRowHeight}
            isScrolling={isScrolling}
            itemContent={(index, row) => {
              prepareRow(row);
              return createElement<Partial<TableRowProps<D>>>(rowComponent || TableRow, {
                onClick: () => onRowClick(row),
                row,
                cellRenderProps,
                cellClassName,
                key: row.id,
              });
            }}
            onScroll={onScroll}
            components={{
              Footer() {
                return hasMore ? <h3 style={{textAlign: 'center'}}>Loading...</h3> : null;
              },
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Table;
