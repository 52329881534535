export enum TaskImportStep {
  SelectSource,
  Mapping,
  Confirm,
}

export enum TasksImportSourceType {
  file = 'file',
  proCore = 'proCore',
}

export const ProCoreErrors = {
  abortedAuthorization: 'The user has aborted authorization.',
};

export const statusSuffix = {
  created: 'new',
  updated: 'edit',
  failed: 'error',
  unchanged: 'unchanged',
};

export const stylesMapping = {
  uniqueId: 'id',
  name: 'name',
  schedStartDate: 'start',
  schedEndDate: 'due',
  subcontractor: 'sub',
  assignedNames: 'assigned',
  location: 'location',
  description: 'description',
  outlineCode: 'wbs',
  type: 'type',
  cost: 'cost',
};
