import * as Sentry from '@sentry/browser';
import {useEffect} from 'react';

import {useAuth} from 'shared/components/AuthUserProvider';

import {Worker} from '../models/worker';

export function useSentryUserInfo(worker: Worker) {
  const {isAuthenticated, user} = useAuth();
  useEffect(() => {
    if (worker && user && !user.isAnonymous) {
      Sentry.setUser({
        id: worker.id,
        email: worker?.email,
        phone: worker?.mobileNumber,
        fb_uid: user.uid,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [isAuthenticated, user, worker]);
}
