export function safeParseFromLocalStorage<ReturnV>(key: string): ReturnV | null {
  try {
    const value = JSON.parse(localStorage.getItem(key));
    if (value != null) {
      return value as ReturnV;
    }
    return null;
  } catch (e) {
    console.warn(`Invalid data in localStorage[${key}]`);
    return null;
  }
}
