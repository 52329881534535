import {useEffect} from 'react';

import env from 'shared/constants/env';

export function useZoomInfo() {
  useEffect(() => {
    if (env.social.zoominfoId) {
      const script = document.createElement('script');
      const handler = `
			(function () {
				var zi = document.createElement('script');
				zi.type = 'text/javascript';
				zi.async = true;
				zi.referrerPolicy = 'unsafe-url';
				zi.src = 'https://ws.zoominfo.com/pixel/${env.social.zoominfoId}';
				var s = document.getElementsByTagName('script')[0];
				s.parentNode.insertBefore(zi, s);
			})();
			`;
      const noscript = document.createElement('noscript');
      const img = `<img src='https://ws.zoominfo.com/pixel/${env.social.zoominfoId}' width="1" height="1" style="display: none;" alt="websights" />`;
      script.append(handler);
      document.head.append(script);
      noscript.append(img);
      document.body.append(noscript);

      return () => {
        script.remove();
        noscript.remove();
      };
    }
  }, []);
}
