import classNames from 'classnames';
import {CSSProperties, FC} from 'react';

interface Props {
  style?: CSSProperties;
  className?: string;
}

const Loader: FC<Props> = ({style, className, children}) => {
  return (
    <div className={classNames('loader', className)} style={style}>
      <div className="loader__figure">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      {children}
    </div>
  );
};
export default Loader;
