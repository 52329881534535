import * as Sentry from '@sentry/react';
import {GanttStatic} from 'dhtmlx-gantt';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {GANTT_PREFERENCES_KEY, TasksViewMode} from 'shared/constants/common';
import {toShortIso} from 'shared/helpers/dates';
import {getTaskChangedFieldsOnly} from 'shared/helpers/task';
import Gantt from 'shared/models/Gantt';
import {TaskObjectType} from 'shared/models/task/const';
import {TaskColorMode, IssueStatusTaskIDsPair} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import {getLaborColorForDate, getTaskColorByStatus} from '../../Dashboard/meta';

export function getTaskFeedback(task: GanttTask, specificDate: string, field: 'daily_labor' | 'progress'): string {
  const formattedDate = toShortIso(specificDate);

  if (task.feedback_by_date) {
    const feedbackEntry = task.feedback_by_date.find((entry) => entry.field === field);

    if (feedbackEntry) {
      const feedbackItem = feedbackEntry.feedback_by_date.find((item) => item.date === formattedDate);

      if (feedbackItem) {
        return feedbackItem.value?.toString();
      }
    }
  }

  return '-';
}

export function getFormattedTaskFeedback(task: GanttTask, date: string, field: 'daily_labor' | 'progress'): string {
  const feedback = getTaskFeedback(task, date, field);
  return feedback !== '-' ? `${feedback}` : '-';
}

export function getColorTasksModeFromLocalStorage(projectId: string): string {
  const storageKey = GANTT_PREFERENCES_KEY;
  const storedPreferences = localStorage.getItem(storageKey);

  if (storedPreferences) {
    try {
      const preferences = JSON.parse(storedPreferences);

      const projectPreferences = preferences?.byProject?.[projectId];

      if (projectPreferences && 'colorTasksMode' in projectPreferences) {
        return projectPreferences.colorTasksMode;
      } else {
        return 'default';
      }
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          name: 'getColorTasksModeFromLocalStorage',
        },
      });
    }
  }
  return 'default';
}

export const calculateRowHeight = (rowHeight) => Math.round((rowHeight * 0.92 + Number.EPSILON) * 10) / 10;

export function refreshTask(gantt: GanttStatic, id: string | number, updates: Partial<GanttTask>) {
  const task: GanttTask = gantt.getTask(id);
  const inlineEditors = gantt.ext.inlineEditors;
  if (task) {
    // don't overwrite task fields which is in queue to update
    if (task.lastChangedFields) {
      for (const field in task.lastChangedFields) {
        if (field in updates) {
          delete updates[field];
        }
      }
    }
    Object.assign(task, updates);
    gantt.refreshTask(id);
    if (inlineEditors) {
      const editorState = inlineEditors.getState();
      const columnName = editorState.columnName;
      const changedFields = Object.keys(getTaskChangedFieldsOnly(task, updates, ['id', 'projectId']) || []);
      if (gantt.ext.inlineEditors.isVisible() && changedFields.includes(columnName)) {
        inlineEditors.setValue();
      }
    }
  }
}

export function updateCollapseIcon(gantt: GanttStatic, parentId: string, childrenChange = 0) {
  const parent = gantt.isTaskExists(parentId) && gantt.getTask(parentId);
  if (parent) {
    const hasChild = parent.subtask_count != undefined ? parent.subtask_count : gantt.hasChild(parentId) ? 1 : 0;
    const diff = hasChild + childrenChange || 0;
    parent.$has_child = diff;
    parent.has_child = diff;
    if (!diff) {
      parent.object_type = TaskObjectType.activity;
      parent.open = false;
    }
    gantt.refreshTask(parentId);
  }
}

export function reloadProjectData() {
  Gantt.list().forEach((inst) => {
    inst.needReload = true;
    inst.callEvent('afterTasksImport', []);
  });
}

export function getTaskColor(gantt: GanttStatic, task: GanttTask, specificDate = ''): string {
  const defaultColor = 'rgba(128, 128, 128)';
  let color = defaultColor;

  if (specificDate && task.per_date_override[specificDate]?.lookahead_color) {
    color = gantt.subcontractorColors?.[task.per_date_override[specificDate]?.lookahead_color]?.fill ?? defaultColor;
  } else {
    switch (gantt.taskColorMode) {
      case TaskColorMode.COMPANY:
        color = task.lookahead_color
          ? gantt.subcontractorColors?.[task.lookahead_color]?.fill ?? defaultColor
          : gantt.subcontractorColors?.[task.responsible_org?.lookahead_color]?.fill ?? defaultColor;
        break;
      case TaskColorMode.STATUS:
        color = getTaskColorByStatus(task.status);
        break;
      case TaskColorMode.LABOR:
        color = getLaborColorForDate(task, specificDate);
        break;
      default:
        color = defaultColor;
    }
  }

  return color;
}

export function getTaskAbbrev(gantt: GanttStatic, task: GanttTask, specificDate: Date): string {
  const formattedDate = toShortIso(specificDate);
  if (task.per_date_override?.[formattedDate]?.abbrev) {
    return task.per_date_override[formattedDate].abbrev;
  }
  if (task.abbrev) {
    return task.abbrev;
  }
  if (task.responsible_org?.abbrev) {
    return task.responsible_org?.abbrev;
  }
  return '';
}

export function isValidViewMode(viewMode: string): boolean {
  return Object.values(TasksViewMode).includes(viewMode as unknown as TasksViewMode);
}

export function getTaskOpenIssuesIds(taskIssues: IssueStatusTaskIDsPair[]): string[] {
  const openIssuesIds = taskIssues?.reduce((acc, pair) => {
    if (pair.issue_task_status !== TaskStatusType.closed && pair.issue_task_ids.length) {
      return acc.concat(pair.issue_task_ids);
    }
    return acc;
  }, []);

  return Array.from(new Set(openIssuesIds || []));
}
