import {useMemo} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';

import ProjectsApi from 'api/projects';
import {filterDuplicatedProjectWorkers} from 'shared/helpers/worker';

import {QUERY_CACHE_KEYS} from '../constants/queryCache';
import {CompanyWorker} from '../models/worker';

const ENTITY_LIMIT = 1000;
const SORT_PARAMS = {
  field: 'fullName',
  order: 'ASC',
};

export const useProjectWorkers = (projectId?: string) => {
  const {projectId: routeProjectId} = useParams<{projectId: string}>();
  const currentProjectId = routeProjectId || projectId;

  const {data: projectWorkers, isLoading} = useQuery<CompanyWorker[]>(
    QUERY_CACHE_KEYS.projectWorkers(currentProjectId),
    ({pageParam = 0}) => {
      const formattedSortFields = {};
      return ProjectsApi.getProjectWorkers(
        currentProjectId,
        {
          filterParams: {
            blendedStatus: ['active', 'invited'],
          },
          sortField: SORT_PARAMS.field,
          sortOrder: SORT_PARAMS.order,
          offset: pageParam * ENTITY_LIMIT,
          limit: ENTITY_LIMIT,
          ...formattedSortFields,
        },
        'data',
      );
    },
    {
      enabled: !!currentProjectId,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );

  return {projectWorkers, isLoading};
};

export const useDistinctProjectWorkers = (projectId?: string) => {
  const {projectWorkers, isLoading} = useProjectWorkers(projectId);
  const uniqueWorkers = useMemo(() => {
    if (projectWorkers?.length) {
      return filterDuplicatedProjectWorkers(projectWorkers);
    }
    return [];
  }, [projectWorkers]);
  return {projectWorkers: uniqueWorkers, isLoading};
};
