import LaptopActions from 'shared/components/LaptopActions/LaptopAction';
import LaptopMedia from 'shared/components/LaptopMedia/LaptopMedia';
import MobileAuthStep from 'shared/components/MobileAuthStep/MobileAuthStep';

const MobileAccountComplete = () => {
  return (
    <MobileAuthStep media={<LaptopMedia />}>
      <LaptopActions />
    </MobileAuthStep>
  );
};

export default MobileAccountComplete;
