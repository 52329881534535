import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect} from 'react';

import WeatherService from 'api/weather';
import {ProjectModel} from 'shared/models/project';

export function useGanttWeatherReport(gantt: GanttStatic, project?: ProjectModel) {
  const today = new Date().toISOString().split('T')[0];
  useEffect(() => {
    if (project && project.city && project.state) {
      WeatherService.getWeather(project.city, project.state).then((report) => (gantt.weatherReport = report));
    } else {
      gantt.weatherReport = null;
    }
  }, [gantt, project, project?.city, project?.state, today]);
}
