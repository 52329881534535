export function filterInternalKeys(data: Record<string, unknown>[]): Record<string, unknown>[] {
  const fieldsToRemove = [
    'actual_end',
    'actual_start',
    'assignment_count',
    'completion_amount',
    'completion_target',
    'completion_unit',
    'created_by',
    'created_using',
    'csi_code',
    'culpable_org_id',
    'custom_code',
    'duration',
    'id',
    'involved_org_ids',
    'keywords',
    'location',
    'phase_code',
    'project_id',
    'responsible_org_id',
    'sched_end_date',
    'sched_start_date',
    'task_ids',
    'time_created',
    'time_removed',
    'time_updated',
  ];

  return data.map((item) => {
    const filteredItem: Record<string, unknown> = {};
    for (const [key, value] of Object.entries(item)) {
      if (!fieldsToRemove.includes(key)) {
        filteredItem[key] = value;
      }
    }
    return filteredItem;
  });
}

export function capitalizeAndJoinWords(input: string): string {
  return input
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function escapeCSV(value: string | number | boolean | null | undefined): string {
  if (value == null) return '';
  const stringValue = String(value);
  if (stringValue.includes(',') || stringValue.includes('"') || stringValue.includes('\n')) {
    return `"${stringValue.replace(/"/g, '""')}"`;
  }
  return stringValue;
}
