import {SetStateAction, useEffect, useState} from 'react';

import {safeParseFromLocalStorage} from 'shared/helpers/ls';

export const getLocalStorageValue = <V,>(key: string, path?: string) => {
  const storageValue = safeParseFromLocalStorage(key);
  if (path) {
    const parts = path?.split('.');
    return parts?.reduce((acc, cur) => {
      if (acc?.[cur] !== null && acc?.[cur] !== undefined) return acc[cur] as V;
      return null;
    }, storageValue as V) as V;
  } else return storageValue as V;
};

export const setLocalStorageValue = <V,>(key: string, value: V, path?: string) => {
  try {
    const parts = path?.split('.') || [];
    let current;
    let valueToSave = (current = safeParseFromLocalStorage(key) || (path ? {} : null));
    if (path) {
      // if path exist and stored value not an object, override ls value
      if (typeof valueToSave !== 'object') valueToSave = current = {};
      parts.forEach((part, index) => {
        if (typeof current !== 'object') current = {};
        if (index !== parts.length - 1) {
          if (!current[part]) current[part] = {};
          current = current[part];
        } else {
          current[part] = value;
        }
      });
    } else {
      valueToSave = value;
    }
    localStorage.setItem(key, JSON.stringify(valueToSave));
  } catch (error) {
    console.warn(error);
  }
};

export function useLocalStorageState<V = unknown>({
  key,
  defaultValue,
  path,
  enabled = true,
}: {
  key: string;
  path?: string;
  defaultValue: V;
  enabled?: boolean;
}) {
  const [value, _setter] = useState<V | null>(getLocalStorageValue<V>(key, path) || defaultValue);

  useEffect(() => {
    if (enabled) {
      _setter(getLocalStorageValue<V>(key, path) || defaultValue);
    }
  }, [key, path, enabled]);

  const setValue = (dispatch: SetStateAction<V>) => {
    _setter((value) => {
      // be careful of this check for callable, https://github.com/microsoft/TypeScript/issues/37750
      const newValue = dispatch instanceof Function ? dispatch(value) : value;
      setLocalStorageValue(key, newValue, path);
      return newValue;
    });
  };

  return [value, setValue] as const;
}

const cache = new Map<string, unknown>();

export function useLocalStorage<V = unknown>({
  key,
  defaultValue,
  path,
  enabled = true,
}: {
  key: string;
  path?: string;
  defaultValue: V;
  enabled?: boolean;
}) {
  const cacheKey = `${key},${path}`;
  const setValue = (value: V) => {
    if (enabled) {
      setLocalStorageValue(key, value, path);
      cache.set(cacheKey, value);
    }
  };

  const getValue = () => {
    if (cache.has(cacheKey)) {
      return (cache.get(cacheKey) ?? defaultValue) as V; // TODO: we dont need fallback for value from cache?
    } else {
      const value = getLocalStorageValue<V>(key, path);
      cache.set(cacheKey, value);
      return value ?? defaultValue;
    }
  };

  return [getValue, setValue] as const;
}
