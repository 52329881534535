import {Dispatch, Reducer, ReducerAction, useReducer} from 'react';

import {ProSubscription, FormattedProPlan, ProPlan, ProPlanTier, FormattedProPlanTiers} from 'shared/models/checkout';

const SUBSCRIPTION_ACTIONS = {
  setSubscription: 'SET_SUBSCRIPTION',
  setProPlans: 'SET_PRO_PLANS',
  setTiers: 'SET_TIERS',
  setSession: 'SET_SESSION',
  setStatus: 'SET_STATUS',
  reset: 'RESET',
} as const;

type SubscriptionActionsValues = (typeof SUBSCRIPTION_ACTIONS)[keyof typeof SUBSCRIPTION_ACTIONS];
interface SubscriptionAction<T extends SubscriptionActionsValues, P = unknown> {
  type: T;
  payload?: P;
}
export enum SubscriptionStep {
  'initial',
  'checkout',
  'pickPlan',
  'upgrading',
  'canceling',
  'finished',
  'rejected',
}
export interface SubscriptionState {
  subscription: ProSubscription;
  prevSubscription: ProSubscription;
  proPlan: FormattedProPlan;
  currentPlan: FormattedProPlanTiers;
  session: string;
  status: SubscriptionStep;
}

type Actions =
  | SubscriptionAction<'SET_SUBSCRIPTION', ProSubscription>
  | SubscriptionAction<'SET_PRO_PLANS', FormattedProPlan>
  | SubscriptionAction<'SET_STATUS', SubscriptionStep>
  | SubscriptionAction<'SET_SESSION', string>
  | SubscriptionAction<'RESET'>;

export const subscriptionActionsCreators = {
  setSubscription(payload: ProSubscription) {
    return {
      type: SUBSCRIPTION_ACTIONS.setSubscription,
      payload,
    };
  },
  setProPlan(payload: ProPlan) {
    return {
      type: SUBSCRIPTION_ACTIONS.setProPlans,
      payload,
    };
  },
  setSession(payload: string) {
    return {
      type: SUBSCRIPTION_ACTIONS.setSession,
      payload,
    };
  },
  setStep(payload: SubscriptionStep) {
    return {
      type: SUBSCRIPTION_ACTIONS.setStatus,
      payload,
    };
  },
  reset() {
    return {
      type: SUBSCRIPTION_ACTIONS.reset,
    };
  },
};

export const findCurrentPlan = (sub?: ProSubscription, planTiers?: ProPlanTier[]) => {
  return sub?.stripeSubscriptionInfo && planTiers
    ? (planTiers.find((t) => sub.stripeSubscriptionInfo.quantity <= +t.upTo) as FormattedProPlanTiers)
    : null;
};

const reducer: Reducer<SubscriptionState, Actions> = (state, action) => {
  switch (action.type) {
    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        prevSubscription: state.subscription && {...state.subscription},
        subscription: action.payload,
        currentPlan: findCurrentPlan(action.payload, state.proPlan?.tiers),
      };
    case 'SET_PRO_PLANS':
      return {
        ...state,
        proPlan: action.payload,
        currentPlan: findCurrentPlan(state.subscription, action.payload.tiers),
      };
    case 'SET_SESSION':
      return {...state, session: action.payload};
    case 'SET_STATUS':
      return {...state, status: action.payload};
    case 'RESET':
      return {
        ...getDefaultSubscriptionState(),
        subscription: state.subscription,
        prevSubscription: state.prevSubscription,
        proPlan: state.proPlan,
        currentPlan: state.currentPlan,
      };
    default:
      return state;
  }
};

export const getDefaultSubscriptionState = (): SubscriptionState => {
  return {
    subscription: null,
    prevSubscription: null,
    proPlan: null,
    session: '',
    status: SubscriptionStep.initial,
    currentPlan: null,
  };
};

export const useSubscriptionState = () => {
  return useReducer(reducer, getDefaultSubscriptionState());
};

export type CheckoutDispatch = Dispatch<ReducerAction<typeof reducer>>;
