import {useCallback, useEffect, useRef} from 'react';

/** This hook is useful when you want to avoid updating state on an unmounted component
 * Hook provides the get method that will return current mount state of component
 * @return {Function} mount state getter*/
export function useMountedState() {
  const mountedRef = useRef(false);
  const getter = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  }, []);
  return getter;
}
