import {GanttStatic} from 'dhtmlx-gantt';

import {TaskDependencyDto, TaskDependencyToGanttLinkType} from 'shared/models/TaskDependency';

export const mapDependencyToGanttLink = (gantt: GanttStatic, dep: TaskDependencyDto) => {
  return {
    id: dep.id,
    target: dep.taskId,
    source: dep.predTaskId,
    type: gantt.config.links[TaskDependencyToGanttLinkType[dep.depType]],
  };
};
