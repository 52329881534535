import {FC, InputHTMLAttributes, useRef} from 'react';

import {safeFormatDate} from '../../../helpers/dates';

type Props = {
  minDate: Date;
  value?: Date | string;
  maxDate?: Date;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'value'>;

const DATE_FORMAT = 'YYYY-MM-DD';

const CoreNativeDatePicker: FC<Props> = ({minDate, maxDate, value, ...props}: Props) => {
  const inputRef = useRef(null);

  return (
    <input
      ref={inputRef}
      type="date"
      value={value ? (typeof value === 'string' ? value : safeFormatDate(value, DATE_FORMAT)) : ''}
      min={safeFormatDate(minDate, DATE_FORMAT)}
      max={safeFormatDate(maxDate, DATE_FORMAT)}
      {...props}
    />
  );
};
export default CoreNativeDatePicker;
