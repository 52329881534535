import {decamelize, decamelizeKeys} from 'humps';

import env from 'shared/constants/env';
import {isAxiosError} from 'shared/helpers/axios';
import {getApiResponseDataRange} from 'shared/helpers/common';
import {prepareTaskFilters} from 'shared/helpers/tasks';
import {WithAffectedIds} from 'shared/models/common';
import {ProjectWorkflow} from 'shared/models/project';
import {TaskChatHistoryStatusResponse} from 'shared/models/task/chat';
import {TaskProjection} from 'shared/models/task/const';
import {IssuesParams, TaskParams} from 'shared/models/task/filter';
import {IssueModel, IssueWithAssigneesDTO} from 'shared/models/task/issue';
import {TaskAssignees} from 'shared/models/task/member';
import {
  IssueModelDTO,
  IssueModelRawDTO,
  TaskDetailsModelDTO,
  TasksResponseType,
  TaskWithAffectedResponse,
  CreateTaskResponse,
  TaskMoveRequest,
  TaskRelativeMoveRequest,
  TaskModelRawDTO,
  ConfigSharedTask,
  TaskCopyRequest,
  CreateSnapshotPayload,
  ResponseBaselineTasks,
  DeleteSnapshotPayload,
  BaselineTasks,
  AddIssueDayPayload,
} from 'shared/models/task/task';
import {TaskDependencyDto} from 'shared/models/TaskDependency';

import ApiAxios from './axios';

class TasksService {
  getBasePath(companyId: string) {
    return `/companies/${companyId}/tasks`;
  }

  private getIssuesWithFilters<T>(
    projectId: string,
    {offset, limit, sortParams: {sortOrder, sortField} = {}, filterParams = {}}: IssuesParams = {},
    disableCamelCaseConverter = false,
  ) {
    const url = `/projects/${projectId}/issues`;
    const preparedSort = {};
    sortField ? Object.assign(preparedSort, {sortField: decamelize(sortField)}) : '';
    sortOrder ? Object.assign(preparedSort, {sortOrder}) : '';

    return ApiAxios.get<T[]>(url, {
      disableCamelCaseConverter,
      params: {
        filter_params: JSON.stringify(prepareTaskFilters(filterParams)),
        limit,
        offset,
        ...preparedSort,
      },
    });
  }

  async getAllIssues(projectId: string, params?: IssuesParams) {
    const result = await this.getIssuesWithFilters<IssueModelDTO>(projectId, params, true);
    return result.data;
  }

  async getTaskIssuesById(projectId: string, issueTaskIds: string[]): Promise<IssueModelDTO[]> {
    const result = await this.getIssuesWithFilters<IssueModelDTO>(projectId, {
      filterParams: {ids: issueTaskIds},
    });
    return result.data;
  }

  getTaskIssues(projectId: string, params: IssuesParams) {
    return this.getIssuesWithFilters<IssueModelRawDTO>(projectId, params, true);
  }

  updateIssue(task: Partial<IssueModel>, isScopedUpdate = false) {
    const url = `${isScopedUpdate ? '/scoped' : ''}/projects/${task.projectId}/issues/${task.id}`;
    return ApiAxios.post<IssueModelDTO>(url, task).then((res) => res.data);
  }

  deleteIssue(issueId: string, projectId: string) {
    return ApiAxios.delete<IssueModelDTO>(`/projects/${projectId}/issues/${issueId}`).then((res) => res.data);
  }

  addIssueAssignee(projectId: string, issueId: string, assignee: TaskAssignees) {
    return ApiAxios.post<TaskDetailsModelDTO>(`/projects/${projectId}/issues/${issueId}/assign`, assignee).then(
      (res) => res.data,
    );
  }

  createIssue(issue: IssueWithAssigneesDTO, isScopedCreate = false) {
    return ApiAxios.post<TaskDetailsModelDTO>(
      `${isScopedCreate ? '/scoped' : ''}/projects/${issue.projectId}/issues`,
      issue,
    ).then((res) => res.data);
  }

  private getTasksWithFilters(
    url: string,
    {limit = 20, offset = 0, params = {}, sortField, sortOrder, projection, includeSummaryTasks}: TaskParams = {},
  ) {
    const preparedSort = {};
    sortField ? Object.assign(preparedSort, {sortField: decamelize(sortField)}) : '';
    sortOrder ? Object.assign(preparedSort, {sortOrder}) : '';
    return ApiAxios.get<TasksResponseType>(url, {
      disableCamelCaseConverter: projection === TaskProjection.task,
      params: {
        filter_params: JSON.stringify(prepareTaskFilters(params)),
        offset,
        limit,
        projection,
        includeSummaryTasks,
        ...preparedSort,
      },
    });
  }

  getProjectTasks(params: TaskParams) {
    return this.getTasksWithFilters(`/projects/${params.params.projectId}/tasks`, params);
  }

  getTasksCount(companyId: string) {
    return this.getTasksWithFilters(this.getBasePath(companyId), {limit: 1, offset: 0}).then((res) => {
      return getApiResponseDataRange(res);
    });
  }

  getTasksForExport(companyId: string, params: TaskParams) {
    return this.getTasksWithFilters(`${this.getBasePath(companyId)}/export`, params).then((res) => {
      const range = res.headers['content-range'] as string;
      const match = range.match(/.+(\d+-\d+)\/(\d+)/);
      return {
        data: res.data,
        total: match && parseInt(match[2], 10),
      };
    });
  }

  getTask(taskId: string) {
    return ApiAxios.get<TaskDetailsModelDTO>(`/tasks/${taskId}`).then((res) => res.data);
  }

  getTasks(taskIds: string[]) {
    const tasksPromises = taskIds.map((taskId) => this.getTask(taskId));
    return Promise.all(tasksPromises);
  }

  /*
   * isScopedUpdate param is used to determine
   *  if task has been changed by non-admin user (worker or foreman)?
   */
  updateTask(task: Partial<TaskDetailsModelDTO>, isScopedUpdate = false, ignoreAffectedTaskIds = false) {
    return ApiAxios.post<TaskDetailsModelDTO>(`${isScopedUpdate ? 'scoped' : ''}/tasks/${task.id}`, task, {
      ignoreAffectedTaskIds,
    }).then((res) => res.data);
  }

  updateTaskWithAffected(task: Partial<TaskDetailsModelDTO>) {
    /*
     * 2.0.0 endpoint returns
     * { task: <task detail>, affected_tasks: <task[]> }
     */
    return ApiAxios.post<TaskWithAffectedResponse>(`tasks/${task.id}`, task, {
      baseURL: env.API_URL_V2,
      disableCamelCaseConverter: true,
    }).then((res) => res.data);
  }

  createTask(task: Partial<TaskDetailsModelDTO>) {
    return ApiAxios.post<TaskDetailsModelDTO>(`/projects/${task.projectId}/tasks`, task).then((res) => res.data);
  }

  async createTaskRaw(task: Partial<TaskDetailsModelDTO>) {
    const res = await ApiAxios.post<CreateTaskResponse>(`/projects/${task.projectId}/tasks`, task, {
      disableCamelCaseConverter: true,
    });
    return res.data;
  }

  deleteTask(taskId: string) {
    return ApiAxios.delete<TaskDetailsModelDTO>(`/tasks/${taskId}`).then((res) => res.data);
  }

  deleteTasks(projectId: string, taskIds: string[]) {
    return ApiAxios.post<{
      affectedTasks: string[];
      deletedDependencyIds: string[];
      deletedTaskIds: string[];
    }>(
      `projects/${projectId}/tasks/delete`,
      {taskIds},
      {
        baseURL: env.API_URL_V2,
      },
    ).then((res) => res.data);
  }

  restoreTask(taskId: string) {
    return ApiAxios.post<TaskDetailsModelDTO>(`/tasks/${taskId}/undelete`).then((res) => res.data);
  }

  startAsyncChatHistory(taskId: string) {
    return ApiAxios.post<{id: string}>(`tasks/${taskId}/chat_history`).then((res) => res.data);
  }

  checkAsyncStatusChatHistory(taskId: string, asyncUploadId: string) {
    return ApiAxios.get<TaskChatHistoryStatusResponse>(`tasks/${taskId}/chat_history/${asyncUploadId}/status`).then(
      (res) => res.data,
    );
  }

  tasksMove(payload: TaskMoveRequest) {
    if (payload.parentTaskId === '0') {
      delete payload.parentTaskId;
    }
    return ApiAxios.post<TaskDetailsModelDTO>(`tasks/move`, payload).then((res) => {
      return res.data;
    });
  }

  taskRelativeMove(payload: TaskRelativeMoveRequest) {
    return ApiAxios.post<TaskModelRawDTO & {affected_task_ids: string[]}>('tasks/move_relative', payload, {
      disableCamelCaseConverter: true,
      ignoreAffectedTaskIds: true,
    }).then((res) => res.data);
  }

  getSharedTask(taskId: string) {
    return ApiAxios.get<TaskDetailsModelDTO>(`shared/tasks/${taskId}`).then((res) => res.data);
  }

  getSharedTaskWorkflow(taskId: string) {
    return ApiAxios.get<ProjectWorkflow>(`shared/tasks/${taskId}/workflow`).then((res) => res.data);
  }

  updateSharedTask(taskId: string, payload: Partial<TaskDetailsModelDTO>, config?: ConfigSharedTask) {
    return ApiAxios.post<TaskDetailsModelDTO>(`shared/tasks/${taskId}`, payload, config).then((res) => res.data);
  }

  getDependencies(taskId: string) {
    return ApiAxios.get<TaskDependencyDto[]>(`tasks/${taskId}/dependencies`).then((res) => res.data);
  }

  saveDependency(
    taskId: string,
    dependency: Omit<TaskDependencyDto, 'id' | 'timeUnblocked'>,
    headers?: Record<string, string>,
  ) {
    return ApiAxios.post<WithAffectedIds<TaskDependencyDto, 'dependency'>>(`tasks/${taskId}/dependencies`, dependency, {
      headers,
    }).then((res) => res.data);
  }

  updateDependency(taskId: string, dependency: Partial<TaskDependencyDto>, headers?: Record<string, string>) {
    return ApiAxios.post<WithAffectedIds<TaskDependencyDto, 'dependency'>>(
      `/tasks/${taskId}/dependencies/${dependency.id}`,
      dependency,
      {headers},
    ).then((res) => res.data);
  }

  deleteDependency(taskId: string, depId: string, headers?: Record<string, string>) {
    return ApiAxios.post(`tasks/${taskId}/dependencies/${depId}/delete`, undefined, {headers});
  }

  addAssignee(taskId: string, assignee: TaskAssignees) {
    return ApiAxios.post<TaskDetailsModelDTO>(`tasks/${taskId}/assignees`, assignee).then((res) => res.data);
  }

  updateAssignee(taskId: string, assignee: TaskAssignees[]) {
    return ApiAxios.patch<TaskDetailsModelDTO>(`tasks/${taskId}/assignees`, assignee).then((res) => res.data);
  }

  addAssignees(taskId: string, assignees: TaskAssignees[]) {
    return ApiAxios.post(`tasks/${taskId}/assignees`, assignees, {baseURL: env.API_URL_V2}).then((res) => res.data);
  }

  // TODO: temporary hardcode app version
  getAppConstants() {
    return ApiAxios.get('appconstants', {params: {appVersion: '4.0.6'}}).then((res) => res.data);
  }

  indentOrOutdentTasks(projectId: string, taskIds: string[], action: 'indent' | 'outdent') {
    return ApiAxios.post<{affectedTaskIds: string[]}>(
      `projects/${projectId}/${action}_tasks`,
      {taskIds},
      {ignoreAffectedTaskIds: true},
    )
      .then((res) => {
        return res.data;
      })
      .catch((e) => {
        if (isAxiosError(e)) {
          if (e.response && e.response.data) {
            return Promise.reject(e.response.data);
          }
          throw e;
        }
        throw e;
      });
  }

  clone(payload: TaskCopyRequest, ignoreAffectedTaskIds = true) {
    return ApiAxios.post<WithAffectedIds<TaskDetailsModelDTO[], 'tasks'>>(`/tasks/clone`, payload, {
      ignoreAffectedTaskIds,
    }).then((res) => {
      return res.data;
    });
  }

  createSnapshot(payload: CreateSnapshotPayload) {
    return ApiAxios.post(`projects/${payload.id}`, payload).then((res) => res.data);
  }

  getBaselineTasks(projectId: string, cutoff: string, taskIds?: string[]) {
    return ApiAxios.get<ResponseBaselineTasks>(`projects/${projectId}/baseline`, {
      params: {cutoff: cutoff, taskids: taskIds ? taskIds.toString() : undefined},
    }).then((res) => decamelizeKeys(res.data) as ResponseBaselineTasks);
  }

  deleteSnapshot(projectId: string, payload: DeleteSnapshotPayload) {
    return ApiAxios.post<{tasks: BaselineTasks[]}>(`projects/${projectId}`, payload).then((res) => res.data);
  }

  addIssueDay(taskId: string, payload: AddIssueDayPayload) {
    return ApiAxios.post<{affectedTasks: TaskDetailsModelDTO[]; task: TaskDetailsModelDTO}>(
      `/tasks/${taskId}/issue_day`,
      payload,
    ).then((res) => res.data);
  }
}

export default new TasksService();
