import cn from 'classnames';
import {useState} from 'react';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {ValidationError} from 'yup';

import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import {CompletionUnits, completionUnitLabels} from 'shared/constants/completionUnits';
import {normalizeI18Key} from 'shared/utils/normalizeI18Key';

import {onInput} from '../../utils/helpers';
import {validationSchema} from '../../utils/validationSchema';
import sharedStyle from '../shared.module.scss';

import styles from './progressSlider.module.scss';

type Completion = {
  completionAmount: number;
  completionUnit: CompletionUnits;
  completionTarget: number;
};

interface ProgressSliderProps {
  completion: Completion;
  cumulativeDailyCompletion: number;
  disabled: boolean;
  handleChange: (completionAmount: number) => void;
  index: number;
  readonlyMode: boolean;
}

const countOperation = {
  increment: 'increment',
  decrement: 'decrement',
} as const;

type CountOperation = keyof typeof countOperation;

const ProgressSlider = ({
  completion,
  cumulativeDailyCompletion,
  disabled,
  handleChange,
  index,
  readonlyMode,
}: ProgressSliderProps) => {
  const {t} = useTranslation('dailies');
  const [animationState, setAnimationState] = useState({increment: false, decrement: false});
  const [error, setError] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleChangeCount = (operation: CountOperation) => {
    const initialCompletionAmount = completion.completionAmount ?? 0;
    const {completionTarget} = completion;
    let newCount = operation === countOperation.increment ? initialCompletionAmount + 1 : initialCompletionAmount - 1;
    newCount = Math.min(Math.max(newCount, 0), completionTarget);
    const shouldNotCount =
      (operation === countOperation.increment && initialCompletionAmount >= completionTarget) ||
      (operation === countOperation.decrement && initialCompletionAmount <= 0);
    if (shouldNotCount) {
      return;
    }
    setAnimationState((prevState) => ({
      ...prevState,
      [operation]: true,
    }));
    handleChange(newCount);
    setTimeout(
      () =>
        setAnimationState((prevState) => ({
          ...prevState,
          [operation]: false,
        })),
      300,
    );
  };

  const {completionAmount = 0, completionTarget = 0} = completion;
  const actualWidth = Math.min(Math.floor((completionAmount / completionTarget) * 100), 100);
  const cumulativeWidth = !disabled
    ? Math.min(Math.floor((cumulativeDailyCompletion / completionTarget) * 100), 100)
    : 0;
  const width = !!completionAmount ? actualWidth : cumulativeWidth;

  let placeholder = '•';
  if (!disabled && isFocused) {
    placeholder = '';
  } else if (!disabled && cumulativeDailyCompletion > 0) {
    placeholder = cumulativeDailyCompletion.toString();
  }

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    e.preventDefault();
    const inputValue = e.target.value.trim().replace(/^0\d+/, '');
    const numericValue = parseInt(inputValue);
    try {
      if (isNaN(numericValue) || numericValue < 0) {
        handleChange(null);
        return;
      }
      handleChange(numericValue);
      const validationContext = {
        dailyCompletionAmount: numericValue,
        completionTarget: completion.completionTarget,
      };
      await validationSchema.validate(validationContext, {abortEarly: false});
    } catch (err) {
      if (err instanceof ValidationError) {
        setError(t(normalizeI18Key(err.message)));
      }
    }
  };

  const inputStyle = cn({
    [sharedStyle.input]: true,
    [sharedStyle.input_error]: !!error,
    [sharedStyle.disabled_placeholder]: disabled,
    [sharedStyle.input_placeholder_empty]: !completionAmount && !!cumulativeDailyCompletion,
  });

  const containerStyle = cn({
    [styles.container]: true,
    [styles.container_error]: !!error,
  });

  const progressStyle = cn({
    [styles.progress_bar]: completionAmount > 0,
    [styles.progress_bar_cumulative]: !completionAmount,
  });

  const buttonStyle = cn({
    [sharedStyle.button]: true,
    [sharedStyle.button_readonly]: readonlyMode,
  });

  return (
    <div>
      <div className={containerStyle} aria-disabled={disabled}>
        <div className={progressStyle} style={{width: `${width}%`}} />
        <Button
          className={buttonStyle}
          disabled={disabled}
          icon={<Icon name="prefix-icon" size={10} />}
          iconOnly
          onClick={() => handleChangeCount(countOperation.decrement)}
        />
        <section>
          {animationState.decrement && <div className={sharedStyle.sinkAwayAnimation}>-1</div>}
          {!readonlyMode ? (
            <input
              className={inputStyle}
              disabled={disabled}
              id={`progress-report-${index}`}
              max={completion.completionTarget}
              name="progress-input"
              onBlur={() => setIsFocused(false)}
              onChange={onChange}
              onFocus={(e) => {
                e.target.select();
                setIsFocused(true);
              }}
              onInput={onInput}
              placeholder={placeholder}
              step="1"
              type="number"
              value={completion.completionAmount ?? ''}
            />
          ) : (
            <span className={inputStyle}>{completion.completionAmount}</span>
          )}
          {animationState.increment && <div className={sharedStyle.floatAwayAnimation}>+1</div>}
          <label htmlFor={`progress-report-${index}`}>{completionUnitLabels[completion.completionUnit]}</label>
        </section>
        <Button
          className={buttonStyle}
          disabled={disabled}
          icon={<Icon name="suffix-icon" size={10} />}
          iconOnly
          onClick={() => handleChangeCount(countOperation.increment)}
        />
      </div>
      {error ? <span className={styles.error}>{error}</span> : null}
    </div>
  );
};

export default ProgressSlider;
