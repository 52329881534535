import {FC, createContext, useContext} from 'react';

interface ProgressReportPopupContext {
  updateCurrentTaskId: (taskId: string) => void;
  currentTaskId: string;
}

const ProgressReportPopupContext = createContext<ProgressReportPopupContext | undefined>(undefined);

const ProgressReportPopupProvider: FC<ProgressReportPopupContext> = ({children, ...props}) => (
  <ProgressReportPopupContext.Provider value={{...props}}>{children}</ProgressReportPopupContext.Provider>
);

export default ProgressReportPopupProvider;

export const useProgressReportPopupContext = () => {
  const context = useContext(ProgressReportPopupContext);
  if (!context) {
    throw new Error('useProgressReportPopupContext must be used within a ProgressReportProvider');
  }
  return context;
};
