import cn from 'classnames';
import {Children, cloneElement, isValidElement, ReactElement, ReactNode} from 'react';

import s from './CtrlChipGroup.module.scss';

type CtrlChipGroupProps = {
  className?: string;
  children: ReactNode[];
  title?: string;
  disabled?: boolean;
};

const CtrlChipGroup = ({className, children, title}: CtrlChipGroupProps) => {
  return (
    <div className={cn(s.CtrlChipGroup, className)}>
      {title && <div className={s.CtrlChipGroup__title}>{title}</div>}
      <div className={s.CtrlChipGroup__grid}>
        {Children.toArray(children).map<ReactElement>(
          (child) =>
            isValidElement(child) &&
            cloneElement(child, {...child.props, className: cn(s.CtrlChipGroup__item, child.props.className)}),
        )}
      </div>
    </div>
  );
};

export default CtrlChipGroup;
