/* eslint-disable  @typescript-eslint/no-explicit-any*/
/*
 * Set of function for use with useReducer hook
 * */

import {Dispatch} from 'react';

interface Action<P = void, T extends string = string> {
  type: T;
  payload: P;
}

interface PayloadActionCreator<P = void, T extends string = string> {
  (payload: P): Action<P, T>;
}

type ActionReturnType<T extends PayloadActionCreator> = T extends (...args: any) => infer R ? R : never;

export type InferActionsReturnType<T extends Record<string, (...args: any) => any>> = T extends Record<string, infer M>
  ? M extends (...args: any) => any
    ? ActionReturnType<M>
    : never
  : never;

export function createActionCreatorWrapper<P = void>() {
  return <T extends string>(type: T): PayloadActionCreator<P, T> =>
    (payload) => ({type, payload});
}

export function createActions<M extends Record<string, (...args: any) => any>>(actions: M) {
  return Object.keys(actions).reduce((acc, key) => {
    return Object.assign(acc, {[key]: actions[key](key)});
  }, {} as {[K in keyof M]: ReturnType<M[K]> extends PayloadActionCreator<infer Payload> ? PayloadActionCreator<Payload, K extends string ? K : string> : never});
}

export function bindActionCreators<
  AC extends Record<string, PayloadActionCreator<A, K>>,
  K extends string,
  A extends Action<never>,
>(actions: AC, dispatch: Dispatch<A>) {
  return Object.keys(actions).reduce((acc, key: keyof AC) => {
    return Object.assign(acc, {[key]: (param: any) => dispatch(actions[key](param) as any)});
  }, {} as {[KK in keyof AC]: (...payload: Parameters<AC[KK]>) => void});
}
