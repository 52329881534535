import {parser, device} from 'shared/utils/UAParser';

const isTablet = parser.getResult()?.device?.type === device.TABLET || false;
const resizer = {resizer: true, width: isTablet ? 10 : 1};

function getBaseLayoutColumns(width?: number) {
  return {
    grid: {
      width: width || 700,
      min_width: 500,
      rows: [
        {
          view: 'grid',
          scrollX: 'gridScroll',
          scrollable: true,
          scrollY: 'scrollVer',
          css: 'gantt_layout_no_bottom_border',
        },
        {view: 'scrollbar', id: 'gridScroll'},
      ],
    },
    timeline: [
      resizer,
      {
        rows: [
          {view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer', css: 'gantt_layout_no_bottom_border'},
          {view: 'scrollbar', id: 'scrollHor'},
        ],
      },
      {view: 'scrollbar', id: 'scrollVer'},
    ],
  };
}

export function getGanttDefaultLayout(width?: number) {
  const baseLayout = getBaseLayoutColumns(width);
  return {
    css: 'gantt-view',
    cols: [baseLayout.grid, ...baseLayout.timeline],
  };
}

export function getGanttTableLayout(width?: number) {
  const baseLayout = getBaseLayoutColumns(width);
  return {
    css: 'table-view',
    cols: [
      {...baseLayout.grid, width: undefined},
      {view: 'scrollbar', id: 'scrollVer'},
    ],
  };
}
export function getGanttLookaheadLayout(width?: number) {
  const baseLayout = getBaseLayoutColumns(width);
  return {
    css: 'lookahead-view',
    cols: [baseLayout.grid, ...baseLayout.timeline],
  };
}

export function getGanttMapLayout(width?: number, className?: string) {
  const baseLayout = getBaseLayoutColumns(width);
  return {
    css: className,
    cols: [
      {
        width: baseLayout.grid.width,
        min_width: baseLayout.grid.min_width,
        cols: [
          {
            rows: baseLayout.grid.rows,
          },
          {view: 'scrollbar', id: 'scrollVer'},
        ],
      },
      resizer,
      {
        css: 'pspdf-cell',
        html: '<div id="foxit-container" style="width: 100%; height: 100%"></div>',
      },
    ],
  };
}
