import {injectable} from 'inversify';
import {makeAutoObservable} from 'mobx';
import {MutableRefObject} from 'react';

export type UIStoreType = {
  getDailiesPrintNodeRef: MutableRefObject<HTMLDivElement> | null;
  setDailiesPrintNodeRef(ref: MutableRefObject<HTMLDivElement>): void;
  setLoading(isLoading: boolean): void;
  isLoading: boolean;
};

@injectable()
export class UIStore implements UIStoreType {
  private dailiesPrintNodeRef: MutableRefObject<HTMLDivElement> | null = null;
  public isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setDailiesPrintNodeRef(ref: MutableRefObject<HTMLDivElement>) {
    this.dailiesPrintNodeRef = ref;
  }

  get getDailiesPrintNodeRef() {
    return this.dailiesPrintNodeRef;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
}
