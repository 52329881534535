import {GanttStatic} from 'dhtmlx-gantt';

import {GanttPluginBase} from 'modules/Tasks/components/Gantt/plugins/ganttPlugin';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {isPlaceholderTask} from 'modules/Tasks/components/Gantt/utils/gantt';

export class ContextMenuPlugin extends GanttPluginBase {
  private eventId: string;
  context: GanttTask;
  constructor(gantt: GanttStatic) {
    super(gantt);
    gantt.contextMenu = this;
  }

  open(e: MouseEvent) {
    const taskId = this.gantt.locate(e);
    if (this.gantt.isTaskExists(taskId)) {
      this.context = this.gantt.getTask(taskId);
      this.gantt.callEvent('onContextMenuCustom', [this.context, this.getBoundary(e)]);
    }
  }

  close() {
    this.context = null;
  }

  getBoundary(e: MouseEvent) {
    return {
      contextElement: e.target,
      getBoundingClientRect() {
        return {
          width: 1,
          height: 1,
          left: e.x,
          top: e.y,
          bottom: 0,
          right: 0,
        };
      },
    };
  }

  init() {
    this.eventId = this.gantt.attachEvent(
      'onContextMenu',
      (taskId: string, linkId: string, e: MouseEvent) => {
        if (taskId && e.target instanceof HTMLElement) {
          e.preventDefault();
          const task = this.gantt.getTask(taskId);
          if (task && !isPlaceholderTask(this.gantt, task)) {
            this.context = task;
            this.gantt.callEvent('onContextMenuCustom', [task, this.getBoundary(e)]);
          }
        }
      },
      undefined,
    );
  }
  clear() {
    this.gantt.detachEvent(this.eventId);
  }
}

declare module 'dhtmlx-gantt' {
  export interface GanttCustomProperties {
    contextMenu?: ContextMenuPlugin;
  }
}
