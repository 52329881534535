import * as Sentry from '@sentry/browser';
import {AuthCredential, ConfirmationResult, User} from 'firebase/auth';
import {ThunkAction} from 'redux-thunk';

import WorkerApi, {SupportedLoginTypes} from 'api/worker';
import FirebaseService from 'services/Firebase';
import errors from 'shared/constants/errors';
import {isFirebaseError} from 'shared/constants/firebase';
import {isAxiosError} from 'shared/helpers/axios';
import {Worker} from 'shared/models/worker';
import {RootState} from 'store';
import {logoutUser} from 'store/actions';

import * as types from './types';
import {LoginActions} from './types';

interface LoginViaPhoneCredentials {
  confirmationCode?: string;
  confirmationResult?: ConfirmationResult;
  deleteIfNotExist?: boolean;
  credentials?: AuthCredential;
}

interface LoginWithEmailAndPassword {
  email: string;
  password: string;
  deleteIfNotExist?: boolean;
}

type ThunkResult<R> = ThunkAction<R, RootState, undefined, LoginActions>;

export const loginSuccessed = (payload?: {token: string; workerId: string}): types.LoginActions => ({
  type: types.LOGIN_SUCCESS,
  payload,
});

export const loginProcess = (): types.LoginActions => ({
  type: types.LOGIN_INPROGRESS,
});

export const fullLogout = (): ThunkResult<any> => {
  return async (dispatch) => {
    await FirebaseService.signOut();
    dispatch(logoutUser());
    ['body', 'html'].map((s) => (document.querySelector(s).scrollTop = 0));
  };
};

const loginFailed = (value: string): types.LoginActions => ({
  type: types.LOGIN_FAILURE,
  payload: value,
});

export const loginWorkerByPhone = ({
  confirmationCode,
  confirmationResult,
  credentials,
}: LoginViaPhoneCredentials): ThunkResult<Promise<User>> => {
  return async () => {
    if (credentials) {
      try {
        await FirebaseService.signInWithCredential(credentials);
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    } else {
      try {
        await FirebaseService.confirmSignInWithMobile(confirmationCode, confirmationResult);
      } catch (error) {
        Sentry.captureException(error);
        throw error;
      }
    }
    return await FirebaseService.getCurrentUser();
  };
};

export const loginWorkerByEmail = ({email, password, deleteIfNotExist = true}: LoginWithEmailAndPassword) => {
  return async (dispatch) => {
    try {
      await FirebaseService.signInWithEmailAndPassword(email, password);
      const loggedUser = await FirebaseService.getCurrentUser();
      const checkSignUp = await WorkerApi.checkSignUp(loggedUser.uid);
      if (checkSignUp) {
        const tokenId = await FirebaseService.getUserIdToken();
        return (await WorkerApi.loginOrSignupWorker({
          user: loggedUser,
          idToken: tokenId,
          loginType: 'password' as SupportedLoginTypes,
        })) as Worker;
      } else {
        if (deleteIfNotExist) {
          await FirebaseService.deleteUser();
        }
        throw new Error(errors.auth.emailNotExist);
      }
    } catch (error) {
      Sentry.captureException(error);
      if (isAxiosError(error) || isFirebaseError(error)) {
        dispatch(loginFailed(error.message));
      }
      console.error(error);
      throw error;
    }
  };
};

export const loginConfirmAuth = ({
  confirmationCode,
  confirmationResult,
  deleteIfNotExist = true,
  credentials,
}: LoginViaPhoneCredentials): ThunkResult<Promise<Worker>> => {
  return async (dispatch) => {
    try {
      const loggedInUser = await dispatch(loginWorkerByPhone({confirmationCode, confirmationResult, credentials}));
      const checkSignUp = await WorkerApi.checkSignUp(loggedInUser.uid);
      if (checkSignUp) {
        const tokenId = await FirebaseService.getUserIdToken();
        return (await WorkerApi.loginOrSignupWorker({user: loggedInUser, idToken: tokenId})) as Worker;
      } else {
        if (deleteIfNotExist) {
          await FirebaseService.deleteUser();
        }
        throw new Error(errors.auth.phoneNotExist);
      }
    } catch (error) {
      if (isAxiosError(error) || isFirebaseError(error)) {
        dispatch(loginFailed(error.message));
      }
      console.error(error);
      throw error;
    }
  };
};

export const requestingPhoneCodeInProgress = (): types.LoginActions => ({
  type: types.REQUESTING_PHONE_CODE_INPROGRESS,
});

export const requestingPhoneCodeSuccess = (): types.LoginActions => ({
  type: types.REQUESTING_PHONE_CODE_SUCCESS,
});

export const requestingPhoneCodeFailed = (): types.LoginActions => ({
  type: types.REQUESTING_PHONE_CODE_FAILED,
});
