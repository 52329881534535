import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';

import OauthApi from 'api/oauth';
import {extractAxiosError} from 'shared/helpers/axios';
import useCountDown from 'shared/helpers/hooks/useCountDown';

type PostMessageType = {
  event: 'success' | 'reject';
  error?: string | Record<string, string>;
};

function ProcoreOAuth() {
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');
  const [loading, setLoading] = useState(!!code);
  const [error, setError] = useState(!code ? 'Invalid data' : null);
  const {counter, start} = useCountDown(10);

  function postMessage(message: PostMessageType) {
    window.opener?.postMessage(message, window.origin);
  }

  async function returnCode(code: string) {
    try {
      setLoading(true);
      await OauthApi.return(code);
      postMessage({event: 'success'});
    } catch (e) {
      const error = extractAxiosError(e);
      postMessage({event: 'reject', error});
      setError(typeof error === 'string' ? error : 'Unexpected error');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!window.opener) window.close();
    returnCode(code);
  }, [code]);

  useEffect(() => {
    if (!counter) {
      postMessage({event: 'reject', error: error});
      window.close();
    }
  }, [counter]);

  useEffect(() => {
    if (error) {
      start();
      postMessage({event: 'reject', error});
    }
  }, [error]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <p style={{textAlign: 'center'}}>
        {loading
          ? 'Process data, please wait...'
          : error
          ? `An error occurred: ${error}`
          : 'You successfully authenticated'}
      </p>
      {error && (
        <p style={{textAlign: 'center'}}>
          This window will close automatically within {counter} second.{' '}
          <a
            href="javascript:void(0)"
            onClick={() => {
              window.close();
            }}
          >
            Continue
          </a>
        </p>
      )}
    </div>
  );
}

export default ProcoreOAuth;
