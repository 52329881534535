import cn from 'classnames';

import box from '../Box/Box.module.scss';

import s from './Companies.module.scss';

type CompaniesProps = {
  className?: string;
};

const Companies = ({className}: CompaniesProps) => {
  const logos = ['webcor', 'sciame', 'kajima', 'burns', 'takenaka', 'nabr', 'imf'];

  const logosList = logos.map((element, index) => {
    return (
      <picture key={`${element}_${index}`} className={s.companies__item}>
        <source
          type="image/webp"
          srcSet={`/images/companies/${element}@1x.webp, /images/companies/${element}@2x.webp 2x`}
        />
        <source
          type="image/png"
          srcSet={`/images/companies/${element}@1x.png, /images/companies/${element}@2x.png 2x`}
        />
        <img className={s.companies__image} src={`/images/companies/${element}@1x.png`} alt="" />
      </picture>
    );
  });

  return (
    <div className={cn(s.companies, className)}>
      <div className={cn(box.box, s.companies__wrap)}>
        <div className={s.companies__container}>
          <div className={s.companies__grid}>{logosList}</div>
        </div>
      </div>
    </div>
  );
};

export default Companies;
