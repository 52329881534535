import {useEffect, useRef} from 'react';

import {GanttPlugin, GanttPluginConstructor} from 'modules/Tasks/components/Gantt/plugins/ganttPlugin';

export function useGanttPlugins<P extends GanttPluginConstructor>(gantt, plugins: P[]) {
  const ref = useRef<GanttPlugin[]>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ref.current = plugins.map((Plugin) => {
      const inst = new Plugin(gantt);
      inst.init();
      return inst;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      ref.current.forEach((p) => p.clear());
      ref.current = [];
    };
  }, [...plugins]);

  return {
    plugins: ref.current,
    clear: () => ref.current.forEach((p) => p.clear()),
  };
}
