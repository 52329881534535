export const IconsMap = {
  active_type: 'active_type',
  add_circle: 'add_circle',
  add_circle_2: 'add_circle_2',
  add_circle_outlined: 'add_circle_outlined',
  'add-circle-outlined': 'add-circle-outlined',
  'add-plus': 'add-plus',
  align_to_left_outlined: 'align_to_left_outlined',
  align_to_left_vs: 'align_to_left_vs',
  'apps-1-3-2': 'apps-1-3-2',
  archive: 'archive',
  arrow_2_bottom: 'arrow_2_bottom',
  arrow_2_left: 'arrow_2_left',
  arrow_2_right: 'arrow_2_right',
  arrow_2_top: 'arrow_2_top',
  arrow_backward: 'arrow_backward',
  arrow_forward: 'arrow_forward',
  'arrow-right': 'arrow-right',
  assign: 'assign',
  autorenew: 'autorenew',
  barcode: 'barcode',
  baseline: 'baseline',
  blend_tool: 'blend_tool',
  'calendar-month': 'calendar-month',
  call: 'call',
  chart_bar_3: 'chart_bar_3',
  chart_pie_1: 'chart_pie_1',
  chat: 'chat',
  check: 'check',
  'checkmark-circle': 'checkmark-circle',
  'checkmark-circle-outline': 'checkmark-circle-outline',
  'checkmark-outlined': 'checkmark-outlined',
  'checkmark-square-outline': 'checkmark-square-outline',
  'checkmark-square-outline-clear': 'checkmark-square-outline-clear',
  chevron_up: 'chevron_up',
  'chevron-bottom': 'chevron-bottom',
  'chevron-left': 'chevron-left',
  'chevron-right': 'chevron-right',
  'chevron-top': 'chevron-top',
  chrome_calendar: 'chrome_calendar',
  circlex: 'circlex',
  clear: 'clear',
  clear_2: 'clear_2',
  clear_circle: 'clear_circle',
  clipboard: 'clipboard',
  clockdelay: 'clockdelay',
  collab: 'collab',
  collapse_all: 'collapse_all',
  column_view_outlined: 'column_view_outlined',
  columns: 'columns',
  'comment-vs': 'comment-vs',
  company: 'company',
  copy: 'copy',
  'copy-s': 'copy-s',
  date: 'date',
  delete: 'delete',
  document_text_outlined: 'document_text_outlined',
  download_outlined: 'download_outlined',
  'download-to': 'download-to',
  drag: 'drag',
  drawing: 'drawing',
  edit: 'edit',
  edit_2: 'edit_2',
  'edit-pencil': 'edit-pencil',
  'editing:clear-formatting': 'editing:clear-formatting',
  'editing:font-color': 'editing:font-color',
  'editing:font-size': 'editing:font-size',
  'editing:line-ending': 'editing:line-ending',
  'editing:opacity': 'editing:opacity',
  'editing:outline-style': 'editing:outline-style',
  'editing:stroke-style': 'editing:stroke-style',
  'editing:stroke-weight': 'editing:stroke-weight',
  enddate: 'enddate',
  error: 'error',
  'error-outlined': 'error-outlined',
  exclamation: 'exclamation',
  expand_all: 'expand_all',
  export: 'export',
  eye: 'eye',
  eye_hide: 'eye_hide',
  filter: 'filter',
  filter_list: 'filter_list',
  flag: 'flag',
  folder: 'folder',
  folder_labeled: 'folder_labeled',
  globe: 'globe',
  grid: 'grid',
  group_equal: 'group_equal',
  group_three: 'group_three',
  help: 'help',
  help_outlined: 'help_outlined',
  hidden_outlined: 'hidden_outlined',
  hierarchy: 'hierarchy',
  'hierarchy-2': 'hierarchy-2',
  history: 'history',
  hourglass_quarter: 'hourglass_quarter',
  image: 'image',
  import: 'import',
  import_2: 'import_2',
  indent: 'indent',
  indent_2: 'indent_2',
  info_outlined: 'info_outlined',
  'insert-a': 'insert-a',
  'insert-b': 'insert-b',
  'insert-i': 'insert-i',
  insurance: 'insurance',
  invisible: 'invisible',
  landing_activity: 'landing_activity',
  landing_assignees: 'landing_assignees',
  landing_subcontractor: 'landing_subcontractor',
  lightbulb: 'lightbulb',
  link: 'link',
  location: 'location',
  lock: 'lock',
  lookahead: 'lookahead',
  lookahead_2: 'lookahead_2',
  menu: 'menu',
  menu_left_right: 'menu_left_right',
  milestone: 'milestone',
  minus: 'minus',
  more_horizontal: 'more_horizontal',
  more_vertical: 'more_vertical',
  'new-tab': 'new-tab',
  outdent: 'outdent',
  outdent_2: 'outdent_2',
  pages_outlined: 'pages_outlined',
  paint_bucket: 'paint_bucket',
  paint_roller: 'paint_roller',
  parenttask: 'parenttask',
  paste: 'paste',
  paste_2: 'paste_2',
  play: 'play',
  plus: 'plus',
  poi: 'poi',
  poi_outlined: 'poi_outlined',
  'prefix-icon': 'prefix-icon',
  print: 'print',
  rain: 'rain',
  redo: 'redo',
  redo_2: 'redo_2',
  remove: 'remove',
  remove_circle_outlined: 'remove_circle_outlined',
  remove_from_trash: 'remove_from_trash',
  responsible: 'responsible',
  restore: 'restore',
  right_rotate_icon: 'right_rotate_icon',
  'rotate-left': 'rotate-left',
  'rotate-right': 'rotate-right',
  search: 'search',
  search_2: 'search_2',
  settings: 'settings',
  share: 'share',
  share_2: 'share_2',
  show: 'show',
  'sms-outlined': 'sms-outlined',
  'social-facebook-line': 'social-facebook-line',
  'social-instagram-line': 'social-instagram-line',
  'social-linkedin-line': 'social-linkedin-line',
  'social-twitter-line': 'social-twitter-line',
  'split-center': 'split-center',
  'split-left': 'split-left',
  'split-right': 'split-right',
  startdate: 'startdate',
  status: 'status',
  status_new: 'status_new',
  subcontractor: 'subcontractor',
  subcontructor: 'subcontructor',
  submit: 'submit',
  subtask: 'subtask',
  'subtask-plus': 'subtask-plus',
  'suffix-icon': 'suffix-icon',
  sun: 'sun',
  support: 'support',
  survey: 'survey',
  swap_horizontal: 'swap_horizontal',
  table: 'table',
  table_2: 'table_2',
  technology: 'technology',
  timeline: 'timeline',
  type: 'type',
  undo: 'undo',
  undo_2: 'undo_2',
  unlock: 'unlock',
  upload: 'upload',
  upload_on_cloud: 'upload_on_cloud',
  upload_on_cloud_2: 'upload_on_cloud_2',
  upload_outlined: 'upload_outlined',
  user: 'user',
  user_hardhat: 'user_hardhat',
  'user-add': 'user-add',
  verified: 'verified',
  'view-circle': 'view-circle',
  visible_outlined: 'visible_outlined',
  warning: 'warning',
  'warning-outline': 'warning-outline',
  wind: 'wind',
  windalt: 'windalt',
  worker: 'worker',
  worker_add: 'worker_add',
  'zoom-in': 'zoom-in',
  'zoom-out': 'zoom-out',
} as const;
