import {VirtualElement} from '@popperjs/core';
import {createContext, useContext} from 'react';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';

export interface GanttContext {
  selector: string | VirtualElement | null;
  onStopEditAction: () => void;
  selectedTask: GanttTask;
  updateSelectedTask: (task: GanttTask) => void;
}
export const GanttContext = createContext<GanttContext>({
  selector: null,
  onStopEditAction: () => null,
  selectedTask: null,
  updateSelectedTask: () => null,
});
export const useGanttContext = () => useContext(GanttContext);
