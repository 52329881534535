import cn from 'classnames';

import Icon from 'shared/components/Icon';

import s from './CtrlBooking.module.scss';

type CtrlBookingProps = {
  className?: string;
  url: string;
  text?: string;
  icon?: string;
  isCollapse: boolean;
  target?: '_black';
  onClick?: () => void;
};

const CtrlBooking = ({className, url, text = 'Text link', target, icon, isCollapse, onClick}: CtrlBookingProps) => {
  return (
    <a
      className={cn(s.ctrlBooking, isCollapse && s.ctrlBooking_isCollapse, className)}
      href={url}
      target={target}
      onClick={onClick}
    >
      {icon && <Icon name={icon} className={s.ctrlBooking__icon} colorFill />}
      <span className={s.ctrlBooking__text}>{text}</span>
    </a>
  );
};

export default CtrlBooking;
