import {useQuery} from 'react-query';

import GroupsService from 'api/groups';

import {QUERY_CACHE_KEYS} from '../constants/queryCache';

export function useGetGroup(groupId: string) {
  return useQuery({
    queryKey: QUERY_CACHE_KEYS.getGroupById(groupId),
    queryFn: () => GroupsService.getGroupByGroupId(groupId),
  });
}
