import {boolean, object, SchemaOf, string} from 'yup';

import {DEFAULT_REQUIRED_MESSAGE} from 'shared/helpers/validationSchemas';

import {TaskImportClientMapping, TasksImportConfig} from '../../utils/types';

export const schema: SchemaOf<TasksImportConfig> = object({
  importWithHierarchy: boolean().nullable(),
  dateFormat: string().nullable().required(DEFAULT_REQUIRED_MESSAGE),
  defaultProject: string().nullable().required(DEFAULT_REQUIRED_MESSAGE),
  mapping: object({
    uniqueId: string().nullable().required(DEFAULT_REQUIRED_MESSAGE),
    name: string().nullable().required(DEFAULT_REQUIRED_MESSAGE),
    schedStartDate: string().nullable().required(DEFAULT_REQUIRED_MESSAGE),
    schedEndDate: string().nullable().required(DEFAULT_REQUIRED_MESSAGE),
    subcontractor: string().nullable(),
    assignedNames: string().nullable(),
    location: string().nullable(),
    predecessors: string().nullable(),
    description: string().nullable(),
    archived: string().nullable(),
    shouldImport: string().nullable(),
    outlineCode: string().nullable(),
    cost: string().nullable(),
    type: string().nullable(),
  }),
});

export function getDefaultValues(dateFormat: string): TasksImportConfig {
  return {
    importWithHierarchy: false,
    dateFormat: dateFormat,
    defaultProject: null,
    mapping: {
      uniqueId: null,
      name: null,
      schedStartDate: null,
      schedEndDate: null,
      subcontractor: null,
      assignedNames: null,
      predecessors: null,
      location: null,
      description: null,
      archived: null,
      shouldImport: null,
      outlineCode: null,
      cost: null,
      type: null,
    },
  };
}

export function getMapping(formikValues, importSettings): TaskImportClientMapping {
  return Object.entries(formikValues).reduce((acc, [key]) => {
    acc[key] = key in importSettings ? importSettings[key] : null;
    return acc;
  }, {} as TaskImportClientMapping);
}
