import {TFunction} from 'i18next';

export const defaultFormValues = {
  projectId: '',
  costImpact: undefined,
  name: '',
  description: '',
  endDate: null,
  startDate: null,
  responsible: '',
  status: null,
  issueType: null,
  responsibleOrgId: '',
  culpableOrgId: '',
  impact: '',
  activities: [],
};

export const NEW_ACTIVITY_ID = 'new';

export const getSaveConfirmationPayload = (t: TFunction) => ({
  description: t('task:confirmation.beforeSave.text', 'Do you want to save your changes?'),
  acceptButton: t('task:confirmation.beforeSave.accept', 'Save Task'),
  cancelButton: t('task:confirmation.beforeSave.reject', "Don't Save"),
});
