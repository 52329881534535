import {Row, TableOptions} from 'react-table';

import s from 'shared/components/Table/Table.module.scss';

import {TableColumn} from './TableColumns';

export interface TableRowProps<D extends Record<string, unknown>> extends TableOptions<D> {
  onClick?: (row: Row<D>) => void;
  row: Row<D>;
  cellClassName: string;
  cellRenderProps: Record<string, unknown>;
  showPreloader?: boolean;
}

export const TableRow = <D extends Record<string, unknown>>({
  cellClassName,
  cellRenderProps,
  onClick,
  row,
}: TableRowProps<D>) => {
  const rowProps = row.getRowProps({
    style: {minHeight: row.minRowHeight, cursor: 'pointer', height: row.fixedRowHeight},
  });
  return (
    <div className={s.tableWorkers__line} {...rowProps} style={rowProps.style} onClick={() => onClick && onClick(row)}>
      {row.cells.map((cell, index) => {
        return (
          <TableColumn
            cell={cell}
            key={cell.column.id}
            columnId={cell.column.id}
            columnIndex={index}
            className={cellClassName}
            renderProps={cellRenderProps}
          />
        );
      })}
    </div>
  );
};
