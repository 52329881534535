// eslint-disable @typescript-eslint/no-explicit-any
type Debounced<F extends (...args: any[]) => any> = ((...args: Parameters<F>) => void) & {cancel?: () => void};
export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout = 0;

  const debounced: Debounced<F> = (...args) => {
    clearTimeout(timeout);
    timeout = window.setTimeout(() => func(...args), waitFor);
  };
  debounced.cancel = () => clearTimeout(timeout);

  return debounced;
};
