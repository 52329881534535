import * as Sentry from '@sentry/browser';
import {injectable} from 'inversify';

type TaskUpdateJob = (tempIdMap: Map<number, string>) => Promise<any>;

type QueueEntry = {
  job: TaskUpdateJob;
  resolve: (any) => void;
  reject: (e: Error) => void;
};

export const FifoQueueStatusTypes = {idle: 'idle', running: 'running'} as const;
type FifoQueueStatus = keyof typeof FifoQueueStatusTypes;

@injectable()
export class TaskUpdateFifoQueue {
  private _jobQueue: QueueEntry[] = [];
  private _queueStatus: FifoQueueStatus = FifoQueueStatusTypes.idle;
  private _idMap: Map<number, string> = new Map();

  private start() {
    if (this._jobQueue.length && this._queueStatus === FifoQueueStatusTypes.idle) {
      this._queueStatus = FifoQueueStatusTypes.running;
      const {job, reject, resolve} = this._jobQueue.shift();
      job(this._idMap)
        .then(resolve)
        .catch((e) => {
          Sentry.captureException(e);
          reject(e);
        })
        .finally(() => {
          this._queueStatus = FifoQueueStatusTypes.idle;
          this.start();
        });
    }
  }

  get queueStatus() {
    return this._queueStatus;
  }

  get idMap() {
    return this._idMap;
  }

  get jobQueue() {
    return this._jobQueue;
  }

  public changeId(oldId: number, newId: string) {
    this._idMap.set(oldId, newId);
  }

  public enqueue(job: TaskUpdateJob) {
    return new Promise((resolve, reject) => {
      this._jobQueue.push({job, resolve, reject});
      this.start();
    });
  }
}
