import cn from 'classnames';

import s from './CommentsClear.module.scss';

type CommentsClearProps = {
  className?: string;
};

const CommentsClear = ({className}: CommentsClearProps) => {
  return (
    <div className={cn(s.CommentsClear, className)}>
      <h3 className={s.CommentsClear__title}>Here is no comments yet</h3>
      <div className={s.CommentsClear__description}>You can be the first and leave a comment in the mobile App</div>
    </div>
  );
};

export default CommentsClear;
