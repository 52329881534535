import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';

import {toShortIso} from 'shared/helpers/dates';
import {TaskObjectType} from 'shared/models/task/const';
import {TaskColorMode} from 'shared/models/task/task';

import {GanttTask} from '../components/Gantt/types';
import {getIssuesBadge} from '../components/Gantt/utils/config';
import {getDrawTaskDuration} from '../components/Gantt/utils/date';
import {getVisibleDateRange, getTaskDateRange, getQuantityIssuesInDay} from '../components/Gantt/utils/gantt';

import {
  calculateRowHeight,
  getTaskFeedback,
  getTaskColor,
  getTaskOpenIssuesIds,
  getTaskAbbrev,
  getColorTasksModeFromLocalStorage,
} from './functions';

export function generateTaskLayer(gantt: GanttStatic, task: GanttTask, skipPristineDates = true) {
  if (task.datesIsPristine && skipPristineDates) {
    return false;
  }
  if (task.date_list?.length && [TaskObjectType.activity, TaskObjectType.task].includes(task.object_type)) {
    const [visibleStartDate, visibleEndDate] = getVisibleDateRange(gantt, getTaskDateRange(task));
    const visibleDuration = Math.abs(dayjs(visibleStartDate).diff(visibleEndDate, 'day'));
    const sizes = gantt.getTaskPosition(task, visibleStartDate, visibleEndDate);
    const container = document.createElement('div');
    const cellRangeProps = getDrawTaskDuration(task);
    const cellSize = sizes.width / visibleDuration;
    const squareSide = calculateRowHeight(sizes.rowHeight);
    const colorTasksMode = getColorTasksModeFromLocalStorage(task.projectId);
    container.className = 'lookahead_container';
    container.dataset.taskId = task.id;

    container.style.left = sizes.left + 'px';
    container.style.top = (sizes.top || 0) + 'px';
    container.style.height = sizes.rowHeight + 'px';

    cellRangeProps.forEach((item) => {
      // we check end date with inclusive operator because of how we set boundary dates for gantt timeline
      // if we want to show tasks util 08.28.2022 gantt.config.end_date will be 08.29.2022 00:00
      if (item.date < gantt.config.start_date || item.date >= gantt.config.end_date) {
        return;
      }
      const padding = (cellSize - squareSide) / 2;
      const square = document.createElement('div');
      square.className = 'lookahead_element';
      square.style.width = squareSide + 'px';
      square.style.height = squareSide + 'px';
      square.style.margin = `0 ${padding}px`;
      square.setAttribute('task-date', toShortIso(item.date));

      if (!item.isFilled) {
        square.classList.add('lookahead_element_empty');
      } else {
        square.classList.add('lookahead_element_filled');
        // Change the innerText based on colorTasksMode
        if (colorTasksMode === TaskColorMode.LABOR) {
          square.innerText = getTaskFeedback(task, toShortIso(item.date), 'daily_labor');
        } else {
          square.innerText = getTaskAbbrev(gantt, task, item.date);
        }
        square.style.backgroundColor = getTaskColor(gantt, task, toShortIso(item.date));

        if (task.status_issue_task_ids_pairs?.length) {
          const issueCount = getQuantityIssuesInDay(
            gantt,
            getTaskOpenIssuesIds(task.status_issue_task_ids_pairs),
            item.date,
          );
          if (issueCount) {
            const badge = getIssuesBadge(issueCount, 'lookahead__badge');
            square.append(badge);
          }
        }
      }

      container.append(square);
    });

    return container;
  } else if (task.object_type === TaskObjectType.summary) {
    const sizes = gantt.getTaskPosition(task, ...getVisibleDateRange(gantt, getTaskDateRange(task)));
    const container = document.createElement('div');
    container.className = 'wbs_container';
    container.dataset.taskId = task.id;

    container.style.left = sizes.left + 'px';
    container.style.width = sizes.width + 'px';
    container.style.top = (sizes.top || 0) + sizes.rowHeight * 0.33 + 'px';
    container.style.height = sizes.rowHeight * 0.33 + 'px';

    return container;
  }
  return false;
}
