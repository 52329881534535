import './dailies-card.scss';
import {DailiesCardComments} from './DailiesCardComments';
import {DailiesCardReportContent} from './DailiesCardReportContent';
import {DailiesCardHeader} from './DailyCardHeader';
import {DailyCardSeparator} from './DailyCardSeparator';

function DailiesReportCard({children, taskId}: {children: React.ReactNode; taskId?: string}) {
  return (
    <section className="dailies-card" data-task-id={taskId}>
      {children}
    </section>
  );
}

DailiesReportCard.Header = DailiesCardHeader;
DailiesReportCard.Separator = DailyCardSeparator;
DailiesReportCard.Content = DailiesCardReportContent;
DailiesReportCard.Comments = DailiesCardComments;

export {DailiesReportCard};
