import {Placement} from '@popperjs/core';
import cn from 'classnames';
import {cloneElement, isValidElement, ReactElement, ReactNode, Ref, useEffect, useMemo, useRef, useState} from 'react';
import {createPortal} from 'react-dom';
import {usePopper} from 'react-popper';

import classes from './tooltip.module.scss';

type TooltipProps<T extends HTMLElement = HTMLElement> = {
  title?: string;
  text: ReactNode;
  children: ReactElement<{ref: Ref<T>}>;
  placement?: Placement;
  type?: 'error' | 'edit' | 'help';
  isHide?: boolean;
  showAfterMs?: number;
};

const CoreTooltip = <T extends HTMLElement>({
  text,
  title,
  placement = 'auto',
  type,
  children,
  isHide,
  showAfterMs = 0,
}: TooltipProps<T>) => {
  const [showPopper, setShowPopper] = useState(false);
  const [referenceElement, setReferenceElement] = useState<T>(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);
  const modifiers = useMemo(
    () => [
      {name: 'arrow', options: {element: arrowElement, padding: 5}},
      {name: 'offset', options: {offset: [0, type === 'help' ? 2 : 10]}},
    ],
    [arrowElement, type],
  );
  const {styles, attributes} = usePopper(referenceElement, popperElement, {
    modifiers,
    placement,
    strategy: 'absolute',
  });
  const timeout = useRef(null);

  useEffect(() => {
    const show = () => {
      if (!showAfterMs) {
        setShowPopper(true);
        return;
      }
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(() => {
        setShowPopper(true);
      }, showAfterMs);
    };
    const hide = () => {
      clearTimeout(timeout.current);
      setShowPopper(false);
    };
    if (referenceElement) {
      referenceElement.addEventListener('mouseenter', show);
      referenceElement.addEventListener('mouseleave', hide);
      referenceElement.addEventListener('click', hide);
    }
    return () => {
      if (referenceElement) {
        referenceElement.removeEventListener('mouseenter', show);
        referenceElement.removeEventListener('mouseleave', hide);
        referenceElement.removeEventListener('click', hide);
      }
    };
  }, [referenceElement, setShowPopper, showAfterMs]);

  useEffect(() => {
    /* Temporary solution
     * when button is reference element and it's become disabled
     * mouseleave event will not trigger and tooltip will be visible forever
     * TODO: i think we needd add extra wrapper around reference element
     * */
    if (referenceElement) {
      const observer = new MutationObserver((mutations) => {
        if (mutations.some((m) => m.oldValue === null)) {
          setShowPopper(false);
        }
      });

      observer.observe(referenceElement, {
        attributes: true,
        attributeFilter: ['disabled'],
        attributeOldValue: true,
      });
      return () => {
        observer.disconnect();
      };
    }
  }, [referenceElement]);

  if (isHide) return children;

  return (
    <>
      {isValidElement(children) && cloneElement(children, {ref: setReferenceElement})}
      {showPopper &&
        (title || text) &&
        createPortal(
          <div
            ref={setPopperElement}
            style={{...styles.popper, visibility: showPopper ? 'visible' : 'hidden', zIndex: 99999}}
            className={cn('react-popper', classes['react-popper'], classes[`react-popper--${type}`])}
            {...attributes.popper}
          >
            {title && <div className="react-popper__title">{title}</div>}
            <div className="react-popper__text">{text}</div>
            <div data-popper-arrow ref={setArrowElement} style={styles.arrow}></div>
          </div>,
          document.body,
        )}
    </>
  );
};
export default CoreTooltip;
