import {GanttStatic} from 'dhtmlx-gantt';
import {TFunction} from 'i18next';

import Svg from 'assets/images/svg-sprite.svg';
import SpriteSVG from 'assets/images/svg-statuses.svg';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {getColumnEditors, generateColumn} from 'modules/Tasks/components/Gantt/utils/config';
import {getGanttColumnsLabels, GANTT_COLUMNS_NAMES} from 'modules/Tasks/components/Gantt/utils/constants';
import {
  costImpactTypes,
  getPrettyTaskStatuses,
  getTaskIssueImpactOptions,
  getTaskIssueTypeOptions,
  taskStatusIcons,
} from 'shared/constants/statuses';
import {subtract} from 'shared/helpers/dates';
import {TaskGanttModel} from 'shared/models/task/task';

export function getColumnsLabels(t: TFunction) {
  const base = getGanttColumnsLabels(t);
  Object.assign(base, {
    [GANTT_COLUMNS_NAMES.name]: t('gantt:columns.issues.activity', 'Issue'),
    [GANTT_COLUMNS_NAMES.subcontractor]: t('gantt:columns.issues.subcontractor', 'Resolving Company'),
    [GANTT_COLUMNS_NAMES.description]: t('gantt:columns.issues.description', 'Description'),
  });
  return base;
}

export function getColumns(gantt: GanttStatic, t?: TFunction) {
  const editors = getColumnEditors();
  // TODO: after lng switch bellow consts became outdated, we need somehow update all dicts on language switch
  const labels = gantt.getColumnLabels(t);
  const issueTypes = getTaskIssueTypeOptions(t);
  const impactTypes = getTaskIssueImpactOptions(t);

  return [
    {
      name: GANTT_COLUMNS_NAMES.checkbox,
      hide: false,
      width: 45,
      resize: false,
      label: `<div class="gantt__custom_checkbox">
                <input class="custom_checkbox_id" type="checkbox"/>
                <label class="gantt__custom_checkbox_label"></label>
              </div>`,
      template: (task: GanttTask) => `
              <button class="gantt__checkbox">
              ${
                !gantt.isSelectedTask(task.id)
                  ? `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="gantt__checkbox_uncheck">
                  <rect x="0.5" y="0.5" width="15" height="15" rx="4.5" stroke="#A5A4A4"/>
                </svg>`
                  : `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="gantt__checkbox_check">
                  <rect x="4.5" y="4.5" width="15" height="15" rx="4.5" fill="#053FE3" stroke="#053FE3"/>
                  <path d="M9.5 12L11.5 14.5L15 10" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>`
              }
            </button>`,
    },
    {
      name: GANTT_COLUMNS_NAMES.uniqueId,
      label: labels[GANTT_COLUMNS_NAMES.uniqueId],
      align: 'left',
      resize: true,
      width: 100,
      hide: true,
    },
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.name,
        width: 400,
        template: (task: GanttTask) => {
          return `
          <div class="gantt__task-name">
            <p data-cy="gantt-activity-item" class="gantt__task-name_link ${
              !task.name ? 'gantt-edit__empty-field' : ''
            }">${task.name}</p>
            <div class="gantt__task-name_open_wrap" title="${task.name}">
                <svg class="gantt__task-name_open" width="24" height="24" style="${
                  task?.time_removed ? 'display: none' : ''
                }" fill="#A5A4A4"><path fill-rule="evenodd" clip-rule="evenodd" d="M2 12C2 6.47715 6.47715 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM4 12C4 16.4183 7.58172 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM13 12.5C13 12.2239 12.7761 12 12.5 12H11.5C11.2239 12 11 12.2239 11 12.5V15.5C11 15.7761 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V12.5ZM12.5 8C12.7761 8 13 8.22386 13 8.5V9.5C13 9.77614 12.7761 10 12.5 10H11.5C11.2239 10 11 9.77614 11 9.5V8.5C11 8.22386 11.2239 8 11.5 8H12.5Z"/></svg>
            </div>
          </div>`;
        },
      },
      labels,
    ),
    {
      name: GANTT_COLUMNS_NAMES.activityNames,
      label: labels[GANTT_COLUMNS_NAMES.activityNames],
      resize: true,
      hide: false,
      template: (task: GanttTask) => {
        return task.activities?.length
          ? task.activities?.map(
              (activity) => `
            <div class="gantt__task-name gantt__activity-name_open" title="${activity.name}" data-activity-id="${
                activity.id
              }">
              <p data-cy="gantt-activity-name" class="gantt__activity-name_link ${
                !activity.name ? 'gantt-edit__empty-field' : ''
              }">
                ${activity.name}
              </p>
            </div>`,
            )
          : `
        <div class="gantt__task-name gantt__task-name_link" data-issue-icon=true title="${task.name}">
          <svg class="icon icon--color-fill status-element__icon" width="10" height="10">
            <use xlink:href="${Svg}#add-plus" />
          </svg>
          <p>
            ${t('gantt:link_activity', 'Link Activity')}
          </p>
        </div>
        `;
      },
    },
    generateColumn({name: GANTT_COLUMNS_NAMES.description, width: 400}, labels),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.startDate,
        width: 125,
        editor: {
          ...editors.startDateEditor,
          map_to: 'start_date_real',
        },
        validate: {
          max: 'end_date_real',
        },
        template: (task: TaskGanttModel) => {
          return task.start_date_real
            ? gantt.templates.grid_date_format(task.start_date_real, GANTT_COLUMNS_NAMES.startDate)
            : '';
        },
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.endDate,
        validate: {
          min: 'start_date_real',
        },
        width: 125,
        editor: {
          ...editors.actualEndDateEditor,
          map_to: 'end_date_real',
        },
        template: (task: TaskGanttModel) => {
          return task.end_date_real
            ? gantt.templates.grid_date_format(subtract(task.end_date_real, 1).toDate(), GANTT_COLUMNS_NAMES.endDate)
            : '';
        },
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.taskStatus,
        hide: true,
        width: 140,
        resize: true,
        editor: {type: 'issueStatusEditor', map_to: GANTT_COLUMNS_NAMES.taskStatus},
        template: (task) => {
          const taskXlink = `${SpriteSVG}#${taskStatusIcons[task.status] || task.status}`;
          return `<div class="status-element status-element--tba">
                  <svg class="icon icon--color-fill status-element__icon" width="12" height="12">
                      <use xlink:href=${taskXlink}>
                  </svg>
                  <span class="status-element__name" style="font-family: Graphik LCG,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif; font-weight: 400">
                    ${getPrettyTaskStatuses(t)[task.status]}
                  </span>
            </div>`;
        },
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.responsible,
        width: 130,
        editor: {type: 'responsibleIssueEditor', map_to: GANTT_COLUMNS_NAMES.responsible},
        template: (task: TaskGanttModel) => {
          if (task) {
            if (task.responsible?.length) {
              return task.responsible[0].member_name;
            }
            return `<div>${t('gantt:responsible.unassigned', 'Unassigned')}</div>`;
          }
          return '';
        },
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.resolvingCompany,
        width: 140,
        editor: editors.subcontractorEditor,
        template: function (task) {
          if (task.responsible_org?.group?.name) {
            return task.responsible_org.group.name;
          }
          if (task?.responsible_party) {
            return task.resposible_party;
          }
          return '';
        },
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.ownership,
        editor: {type: 'subcontractorEditor', map_to: GANTT_COLUMNS_NAMES.ownership},
        template: (task) => {
          if (task.culpable_org?.group?.name) {
            return task.culpable_org.group.name;
          }
          return '';
        },
        width: 140,
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.issueType,
        editor: {type: 'issueTypeEditor', map_to: GANTT_COLUMNS_NAMES.issueType},
        template: (task) => {
          return issueTypes.find((opt) => opt.value === task.issue_type)?.label;
        },
        width: 140,
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.severity,
        width: 140,
        editor: {type: 'issuesSeverityEditor', map_to: GANTT_COLUMNS_NAMES.severity},
        template: (task) => {
          return impactTypes.find((opt) => opt.value === task.impact)?.label;
        },
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.trackNumber,
        width: 140,
      },
      labels,
    ),
    generateColumn(
      {
        name: GANTT_COLUMNS_NAMES.costImpact,
        width: 140,
        editor: {type: 'costImpactEditor', map_to: GANTT_COLUMNS_NAMES.costImpact},
        template: (task) => {
          return task.cost_impact === null
            ? ''
            : costImpactTypes.find((opt) => !!opt.value === !!task.cost_impact)?.label;
        },
      },
      labels,
    ),
  ];
}
