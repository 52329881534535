import {GanttStatic} from 'dhtmlx-gantt';
import equal from 'fast-deep-equal';
import {TFunction} from 'i18next';
import {toast} from 'react-toastify';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {extendWorkDateRange} from 'modules/Tasks/components/Gantt/utils/date';
import {generateTaskLayer} from 'modules/Tasks/utils/generateTaskLayer';
import {GanttNames} from 'shared/constants/gantt';
import {isSameDay} from 'shared/helpers/dates';
import {debounce} from 'shared/helpers/debounce';
import {useMixpanel} from 'shared/hooks/analytics/useMixpanel';
import {TaskObjectType} from 'shared/models/task/const';

export const addLookaheadTimelineLayer = (
  gantt: GanttStatic,
  mixpanel: ReturnType<typeof useMixpanel>,
  t: TFunction,
) => {
  // disable default task renderer
  gantt.config.type_renderers[gantt.config.types.task] = () => null;

  const domUtils = gantt.utils.dom;
  const tasksContainer = document.querySelector<HTMLDivElement>('.gantt_task');
  const getTaskId = (e: MouseEvent) =>
    e.target instanceof HTMLElement ? e.target.closest<HTMLDivElement>(`[data-task-id]`)?.dataset?.taskId : null;
  let clickedTaskId: string;

  const updateTask = debounce((task: GanttTask) => {
    gantt.updateTask(task.id, task);
  }, 1000);

  const onMouseDown = (e: MouseEvent) => {
    if (gantt.config.readonly) {
      return;
    }

    if (e.target instanceof HTMLElement) {
      const taskId = getTaskId(e);
      if (taskId) {
        clickedTaskId = taskId;
      }
    }
  };
  const onMouseUp = (e: MouseEvent) => {
    if (gantt.config.readonly) {
      return;
    }

    const currentPosition = domUtils.getRelativeEventPosition(e, gantt.$task_data);
    if (e.target instanceof HTMLDivElement && clickedTaskId) {
      const taskId = getTaskId(e);
      if (gantt.isTaskExists(taskId)) {
        const task: GanttTask = gantt.getTask(taskId);
        const isClickableArea = !!e.target.closest('.gantt_task_row') || !!e.target.closest('.lookahead_container');
        const isLeftClick = e.button === 0;
        const preventEdit =
          task.object_type !== TaskObjectType.activity || taskId !== clickedTaskId || task.time_removed || !isLeftClick;
        if (isClickableArea && !preventEdit) {
          const initialDateList = task.date_list;
          const clickedDate = gantt.dateFromPos(currentPosition.x);
          const dateList = extendWorkDateRange(task.datesIsPristine ? [] : task.date_list, clickedDate);
          task.datesIsPristine = false;
          if (dateList.length) {
            // Some date alterations are not permitted, depending on actual_start/actual_end
            if (task.actual_start && task.actual_end) {
              toast.warning(
                t('gantt:toast.warning.activity_actual_both', 'Dates cannot be changed for actualized activities!'),
              );
              return;
            }
            if ((task.actual_start && clickedDate < task.actual_start) || isSameDay(clickedDate, task.actual_start)) {
              toast.warning(
                t(
                  'gantt:toast.warning.activity_actual_start',
                  'Activities cannot be rescheduled earlier than an actual start date!',
                ),
              );
              return;
            }

            Object.assign(task, {date_list: dateList, dataVersion: +new Date()});
            const isDateListChanged = !equal(initialDateList, dateList);
            if (isDateListChanged) {
              task.lastChangedFields.dateList = {newValue: dateList, oldValue: initialDateList};
              const isAddingDate = dateList.length > initialDateList.length;
              mixpanel.track(mixpanel.events[GanttNames.lookahead].timeline[isAddingDate ? 'create' : 'delete']);
            }
            gantt.refreshTask(task.id, false);
            updateTask(task);
          } else {
            toast.warning(
              t('gantt:toast.warning.activity_duration', 'The duration of the activity cannot be less than one day!'),
            );
          }
        }
        clickedTaskId = null;
      }
    }
  };

  const layerId = gantt.addTaskLayer((task: GanttTask) => {
    return generateTaskLayer(gantt, task);
  });

  tasksContainer.addEventListener('mousedown', onMouseDown);
  tasksContainer.addEventListener('mouseup', onMouseUp);
  return () => {
    tasksContainer.removeEventListener('mousedown', onMouseDown);
    tasksContainer.removeEventListener('mouseup', onMouseUp);
    gantt.removeTaskLayer(layerId);
  };
};
