import cn from 'classnames';
import {FC} from 'react';

import {CommentsTimeline} from '../CommentsTimeline/CommentsTimeline';

import s from './ChatTimeline.module.scss';

type Props = {
  isLoading?: boolean;
  taskId: string;
};

const ChatTimeline: FC<Props> = ({taskId}) => (
  <div className={cn(s.ChatTimeline, 'loader-container')}>
    <CommentsTimeline taskId={taskId} />
  </div>
);

export default ChatTimeline;
