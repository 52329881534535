import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import SearchInput, {SearchInputProps} from 'shared/components/CoreNewUI/SearchInput/SearchInput';

type SearchFieldProps = {
  className?: string;
  onClick?: () => void;
  active?: boolean;
};

const SearchField = ({className, onClick, active, ...props}: SearchFieldProps & SearchInputProps) => {
  return (
    <div className={className} onClick={onClick}>
      <SearchInput
        {...props}
        iconPosition="start"
        height="h36"
        button={
          <CtrlButton
            id="toolbarFiltersToggle"
            color="clear"
            size="xs"
            icon="filter_list"
            iconOnly={true}
            activeDot={active}
            active={active}
          >
            Filter
          </CtrlButton>
        }
      />
    </div>
  );
};

export default SearchField;
