import {useEffect, useRef, useState} from 'react';
import {QueryKey} from 'react-query';
import {useHistory, useRouteMatch} from 'react-router';

import {useLocalizedRoutes} from 'shared/constants/routes';
import {CompanyWorker} from 'shared/models/worker';

import {WorkerHistoryType} from '../WorkerDetails';
import {useQueryCache} from 'shared/hooks/useQueryCache/useQueryCache';

export default function useProjectWorker() {
  const [projectWorker, setProjectWorker] = useState<CompanyWorker>(null);
  const {cacheHelper} = useQueryCache();
  const history = useHistory<WorkerHistoryType>();
  const routes = useLocalizedRoutes();
  const match = useRouteMatch<{id: string}>([routes.projectWorker, routes.worker]);
  const queryKeyRef = useRef<QueryKey>('');

  const refetchProjectWorker = () => {
    const worker = findWorkerFromQuery();
    setProjectWorker(worker);
    return {data: worker};
  };

  const findWorkerFromQuery = () => {
    const cachedQuery = cacheHelper.findRecentPagedQuery(queryKeyRef.current);
    return cachedQuery?.state?.data?.pages?.reduce<CompanyWorker>((acc, page) => {
      const cachedWorker = page?.find((w) => w.id === match.params.id) as CompanyWorker;
      if (cachedWorker) {
        acc = Object.assign({}, cachedWorker);
        return acc;
      }
      return acc;
    }, null);
  };

  useEffect(() => {
    if (history.location.state?.queryKey) queryKeyRef.current = history.location.state?.queryKey;
    const worker = findWorkerFromQuery();
    setProjectWorker(worker);
    if (!worker?.id && match.params.id !== 'new') {
      history.push(routes.projectWorkers);
    }
  }, [projectWorker?.id, history.location.state?.queryKey]);

  return {
    refetchProjectWorker,
    projectWorker,
  };
}
