import dayjs from 'dayjs';
import {ChangeEvent, Dispatch, SetStateAction, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {reach, ValidationError} from 'yup';

import DatePicker from 'shared/components/CoreForm/DatePicker';
import Icon from 'shared/components/Icon';
import {Pill} from 'shared/components/Pill/Pill';
import {HSpacer} from 'shared/components/Spacer/HSpacer';
import {IconsMap} from 'shared/constants/icons';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {normalizeI18Key} from 'shared/utils/normalizeI18Key';

import {FeedbackImage, FeedbackMessage} from '../hooks/useHandleSubmitProgressReport';
import {useProgressReport} from '../hooks/useProgressReport';
import {validationSchema} from '../utils/validationSchema';

import styles from './components.module.scss';

export type CommentValues = {
  comment: FeedbackMessage;
  image: FeedbackImage;
};

interface ProgressReportCommentFormProps {
  commentValues: CommentValues;
  onChange: (values: CommentValues) => void;
  taskId: string;
  onImageErrors: Dispatch<SetStateAction<ImageError>>;
}

type ImageDetail = {
  index: number;
  message: string;
  name: string;
};

export type ImageError = {
  fileSize: ImageDetail[];
  fileType: ImageDetail[];
};

/**
 * ! Do not remove!
 * t('dailies:progress_report.comments_form.errors.unsupported_file_format')
 * t('dailies:progress_report.comments_form.errors.file_size_too_large')
 */

export function ProgressReportCommentForm({
  commentValues,
  onChange,
  taskId,
  onImageErrors,
}: ProgressReportCommentFormProps) {
  const {t} = useTranslation('dailies');
  const {data} = useProgressReport(taskId);
  const [imageErrors, setImageErrors] = useState<ImageError>(null);
  const {
    mixpanel: {events, track},
  } = useAnalyticsService();

  function handleChangeText(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const updatedComment: FeedbackMessage = {
      ...commentValues.comment,
      comment: value,
    };
    track(events.dailies.dailiesActivityPopupEnterComment);
    onChange({...commentValues, comment: updatedComment});
  }

  function handleAddImages(e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const files = e.target.files;
    const newImages: File[] = [];
    for (let i = 0; i < files.length; i++) {
      newImages.push(files.item(i));
    }
    // For nested schemas, reach will retrieve an inner schema based on the provided path.
    reach(validationSchema, 'imageFiles')
      .validate(newImages, {abortEarly: false})
      .then(() => {
        setImageErrors(null);
        onChange({
          ...commentValues,
          image: {...commentValues.image, images: [...commentValues.image.images, ...newImages]},
        });
      })
      .catch((err: unknown) => {
        if (err instanceof ValidationError) {
          const formattedErrors = err.inner.reduce((acc, currError) => {
            const index = parseInt(currError.path.replace(/\D/g, ''), 10);
            const originalValue = currError.params.originalValue as File;
            const errObj: ImageDetail = {
              index: Number.isNaN(index) ? undefined : index,
              message: t(normalizeI18Key(currError.message)),
              name: originalValue.name,
            };
            if (!acc[currError.type]) {
              acc[currError.type] = [errObj];
            } else {
              acc[currError.type].push(errObj);
            }
            return acc;
          }, {} as ImageError);

          setImageErrors(formattedErrors);

          const erroneousFileNames = new Set(
            formattedErrors.fileSize.concat(formattedErrors.fileType).map((errObj) => {
              return errObj?.name;
            }),
          );
          const filteredFiles = newImages.filter((file) => !erroneousFileNames.has(file.name));

          onChange({
            ...commentValues,
            image: {...commentValues.image, images: [...commentValues.image.images, ...filteredFiles]},
          });

          onImageErrors(formattedErrors);
        }
      });
    if (e.target) {
      e.target.value = '';
    }
  }

  function removeStagedImage(idxToRemove: number) {
    const updatedImages = Array.from(commentValues.image.images).filter((_, i) => i !== idxToRemove);
    onChange({...commentValues, image: {...commentValues.image, images: updatedImages}});
  }

  function removeStagedImageErrors(errorType: 'fileSize' | 'fileType', idxToRemove: number) {
    setImageErrors((prevErrors) => ({
      ...prevErrors, // spread the existing errors
      [errorType]: prevErrors[errorType]?.filter((_, idx) => idx !== idxToRemove),
    }));

    onImageErrors((prevErrors) => ({
      ...prevErrors, // spread the existing errors
      [errorType]: prevErrors[errorType]?.filter((_, idx) => idx !== idxToRemove),
    }));
  }

  function handleChangeDate(dateTag: Date) {
    onChange({
      ...commentValues,
      comment: {...commentValues.comment, dateTag},
      image: {...commentValues.image, dateTag},
    });
  }

  function filterDate(selectedDate: Date) {
    const startOfSelectedDate = dayjs(selectedDate).startOf('day');
    const isToday = startOfSelectedDate.isSame(dayjs().startOf('day'), 'day');
    const isInDateList = data?.initialTask.dateList.some((dlDate) =>
      startOfSelectedDate.isSame(dayjs(dlDate).startOf('day'), 'day'),
    );

    return isToday || isInDateList;
  }

  function getDayClassName(day: Date) {
    return filterDate(day) ? 'progressReportfilteredDate' : null;
  }

  function flagAsPotentialIssue(e: ChangeEvent<HTMLInputElement>) {
    track(events.dailies.dailiesActivityPopupFlagIssue, {checked: e.target.checked});
    onChange({...commentValues, comment: {...commentValues.comment, flagAsPotentialIssue: e.target.checked}});
  }

  function clickImageUpload() {
    track(events.dailies.dailiesActivityPopupAddPhoto);
  }

  function clickCalendar() {
    track(events.dailies.dailiesActivityPopupCalendarPicker);
  }

  return (
    <>
      <label htmlFor="checkbox" className={styles.checkboxContainer}>
        <p className={styles.flag}>{t('progress_report.comments_form.potential_issue')}</p>
        <HSpacer />
        <input
          type="checkbox"
          id="checkbox"
          checked={commentValues.comment.flagAsPotentialIssue}
          onChange={flagAsPotentialIssue}
        />
      </label>
      <div className={styles.inputContainer}>
        <input
          className={styles.textInput}
          name="comment"
          onChange={handleChangeText}
          placeholder={t('progress_report.comments_form.input_placeholder')}
          type="text"
          value={commentValues.comment.comment}
        />
        <label htmlFor="image_uploads" className={styles.fileInputLabel}>
          <input
            onChange={handleAddImages}
            onClick={() => setImageErrors(null)}
            type="file"
            id="image_uploads"
            name="file"
            accept="image/jpg, image/jpeg, image/png"
            multiple
          />
          <Icon colorFill onClick={clickImageUpload} name={IconsMap.image} size={24} />
        </label>
        <div className={styles.dateInputWrapper}>
          <DatePicker
            calendarClassName={styles.calendar}
            className={styles.dateInput}
            dateFormat="M/d/yy"
            dayClassName={getDayClassName}
            filterDate={filterDate}
            id="date-picker"
            onChange={handleChangeDate}
            onInputClick={clickCalendar}
            selected={dayjs(commentValues.comment.dateTag).toDate()}
          />
        </div>
      </div>

      {imageErrors?.fileSize?.length > 0 ? (
        <span className={styles.imageFileSizeErrorMessage}>
          {t('progress_report.comments_form.errors.file_size_error', {
            files: imageErrors?.fileSize.map((image) => image.name).join(', '),
          })}
        </span>
      ) : null}

      <ul className={styles.imageList}>
        {imageErrors?.fileSize?.length
          ? imageErrors.fileSize.map((fileSizeErr, idx) => (
              <li key={fileSizeErr.message + idx}>
                <Pill
                  color="red"
                  size="xs"
                  onClick={() => {
                    removeStagedImageErrors('fileSize', idx);
                  }}
                  icon={<Icon name={IconsMap.delete} />}
                >
                  {fileSizeErr.name}
                </Pill>
              </li>
            ))
          : null}

        {imageErrors?.fileType?.length
          ? imageErrors.fileType.map((fileTypeErr, idx) => (
              <li key={fileTypeErr.name + idx}>
                <Pill
                  size="xs"
                  onClick={() => {
                    removeStagedImageErrors('fileType', idx);
                  }}
                  icon={<Icon name={IconsMap.delete} />}
                >
                  {fileTypeErr.name}
                </Pill>
              </li>
            ))
          : null}

        {commentValues.image.images.length
          ? Array.from(commentValues.image.images, (imageFile, idx) => (
              <li key={imageFile.name + imageFile.size + idx}>
                <Pill
                  size="xs"
                  onClick={() => {
                    removeStagedImage(idx);
                  }}
                  icon={<Icon name={IconsMap.delete} />}
                >
                  {imageFile.name}
                </Pill>
              </li>
            ))
          : null}
      </ul>
    </>
  );
}
