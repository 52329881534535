import {GanttStatic} from 'dhtmlx-gantt';
import {Fragment, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {useUndo} from 'modules/Tasks/components/ActionsBar/hooks/useUndo';
import {ActionGroup, ActionItem} from 'modules/Tasks/components/ActionsBar/types';
import FilterDropdown from 'modules/Tasks/components/IssuesActionsBar/components/IssuesFilterDropdown';
import {IssuesBulkActions} from 'modules/Tasks/components/IssuesActionsBar/IssuesBulkActions';
import {useIssuesBulkActions} from 'modules/Tasks/components/IssuesActionsBar/useIssuesBulkActions';
import ProjectCollaboratePopup from 'shared/components/ProjectCollabPopup';
import {TasksViewMode} from 'shared/constants/common';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';

import DateDropdown from '../ActionsBar/components/DateDropdown/DateDropdown';
import ExportDropdown from '../ActionsBar/components/ExportDropdown/ExportDropdown';
import GanttColumnsDropdown from '../ActionsBar/components/GanttColumnsDropdown/GanttColumnsDropdown';
import {Toolbar} from '../Toolbar/Toolbar';

import s from './IssuesActionsBar.module.scss';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {TaskFilterQuery} from 'shared/models/task/filter';
import {useFilterContext} from '../Filters/FilterProvider';
import {isFilterFieldsDirty, getDefaultFilterParamsByViewMode} from '../Filters/utils/functions';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';

type ActionsBarProps = {
  className?: string;
  gantt?: GanttStatic;
  projectId: string;
};

const DATE_RANGE_FILTER_KEYS: (keyof TaskFilterQuery)[] = ['schedWeeks', 'schedEndFirst'];
const FILTER_EXCLUDE_KEYS: (keyof TaskFilterQuery)[] = [...DATE_RANGE_FILTER_KEYS, 'q', 'showPastDue'];

const IssuesActionsBar = ({gantt, projectId}: ActionsBarProps) => {
  const {t} = useTranslation('gantt');
  const {viewMode, queryParams} = useFilterContext();
  const {cantUndo, canRedo} = useUndo(gantt);

  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.issues;

  const [showCollaborationPopup, setShowCollaborationPopup] = useState(false);

  const {selected, bulkType, actions} = useIssuesBulkActions({gantt, projectId});

  const hasSelectedActivities = (selected) =>
    Array.isArray(selected) && selected.filter((id) => gantt.isTaskExists(id)).map((id) => gantt.getTask(id));

  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);

  const undoOrRedo = (action: 'undo' | 'redo') => {
    return () => {
      if (action === 'undo') gantt.undo();
      if (action === 'redo') gantt.redo();
    };
  };

  const dateFilterActive = isFilterFieldsDirty({
    queryParams,
    fields: DATE_RANGE_FILTER_KEYS,
    initialValues: getDefaultFilterParamsByViewMode(viewMode),
  });

  const filtersActive = isFilterFieldsDirty({
    queryParams,
    exclude: FILTER_EXCLUDE_KEYS,
    initialValues: getDefaultFilterParamsByViewMode(viewMode),
  });

  const moreActions: ActionItem[] = useMemo(() => {
    return [
      {
        text: t('toolbar.actions.export', 'Export to CSV'),
        name: 'export',
        icon: 'export',
        actionType: 'general',
        customButton: <ExportDropdown exportIssues />,
      },
    ];
  }, [t]);

  const adminActionGroups: ActionGroup[] = useMemo(() => {
    return [
      {
        name: 'ur',
        actions: [
          {
            name: 'undo',
            text: t('toolbar.buttons.undo', 'Undo'),
            icon: 'undo_2',
            onClick: undoOrRedo('undo'),
            actionType: 'general',
            disabled: () => !cantUndo,
          },
          {
            name: 'redo',
            text: t('toolbar.buttons.redo', 'Redo'),
            icon: 'redo_2',
            onClick: undoOrRedo('redo'),
            actionType: 'general',
            disabled: () => !canRedo,
          },
        ],
      },
      {
        name: 'bulk',
        responsive: true,
        actions: [
          {
            name: 'delete',
            text: t('toolbar.buttons.delete', 'Delete'),
            icon: 'remove_from_trash',
            color: 'actionWarning',
            disabled: (selected: string[]) => selected.length !== 1,
            onClick: () => actions.deleteOneSelectedIssue(projectId),
            actionType: 'single',
          },
          {
            text: t('contextMenu.issues.buttons.assign', 'Assign'),
            name: 'assign',
            icon: 'user-add',
            onClick: actions.setAssignee,
            disabled: (selected) => !hasSelectedActivities(selected),
          },
          {
            text: t('contextMenu.issues.buttons.status', 'Status'),
            name: 'status',
            icon: 'status_new',
            onClick: actions.setStatus,
            disabled: (selected) => !hasSelectedActivities(selected),
          },
        ],
        moreActions: moreActions,
      },
    ];
  }, [gantt, hasAnyAdminRole, projectId, cantUndo, canRedo]);

  const nonAdminActionGroups: ActionGroup[] = [];

  let chosenActionGroups = adminActionGroups;
  if (!hasAnyAdminRole) {
    chosenActionGroups = nonAdminActionGroups;
  }

  const filterItems = useMemo(() => {
    const commonItems = [
      <GanttColumnsDropdown
        key="columns"
        gantt={gantt}
        button={<CtrlButton color="action" icon="column_view_outlined" iconOnly={true} />}
      >
        Columns
      </GanttColumnsDropdown>,
      <DateDropdown
        key="dates"
        gantt={gantt}
        active={dateFilterActive}
        onOpenDateFilter={() => mixpanel.track(mixpanel.events.issues.dateFilter)}
        navigationType={viewMode === TasksViewMode.lookahead ? 'time' : 'scale'}
      />,
      <FilterDropdown key="filter" sideContentDirection="left" active={filtersActive} buttonIconOnly={true} />,
    ];
    if (hasAnyAdminRole) {
      return commonItems.concat([
        <CtrlButton
          key="projectCollab"
          icon="collab"
          size="s"
          className={s.collaborate}
          color="second"
          onClick={() => mixpanel.trackWithAction(() => setShowCollaborationPopup(true), mixpanelEvents.collaborate)}
        >
          {t('toolbar.actions.collaborate', 'Collaborate')}
        </CtrlButton>,
      ]);
    }
    return commonItems;
  }, [hasAnyAdminRole, viewMode, filtersActive, dateFilterActive]);

  return (
    <>
      <Toolbar readonly={!hasAnyAdminRole} staticItems={filterItems}>
        {chosenActionGroups.map((group, idx) => {
          const index = chosenActionGroups.length > 1 ? idx : undefined;
          return (
            <Fragment key={group.name}>
              <Toolbar.Section index={index}>
                {group.actions.map((action) => (
                  <Toolbar.Item key={action.name} selected={selected} {...action} />
                ))}
              </Toolbar.Section>
              {!!group.moreActions?.length ? (
                <Toolbar.MoreActions canEdit={!hasAnyAdminRole} actions={group.moreActions} selected={selected} />
              ) : null}
            </Fragment>
          );
        })}
      </Toolbar>
      <IssuesBulkActions gantt={gantt} bulkType={bulkType} projectId={projectId} closePopup={actions.closeBulkModal} />
      {showCollaborationPopup && <ProjectCollaboratePopup visible onClose={() => setShowCollaborationPopup(false)} />}
    </>
  );
};

export default IssuesActionsBar;
