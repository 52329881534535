import dayjs from 'dayjs';

import {safeParseDate} from 'shared/helpers/dates';

export function useFreeTrialDates(proStartTime: string) {
  const minStartCountDownDate = new Date(2021, 3, 20);
  const parsedProStartTime = proStartTime ? safeParseDate(proStartTime) : null;
  const startCountDownDate =
    parsedProStartTime && dayjs(parsedProStartTime).isAfter(dayjs(minStartCountDownDate))
      ? parsedProStartTime
      : minStartCountDownDate;
  const daysTillEnd = dayjs(startCountDownDate).diff(new Date(), 'days') + 30;
  return [startCountDownDate, daysTillEnd] as const;
}
