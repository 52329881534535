import {FC} from 'react';

type Props = {
  title: string;
};

const AppCard: FC<Props> = ({title, children}) => {
  return (
    <div className="app-card app-task__item">
      <h2 className="app-card__title">{title}</h2>
      <div className="app-card__body">{children}</div>
    </div>
  );
};
export default AppCard;
