import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import {Field, FieldProps, Formik, FormikProps, FormikValues} from 'formik';
import {TFunction} from 'i18next';
import {FC, useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {batch} from 'react-redux';
import {generatePath, useHistory} from 'react-router';
import {toast} from 'react-toastify';
import * as Yup from 'yup';

import WorkerApi from 'api/worker';
import EventManager from 'services/EventManager';
import FirebaseService from 'services/Firebase/Firebase';
import {useAuth} from 'shared/components/AuthUserProvider';
import CountryCodeSelector from 'shared/components/CoreForm/CountryCodeSelector';
import EmailInput from 'shared/components/CoreForm/EmailInput';
import FormControl from 'shared/components/CoreForm/FormControl';
import {usePasswordVisibility} from 'shared/components/CoreForm/hooks/usePasswordVisibility';
import PhoneInput from 'shared/components/CoreForm/PhoneInput';
import ExternalLink from 'shared/components/ExternalLink';
import Loader from 'shared/components/Loader';
import {PHONE_MASKS_BY_CODE} from 'shared/constants/common';
import env from 'shared/constants/env';
import {getHumanizedFbError, isFirebaseAuthError} from 'shared/constants/firebase';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {getLocalizedLink} from 'shared/helpers/common';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {useUnmount} from 'shared/hooks/core/useUnmount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useLandingStyles} from 'shared/hooks/useLandingStyles';
import useUserAgent from 'shared/hooks/useUserAgent';
import {GetStartedSignupForm} from 'shared/models/onboardingForms';
import {Worker} from 'shared/models/worker';
import {useRootDispatch, useRootSelector} from 'store';
import * as companyActions from 'store/company/actions';
import {setCompany, setIsLoading} from 'store/onboarding';
import {registerWorker, updateWorkerObject} from 'store/onboarding/actions';
import {profileActions} from 'store/profile';
import {loadWorkerCompanies, loadWorkerCompanyWorkers} from 'store/profile/actions';

import s from './GetStartedSignup.module.scss';

const ENDING_FOR_COMPANY_NAME = "'s Company";

const GetStartedSignup: FC = () => {
  const {mixpanel, useZoomInfo} = useAnalyticsService({publicPage: true});
  useZoomInfo();
  const {
    t,
    i18n: {language},
  } = useTranslation(['signup', 'sign_in']);
  const phoneFormRef = useRef<HTMLDivElement>();
  const dispatch = useRootDispatch();
  const history = useHistory();
  const routes = useLocalizedRoutes();
  const mixpanelEvents = mixpanel.events.signup.getStarted;
  const userAgent = useUserAgent();
  const {isLoading} = useRootSelector((state) => ({
    isLoading: state.onboarding.isLoading,
  }));
  const {user} = useAuth();
  const formik = useRef<FormikProps<GetStartedSignupForm>>(null);
  const queryParams = useMemo(() => new URLSearchParams(history.location.search), [history.location.search]);
  useLandingStyles();

  // login states
  const passwordVisibility = usePasswordVisibility();
  const confirmVisibility = usePasswordVisibility();

  useEffectOnce(() => {
    mixpanel.track(mixpanelEvents.page);
  });

  useEffect(() => {
    if (!user) {
      FirebaseService.signInAnonymously();
    }
  }, [user]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      fullName: Yup.string()
        .required(t('signup:validation.fullName.required', 'Full Name is required field.'))
        .min(3, t('signup:validation.fullName.min', 'At least 3 characters is required!'))
        .max(200, t('signup:validation.fullName.max', 'No more than 200 characters!')),
      email: Yup.string()
        .email(t('signup:validation.email.valid', 'Must be a valid email'))
        .required(t('signup:validation.email.required', 'This field is required')),
      password: Yup.string()
        .required(t('signup:validation.password.required', 'This field is required'))
        .min(6, t('signup:validation.password.min', 'Must be at least 6 characters long'))
        .max(255, t('signup:validation.password.max', 'Must not exceed 255 characters long')),
      passwordRepeat: Yup.string()
        .required(t('signup:validation.password.required', 'This field is required'))
        .oneOf([Yup.ref('password'), null], t('signup:validation.password.match', 'Passwords must match')),
      mobileNumber: Yup.string()
        .transform((value) => {
          if (typeof value === 'string' && value === '(') return '';
          return value;
        })
        .test({
          name: 'is-valid-phone',
          message: t('signup:validation.mobileNumber.invalid', 'Invalid phone number'),
          test(value, ctx) {
            if (typeof value === 'string' && value.trim()) {
              const maskConfig = PHONE_MASKS_BY_CODE[ctx.parent.countryCode];
              const mask =
                maskConfig.alterPredicate && maskConfig.alterPredicate(value.replace(/\D+/g, ''))
                  ? maskConfig.alterMask
                  : maskConfig.mask;
              const regexp = new RegExp(
                mask
                  .replace(/\d/g, '\\d')
                  .replace(/([()])/g, '\\$1')
                  .replace(/\?/g, '\\d?'),
              );
              return regexp.test(value);
            }
            return true;
          },
        }),

      code: Yup.string(),
    });
  }, []);

  const loadCompaniesAndWorkers = (workerId: string) => {
    return Promise.all([dispatch(loadWorkerCompanies(workerId)), dispatch(loadWorkerCompanyWorkers(workerId))]);
  };

  const trackAnalyticsEventsAfterSubmit = (worker: Worker, companyName: string) => {
    mixpanel.track(mixpanelEvents.companyCreated, {
      companyName: companyName,
      fullName: worker.fullName,
      email: worker.email,
    });
    EventManager.pushToGtmDataLayer({event: 'c4-getstarted-company'});
    EventManager.pushToGtmDataLayer({
      event: 'Crews_getstarted_email_signup',
      eventCategory: 'Registration',
      eventAction: 'Completion',
    });
    EventManager.trackPixelCustomEvent('Sales_SignUp');
  };

  async function afterCreateCompany(worker: Worker, companyName: string) {
    trackAnalyticsEventsAfterSubmit(worker, companyName);
    if (userAgent.device.type === 'mobile') {
      history.push(generatePath(routes.mobileAccountComplete));
    } else {
      batch(() => {
        dispatch(profileActions.setWorker(worker));
        dispatch(profileActions.setCompanyProfileOpened(true));
      });

      // put workerId to localStorage
      FirebaseService.setWorkerIdToLS(worker.id);
      history.push(generatePath(routes.project, {id: 'new'}));
    }
  }

  const createUserWithEmailAndPassword = async (values: FormikValues) => {
    const email = values.email;
    const password = values.password;
    try {
      dispatch(setIsLoading(true));
      await FirebaseService.createUserWithEmailAndPassword(email, password);
      return true;
    } catch (error) {
      Sentry.captureException(error);
      if (isFirebaseAuthError(error) && error.code === 'auth/email-already-in-use') {
        toast(t('signup:errors.email.already-in-use', 'This email address is already in use. Please, login.'));
        dispatch(setIsLoading(false));
        history.push(routes.logIn);
      } else {
        toast(getHumanizedFbError(error, t as TFunction));
      }
      return false;
    }
  };

  const loginOrSignUp = async () => {
    const res = await dispatch(registerWorker({loginType: 'password'}));
    if (registerWorker.fulfilled.match(res)) {
      return res.payload;
    } else {
      toast(res.error.message);
    }
  };

  const updateMobileNumber = async (payload: Partial<Worker>) => {
    try {
      await WorkerApi.updateWorkerData(payload, payload.id);
    } catch (err: unknown) {
      toast.warn(t('signup:errors.phone.incorrect', 'Phone number seems to be incorrect.'));
    }
  };

  const updateWorker = async (id: string) => {
    const model = {...formik.current.values};
    const mobileNumber = model.mobileNumber ? `${model.countryCode}${model.mobileNumber}` : '';

    delete model.mobileNumber;
    delete model.companyName;
    delete model.password;
    delete model.passwordRepeat;
    delete model.acceptTermsAndPolicy;
    delete model.countryCode;

    const updatedWorker = await dispatch(
      updateWorkerObject({
        id,
        workerObject: {...model},
      }),
    );

    if (updateWorkerObject.fulfilled.match(updatedWorker)) {
      if (mobileNumber) {
        await updateMobileNumber({id, mobileNumber});
      }
      return updatedWorker.payload;
    } else {
      toast.warning(updatedWorker.error.message);
    }
  };

  const createCompany = async (name: string, workerId: string) => {
    const companyCreateResult = await dispatch(
      companyActions.createCompany({companyObject: {companyName: name}, workerId}),
    );
    if (companyActions.createCompany.rejected.match(companyCreateResult)) {
      toast(companyCreateResult.error.message || 'Failed to create company');
    } else {
      return companyCreateResult.payload;
    }
  };

  const submitForm = async (values: GetStartedSignupForm) => {
    dispatch(setIsLoading(true));
    mixpanel.track(mixpanelEvents.buttons.submit);
    if (await createUserWithEmailAndPassword(values)) {
      const registeredWorker = await loginOrSignUp();
      if (registeredWorker) {
        mixpanel.track(mixpanelEvents.workerCreated, {fullName: values.fullName, email: values.email});
        EventManager.pushToGtmDataLayer({event: 'c4-gestarted-user'});
        const updatedWorker = await updateWorker(registeredWorker.id);
        if (updatedWorker && (await createCompany(values.companyName, updatedWorker.id))) {
          afterCreateCompany(updatedWorker, values.companyName);
          const [, companyWorkers] = await loadCompaniesAndWorkers(updatedWorker.id);
          if (loadWorkerCompanyWorkers.fulfilled.match(companyWorkers) && companyWorkers.payload.length) {
            dispatch(setCompany(companyWorkers.payload[0]));
          }
        }
      }
    }
    dispatch(setIsLoading(false));
  };

  const onCancelBtnClick = () => {
    mixpanel.track(mixpanelEvents.buttons.cancel);
    history.push(routes.landing);
  };

  const onTermsLinksClick = (linkName: 'policy' | 'terms') => {
    mixpanel.track(mixpanelEvents.links[linkName]);
  };

  const onChangeFullName = (value: string) => {
    const firstName = value.split(' ')?.[0] || '';
    const prevFirstName = formik.current.values.fullName.split(' ')?.[0] || '';
    const prevCompanyName = prevFirstName ? prevFirstName + ENDING_FOR_COMPANY_NAME : '';
    const companyName =
      formik.current.values.companyName !== prevCompanyName
        ? formik.current.values.companyName
        : firstName + ENDING_FOR_COMPANY_NAME;
    formik.current.setValues({...formik.current.values, fullName: value, companyName});
  };

  useUnmount(() => {
    dispatch(setIsLoading(false));
  });

  return (
    <div className={s.startedSignup}>
      <div className={s.formOnboarding}>
        <div className={s.formOnboarding__containerGrid}>
          <Formik<GetStartedSignupForm>
            initialValues={{
              fullName: '',
              companyName: '',
              agreePrivacyPolicy: true,
              agreeDigestSms: true,
              acceptTermsAndPolicy: false,
              email: queryParams.get('email') || '',
              password: '',
              passwordRepeat: '',
              mobileNumber: '',
              countryCode: '+1',
            }}
            innerRef={formik}
            onSubmit={submitForm}
            validationSchema={validationSchema}
          >
            {({values, submitForm, setFieldValue, isValid}) => (
              <div className={s.formOnboarding__container}>
                <header className={s.formOnboarding__header}>
                  <h3 className={s.formOnboarding__title}>Set up your account</h3>
                  <div className={s.formOnboarding__description}>Fill in your profile details</div>
                </header>
                <div className={cn(s.formOnboarding__body)}>
                  {isLoading && <Loader />}
                  <div className={s.formOnboarding__item}>
                    <FormControl
                      name="fullName"
                      label={t('signup:fields.fullName.label', 'Full Name')}
                      labelClassName={s.formOnboarding__label}
                      data-cy="signup_full_name_input"
                    >
                      <Field name="fullName">
                        {({field}) => (
                          <input
                            {...field}
                            className={s.formOnboarding__input}
                            onChange={(e) => onChangeFullName(e.target.value)}
                          />
                        )}
                      </Field>
                    </FormControl>
                  </div>
                  <div className={s.formOnboarding__item}>
                    <FormControl
                      name="email"
                      label={t('signup:fields.email.label', 'Email')}
                      labelClassName={s.formOnboarding__label}
                      data-cy="signup_email_input"
                    >
                      <Field>{({field}) => <EmailInput {...field} className={s.formOnboarding__input} />}</Field>
                    </FormControl>
                  </div>
                  <div className={s.formOnboarding__item}>
                    <FormControl
                      name="password"
                      label={t('signup:get_started_signup.fields.password', 'Create A Password')}
                      iconName={passwordVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
                      onIconClick={passwordVisibility.toggleVisibility}
                      labelClassName={s.formOnboarding__label}
                      iconColor="#df413b"
                      data-cy="signup_password_input"
                    >
                      <Field
                        name="password"
                        type={passwordVisibility.visible ? 'text' : 'password'}
                        className={s.formOnboarding__input}
                      />
                    </FormControl>
                  </div>
                  <div className={s.formOnboarding__item}>
                    <FormControl
                      name="passwordRepeat"
                      label={t('signup:get_started_signup.fields.repeat_pw', 'Confirm Password')}
                      iconName={confirmVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
                      iconColor="#df413b"
                      onIconClick={confirmVisibility.toggleVisibility}
                      labelClassName={s.formOnboarding__label}
                      data-cy="signup_password_repeat_input"
                    >
                      <Field
                        name="passwordRepeat"
                        type={confirmVisibility.visible ? 'text' : 'password'}
                        className={s.formOnboarding__input}
                      />
                    </FormControl>
                  </div>

                  <div className={cn(s.formOnboarding__item, s.formOnboarding__group)} ref={phoneFormRef}>
                    <FormControl
                      name="countryCode"
                      label={t('signup:fields.phone_code.label', 'Code')}
                      labelClassName={s.formOnboarding__label}
                    >
                      <CountryCodeSelector
                        onChange={(value) => setFieldValue('countryCode', value)}
                        className={s.formOnboarding__code}
                        name="countryCode"
                        initialValue={values.countryCode}
                        width={phoneFormRef.current?.clientWidth}
                      />
                    </FormControl>
                    <FormControl
                      name="mobileNumber"
                      label={t('signup:fields.mobileNumber.label', 'Phone Number')}
                      labelClassName={cn(s.formOnboarding__label)}
                      className={s.formOnboarding__phone}
                      data-cy="signup_phone_number"
                    >
                      <Field name="mobileNumber">
                        {(props: FieldProps) => (
                          <PhoneInput
                            {...props.field}
                            onManualChange={(value) => setFieldValue(props.field.name, value)}
                            countryCode={values.countryCode}
                            className={cn(s.formOnboarding__input)}
                            id="apply_onboarding_phone"
                            placeholder={t('sign_in:fields.phone_number.placeholder', 'Enter Phone Number')}
                          />
                        )}
                      </Field>
                    </FormControl>
                  </div>
                  <div className={s.formOnboarding__item}>
                    <FormControl
                      name="companyName"
                      label={t('signup:fields.companyName.label', 'Company Name')}
                      labelClassName={s.formOnboarding__label}
                      data-cy="signup_company_name"
                    >
                      <Field name="companyName" className={s.formOnboarding__input} />
                    </FormControl>
                  </div>
                  <div className={`form-onboarding-z__item ${s.formOnboarding__item__inline}`}>
                    <Field name="acceptTermsAndPolicy">
                      {({field}) => (
                        <>
                          <input
                            {...field}
                            id={field.name}
                            checked={field.value}
                            className={s.acceptTerms__input}
                            type="checkbox"
                            data-cy="signup_accept_tas"
                          />
                          <label htmlFor={field.name} className={s.acceptTerms__label}>
                            {t('signup:get_started_signup.accept_terms.1', 'I accept The')}
                            <ExternalLink
                              onClick={() => onTermsLinksClick('policy')}
                              target="_blank"
                              href={getLocalizedLink(env.legalPages.termsOfService, language)}
                              text={t('signup:get_started_signup.accept_terms.2', 'Terms Of Service')}
                            />
                            {t('signup:get_started_signup.accept_terms.3', ' And ')}
                            <ExternalLink
                              onClick={() => onTermsLinksClick('terms')}
                              target="_blank"
                              href={getLocalizedLink(env.legalPages.privacyPolicy, language)}
                              text={t('signup:get_started_signup.accept_terms.4', 'Privacy Policy')}
                            />
                          </label>
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className={s.formOnboarding__footer}>
                  <button
                    className={`${s.formOnboarding__actionBtns} ${s.cancelBtn}`}
                    disabled={isLoading}
                    onClick={onCancelBtnClick}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`${s.formOnboarding__actionBtns} ${s.submitBtn}`}
                    onClick={submitForm}
                    disabled={isLoading || !isValid}
                    data-cy="signup_submit_btn"
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </Formik>
          <div className={s.formOnboarding__image}></div>
        </div>
      </div>
    </div>
  );
};
export default GetStartedSignup;
