import * as Sentry from '@sentry/browser';

import ChatService from 'api/chat';
import {toShortIso} from 'shared/helpers/dates';
import {Feedback, MimeType} from 'shared/models/feedback';

import {FeedbackImage} from '../hooks/useHandleSubmitProgressReport';

import {getImageDimensions} from './helpers';

export async function uploadImagesToMatrix(image: FeedbackImage, accessToken: string, dateTag: Date) {
  const promises = image.images.map<Promise<Feedback>>(async (img) => {
    const {height, width} = await getImageDimensions(img);
    const mimetype = img.type as MimeType;
    try {
      const imageData = await ChatService.uploadAssetToMatrix(accessToken, img);
      return {
        feedback_type: image.feedbackType,
        date: toShortIso(dateTag), // uses comment date as feedback date
        payload: {
          [image.feedbackType]: {
            content: {
              body: img.name,
              info: {
                h: height,
                mimetype,
                size: img.size,
                w: width,
              },
              msgtype: 'm.image',
              url: imageData.contentUri,
            },
          },
        },
      };
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          context: uploadImagesToMatrix.name,
          name: img.name,
        },
      });
      return null;
    }
  });
  const results = await Promise.all(promises);
  return results;
}
