export type CompletePercentageFunc = (percentage: number) => void;

export async function pollResults<R extends {data: {status: string; progressPercent?: number}}>(params: {
  importId: string;
  maxWaitSec: number;
  completePercentage?: CompletePercentageFunc;
  fetcher: () => Promise<R>;
}) {
  const resp = await params.fetcher();
  let data = resp.data;
  let iters = 1;

  if (typeof params.completePercentage === 'function') {
    params.completePercentage(resp.data.progressPercent);
  }

  while (data && (data.status === 'queued' || data.status === 'running') && iters < params.maxWaitSec) {
    await sleep(window.Cypress ? 5000 : 2000);
    const response = await params.fetcher();
    params.completePercentage?.(response.data.progressPercent);
    data = response.data;
    iters += 1;
  }

  return data as R['data'];
}

export function runAllSettled<T>(promises: Promise<T>[]) {
  return Promise.allSettled(promises).then((res) => {
    return res.reduce(
      (all, cur) => {
        if (cur.status === 'fulfilled') all.fulfilled.push(cur);
        else all.rejected.push(cur);
        return all;
      },
      {fulfilled: [] as PromiseFulfilledResult<T>[], rejected: [] as PromiseRejectedResult[]},
    );
  });
}

async function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
