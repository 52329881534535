import {useQuery} from 'react-query';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {TaskChatEvent, AsyncJobChatHistoryStatus} from 'shared/models/task/chat';

import {isAsyncJobInProgress} from '../components/CommentsTab/utils/helpers';

const REFETCH_INTERVAL_MS = 2500;
const STALE_TIME = 5 * (60 * 1000); // 5 min

export function useLoadTaskChatHistory(taskId: string): {
  chatEvents: TaskChatEvent[];
  jobStatus: AsyncJobChatHistoryStatus;
} {
  const {data: asyncUploadId} = useQuery(
    QUERY_CACHE_KEYS.initAsyncJobChatHistory(taskId),
    () => {
      return TasksApi.startAsyncChatHistory(taskId);
    },
    {
      enabled: !!taskId,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME,
    },
  );

  const {data: reqMatrixEvents} = useQuery(
    QUERY_CACHE_KEYS.checkStatusAsyncJobChatHistory(asyncUploadId?.id),
    () => {
      return TasksApi.checkAsyncStatusChatHistory(taskId, asyncUploadId.id);
    },
    {
      enabled: !!asyncUploadId?.id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchInterval: (data) => (isAsyncJobInProgress(data?.status) ? REFETCH_INTERVAL_MS : false),
      onError: (e) => {
        toast(e);
        console.error(e);
      },
      staleTime: STALE_TIME,
    },
  );

  return {chatEvents: reqMatrixEvents?.result?.events, jobStatus: reqMatrixEvents?.status};
}
