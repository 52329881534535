import {useLayoutEffect} from 'react';

export function useLandingStyles() {
  useLayoutEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `${process.env.PUBLIC_URL}/stylesheets/landings.min.css?v=${process.env.REACT_APP_SENTRY_RELEASE}`;
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    };
  }, []);
}
