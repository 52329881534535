import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect, useRef} from 'react';
import {useHistory, useLocation} from 'react-router';

import {TasksLocationState} from 'modules/Tasks/types/location';
import {updateSearchQuery} from 'modules/Tasks/utils/updateSearchQuery';
import {TasksViewMode} from 'shared/constants/common';
import {IOC_TYPES} from 'shared/models/ioc';
import {IssueActiveTab, TaskActiveTab} from 'shared/models/task/const';
import {useInjectStore} from 'shared/providers/injection';
import {UIStoreType} from 'shared/stores/UIStore';

import {useFilterContext} from '../../Filters/FilterProvider';

type MentionsRef = {
  taskId: string;
  eventId: string;
} | null;

export const useNavigateToMentions = (gantt: GanttStatic, setActivityDetailsTask: (id: string) => void) => {
  const mentionsRef = useRef<MentionsRef>(null);
  const location = useLocation<TasksLocationState>();
  const uiStore = useInjectStore<UIStoreType>(IOC_TYPES.UIStore);
  const {viewMode} = useFilterContext();
  const history = useHistory<TasksLocationState>();

  useEffect(() => {
    const eventId = location.state?.eventId;
    const taskId = location.state?.taskId;
    if (eventId && taskId) {
      mentionsRef.current = {taskId, eventId};
    }
    if (
      mentionsRef.current?.eventId &&
      mentionsRef.current?.taskId &&
      gantt.isTaskExists(mentionsRef.current?.taskId) &&
      !uiStore.isLoading
    ) {
      if (viewMode === TasksViewMode.issues) {
        setActivityDetailsTask(mentionsRef.current?.taskId);
        history.replace({
          pathname: location.pathname,
          search: updateSearchQuery(location.search, {activeTab: IssueActiveTab.chat}),
          state: {eventId: mentionsRef.current?.eventId},
        });
      } else {
        setActivityDetailsTask(mentionsRef.current?.taskId);
        history.replace({
          pathname: location.pathname,
          search: updateSearchQuery(location.search, {activeTab: TaskActiveTab.chat}),
          state: {
            eventId: mentionsRef.current?.eventId,
            activeTab: TaskActiveTab.chat,
            taskId: mentionsRef.current?.taskId,
          },
        });
      }
      mentionsRef.current = null;
    }
  }, [
    gantt,
    history,
    location.pathname,
    location.search,
    location.state?.eventId,
    location.state?.taskId,
    setActivityDetailsTask,
    uiStore.isLoading,
    viewMode,
  ]);
};
