import {ExternalServices} from 'shared/constants/common';

import ApiAxios from './axios';

type OAuthStatus = 'authorized' | 'unauthorized';

interface OAuthCheckResponse {
  status: OAuthStatus;
}

interface OAuthReturnParams {
  code: 'string';
  service: ExternalServices;
}

interface OAuthStartParams {
  redirectUri: 'string';
  service: ExternalServices;
}

interface OAuthStartResponse {
  authUrl: 'string';
}

class OAuthService {
  check(service: ExternalServices = ExternalServices.Procore): Promise<boolean> {
    return ApiAxios.get<OAuthCheckResponse>('/oauth/check', {params: {service}}).then((res) => {
      return res.data?.status === 'authorized';
    });
  }

  start(service: ExternalServices = ExternalServices.Procore, redirectUri = `${window.origin}/oauth/return`) {
    return ApiAxios.post<OAuthStartResponse>('/oauth/start', {service, redirectUri} as OAuthStartParams).then(
      (res) => res.data.authUrl,
    );
  }
  return(code: string, service: ExternalServices = ExternalServices.Procore) {
    return ApiAxios.post<void>('/oauth/return', {service, code} as OAuthReturnParams);
  }
}

export default new OAuthService();
