import cn from 'classnames';
import {FC, ReactElement, ReactNode} from 'react';

import s from './Panel.module.scss';

type PanelProps = {
  title: ReactNode;
  actions?: ReactNode;
  children?: never;
  subActions?: ReactNode[];
  timelineBadge?: ReactElement;
};

const Panel: FC<PanelProps> = ({title, actions, subActions, timelineBadge}) => {
  return (
    <>
      <div className="panel screen__panel">
        <h1 className="panel__title">{title}</h1>
        <div className={cn('panel__actions', s.panel__actions)}>
          {timelineBadge && timelineBadge}
          {actions ? <div className={s.panel__actionsDesktop}>{actions}</div> : null}
        </div>
      </div>
      {actions ? <div className={s.panel__actionsResponsive}>{actions}</div> : null}
      {!!subActions?.length && (
        <div className="panel-actions screen__panel-actions">
          {subActions.map((component, key) => (
            <div className="panel-actions__item" key={key}>
              {component}
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default Panel;
