import {FunctionComponent, useEffect} from 'react';
import {useParams} from 'react-router';

import {useRemovePreviousProjectActivities} from 'modules/Tasks/hooks/useRemovePreviousProjectActivities';
import {useTasksLockedInterceptor} from 'modules/Tasks/hooks/useTasksInterceptor';
import View from 'modules/Tasks/Views/View';
import {RouteParams, useLocalizedRoutes} from 'shared/constants/routes';
import {setDefaultTimezone} from 'shared/helpers/dates';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useSaveLastSelectedProjectId} from 'shared/hooks/useSaveLastSelectedProjectId';
import {usePrefetchWorkFlowStatuses} from 'shared/hooks/useWorkflowStatuses';

import FilterProvider from './components/Filters/FilterProvider';
import './styles.scss';
import {TasksTutorialPopup} from './components/popups/TasksTutorialPopup';

const Tasks: FunctionComponent = () => {
  const {projectId} = useParams<RouteParams['tasks']>();
  const project = useProjectSelector(projectId);
  const routes = useLocalizedRoutes();
  usePrefetchWorkFlowStatuses(projectId);
  useSaveLastSelectedProjectId(routes.tasks);
  useRemovePreviousProjectActivities();
  useTasksLockedInterceptor();

  useEffect(() => {
    project && setDefaultTimezone(project.timezone);
    return () => {
      setDefaultTimezone();
    };
  }, [project]);

  return (
    <>
      <FilterProvider projectId={projectId} key={projectId}>
        {!!project?.id ? <View /> : null}
      </FilterProvider>
      <TasksTutorialPopup />
    </>
  );
};

export default Tasks;
