import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {TaskStatusType} from 'shared/models/task/taskStatus';

export const statusColors: Record<TaskStatusType, string> = {
  [TaskStatusType.tba]: '#ff7c43',
  [TaskStatusType.assigned]: '#bc5eaa',
  [TaskStatusType.inProgress]: '#4764a4',
  [TaskStatusType.rework]: '#ffd777',
  [TaskStatusType.blocked]: '#de425b',
  [TaskStatusType.done]: '#45cba3',
  [TaskStatusType.verified]: '#88b388',
  [TaskStatusType.approved]: '#0b991b',
  [TaskStatusType.archived]: '#818181',
  [TaskStatusType.closed]: '#000000',
  [TaskStatusType.unblocked]: '#cccccc', // TODO: need color
  [TaskStatusType.notDone]: '#cccccc', // TODO: need color
};

export function getTaskColorByStatus(taskStatus: TaskStatusType, fallbackColor = '#A1A1A1'): string {
  const MAP_TASK_STATUS_TO_COLOR = {
    [TaskStatusType.tba]: '#A1A1A1',
    [TaskStatusType.assigned]: '#122D8B',
    [TaskStatusType.inProgress]: '#5779F2',
    [TaskStatusType.unblocked]: '#5779F2',
    [TaskStatusType.blocked]: '#FA380D',
    [TaskStatusType.done]: '#63E37F',
    [TaskStatusType.notDone]: '#DB9F06',
    [TaskStatusType.verified]: '#089326',
    [TaskStatusType.closed]: '#282828',
  };
  return MAP_TASK_STATUS_TO_COLOR[taskStatus] || fallbackColor;
}

export function getLaborColorForDate(task: GanttTask, date: string, fallbackColor = '#A1A1A1'): string {
  const projectedLabor = task.projected_labor ? Number(task.projected_labor) : null;
  if (projectedLabor === null) {
    return fallbackColor;
  }

  const dailyLaborFeedback = task.feedback_by_date.find((fb) => fb.field === 'daily_labor');
  if (!dailyLaborFeedback || !dailyLaborFeedback.feedback_by_date) {
    return fallbackColor;
  }

  const laborEntry = dailyLaborFeedback.feedback_by_date.find((entry) => entry.date === date);
  if (!laborEntry) {
    return fallbackColor;
  }

  const actualLabor = Number(laborEntry.value);

  if (actualLabor < projectedLabor) {
    return '#FA380D'; // Under projected labor
  } else if (actualLabor === projectedLabor) {
    return '#0b991b'; // Exactly at projected labor
  } else {
    return '#FFA500'; // Over projected labor
  }
}

export const orderedStatusList: TaskStatusType[] = [
  TaskStatusType.closed,
  TaskStatusType.archived,
  TaskStatusType.approved,
  TaskStatusType.verified,
  TaskStatusType.done,
  TaskStatusType.blocked,
  TaskStatusType.unblocked,
  TaskStatusType.notDone,
  TaskStatusType.rework,
  TaskStatusType.inProgress,
  TaskStatusType.assigned,
  TaskStatusType.tba,
];
