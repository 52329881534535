import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from 'react-query';

import {useTasksBulkOperations} from 'modules/Tasks/components/TasksActionsBar/useTasksBulkOperations';
import {BulkProps, TasksBulkTypes} from 'modules/Tasks/utils/constants';
import AsyncTaskTypeSelect from 'shared/components/CoreForm/Select/AsyncTaskTypeSelect';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import Confirmation from 'shared/components/Confirmation';

const BulkType = ({onClose, gantt, projectId}: BulkProps) => {
  const [selectedType, setSelectedType] = useState(null);
  const client = useQueryClient();
  const {t} = useTranslation(['gantt', 'task']);
  const {bulkUpdate} = useTasksBulkOperations(gantt);

  return (
    <Confirmation
      visible
      title={t('gantt:bulk_type.confirmation.title', 'Type')}
      description={t('gantt:bulk_type.confirmation.description', 'Set type for multiple activities')}
      cancelButton={t('gantt:bulk_assign.confirmation.buttons.cancel', 'Cancel')}
      acceptButton={t('gantt:bulk_assign.confirmation.buttons.accept', 'Submit')}
      onAccept={async () => {
        bulkUpdate({type: selectedType});
        client.refetchQueries(QUERY_CACHE_KEYS.taskTypes(projectId));
      }}
      onClose={onClose}
      isDisabledAcceptBtn={!selectedType}
      field={
        <AsyncTaskTypeSelect
          isCreatable
          isClearable
          onChange={setSelectedType}
          value={selectedType}
          name={TasksBulkTypes.type}
          placeholder={t('task:task_form.form.type.placeholder', 'Choose Type')}
          menuPosition="fixed"
        />
      }
    />
  );
};

export default BulkType;
