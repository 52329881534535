import {RecaptchaVerifier} from 'firebase/auth';
import {useCallback} from 'react';

import FirebaseDefault from 'services/Firebase';

export function useRecaptcha() {
  const create = useCallback((containerId: string, mobileNumber?: string) => {
    if (document.getElementById(containerId) === null) {
      const container = document.createElement('div');
      container.setAttribute('id', containerId);
      document.body.appendChild(container);
    }
    if (mobileNumber) {
      FirebaseDefault.toggleRecapchaAutoVerificationForTesting(mobileNumber);
    }
    return FirebaseDefault.initRecaptchaVerifier(containerId, 'invisible');
  }, []);

  const destroy = useCallback((recaptcha: RecaptchaVerifier, containerId: string) => {
    if (recaptcha !== null) {
      recaptcha.clear();
      if (!!document.getElementById(containerId)) {
        document.body.removeChild(document.getElementById(containerId));
      }
    }
    FirebaseDefault.toggleRecapchaAutoVerificationForTesting();
  }, []);

  return {
    create,
    destroy,
  };
}
