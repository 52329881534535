import {useCallback} from 'react';

import {useRootDispatch} from 'store';
import {getCompanySubscriptions} from 'store/profile/actions';
import {getAllProjects} from 'store/projects/actions';

import {useEffectWithCompanyId} from './useEffectWithCompany';

export function useCompanyDataLoader() {
  const dispatch = useRootDispatch();
  const loadProjects = useCallback(
    (companyId) => {
      dispatch(getAllProjects(companyId));
    },
    [dispatch],
  );
  useEffectWithCompanyId((companyId) => {
    loadProjects(companyId);
    dispatch(getCompanySubscriptions(companyId));
  });
}
