import {useTranslation} from 'react-i18next';

import Icon from 'shared/components/Icon';
import {IconsMap} from 'shared/constants/icons';

import {DailiesCommentForm} from './DailiesCommentForm';
import {useDailiesReports} from './hooks/useDailiesReports';

export function DailiesReportHeader({selectedDay}: {selectedDay: string}) {
  const {t} = useTranslation('dailies');
  const {totalActivities, totalReportedManpower, totalReportedProgress} = useDailiesReports();

  return (
    <header className="daily-report__header">
      <div className="daily-report__title">
        {t('dailies_report.daily_report')}
        <span className="daily-report__date">
          {selectedDay} <Icon name={IconsMap.chrome_calendar} />
        </span>
      </div>
      <DailiesCommentForm />
      <div className="daily-report__content">
        {/* TODO: add weather and temperature once we have a concept of persisting this data */}
        {/* https://journey-builders.atlassian.net/browse/CNA-3901 */}
        {/* <div className="daily-report__content--item daily-report__item--weather"> */}
        {/*   <span className="daily-report__text">75% chance of snow</span> */}
        {/* </div> */}
        {/* <div className="daily-report__content--item daily-report__item--temperature"> */}
        {/*   <span className="daily-report__text">31° F (max)</span> */}
        {/* </div> */}
        <div className="daily-report__content--item daily-report__item--activities">
          <span className="daily-report__text">
            {t('dailies_report.total_activities')} {totalActivities}
          </span>
          <span className="daily-report__text">
            {t('dailies_report.total_entries')} {totalReportedProgress}
          </span>
        </div>
        <div className="daily-report__content--item daily-report__item--manpower">
          <span className="daily-report__text">
            {t('dailies_report.total_manpower')} {totalReportedManpower}
          </span>
        </div>
        {/* TODO: add Potential issues when comments are added in fast follow  */}
        {/* https://journey-builders.atlassian.net/browse/CNA-3901 */}
        {/* <div className="daily-report__content--item daily-report__item--issues"> */}
        {/*   <span className="daily-report__text">Potential Issues 1</span> */}
        {/* </div> */}
      </div>
    </header>
  );
}
