/* eslint-disable @typescript-eslint/no-unused-vars */
import {ComponentProps, FC, useEffect, useRef, useState} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router';
import {ActionMeta} from 'react-select';

import ProjectsApi from 'api/projects';
import CoreSelectField from 'shared/components/CoreForm/Select/Select';
import {defaultReactSelectStyles} from 'shared/components/CoreForm/Select/styles';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

type Props = {
  name: string;
  value: string;
  loadingPlaceholder?: string;
  isFocusWhenLoading?: boolean;
  _projectId?: string;
  noOptionsMessage?: string;
};

const AsyncTaskTypeSelect: FC<Props & ComponentProps<typeof CoreSelectField>> = ({
  name,
  value,
  loadingPlaceholder = 'Loading...',
  placeholder,
  onChange,
  isCreatable = false,
  isDisabled,
  isFocusWhenLoading,
  _projectId,
  ...props
}) => {
  // TODO: get rid of binding to query params
  const urlParams = useParams<{status: string; id: string; projectId: string}>();
  const selectRef = useRef(null);
  const [selected, setSelected] = useState<string>(null);
  const projectId = _projectId || urlParams.projectId;

  useEffect(() => {
    value && setSelected(value);
  }, [value]);
  const {
    data: taskTypes,
    isLoading,
    refetch: refetchTaskTypes,
  } = useQuery(
    QUERY_CACHE_KEYS.taskTypes(projectId),
    () => {
      const options: CoreOptionType[] = [];
      return ProjectsApi.getProjectTaskTypes(projectId).then((res) => {
        res.forEach((label) => {
          if (label.length) options.push({value: label, label: label});
        });
        return options;
      });
    },
    {enabled: !!projectId, refetchOnWindowFocus: false},
  );

  useEffect(() => {
    if (isFocusWhenLoading) selectRef.current?.focus();
  }, [taskTypes]);

  const onSelectChange = (value: string, {action}: ActionMeta<CoreOptionType>) => {
    setSelected(value);
    onChange(value);
    if (action === 'create-option') {
      refetchTaskTypes();
    }
  };

  const SelectType = isCreatable ? CoreSelectField.Creatable : CoreSelectField;

  return (
    <SelectType
      isSearchable
      isClearable
      ref={selectRef.current}
      isDisabled={isLoading || isDisabled}
      isLoading={isLoading}
      options={taskTypes}
      name={name}
      placeholder={isLoading ? loadingPlaceholder : placeholder}
      className="react-select"
      classNamePrefix="react-select"
      openMenuOnClick={!isLoading}
      onChange={onSelectChange}
      styles={defaultReactSelectStyles}
      value={selected || null}
      isValidNewOption={(val) => !!val.trim()}
      {...props}
    />
  );
};

export default AsyncTaskTypeSelect;
