import {TFunction} from 'i18next';
import {ChangeEvent, forwardRef} from 'react';

import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

import Icon from '../Icon';

type NoAvatarProps = {
  onImageChange: (event: ChangeEvent<HTMLInputElement>) => void;
  browseEvent?: string;
  t: TFunction;
};

export const NoAvatar = forwardRef<HTMLInputElement, NoAvatarProps>((props, ref) => {
  const {mixpanel} = useAnalyticsService();
  return (
    <>
      <div className="avatar-add ctrl-upload__avatar">
        <Icon name="user" size={24} colorFill={true} className="avatar-add__icon" />
        <span className="avatar-add__text">Add Photo</span>
        <input
          className="avatar-add__field"
          name="profileImage"
          id="profileImage"
          accept=".jpg,.jpeg,.png,.bmp"
          onChange={props.onImageChange}
          type="file"
          ref={ref}
        />
      </div>
      <div className="ctrl-dnd ctrl-upload__body">
        <div className="ctrl-dnd__text">
          {props.t('common:cropper.selectTitle', 'Select a profile photo here to upload')}
        </div>
        <button
          className="z-ctrl-btn z-ctrl-btn--size-xl ctrl-dnd__button"
          onClick={() => props?.browseEvent && mixpanel.track(props.browseEvent)}
          type="button"
        >
          <Icon name="upload_outlined" size={24} className="z-ctrl-btn__icon" colorFill={true} />
          <label style={{cursor: 'pointer'}} htmlFor="profileImage" className="z-ctrl-btn__text">
            {props.t('common:cropper.browse', 'Browse')}
          </label>
        </button>
      </div>
    </>
  );
});
NoAvatar.displayName = 'NoAvatar';
