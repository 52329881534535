import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import {ChangeEvent, FC, memo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import ExportApi from 'api/export';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {Activity} from 'modules/Tasks/components/Gantt/types';
import {loadIssues} from 'modules/Tasks/components/Gantt/utils/load';
import {ExportToCSV} from 'services/ExportToCSV';
import Icon from 'shared/components/Icon';
import {toShortIso} from 'shared/helpers/dates';
import {addStatusToFilters} from 'shared/helpers/tasks';
import {useCompany} from 'shared/hooks/useCompany';
import {useTasksUrlState} from 'shared/hooks/useTasksUrlState';
import {TaskGanttModel} from 'shared/models/task/task';

import s from './ExportDropdown.module.scss';
import {escapeCSV, capitalizeAndJoinWords} from './util';
import CtrlBtnOption from 'shared/components/CoreNewUI/CtrlBtnOption/CtrlBtnOption';
import CtrlChip from 'shared/components/CoreNewUI/CtrlChip/CtrlChip';
import CtrlChipGroup from 'shared/components/CoreNewUI/CtrlChipGroup/CtrlChipGroup';

const availableDateFormats = ['MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD'] as const;

type ExportButtonProps = {
  exportIssues?: boolean;
};

const ExportButton: FC<ExportButtonProps> = ({exportIssues = false}) => {
  const {t} = useTranslation('gantt');
  const {queryParams} = useFilterContext();
  const {projectId} = useParams<{projectId: string}>();
  const [isExporting, setIsExporting] = useState(false);
  const [exportDateFormat, setExportDateFormat] = useState<string>(availableDateFormats[0]);
  const company = useCompany();
  const tasksState = useTasksUrlState();

  const onChangeExportDateFormat = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setExportDateFormat(value);
  };

  const startExport = async () => {
    const res = await ExportApi.exportTasks(company.id, {
      dateFormat: exportDateFormat,
      params: addStatusToFilters(tasksState, {...queryParams, projectId}),
    });
    setIsExporting(true);
    const exportData = await ExportApi.pollExportTasksResults(company.id, res.data.id, 200);
    if (exportData.status === 'finished') {
      if (exportData.result.errors) {
        toast.error(exportData.result.errors[0].code);
      } else if (exportData.result.error) {
        toast.error(t('toast.error.unexpected', 'Unexpected server error'));
      } else {
        const csvExport = new ExportToCSV();
        csvExport.download(company.companyName + '_' + new Date().toLocaleString(), '', exportData.resultFileUrl);
      }
    }
    setIsExporting(false);
  };

  const startIssuesExport = async () => {
    try {
      const {tasks} = await new Promise<{tasks: TaskGanttModel[]}>((resolve) => {
        loadIssues({
          projectId,
          queryParams: {},
          setLoading: setIsExporting,
          done: resolve,
        });
      });

      const formatDate = (date: Date | undefined): string => {
        return date ? dayjs(date).format(exportDateFormat) : '';
      };

      const flatExportData =
        tasks?.map((issue) => {
          const taskNames = issue.activities.map((activity: Activity) => activity.name).join(', ');

          return {
            activity_names: taskNames,
            cost_impact: issue.cost_impact ? 'Yes' : 'No',
            description: issue.description || '',
            end: formatDate(issue.end_date),
            impact: issue.impact || '',
            issue: issue.name || '',
            issue_type: capitalizeAndJoinWords(issue.issue_type || ''),
            liable_company: issue.culpable_org?.abbrev || '',
            responsible: issue.responsible?.map((r) => r.member_name).join(', ') || '',
            responsible_company: issue.responsible_org?.abbrev || '',
            start: toShortIso(issue.start_date),
            status: capitalizeAndJoinWords(issue.status || ''),
            tracking_number: issue.cost_tracking_number || '',
            unique_id: issue.unique_id || '',
          };
        }) || [];

      const headers = flatExportData.length > 0 ? Object.keys(flatExportData[0]) : [];
      const formattedHeaders = headers.map(capitalizeAndJoinWords);

      let csvContent = formattedHeaders.map(escapeCSV).join(',') + '\n';

      flatExportData.forEach((row) => {
        const rowContent = headers.map((header) => escapeCSV(row[header])).join(',');
        csvContent += rowContent + '\n';
      });

      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement('a');
      const timestamp = new Date().toISOString().replace(/[-:]/g, '').replace('T', '_').split('.')[0];
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `${company.companyName}_issues_${timestamp}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(function () {
          URL.revokeObjectURL(link.href);
        }, 4e4); // 40s
      }
    } catch (error: unknown) {
      toast.error(t('toast.error.unexpected', 'Unexpected error'));
      Sentry.captureException(error, {tags: {context: 'ExportButton', name: startIssuesExport.name}});
    }
  };

  return (
    <CtrlBtnOption
      disabled={isExporting ?? !company?.id}
      title={
        isExporting ? t('toolbar.actions.exporting', 'Exporting...') : t('toolbar.actions.export', 'Export to CSV')
      }
      icon={<Icon name="export" colorFill className={s.iconColor} />}
      onClick={exportIssues ? startIssuesExport : startExport}
    >
      <CtrlChipGroup title={t('toolbar.actions.chooseDate', 'Choose date format for Export')}>
        {availableDateFormats.map((format, index) => {
          return (
            <CtrlChip
              key={`${format}|${index}`}
              label={format}
              value={format}
              name="dateFormat"
              checked={exportDateFormat === format}
              onChange={onChangeExportDateFormat}
            />
          );
        })}
      </CtrlChipGroup>
    </CtrlBtnOption>
  );
};
export default memo(ExportButton);
