import classnames from 'classnames';
import {ButtonHTMLAttributes, cloneElement, forwardRef, isValidElement, ReactElement, ReactNode} from 'react';

import Icon, {IconProps} from '../Icon';

type ButtonSize = 'xl' | 'xs' | 'l' | 'm' | 'xxs';
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: ReactElement<IconProps>;
  iconOnly?: boolean;
  suffix?: string;
  buttonSize?: ButtonSize;
  isLoading?: boolean;
  colorStyle?: string;
  rightAddon?: ReactNode;
}

function sfx(target: string, suffix?: string) {
  return suffix ? suffix + '-' + target : target;
}
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({className, children, icon, suffix, buttonSize, iconOnly, isLoading, colorStyle, rightAddon, ...props}, ref) => {
    const getIcon = () =>
      icon && isValidElement(icon)
        ? cloneElement(icon, {
            ...icon.props,
            className: classnames(
              iconOnly ? sfx('ctrl-btn-clear__icon', suffix) : sfx('ctrl-btn__icon', suffix),
              icon.props.className,
            ),
          })
        : null;
    return (
      <button
        className={classnames(
          iconOnly ? sfx('ctrl-btn-clear', suffix) : sfx('ctrl-btn', suffix),
          buttonSize ? sfx('ctrl-btn--size-' + buttonSize, suffix) : '',
          className,
          {'is-processing': isLoading},
          colorStyle && sfx(`ctrl-btn--color-${colorStyle}`, suffix),
        )}
        type="button"
        {...props}
        ref={ref}
      >
        {iconOnly && <span className={sfx('ctrl-btn-clear__text', suffix)}>{children}</span>}
        {isLoading ? (
          <Icon className="ctrl-btn__icon" colorFill name="autorenew" size={icon?.props?.size || 24} />
        ) : (
          getIcon()
        )}
        {!iconOnly && (
          <span className={sfx('ctrl-btn__text', suffix)} style={{marginRight: rightAddon ? 5 : 0}}>
            {children}
          </span>
        )}
        {!iconOnly && isValidElement(rightAddon) && rightAddon}
      </button>
    );
  },
);
Button.displayName = 'Button';
export default Button;
