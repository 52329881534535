import {useEffect, useState} from 'react';

import {parser, device} from 'shared/utils/UAParser';

export function useIsTablet(): boolean {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const result = parser.getResult();
    const deviceType = result.device.type;
    if (deviceType === device.TABLET) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
  }, []);

  return isTablet;
}
