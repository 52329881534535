import {decamelizeKeys} from 'humps';

import ApiAxios from 'api/axios';
import {GanttApiResponse} from 'modules/Tasks/components/Gantt/types';
import {prepareTaskFilters} from 'shared/helpers/tasks';
import {TaskParams, TaskFilterParams} from 'shared/models/task/filter';
import {TaskGanttModelDTO} from 'shared/models/task/task';

class GanttService {
  private getBaseUrl = (projectId) => `/projects/${projectId}/gantt`;

  getTasksWithFilters(
    projectId: string,
    {limit = 20, offset = 0, params = {}, projection, sortOrder, sortField}: TaskParams = {},
  ) {
    return this.getTasks(projectId, {
      filter_params: JSON.stringify(prepareTaskFilters(params)),
      offset,
      limit,
      projection,
      sortOrder,
      sortField,
    });
  }

  getTasks(projectId: string, params?: TaskFilterParams) {
    return ApiAxios.get<GanttApiResponse>(this.getBaseUrl(projectId), {
      params: {...params, parentIds: params?.parentIds?.join(',')},
    });
  }

  getSubTasks(projectId: string, parentId: string) {
    return ApiAxios.get<GanttApiResponse>(this.getBaseUrl(projectId), {
      params: {parent_id: parentId},
    }).then((res) => {
      return {
        data: {
          ...res.data,
          tasks: decamelizeKeys(res.data.tasks) as TaskGanttModelDTO[],
        },
      };
    });
  }
}

export default new GanttService();
