import {AxiosResponse} from 'axios';
import {decamelize, decamelizeKeys} from 'humps';

import {prepareWorkerFiltersForUrl} from 'shared/helpers/worker';
import {ProjectModel} from 'shared/models/project';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {CompanyInvite, CompanyWorker, CompanyWorkerRequestParams, WorkerTaskLoad} from 'shared/models/worker';

import ApiAxios from './axios';

type InviteQueryParams = {
  sendInviteSms: boolean;
  sendInviteEmail: boolean;
  inviteAsAdmin: boolean;
  inviteAsForeman: boolean;
};

const defaultInviteQueryParams: InviteQueryParams = {
  sendInviteSms: true,
  sendInviteEmail: false,
  inviteAsAdmin: false,
  inviteAsForeman: false,
};

class CompanyWorkersService {
  private getBasePath(companyId: string) {
    return `/companies/${companyId}/companyworkers`;
  }

  private getBasePathProjects(projectId: string) {
    return `/projects/${projectId}/companyworkers`;
  }

  getAll<RT extends 'data' | 'response' = 'data'>(
    companyId: string,
    {offset, limit, filterParams, sortOrder, sortField}: CompanyWorkerRequestParams,
    returnType?: RT,
  ): RT extends 'data' ? Promise<CompanyWorker[]> : Promise<AxiosResponse<CompanyWorker[]>>;
  getAll(
    path: string,
    {offset, limit, filterParams, sortOrder, sortField}: CompanyWorkerRequestParams,
    returnType: 'data' | 'response' = 'data',
  ) {
    const preparedSort = {};
    sortField ? Object.assign(preparedSort, {sortField: decamelize(sortField)}) : '';
    sortOrder ? Object.assign(preparedSort, {sortOrder}) : '';
    return ApiAxios.get<CompanyWorker[]>(path, {
      params: {
        offset,
        limit,
        filterParams: JSON.stringify(decamelizeKeys(prepareWorkerFiltersForUrl(filterParams))),
        ...preparedSort,
      },
    }).then((res) => (returnType === 'data' ? res.data : res));
  }

  getWorkerTaskLoad(companyId: string, dateBegin: string | Date) {
    return ApiAxios.get<WorkerTaskLoad>(`/companies/${companyId}/worker_task_load`, {params: {dateBegin}}).then(
      (res) => res.data,
    );
  }

  getCompanyWorker(companyId: string, companyWorkerId: string) {
    return ApiAxios.get<CompanyWorker>(`${this.getBasePath(companyId)}/${companyWorkerId}`).then((res) => res.data);
  }

  getAllCompaniesWorkers<RT extends 'data' | 'response' = 'data'>(
    companyId: string,
    params: CompanyWorkerRequestParams,
    type?: RT,
  ) {
    return this.getAll(this.getBasePath(companyId), params, type).then((res) => res);
  }

  getAllProjectWorkers<RT extends 'data' | 'response' = 'data'>(
    projectId: string,
    params: CompanyWorkerRequestParams,
    type?: RT,
  ) {
    return this.getAll(this.getBasePathProjects(projectId), params, type).then((res) => res);
  }

  updateCompanyWorker(worker: Partial<CompanyWorker>) {
    return ApiAxios.post<CompanyWorker>(`${this.getBasePath(worker.companyId)}/${worker.id}`, worker).then(
      (res) => res.data,
    );
  }

  updateInvitedCompanyWorker({
    companyId,
    companyWorkerId,
    sendInviteEmail,
    sendInviteSms,
    ...payload
  }: UpdateCompanyWorkerPayload) {
    return ApiAxios.post<UpdateCompanyWorkerResponse>(
      `${this.getBasePath(companyId)}/${companyWorkerId}/worker`,
      payload,
      {params: {sendInviteEmail, sendInviteSms}},
    ).then((res) => res.data);
  }

  updateInvitedProjectWorker({
    projectId,
    companyWorkerId,
    sendInviteEmail,
    sendInviteSms,
    ...payload
  }: UpdateCompanyWorkerPayload) {
    return ApiAxios.post<UpdateCompanyWorkerResponse>(
      `${this.getBasePathProjects(projectId)}/${companyWorkerId}/worker`,
      payload,
      {params: {sendInviteEmail, sendInviteSms}},
    ).then((res) => res.data);
  }

  getTasks(companyId: string, companyWorkerId: string) {
    return ApiAxios.get<TaskDetailsModelDTO[]>(`${this.getBasePath(companyId)}/${companyWorkerId}/tasks`).then(
      (res) => res.data,
    );
  }

  getProjects(companyId: string, companyWorkerId: string) {
    return ApiAxios.get<ProjectModel[]>(`${this.getBasePath(companyId)}/${companyWorkerId}/projects`).then(
      (res) => res.data,
    );
  }

  inviteToCompany(payload: InviteWorkerPayload, queryParams: InviteQueryParams = defaultInviteQueryParams) {
    return this.invite(payload, queryParams, `/companies/${payload.companyId}/invitecontact`);
  }

  inviteToProject(payload: InviteWorkerPayload, queryParams: InviteQueryParams = defaultInviteQueryParams) {
    return this.invite(payload, queryParams, `/projects/${payload.projectId}/invitecontact`);
  }

  invite(payload: InviteWorkerPayload, queryParams: InviteQueryParams = defaultInviteQueryParams, url: string) {
    return ApiAxios.post<CompanyWorker>(url, payload, {
      params: queryParams,
    }).then((res) => res.data);
  }
}

export default new CompanyWorkersService();

type InviteWorkerPayload = {
  companyId: string;
  workerName: string;
  workerPhoneNumber: string;
  workerEmail: string;
  trade: string;
  orgMappingIds: string[];
} & CompanyInvite;

type UpdateCompanyWorkerPayload = {
  companyWorkerId: string;
  companyId?: string;
  projectId?: string;
  fullName: string;
  trade: string;
  mobileNumber: string;
  email: string;
  orgMappingIds: string[];
  sendInviteEmail?: boolean;
  sendInviteSms?: boolean;
};
export type UpdateCompanyWorkerResponse = {
  fullName: string;
  mobileNumber: string;
  trade: string;
  id: string;
};
