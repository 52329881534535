import cn from 'classnames';
import {ChangeEvent, useState} from 'react';

import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';

import {onInput} from '../../utils/helpers';
import sharedStyle from '../shared.module.scss';

import styles from './actualManpowerCounter.module.scss';

interface ActualManpowerCounterProps {
  count: number;
  disabled: boolean;
  handleChangeCount: (newCount: number) => void;
  index: number;
  readOnlyMode: boolean;
}

const ActualManpowerCounter = ({
  count,
  disabled,
  handleChangeCount,
  index,
  readOnlyMode,
}: ActualManpowerCounterProps) => {
  const [animationState, setAnimationState] = useState({increment: false, decrement: false});

  const increment = () => {
    setAnimationState((prev) => ({...prev, increment: true}));
    handleChangeCount(count + 1);
    setTimeout(() => setAnimationState((prev) => ({...prev, increment: false})), 300);
  };

  const decrement = () => {
    if (count <= 0 || count === null) {
      return;
    }
    setAnimationState((prev) => ({...prev, decrement: true}));
    handleChangeCount(Math.max(count - 1, 0));
    setTimeout(() => setAnimationState((prev) => ({...prev, decrement: false})), 300);
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const inputValue = e.target.value.trim().replace(/^0\d+/, '');
    const newValue = parseInt(inputValue, 10);

    if (isNaN(newValue) || newValue < 0) {
      handleChangeCount(null);
      return;
    }
    handleChangeCount(newValue);
  };

  const inputStyle = cn({
    [sharedStyle.input]: true,
    [sharedStyle.disabled_placeholder]: disabled,
  });

  const buttonStyle = cn({
    [sharedStyle.button]: true,
    [sharedStyle.button_readonly]: readOnlyMode,
  });

  return (
    <div className={styles.counter} aria-disabled={disabled}>
      <Button
        className={buttonStyle}
        disabled={disabled}
        icon={<Icon size={10} name="prefix-icon" />}
        iconOnly
        onClick={!readOnlyMode ? decrement : undefined}
      />
      {animationState.decrement && <div className={sharedStyle.sinkAwayAnimation}>-1</div>}
      <section>
        {!readOnlyMode ? (
          <input
            className={inputStyle}
            disabled={disabled}
            id={`counter-${index}`}
            min={0}
            name="counter-input"
            onChange={!readOnlyMode ? onChange : undefined}
            onFocus={(e) => e.target.select()}
            onInput={onInput}
            placeholder="•"
            step="1"
            type="number"
            value={count ?? ''}
          />
        ) : (
          <span className={inputStyle}>{count}</span>
        )}
      </section>
      {animationState.increment && <div className={sharedStyle.floatAwayAnimation}>+1</div>}
      <Button
        className={buttonStyle}
        disabled={disabled}
        icon={<Icon size={10} name="suffix-icon" />}
        iconOnly
        onClick={!readOnlyMode ? increment : undefined}
      />
    </div>
  );
};

export default ActualManpowerCounter;
