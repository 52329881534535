import {onAuthStateChanged, User} from 'firebase/auth';
import {createContext, FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';

import Firebase from 'services/Firebase';
import {fullLogout} from 'store/ducks/auth/actions';

type AuthUserContext = {
  user: User;
  initialized: boolean;
  isAuthenticated: () => boolean;
};
const AuthUserContext = createContext<AuthUserContext>({
  user: null,
  initialized: false,
  isAuthenticated: () => false,
});

export const useAuth = () => {
  return useContext(AuthUserContext);
};

const AuthUserProvider: FC = ({children}) => {
  const [initialized, setInitialized] = useState(false);
  const [user, setUser] = useState<User>(null);
  const prevUser = useRef<User>(null);
  const dispatch = useDispatch();
  const isAuthenticated = useCallback(() => {
    return !!user && !user.isAnonymous && !!Firebase.getWorkerIdFromLS();
  }, [user]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(Firebase.auth, (authUser) => {
      setUser((user) => {
        prevUser.current = user;
        return authUser;
      });
      if (prevUser.current && !authUser) {
        setTimeout(() => {
          dispatch(fullLogout());
        }, 50);
      }
      setInitialized(true);
    });
    return () => unsubscribe();
  }, [setInitialized, setUser, dispatch]);

  useEffect(() => {
    if (!initialized && user && !user.isAnonymous && !Firebase.getWorkerIdFromLS()) {
      setTimeout(() => dispatch(fullLogout()), 100);
    }
  }, [initialized, user, dispatch]);

  return (
    <AuthUserContext.Provider value={{user, initialized, isAuthenticated}}>
      {initialized ? children : null}
    </AuthUserContext.Provider>
  );
};
export default AuthUserProvider;
