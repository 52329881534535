import {FC} from 'react';

import TiersPricingTable, {PriceTable} from '../';

const TooltipView: FC<PriceTable> = ({tiers}) => {
  return (
    <TiersPricingTable tiers={tiers} className="plan-price--view-tooltip plan-current__table" placement="tooltip" />
  );
};
export default TooltipView;
