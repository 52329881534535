import cn from 'classnames';
import {ChangeEvent, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import Icon from 'shared/components/Icon';

import Suggestions from '../Suggestions/Suggestions';

import s from './MembersAdd.module.scss';

type MembersAddProps = {
  className?: string;
  title: string;
  onClose?: () => void;
  inviteMember?: () => void;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onAfterSelectOrg: (org: string) => void;
  orgsName: string[];
};

const ONE_SYMBOL = 1;

const MembersAdd = ({
  className,
  title,
  onClose,
  onChange,
  inviteMember,
  onAfterSelectOrg,
  orgsName = [],
}: MembersAddProps) => {
  const [showSuggestion, setShowSuggestion] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const {t} = useTranslation('task');

  const onCloseSuggestion = () => {
    setShowSuggestion(false);
  };

  const onChangeInput = (e) => {
    setSearchValue(e?.target?.value);
    if (e?.target?.value.length && !showSuggestion) {
      setShowSuggestion(true);
    }
    onChange(e);
  };

  const onSelectOrg = (org: string) => {
    setSearchValue(org);
    onAfterSelectOrg(org);
    setShowSuggestion(false);
  };

  const orgsForSuggestion = useMemo(() => {
    const filteredOrgs = [];
    if (searchValue.length > ONE_SYMBOL) {
      for (const org of orgsName) {
        if (org.includes(searchValue)) {
          filteredOrgs.push(org);
        }
      }
    }
    return filteredOrgs;
  }, [searchValue, orgsName]);

  return (
    <section className={cn(s.MembersAdd, className)}>
      <h3 className={s.MembersAdd__title}>{title}</h3>
      <div className={s.MembersAdd__actions}>
        <CtrlButton icon="arrow_2_left" color="second" view="link" onClick={onClose}>
          {t('responsible_panel.back', 'Back')}
        </CtrlButton>
        <CtrlButton view="link" onClick={inviteMember}>
          {t('responsible_panel.invite', 'Invite Member')}
        </CtrlButton>
      </div>
      <FormControl icon={<Icon colorFill name="search_2" />}>
        <input
          type="text"
          placeholder={t('responsible_panel.search', 'Search by name or organization...')}
          value={searchValue}
          onChange={onChangeInput}
        />
      </FormControl>
      {!!orgsForSuggestion.length && showSuggestion && (
        <Suggestions orgs={orgsForSuggestion} onClick={onSelectOrg} onClose={onCloseSuggestion} />
      )}
    </section>
  );
};

export default MembersAdd;
