import cn from 'classnames';

import ColorsList from './components/ColorsList/ColorsList';
import SubcontractorForm from './components/Subcontactor/SubcontractorForm';
import SubcontractorMember from './components/Subcontactor/SubcontractorMember/SubcontractorMember';
import s from './SubcontractorTab.module.scss';

type SubcontractorTabProps = {
  className?: string;
};

const SubcontractorTab = ({className}: SubcontractorTabProps) => {
  return (
    <div className={cn(s.subcontractorTab, className)}>
      <SubcontractorForm className={s.subcontractorTab__header} />
      <div className={s.subcontractorTab__body}>
        <div className={s.subcontractorTab__part}>
          <ColorsList />
        </div>
      </div>
      <div className={cn(s.subcontractorTab__member)}>
        <SubcontractorMember />
      </div>
    </div>
  );
};

export default SubcontractorTab;
