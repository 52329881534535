import {createContext, useContext} from 'react';

import {TabProps} from './types';

export interface TabsContext {
  activeTab: number;
  setActiveTab: (tab: number) => void;
  tabs: TabProps[];
  setTabs: (tabs: TabProps[]) => void;
}
export const TabsContext = createContext<TabsContext>({
  activeTab: 0,
  setActiveTab: () => undefined,
  tabs: [],
  setTabs: () => undefined,
});
export const useTabsContext = () => useContext(TabsContext);
