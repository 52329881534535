import dayjs from 'dayjs';
import {camelize} from 'humps';

import {FeedbackGroupedByDate, FeedbackTypes} from 'shared/models/task/comments';
import {FeedbackProjectModelDTO, ExtChatEventMention} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {CompanyWorker} from 'shared/models/worker';

export function groupFeedback({
  feedbackList,
  findReportSubmitterById,
  filterFeedbackByType,
  filterFeedbackByDate,
}: {
  feedbackList: FeedbackProjectModelDTO[];
  findReportSubmitterById: (workerId: string) => CompanyWorker | undefined;
  filterFeedbackByType?: string[];
  filterFeedbackByDate?: [string, string];
}) {
  const groupedFeedback: FeedbackGroupedByDate = new Map();
  feedbackList.forEach((feedback) => {
    const {baseId, feedbackType, date} = feedback;
    const feedbackTypeKey = `${camelize(feedbackType)}Comments`;

    // Exclude feedbackByType
    if (filterFeedbackByType && filterFeedbackByType.includes(feedbackType)) {
      return;
    }

    // Case not covered by current design
    // Exclude ext_chat_events with type === 'm.room.message.text'
    if (
      feedback?.payload?.extChatEvent &&
      'event' in feedback?.payload?.extChatEvent &&
      (feedback.payload.extChatEvent?.event?.type === 'm.room.message.text' ||
        feedback.payload.extChatEvent?.event?.type === 'm.room.message')
    ) {
      return;
    }

    // Exclude feedback outside of date range
    if (filterFeedbackByDate) {
      const [startDate, endDate] = filterFeedbackByDate.map(dayjs);
      const feedbackDate = dayjs(date);

      // Include feedback if the date is on or after the start date AND on or before the end date
      if (feedbackDate.isBefore(startDate) || feedbackDate.isAfter(endDate)) {
        return; // Skip this feedback item as it's outside the specified date range
      }
    }

    if (!groupedFeedback.has(baseId)) {
      groupedFeedback.set(baseId, {
        key: baseId,
        date: dayjs(feedback?.date ?? feedback.timeCreated).format('YYYY-MM-DD'),
        feedbackTypes: {} as FeedbackTypes,
      });
    }

    const group = groupedFeedback.get(baseId);

    if (!group.feedbackTypes[feedbackTypeKey]) {
      group.feedbackTypes[feedbackTypeKey] = [];
    }

    const worker = findReportSubmitterById(feedback.workerId);

    group.feedbackTypes[feedbackTypeKey].push({
      id: feedback.id,
      date: feedback.date ?? null,
      timeCreated: feedback?.timeCreated,
      projectId: feedback.projectId,
      taskId: feedback.taskId,
      valueInteger: feedback.payload.valueInteger ?? null,
      body: feedback.payload?.mImage?.content?.body ?? feedback.payload?.mMessage?.content?.body ?? null,
      status: (feedback.payload?.taskEvent?.type?.split('.').slice(-1)[0] as TaskStatusType) ?? null, // e.g., 'com.bycore.task.assigned' becomes 'assigned'
      imageUrl: feedback.payload?.mImage?.fullImageUrl ?? null,
      thumbnailUrl: feedback.payload?.mImage?.thumbnailUrl ?? null,
      imageAlt: feedback.payload?.mImage?.content?.body ?? null,
      workerFullName: worker?.workerFull?.fullName ?? null,
      tags: feedback?.tags ?? [],
      ...(feedbackIsMentionContent(feedback)
        ? {
            activities: feedback.payload.extChatEvent.event.content.activities ?? [],
            attachments: feedback.payload.extChatEvent.event.content.attachments ?? [],
            body: feedback.payload.extChatEvent.event.content.formattedBody ?? null,
            groupId: feedback.payload.extChatEvent.groupId,
            isPublic: !feedback.payload.extChatEvent.event.content.mentionIsNotPublic,
            issues: feedback.payload.extChatEvent.event.content.issues ?? [],
            roomId: feedback.payload.extChatEvent.event.roomId,
            eventId: feedback.payload.extChatEvent.eventId,
          }
        : {}),
    });
  });
  return groupedFeedback;
}

export const feedbackIsMentionContent = (
  feedback: FeedbackProjectModelDTO,
): feedback is FeedbackProjectModelDTO & {
  payload: {extChatEvent: ExtChatEventMention};
} => {
  return (
    feedback.feedbackType === 'ext_chat_event' &&
    'event' in feedback.payload.extChatEvent &&
    feedback.payload.extChatEvent.event.type === 'com.bycore.task.mention'
  );
};
