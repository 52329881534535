import {GanttStatic} from 'dhtmlx-gantt';
import {useRef} from 'react';
import {useParams} from 'react-router';
import StateManager from 'react-select';

import {useInlineSelectController} from 'modules/Tasks/components/Gantt/components/Editors/useInlineSelectController';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import Select from 'shared/components/CoreForm/Select/Select';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {useMount} from 'shared/hooks/core/useMount';

type Props<O = CoreOptionType> = {
  gantt: GanttStatic;
  task: GanttTask;
  options: O[];
  name: keyof GanttTask;
};
const SelectEditor = <O,>({gantt, task, options, name}: Props<O>) => {
  const selectRef = useRef<StateManager>();
  // TODO: will fix in next iteration
  const {projectId} = useParams<{projectId: string}>();
  const {updateValue, onKeyUp, onKeyDown, onClose} = useInlineSelectController(gantt);

  useMount(() => {
    selectRef.current.focus();
  });

  const onChange = async (value: string) => {
    if (task.status !== value) {
      updateValue(task, name, value);
    }
  };

  return (
    <Select
      projectId={projectId || task.projectId || task.project_id}
      value={task[name]}
      options={options}
      className={`react-select`}
      ref={selectRef}
      menuPlacement="auto"
      size="xs"
      onChange={onChange}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      menuIsOpen={true}
      closeMenuOnSelect={false}
      tabSelectsValue={false}
    />
  );
};

export default SelectEditor;
