import {FC, PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';

import {ConfirmationProps} from 'shared/components/Confirmation/Confirmation';
import {ActionButtonConfig} from 'shared/components/Confirmation/useConfirm/state';
import {usePopupScrollHandler} from 'shared/hooks/usePopupScrollHandler';

import ActionButton from './components/ActionButton';
import ConfirmationCleanLayout from './ConfirmationCleanLayout';
import s from './NewConfirmation.module.scss';

const getActionButtonProps = (config: string | ActionButtonConfig) => {
  return typeof config === 'string' ? {title: config} : config;
};

const NewConfirmation: FC<PropsWithChildren<ConfirmationProps>> = ({
  description,
  title,
  acceptButton,
  cancelButton,
  onAccept,
  onReject,
  onClose,
  children,
}: PropsWithChildren<ConfirmationProps>) => {
  usePopupScrollHandler();
  const {t} = useTranslation('common');
  const cb = cancelButton || t('confirm.cancel', 'Cancel');
  const ab = acceptButton || t('confirm.accept', 'OK');
  return (
    <ConfirmationCleanLayout title={title} description={description} onClose={onClose}>
      {children}
      <footer className={s.confirmation__footer}>
        <div className={s['confirmation__footer--actions']}>
          {!!(cb && onReject) ? (
            <ActionButton
              onClick={onReject}
              dataCy="btnConfirmCancel"
              className={`${s.confirmation__button} ${s['confirmation__button--cancel']}`}
              {...getActionButtonProps(cb)}
            />
          ) : null}
          {!!(ab && onAccept) ? (
            <ActionButton
              onClick={onAccept}
              className={s.confirmation__button}
              dataCy="btnConfirmOk"
              {...getActionButtonProps(ab)}
            />
          ) : null}
        </div>
      </footer>
    </ConfirmationCleanLayout>
  );
};
export default NewConfirmation;
