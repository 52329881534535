import {ChangeEvent, FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import {ProjectModel} from 'shared/models/project';
import {useRootDispatch} from 'store';
import * as projectAsyncActions from 'store/projects/actions';

import {useCompanyWorkerRoles} from '../../hooks/useCompanyWorkerRoles';
import SkeletonFieldPreloader from '../CoreForm/SkeletonFieldPreloader';
import FieldInline from '../CoreNewUI/FormControlInline/FieldInline/FieldInline';
import FormControlInline from '../CoreNewUI/FormControlInline/FormControlInline';

import s from './ProjectNameInlineEdit.module.scss';

type Props = {
  project: ProjectModel;
  pretext?: string;
};

const ProjectNameInlineEdit: FC<Props> = ({pretext, project}: Props) => {
  const [projectName, setProjectName] = useState<string>('');
  const dispatch = useRootDispatch();
  const {t} = useTranslation(['project']);
  const [isLoading, setIsLoading] = useState(false);
  const {hasAnyAdminRole} = useCompanyWorkerRoles(project?.id);

  useEffect(() => {
    if (project?.name) {
      setProjectName(project.name);
    }
  }, [project?.name]);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setProjectName(value);
  };

  const onBlurHandler = async () => {
    const trimmed = projectName.trim();
    if (!trimmed.length) {
      toast.error(t('project:toast.error.inline_form.name.required', "Project name can't be empty."));
      setProjectName(project.name);
      return;
    }
    if (project.name !== projectName) {
      const model = Object.assign({}, project, {name: trimmed});
      await updateProject(model);
    }
  };

  const updateProject = async (model: ProjectModel) => {
    setIsLoading(true);
    const res = await dispatch(projectAsyncActions.updateProject(model));
    if (projectAsyncActions.updateProject.rejected.match(res)) {
      toast.error(res.error.message);
    } else {
      toast.success(t('project:toast.success.project.updated', 'Project updated'));
    }
    setIsLoading(false);
  };

  return (
    <FormControlInline labelIsHidden className={s.projectNameInlineEditInput__container}>
      <SkeletonFieldPreloader when={isLoading || !project?.name}>
        <FieldInline
          autoGrowth={{minWidth: 100, maxWidth: 350}}
          className={s.projectNameInlineEditInput}
          disabled={!hasAnyAdminRole}
          element="input"
          name="projectName"
          onBlur={onBlurHandler}
          onChange={onInputChange}
          placeholder=""
          pretext={pretext}
          sizeClass="l"
          value={projectName}
        />
      </SkeletonFieldPreloader>
    </FormControlInline>
  );
};
export default ProjectNameInlineEdit;
