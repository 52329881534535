import cn from 'classnames';

import Icon from '../Icon/Icon';

import s from './CtrlTab.module.scss';

type CtrlTabProps = {
  className?: string;
  text?: string;
  isActive?: boolean;
  iconName: string;
  onClick: () => void;
};

const CtrlTab = ({className, text = 'Button', isActive, iconName, onClick}: CtrlTabProps) => {
  return (
    <button className={cn(s.ctrlTab, isActive && s[`ctrlTab_isActive`], className)} onClick={onClick}>
      <Icon className={s.ctrlTab__icon} iconName={iconName} />
      <span className={s.ctrlTab__text}>{text}</span>
    </button>
  );
};

export default CtrlTab;
