import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import Avatar from 'shared/components/Avatar';
import {GroupMemberRole} from 'shared/models/task/const';
import {TaskAssignees} from 'shared/models/task/member';

import './style.css';

type Props = {
  assignees: TaskAssignees[];
};

const AssigneesPart = ({assignees}: Props) => {
  const {t} = useTranslation('shared_task');
  const sortedAssignees = useMemo(() => {
    return assignees?.sort((a) => {
      return a.memberRole === GroupMemberRole.responsible ? -1 : 0;
    });
  }, [assignees]);

  return (
    <div className="app-task-assignees">
      <div className="app-task-assignees__grid">
        {sortedAssignees.length
          ? sortedAssignees.map((item, idx) => (
              <div
                className="app-task-user app-task-assignees__item"
                key={idx}
                title={
                  item.memberRole === GroupMemberRole.responsible
                    ? t('assignees.responsible_title', 'Responsible')
                    : null
                }
              >
                <Avatar
                  src={item.memberProfilePicUrl}
                  className="app-task-user__avatar"
                  icon={
                    item.memberRole === GroupMemberRole.responsible ? (
                      <svg
                        className="icon-inline icon-inline--color-fill avatar__addition avatar__addition--responsible"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path d="M20 6.5v9c0 .276-.224.5-.5.5h-5.5c-1.105 0-2-.895-2-2h-6v7.5c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-19c0-.276.224-.5.5-.5h1c.276 0 .5.224.5.5v1.5h6c1.105 0 2 .895 2 2h5.5c.276 0 .5.224.5.5z"></path>
                      </svg>
                    ) : (
                      <svg
                        className="icon-inline icon-inline--color-fill ctrl-check-worker__icon assignees_eye-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="12"
                        height="12"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.994 5.001c4.394-.045 8.364 2.616 9.994 6.7.016.099.016.201 0 .3.003.113-.017.225-.06.33-1.631 4.051-5.569 6.695-9.934 6.67-4.37.022-8.309-2.631-9.934-6.69-.04-.098-.06-.204-.06-.31.001-.113.022-.224.06-.33 1.631-4.051 5.569-6.695 9.934-6.67zm-7.925 7c1.465 3.05 4.544 4.992 7.925 5 3.382-.008 6.461-1.95 7.925-5-1.46-3.054-4.542-4.998-7.925-4.998s-6.466 1.944-7.925 4.998zm7.925-2c1.104 0 1.999.895 1.999 2 0 1.104-.895 2-1.999 2s-1.999-.896-1.999-2c0-1.105.895-2 1.999-2zm-3.998 2c0-2.209 1.79-4 3.998-4s3.998 1.791 3.998 4-1.79 4-3.998 4-3.998-1.791-3.998-4z"
                        />
                      </svg>
                    )
                  }
                />
                <div className="app-task-user__name">{item.memberName}</div>
                <div className="app-task-user__trade">{item.memberTrade}</div>
              </div>
            ))
          : t('not_applicable', 'N/A')}
      </div>
    </div>
  );
};
export default AssigneesPart;
