interface BaseAction<K = string> {
  type: K;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

type Handler<S, A> = (state: S, action: A) => S;
type Handlers<S, A extends BaseAction> = {
  [K in A['type']]: Handler<S, A extends BaseAction<K> ? A : never>;
};

export function createReducer<S, A extends BaseAction>(initialState: S, handlers: Handlers<S, A>) {
  return (state = initialState, action: A): S =>
    handlers.hasOwnProperty(action.type) ? handlers[action.type](state, action) : state;
}
