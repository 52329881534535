const activeCompanyLocalStorageToken = 'activeCompanyId';
const activeProjectLocalStorageToken = 'activeProjectId';

export const setActiveCompanyToLocalStorage = (workerId: string, companyId: string) => {
  try {
    const map = JSON.parse(localStorage.getItem(activeCompanyLocalStorageToken));
    localStorage.setItem(
      activeCompanyLocalStorageToken,
      JSON.stringify(Object.assign(map || {}, {[workerId]: companyId})),
    );
  } catch (error) {
    console.warn('Cannot set active companyId to localStorage');
    console.warn(error);
  }
};

export const getActiveCompanyFromLocalStorage = (workerId: string) => {
  try {
    const map = JSON.parse(localStorage.getItem(activeCompanyLocalStorageToken));
    return map?.[workerId] as string;
  } catch (error) {
    console.warn('Cannot parse active companyId from localStorage');
    console.warn(error);
    return undefined;
  }
};

export const setActiveProjectToLocalStorage = (workerId: string, companyId: string, projectId: string) => {
  try {
    const map = JSON.parse(localStorage.getItem(activeProjectLocalStorageToken)) || {};
    const workerMap = map?.[workerId] || {};
    localStorage.setItem(
      activeProjectLocalStorageToken,
      JSON.stringify(Object.assign(map, {[workerId]: {...workerMap, [companyId]: projectId}})),
    );
  } catch (error) {
    console.warn('Cannot set active projectId to localStorage');
    console.warn(error);
  }
};

export const getActiveProjectFromLocalStorage = (workerId: string, companyId: string) => {
  try {
    const map = JSON.parse(localStorage.getItem(activeProjectLocalStorageToken));
    return map?.[workerId]?.[companyId] as string;
  } catch (error) {
    console.warn('Cannot parse active projectId from localStorage');
    console.warn(error);
    return undefined;
  }
};
