export enum CompletionUnits {
  Percent = '%',
  LF = 'LF',
  SF = 'SF',
  CY = 'CY',
  EA = 'EA',
  m = 'm',
  m2 = 'm^2',
  m3 = 'm^3',
  ton = 'T',
  MT = 'mt',
  kg = 'kg',
}

export const completionUnitLabels = {
  [CompletionUnits.Percent]: CompletionUnits.Percent,
  [CompletionUnits.LF]: CompletionUnits.LF,
  [CompletionUnits.SF]: CompletionUnits.SF,
  [CompletionUnits.CY]: CompletionUnits.CY,
  [CompletionUnits.EA]: CompletionUnits.EA,
  [CompletionUnits.m]: CompletionUnits.m,
  [CompletionUnits.m2]: 'm²',
  [CompletionUnits.m3]: 'm³',
  [CompletionUnits.ton]: 'Ton',
  [CompletionUnits.MT]: 'MT',
  [CompletionUnits.kg]: 'kg',
};
export const taskCompletionUnitOptions = [
  {value: CompletionUnits.Percent, label: CompletionUnits.Percent},
  {value: CompletionUnits.LF, label: CompletionUnits.LF},
  {value: CompletionUnits.SF, label: CompletionUnits.SF},
  {value: CompletionUnits.CY, label: CompletionUnits.CY},
  {value: CompletionUnits.EA, label: CompletionUnits.EA},
  {value: CompletionUnits.m, label: CompletionUnits.m},
  {value: CompletionUnits.m2, label: 'm²'},
  {value: CompletionUnits.m3, label: 'm³'},
  {value: CompletionUnits.ton, label: 'Ton'},
  {value: CompletionUnits.MT, label: 'MT'},
  {value: CompletionUnits.kg, label: CompletionUnits.kg},
];
