import cn from 'classnames';
import dayjs from 'dayjs';
import {useMemo} from 'react';

import {convertToTimezone, formatDate} from 'shared/helpers/dates';
import {TaskDetailsModelDTO, TaskHistoryType} from 'shared/models/task/task';

import {Comments} from '../Comments/Comments';
import InfoHistory from '../InfoHistory/InfoHistory';

import s from './HistoryList.module.scss';

type HistoryListProps = {
  className?: string;
  history: TaskDetailsModelDTO['statusHistory'];
  timezone: string;
};

type HistoryByDate = Record<number, TaskHistoryType[]>;

const HistoryList = ({className, history, timezone}: HistoryListProps) => {
  const historyByDate = useMemo(() => {
    return history
      ? history.reduce((acc, cur) => {
          const historyDate = convertToTimezone(cur.timeUpdated, timezone);
          const date = dayjs(historyDate).startOf('day');
          acc[+date] = (acc[+date] || []).concat(cur);
          return acc;
        }, {} as HistoryByDate)
      : ({} as HistoryByDate);
  }, [history]);

  const sortedDates = useMemo(
    () =>
      Object.keys(historyByDate)
        .map(Number)
        .sort((a, b) => b - a),
    [historyByDate],
  );
  return (
    <div className={cn(s.historyList, className)}>
      {sortedDates.map((dateNumber) => (
        <div key={dateNumber} className={s.historyList__group}>
          <Comments.Date className={s.historyList__date} date={formatDate(dateNumber, 'D MMM')} />
          {(historyByDate[dateNumber] as TaskHistoryType[])?.map((historyItem) => (
            <InfoHistory
              key={historyItem.timeUpdated}
              className={s.historyList__item}
              historyItem={historyItem}
              timezone={timezone}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default HistoryList;
