import './styles.scss';

import cn from 'classnames';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {TasksLocationState} from 'modules/Tasks/types/location';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import {KEYCODE} from 'shared/constants/common';
import {IconsMap} from 'shared/constants/icons';
import {useOutsideClick} from 'shared/hooks/core/useOusideClick';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCalculatePosition} from 'shared/hooks/useCalculatePosition';
import {useKey} from 'shared/hooks/useKey';

import {CommentsList} from './CommentCard/CommentList';
import {CommentForm, CommentFormRef} from './CommentForm/CommentForm';

export function CommentsTimeline({taskId}: {taskId: string}) {
  const location = useLocation<TasksLocationState>();
  const history = useHistory();
  const {t} = useTranslation('comments');
  const {viewMode} = useFilterContext();
  const {
    mixpanel: {trackWithAction, events},
  } = useAnalyticsService();
  const commentEvents = events.tasks.sidePanel.comments;
  const [isCommentFormVisible, setIsCommentFormVisible] = useState(false);
  const buttonRef = useRef(null); // Ref for the CTA button
  const formRef = useRef<CommentFormRef>(null); // Ref for the CTA button
  const formTopOffset = useCalculatePosition(buttonRef, isCommentFormVisible, 305);
  const classList = cn({
    'icon-rotate-forward': isCommentFormVisible,
    'icon-rotate-backward': !isCommentFormVisible,
  });

  useEffect(() => {
    if (location.state?.newIssueFocus) {
      buttonRef.current?.click();

      let observer: MutationObserver;

      // comment form container <div />
      const targetNode = formRef.current?.containerRef?.current;

      if (targetNode instanceof HTMLElement) {
        observer = new MutationObserver((mutations) => {
          for (const mutation of mutations) {
            // check to see if children are present
            if (mutation.target.hasChildNodes()) {
              formRef.current?.focusInput();
              observer.disconnect();
              break;
            }
          }
        });

        observer.observe(targetNode, {attributes: true});
      }

      return () => {
        observer?.disconnect();
        history.replace({state: {}});
      };
    }
  }, [history, location.state, location.state?.newIssueFocus]);

  // Close the form on ESC key press
  useKey({condition: isCommentFormVisible, key: KEYCODE.ESC, actionFn: () => setIsCommentFormVisible(false)});
  useOutsideClick({
    ref: formRef.current?.containerRef,
    ignore: isCommentFormVisible ? ['.react-datepicker', '.comments-timeline-form-action'] : [],
    callback: () => {
      setIsCommentFormVisible(false);
    },
  });

  function handleCommentFormToggle() {
    trackWithAction(() => setIsCommentFormVisible(!isCommentFormVisible), commentEvents.clickAddCommentButton, {
      viewMode,
    });
  }

  return (
    <>
      <div className="comments-timeline">
        <div className="comments-timeline--list">
          <CommentsList taskId={taskId} />
        </div>

        <div className="comments-timeline-form-action">
          <CtrlButton
            ref={buttonRef}
            aira-label="Add Comment"
            color="second"
            size="xs"
            shadow
            className={`${classList} button-ignore-outside-click`} // Add the class here
            icon={IconsMap.plus}
            onClick={handleCommentFormToggle}
          >
            {t('comments_timeline.cta')}
          </CtrlButton>
        </div>
      </div>

      <CommentForm
        ref={formRef}
        isFormVisible={isCommentFormVisible}
        setIsCommentFormVisible={setIsCommentFormVisible}
        style={{top: formTopOffset}}
        taskId={taskId}
      />
    </>
  );
}
