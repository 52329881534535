import classNames from 'classnames';
import equal from 'fast-deep-equal';
import {Children, cloneElement, FC, isValidElement, ReactElement, useEffect} from 'react';

import {useTabsContext} from './TabsContext';
import {TabProps} from './types';

type TabsContentContainerProps = {
  children: ReactElement<TabProps> | ReactElement<TabProps>[];
  className?: string;
};

const TabsContentContainer: FC<TabsContentContainerProps> = ({className, children}) => {
  const {setTabs, tabs} = useTabsContext();

  useEffect(() => {
    const newTabs = Children.toArray(children).map(
      (ch, index) =>
        isValidElement<TabProps>(ch) && {
          title: ch.props.title,
          index: ch.props.index || index,
          disabled: ch.props.disabled,
        },
    );
    if (!equal(newTabs, tabs)) {
      setTabs(newTabs);
    }
  }, [children, setTabs, tabs]);

  return (
    <>
      {Children.toArray(children).map((child, index) =>
        isValidElement<TabProps>(child)
          ? cloneElement(child, {
              ...child.props,
              index,
              className: classNames(child.props.className, className),
            })
          : null,
      )}
    </>
  );
};
export default TabsContentContainer;
