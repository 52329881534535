import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import CtrlTab from '../../CtrlTab/CtrlTab';
import {TabsType} from '../../MediaBlocks/MediaBlocks';

import s from './ContentTabs.module.scss';

type ContentTabsProps = {
  className?: string;
  updateActiveTab: (activeTab: TabsType) => void;
  activeTab: TabsType;
};

const ContentTabs = ({className, updateActiveTab, activeTab}: ContentTabsProps) => {
  const {t} = useTranslation('landing2');

  return (
    <>
      <div className={cn(s.contentTabs, className)}>
        <div className={s.contentTabs__nav}>
          <CtrlTab
            onClick={() => updateActiveTab(TabsType.activity)}
            className={s.contentTabs__navItem}
            text={t('section_one.tabs.activity', 'Activity')}
            iconName="landing_activity"
            isActive={activeTab === TabsType.activity}
          />
          <CtrlTab
            onClick={() => updateActiveTab(TabsType.subcontractor)}
            className={s.contentTabs__navItem}
            text={t('section_one.tabs.subcontractor', 'Subcontractor')}
            iconName="landing_subcontractor"
            isActive={activeTab === TabsType.subcontractor}
          />
          <CtrlTab
            onClick={() => updateActiveTab(TabsType.assignees)}
            className={s.contentTabs__navItem}
            text={t('section_one.tabs.assignees', 'Assignee')}
            iconName="landing_assignees"
            isActive={activeTab === TabsType.assignees}
          />
        </div>
      </div>
    </>
  );
};

export default ContentTabs;
