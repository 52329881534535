import {TFunction} from 'i18next';

export const avatarPath = '/images/testimonials';

export const getSlides = (t: TFunction) => [
  {
    text: t('landing2:reviews.slides.first.text'),
    authorName: t('landing2:reviews.slides.first.authorName'),
    authorPosition: t('landing2:reviews.slides.first.authorPosition'),
    authorImage: 'review-avatar-6',
  },
  {
    text: t('landing2:reviews.slides.second.text'),
    authorName: t('landing2:reviews.slides.second.authorName'),
    authorPosition: t('landing2:reviews.slides.second.authorPosition'),
    authorImage: 'review-avatar-4',
  },
  {
    text: t('landing2:reviews.slides.firth.text'),
    authorName: t('landing2:reviews.slides.firth.authorName'),
    authorPosition: t('landing2:reviews.slides.firth.authorPosition'),
    authorImage: 'review-avatar-2',
  },
  {
    text: t('landing2:reviews.slides.fourth.text'),
    authorName: t('landing2:reviews.slides.fourth.authorName'),
    authorPosition: t('landing2:reviews.slides.fourth.authorPosition'),
    authorImage: 'review-avatar-7',
  },
  {
    text: t('landing2:reviews.slides.fifth.text'),
    authorName: t('landing2:reviews.slides.fifth.authorName'),
    authorPosition: t('landing2:reviews.slides.fifth.authorPosition'),
    authorImage: 'review-avatar-5',
  },
  {
    text: t('landing2:reviews.slides.sixth.text'),
    authorName: t('landing2:reviews.slides.sixth.authorName'),
    authorPosition: t('landing2:reviews.slides.sixth.authorPosition'),
    authorImage: 'review-avatar-3',
  },
];
