import {useEffect} from 'react';
import {useParams} from 'react-router';

import {RouteParams} from 'shared/constants/routes';
import {usePrevious} from 'shared/hooks/core/usePrevious';
import Gantt from 'shared/models/Gantt';

export const useRemovePreviousProjectActivities = () => {
  const {projectId} = useParams<RouteParams['tasks']>();
  const prevProjectId = usePrevious(projectId);

  useEffect(() => {
    if (prevProjectId && prevProjectId !== projectId) {
      Gantt.list().forEach((instance) => {
        instance.clearAll();
      });
    }
  }, [prevProjectId, projectId]);
};
