import cn from 'classnames';

import s from './CtrlColorPreview.module.scss';

type CtrlColorPreviewProps = {
  className?: string;
  color: string;
  abbr: string;
  size?: 's';
};

const CtrlColorPreview = ({className, color, abbr, size}: CtrlColorPreviewProps) => {
  return (
    <div
      className={cn(s.ctrlColorPreview, size && s[`ctrlColorPreview_size_${size}`], className)}
      style={{backgroundColor: color}}
    >
      {abbr}
    </div>
  );
};

export default CtrlColorPreview;
