import classnames from 'classnames';
import {CSSProperties, FC} from 'react';
import * as React from 'react';

import Loader from '../Loader';

import PopupContent, {PopupContentProps} from './PopupContent';

type PopupProps = {
  visible: boolean;
  style?: CSSProperties;
};

// eslint-disable-next-line @typescript-eslint/ban-types
type WithClassName<T = {}> = {
  className?: string;
  style?: React.CSSProperties;
} & T;

const PopupHeader: FC<WithClassName> = ({children, className}) => (
  <header className={classnames('popup__header', className)}>{children}</header>
);
const PopupBody: FC<WithClassName & {isLoading?: boolean}> = ({children, className, style, isLoading}) => (
  <div className={classnames('popup__body loading-container', className)} style={style}>
    {children}
    {isLoading && <Loader />}
  </div>
);
const PopupFooter: FC<WithClassName> = ({children, className}) => (
  <footer className={classnames('popup__footer', className)}>{children}</footer>
);

const Popup: FC<PopupProps & PopupContentProps> & {
  Header: typeof PopupHeader;
  Body: typeof PopupBody;
  Footer: typeof PopupFooter;
} = ({visible, ...rest}) => {
  return visible ? <PopupContent {...rest} /> : null;
};
Popup.Header = PopupHeader;
Popup.Body = PopupBody;
Popup.Footer = PopupFooter;
export default Popup;
