import {useMemo} from 'react';

import {useAppConstants} from 'shared/hooks/useAppConstants';
import {decamelize} from 'humps';

export type LookaheadColors = {
  [key: string]: {
    fill: string;
    outline?: string;
    userSelectable: boolean;
  };
};

export function useResponsibleOrgColors() {
  const data = useAppConstants();

  const lookaheadColors = useMemo(() => {
    const result = {};
    for (const color in data?.locales?.uS?.lookaheadColors) {
      if (data?.locales?.uS?.lookaheadColors[color]) {
        const {fill, userSelectable} = data.locales.uS.lookaheadColors[color];
        result[decamelize(color)] = {userSelectable, fill: fill.replace('0x', '#')};
      }
    }
    return Object.keys(result).length ? (result as LookaheadColors) : null;
  }, [data]);

  const mappedLookaheadColors = useMemo(() => {
    const result = {};
    for (const key in lookaheadColors) {
      if (lookaheadColors[key] && lookaheadColors[key].userSelectable) {
        result[lookaheadColors[key].fill] = key;
      }
    }
    return result;
  }, [lookaheadColors]);

  return {lookaheadColors, mappedLookaheadColors};
}
