import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {CellProps} from 'react-table';

import {CenteredContainer} from 'shared/components/Table/components/Columns/Centered';
import {toTitleCase} from 'shared/helpers/common';
import {getCompanyWorkerStatus} from 'shared/helpers/statuses';
import {CompanyWorker, WorkerBlendedStatus} from 'shared/models/worker';

import s from './StatusColumn.module.scss';

export const StatusColumn = ({row}: CellProps<CompanyWorker, Worker>) => {
  const {t} = useTranslation('common');
  const workerStatus = row.original.workerFull.status;
  const companyWorkerStatus = row.original.status;
  const mappedStatus = getCompanyWorkerStatus(companyWorkerStatus, workerStatus);
  return (
    <CenteredContainer>
      <div
        className={cn(
          s.statusColumn,
          companyWorkerStatus && s[`statusColumn_${companyWorkerStatus.toLocaleLowerCase()}`],
        )}
      >
        {t(`worker_status.${mappedStatus as WorkerBlendedStatus}`) || toTitleCase(mappedStatus)}
      </div>
    </CenteredContainer>
  );
};
