import axios from 'axios';
import {camelizeKeys, decamelizeKeys, HumpsProcessor} from 'humps';

import {FirebaseTokenProvider} from 'services/Firebase/FirebaseTokenProvider';
import {TasksObserver} from 'services/TasksObserver/TasksObserver';
import env from 'shared/constants/env';
import {throttle} from 'shared/helpers/throttle';
import {CompanyStatus} from 'shared/models/company';
import store from 'store';
import {profileActions} from 'store/profile';
import {getActiveProjectFromLocalStorage} from 'store/profile/utils';

import CompanyApi from './company';

const ApiAxios = axios.create({baseURL: env.API_URL});

export const camelCaseConverter: HumpsProcessor = (key, convert, options) => {
  // for example: "x-amz" or "2023-01-01" in task.perDateOverrides
  if (key.includes('-')) return key;
  return convert(key, options);
};

export function initApiInterceptors(tokenProvider: FirebaseTokenProvider) {
  // authentication interceptor, add Authorization header for any request to /api
  ApiAxios.interceptors.request.use(async (req) => {
    const token = await tokenProvider.getUserIdToken();
    if (!!token && !req.headers.Authorization) {
      req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
  });

  // set platform header for any requests to /api
  ApiAxios.interceptors.request.use(async (req) => {
    req.headers['X-Journey-Platform'] = 'c4';
    if (window.location.pathname.includes('signup/alt-a')) {
      req.headers['X-Journey-Platform'] = 'c4_konvert';
    }
    return req;
  });

  // request body transformation from/to camel/snake case
  ApiAxios.interceptors.response.use((res) => {
    if (res.config.disableCamelCaseConverter) {
      return res;
    }
    if (res.data && res.headers['content-type'] === 'application/json') {
      // emit changes to observer
      if (
        !res.config.ignoreAffectedTaskIds &&
        res?.data?.affected_task_ids &&
        Array.isArray(res.data.affected_task_ids) &&
        res.data.affected_task_ids.length
      ) {
        const inst = TasksObserver.getInstance();
        inst.load({
          ids: res.data.affected_task_ids as string[],
          projectId: getActiveProjectFromLocalStorage(
            store.getState().profile.worker?.id,
            store.getState().profile.activeCompanyId,
          ),
        });
      }
      // exclusion case for import phase
      let savedSamplesFromCamelize = [];
      if (res.config.url.includes('prep/status') && res.data?.result?.samples) {
        savedSamplesFromCamelize = [...res.data.result.samples];
      }
      res.data = camelizeKeys(res.data, camelCaseConverter);
      if (savedSamplesFromCamelize.length) {
        res.data.result.samples = savedSamplesFromCamelize;
      }
    }
    return res;
  });

  ApiAxios.interceptors.request.use((req) => {
    const config = {...req};
    if (config.headers['Content-Type'] && config.headers['Content-Type'].includes('multipart/form-data')) {
      return config;
    }

    config.params = req.params && decamelizeKeys(req.params, camelCaseConverter);
    config.data = req.data && decamelizeKeys(req.data, camelCaseConverter);
    return config;
  });

  const dispatchShowAccessDeniedPopup = throttle(async () => {
    const activeCompanyId = store.getState().profile.activeCompanyId;
    const company = await CompanyApi.getCompany(activeCompanyId);
    if (company.status === CompanyStatus.Disabled) {
      if (!store.getState().profile.showAccessDeniedPopup) {
        store.dispatch(profileActions.showAccessDeniedPopup(true));
      }
    }
  }, 1500);

  ApiAxios.interceptors.response.use(
    (res) => res,
    (rej) => {
      if (rej.response?.status === 403) {
        dispatchShowAccessDeniedPopup();
      }
      return Promise.reject(rej);
    },
  );
}

export default ApiAxios;

declare module 'axios' {
  export interface AxiosRequestConfig {
    disableCamelCaseConverter?: boolean;
    ignoreAffectedTaskIds?: boolean;
  }
}
