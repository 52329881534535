import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import {removeDuplicateNames} from 'modules/Subcontractors/components/SubcontractorsPanel/utils/getSimilarNames';

import s from './SubcontractorSimilar.module.scss';

type SubcontractorSimilarProps = {
  name?: string;
  className?: string;
  subcontractorNames?: Array<string>;
};

const MAX_SIMILAR_SIZE = 4;

const SubcontractorSimilar = ({className, subcontractorNames, name}: SubcontractorSimilarProps) => {
  const similarList =
    !!removeDuplicateNames(subcontractorNames) &&
    removeDuplicateNames(subcontractorNames).map((subcontractorName, index) => {
      if (index > MAX_SIMILAR_SIZE) return;
      return (
        <div
          key={`${subcontractorName}_${index}`}
          className={cn(
            s.subcontractorSimilar__item,
            name === subcontractorName && s.subcontractorSimilar__item_isFullSimilar,
          )}
        >
          {subcontractorName}
        </div>
      );
    });

  const {t} = useTranslation('panel');

  return (
    <div className={cn(s.subcontractorSimilar, className)}>
      <div className={s.subcontractorSimilar__label}>
        {t('tab.subcontractors.form.similar.label', 'Already taken and similar names')}
      </div>
      <div className={s.subcontractorSimilar__body}>
        <div className={s.subcontractorSimilar__grid}>{similarList}</div>
      </div>
    </div>
  );
};

export default SubcontractorSimilar;
