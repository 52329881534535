import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router';

import {TasksLocationState} from 'modules/Tasks/types/location';
import {TasksViewMode} from 'shared/constants/common';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskActiveTab} from 'shared/models/task/const';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {useInjectStore} from 'shared/providers/injection';
import {UIStoreType} from 'shared/stores/UIStore';

import {useFilterContext} from '../../Filters/FilterProvider';

export const useTaskMentionClickHandler = (
  gantt: GanttStatic,
  openTask: (taskId: string) => void,
  openIssue: (issueId: string) => void,
) => {
  const location = useLocation<TasksLocationState>();
  const history = useHistory<TasksLocationState>();
  const {viewMode} = useFilterContext();
  const uiStore = useInjectStore<UIStoreType>(IOC_TYPES.UIStore);

  useEffect(() => {
    const handleTaskSelection = (id: string, isIssue: boolean, status: TaskStatusType) => {
      const search = new URLSearchParams();
      search.append('view', isIssue ? TasksViewMode.issues : TasksViewMode.gantt);
      search.append('activeTab', TaskActiveTab.info.toString());
      if (isIssue) {
        search.append('statusList', status);
      }
      history.replace({
        pathname: location.pathname,
        search: search.toString(),
        state: {taskId: id, activeTab: TaskActiveTab.info},
      });
      if (!uiStore.isLoading && gantt.isTaskExists(id)) {
        const activity = gantt.getTask(id);
        if (activity) {
          const lastSelectedTaskId = gantt.getLastSelectedTask();
          if (lastSelectedTaskId) {
            gantt.unselectTask(lastSelectedTaskId);
          }
          gantt.selectTask(id);
          const {top} = gantt.getTaskPosition(activity, activity.start_date, activity.end_date);
          gantt.scrollTo(null, top - 100);
          if (isIssue) {
            openIssue(id);
          } else {
            openTask(id);
          }
        }
      }
    };

    const handleClick = (e: MouseEvent) => {
      const target = e.target as Element;
      const clickedElement = target.closest(`li[data-bycore-task-id]`);
      if (clickedElement) {
        const id = clickedElement.getAttribute('data-bycore-task-id');
        const isIssue = clickedElement.getAttribute('data-bycore-is-issue') === 'true';
        const status = clickedElement.getAttribute('data-bycore-status') as TaskStatusType;
        handleTaskSelection(id, isIssue, status);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [gantt, history, location.pathname, openIssue, openTask, uiStore.isLoading, viewMode]);
};
