import cn from 'classnames';

import Icon from '../Icon/Icon';

import s from './Social.module.scss';

type Link = {
  icon: string;
  text: string;
  url: string;
};

type SocialProps = {
  className?: string;
  links: Link[];
};

const Social = ({className, links}: SocialProps) => {
  return (
    <ul className={cn(s.social, className)}>
      {links.map((element) => (
        <li key={element.icon} className={s.social__item}>
          <a className={s.social__link} href={element.url} rel="noopener noreferrer" target="_blank">
            <Icon iconName={element.icon} className={s.social__linkIcon} />
            <span className={s.social__linkText}>{element.text}</span>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default Social;
