import {date, number, object, string} from 'yup';

export const validationSchema = object().shape(
  {
    name: string()
      .max(200, 'Task Name too long')
      .required('This field is required')
      .test('onlySpaces', 'This field is required', (value) => value?.replaceAll(' ', '').length !== 0),
    startDate: date().required('This field is required').nullable(),
    endDate: date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('end_date_after_start_date', 'Must be after start date', (value, ctx) => {
        if (!(value instanceof Date)) {
          return true;
        }
        return value >= ctx.parent.startDate;
      }),

    description: string().required('This field is required').max(2000, 'Task Description too long'),
    status: string(),
    issueType: string().nullable(),
    responsible: string(),
    responsibleOrgId: string(),
    culpableOrgId: string(),
    impact: string(),
    costDays: number().typeError('This field is a number type'),
  },
  [['completionTarget', 'completionUnit']],
);
