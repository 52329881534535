import {GanttStatic} from 'dhtmlx-gantt';

import {EventStore} from 'modules/Tasks/components/Gantt/utils/eventStore';
import {GanttEventNameUnion} from 'modules/Tasks/components/Gantt/utils/gantt';
import {QueryCacheHelper} from 'shared/hooks/useQueryCache/QueryCacheHelper';
import {TaskFilterQuery} from 'shared/models/task/filter';
import {
  TaskGanttModelDTO,
  GanttLinkModelDTO,
  TaskGanttModel,
  TaskRelativeDirection,
  FeedbackByDateTask,
  GanttTaskCustomField,
} from 'shared/models/task/task';
import {TaskDependency} from 'shared/models/TaskDependency';

export interface GanttComponentApi {
  gantt: GanttStatic;
  cacheHelper: QueryCacheHelper;
  eventStore: EventStore<GanttEventNameUnion>;
  dataProcessor: any;
}

export interface GanttApiResponse {
  tasks: TaskGanttModelDTO[];
  links: GanttLinkModelDTO[];
}

export type IssuesDictionary = {
  [key: string]: {
    startDate: Date;
    endDate: Date | null;
  };
};

export type Activity = {id: string; name: string};

export interface FeedbackRollup {
  average_labor: string;
  completion_amount: string;
}

export interface GanttTask extends TaskGanttModel {
  time_removed: string;
  $index: number;
  $open: boolean;
  $source: number[];
  $target: number[];
  $move_data: {
    index: number;
    parent: string;
  };
  $local_index: number;
  $drop_target: string;
  openedEditPanel: boolean;
  activities: Activity[];
  row_height?: number;
  description: string;
  relativeDir: TaskRelativeDirection;
  relativeToId: string;
  doNotSendEndDate?: boolean;
  isReadyEdit: boolean;
  isSubtask: boolean;
  issueTaskIds: string[];
  readonly: boolean;
  location: string;
  type: string;
  assignees: TaskAssigneesGantt[];
  dataVersion?: number;
  checked?: boolean;
  rownum: number;
  sourceDeps: TaskDependency[];
  targetDeps: TaskDependency[];
  focus: string;
  baseline_start?: Date;
  baseline_end?: Date;
  variance?: number;
  feedback_rollup: FeedbackRollup;
  feedbackByDate?: FeedbackByDateTask[];
  custom_fields: GanttTaskCustomField[];
  isPending?: boolean;
}

export type TaskAssigneesGantt = {
  member_id: string;
  member_name: string;
};

export enum DragMode {
  resize = 'resize',
  process = 'process',
  move = 'move',
  ignore = 'ignore',
}

export interface LoadOptions {
  collapsed?: string[];
  projectId: string;
  queryParams?: Partial<TaskFilterQuery>;
  dataRange?: Date[];
  done: (data: any) => void;
  setLoading: (boolean) => void;
}
