import * as Sentry from '@sentry/browser';
import type {GanttStatic} from 'dhtmlx-gantt';
import {t} from 'i18next';
import {toast} from 'react-toastify';

import {container} from 'IocContainer';
import {IOC_TYPES} from 'shared/models/ioc';
import {UIStoreType} from 'shared/stores/UIStore';

export class SimpleLock {
  private locked = false;

  lock() {
    this.locked = true;
  }

  unlock() {
    this.locked = false;
  }

  isLocked() {
    return this.locked;
  }

  async run(cb: () => Promise<unknown>) {
    if (!this.isLocked()) {
      this.lock();
      try {
        await cb();
      } finally {
        this.unlock();
      }
    } else {
      toast.warn(
        t(
          'gantt:toast.error.operation_locked',
          'Sorry, your operation has been rejected because another in progress, try again.',
        ),
      );
    }
  }
}

export class GanttSimpleLock extends SimpleLock {
  /**
   * Purposefully did not inject this into IOC the container.
   * Would ideally be a singleton on the IOC container but
   * unclear of any fallout from making that change would occur.
   */
  uiStore = container.get<UIStoreType>(IOC_TYPES.UIStore);

  constructor(private gantt: GanttStatic) {
    super();
  }

  static _inst: GanttSimpleLock;

  static getInstance(gantt: GanttStatic) {
    if (GanttSimpleLock._inst && GanttSimpleLock._inst.gantt.name === gantt.name) {
      return GanttSimpleLock._inst;
    }
    return (GanttSimpleLock._inst = new GanttSimpleLock(gantt));
  }

  private _reason: string;
  private _showLoader: boolean;

  async runGanttLockOperation({
    callback,
    waitFor,
    showLoader = true,
  }: {
    callback: () => unknown;
    waitFor?: string;
    showLoader?: boolean;
  }): Promise<void> {
    if (waitFor) {
      this._reason = waitFor;
      this._showLoader = showLoader;
    }

    super.run(async () => {
      this.lock();
      if (this._showLoader) {
        this.uiStore.setLoading(true);
      }

      try {
        await callback();
      } catch (e) {
        Sentry.captureException(e);
      } finally {
        this.uiStore.setLoading(false);
        this.unlock(waitFor);
      }
    });
  }

  lock() {
    if (!this.isLocked()) {
      super.lock();
      if (this._showLoader) {
        this.uiStore.setLoading(true);
      }
    }
  }

  unlock(reason?: string): void {
    if (!this._reason || this._reason === reason) {
      super.unlock();
      if (this._showLoader) {
        this.uiStore.setLoading(false);
      }
      this._reason = null;
    }
  }
}
