export const defaultReactSelectStyles = {
  clearIndicator: () => ({cursor: 'pointer'}),
  container: () => ({}),
  control: () => ({}),
  dropdownIndicator: () => ({cursor: 'pointer'}),
  group: () => ({}),
  groupHeading: () => ({}),
  indicatorsContainer: () => ({}),
  indicatorSeparator: () => ({}),
  input: () => ({}),
  loadingIndicator: () => ({}),
  loadingMessage: () => ({}),
  menu: ({bottom, ...rest}) => ({bottom, top: 'unset !important', rest}),
  menuList: (base) => ({
    ...base,
    '::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none', // IE and Edge
    scrollbarWidth: 'none', // Firefox
  }),
  menuPortal: (base) => ({...base, zIndex: 9999}),
  multiValue: () => ({}),
  multiValueLabel: () => ({}),
  multiValueRemove: () => ({}),
  noOptionsMessage: () => ({}),
  option: () => ({}),
  placeholder: () => ({}),
  singleValue: () => ({}),
  valueContainer: () => ({}),
};
