import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {useDispatch, useSelector, TypedUseSelectorHook} from 'react-redux';
import {createLogger} from 'redux-logger';

import reducers from './ducks';
import onboardingReducer from './onboarding';
import profileReducer from './profile';
import projectsReducer from './projects';
import tasksReducer from './tasks';
import workersReducer from './workers';

const store = configureStore({
  reducer: {
    ...reducers,
    tasks: tasksReducer,
    workers: workersReducer,
    projects: projectsReducer,
    profile: profileReducer,
    onboarding: onboardingReducer,
  },
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    if (process.env.NODE_ENV === 'production') {
      return middleware;
    }
    return middleware.concat(
      createLogger({
        collapsed: true,
      }),
    );
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type RootDispatch = typeof store.dispatch;
export type RootThunk<R = unknown> = ThunkAction<R, RootState, unknown, Action>;

export function useRootDispatch() {
  return useDispatch<RootDispatch>();
}
export const useRootSelector: TypedUseSelectorHook<RootState> = (...args) => {
  return useSelector(...args);
};
export default store;
