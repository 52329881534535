import {FC} from 'react';

type Props = {
  className: string;
};

const SvgPoint: FC<Props> = ({className}: Props) => {
  return (
    <svg viewBox="0 0 40 40" width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle cx="20" cy="20" fill="#383a36" r="20" />
    </svg>
  );
};

export default SvgPoint;
