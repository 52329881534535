import cn from 'classnames';

import s from './ChatTimelinePlaceholder.module.scss';

type Props = {
  className?: string;
  title: string;
  description: string;
};

const ChatTimelinePlaceholder = ({className, title, description}: Props) => {
  return (
    <div className={cn(s.EmptyTimeline, className)}>
      <h3 className={s.EmptyTimeline__title}>{title}</h3>
      <div className={s.EmptyTimeline__description}>{description}</div>
    </div>
  );
};

export default ChatTimelinePlaceholder;
