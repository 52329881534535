import {useTranslation} from 'react-i18next';

import PanelSection from '../PanelSection/PanelSection';

import Wbs from './Wbs/Wbs';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';
import {TaskDetailsModelDTO} from 'shared/models/task/task';

interface WbsListProps {
  wbs: TaskDetailsModelDTO;
  loading: boolean;
}

const WbsSection = ({wbs, loading}: WbsListProps) => {
  const {t} = useTranslation(['task']);
  return (
    <PanelSection title={t('task:task_form.parts.wbs.title', 'WBS')} width="narrow">
      <SkeletonPreloader when={loading || !wbs}>
        <Wbs id={wbs?.uniqueId} title={wbs?.name} status={wbs?.status} />
      </SkeletonPreloader>
    </PanelSection>
  );
};

export default WbsSection;
