import {useParams} from 'react-router';

import WorkersApi from 'api/workers';

import useProjectWorker from './hooks/useProjectWorker';
import {useProjectOrgs, useProjectWorkerProject, useProjectWorkerTasks} from './queries';
import WorkerDetails from './WorkerDetails';

const inviteMember = WorkersApi.inviteToProject.bind(WorkersApi);
const updateMember = WorkersApi.updateInvitedProjectWorker.bind(WorkersApi);

const ProjectWorker = () => {
  const {projectId} = useParams<{projectId: string}>();
  const orgsOptions = useProjectOrgs(projectId);
  const {projectWorker, refetchProjectWorker} = useProjectWorker();
  const {data: projects} = useProjectWorkerProject(projectId);
  const {data: workerTasks, isFetching: isFetchingTasks} = useProjectWorkerTasks(projectId, projectWorker?.workerId);

  return (
    <WorkerDetails
      worker={projectWorker}
      projects={projects}
      refetchProfile={refetchProjectWorker}
      orgsOptions={orgsOptions}
      workerTasks={workerTasks}
      isFetchingTasks={isFetchingTasks}
      inviteMember={inviteMember}
      updateMember={updateMember}
    />
  );
};

export default ProjectWorker;
