import {cloneElement} from 'react';

import CtrlBtnOption from 'shared/components/CoreNewUI/CtrlBtnOption/CtrlBtnOption';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import Dropdown from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import Icon from 'shared/components/Icon';
import {IconsMap} from 'shared/constants/icons';

import {ActionItem} from '../ActionsBar/types';

import s from './Toolbar.module.scss';

type ToolbarMoreActionsProps = {
  actions: ActionItem[];
  selected: string[];
  canEdit?: boolean;
  viewportPosition?: 'left' | 'right';
};

function ToolbarMoreActions({actions, selected, canEdit, viewportPosition = 'left'}: ToolbarMoreActionsProps) {
  // Determine the final viewport position
  const finalViewportPosition = canEdit ? 'right' : viewportPosition;

  return (
    <Dropdown
      dataCy="btnMore"
      className={s.moreActions}
      toggleElement={
        <CtrlButton color="action" size="xs" icon={IconsMap.more_horizontal} iconOnly={true}>
          More
        </CtrlButton>
      }
      viewportPosition={finalViewportPosition}
    >
      {actions
        .filter((action) => !action.hide)
        .map(({name, text, icon, customButton, onClick, disabled, dataCy}, i) =>
          customButton ? (
            cloneElement(customButton, {key: `${name}_${i}`})
          ) : (
            <CtrlBtnOption
              data-cy={dataCy}
              key={`${name}_${i}`}
              title={text}
              onClick={onClick}
              disabled={disabled?.(selected)}
              icon={<Icon name={icon} colorFill />}
            />
          ),
        )}
    </Dropdown>
  );
}

export default ToolbarMoreActions;
