import {createContext, useContext} from 'react';

import {CompanyModel} from 'shared/models/company';

import {CheckoutDispatch, SubscriptionState, getDefaultSubscriptionState} from './state';

interface SubscriptionContext {
  company: CompanyModel;
  state: SubscriptionState;
  dispatch: CheckoutDispatch;
  onCanceled: () => void;
  childWindow: Window;
  setCheckoutPopup: (windowRef: Window | null) => void;
}
const dummyFn = () => console.warn('not implemented');
const context = createContext<SubscriptionContext>({
  state: getDefaultSubscriptionState(),
  company: null,
  dispatch: dummyFn,
  onCanceled: dummyFn,
  childWindow: null,
  setCheckoutPopup: dummyFn,
});

export const useSubscriptionContext = () => useContext(context);
export const CheckoutContextProvider = context.Provider;
