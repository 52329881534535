import {ProjectModel} from 'shared/models/project';

export type ProjectFilterParams = Pick<
  ProjectModel,
  | 'architectCompanyGroupMappingId'
  | 'engineerCompanyGroupMappingId'
  | 'ownerCompanyGroupMappingId'
  | 'subcontractorCompanyGroupMappingId'
>;

export const getDefaultValues = (): ProjectFilterParams => {
  return {
    ownerCompanyGroupMappingId: '',
    architectCompanyGroupMappingId: '',
    engineerCompanyGroupMappingId: '',
    subcontractorCompanyGroupMappingId: '',
  } as ProjectFilterParams;
};
