import cn from 'classnames';
import {useState} from 'react';

import ContentTabs from '../ContentSection/ContentTabs/ContentTabs';

import s from './MediaBlocks.module.scss';

export enum TabsType {
  activity = 'activity',
  subcontractor = 'subcontractor',
  assignees = 'assignees',
}

export const FixProblemsMediaBlock = () => {
  const [activeTab, setActiveTab] = useState(TabsType.activity);

  return (
    <div data-aos="fade-down" data-aos-duration="300" className={s.mediaBlocks}>
      <ContentTabs updateActiveTab={setActiveTab} activeTab={activeTab} />
      {activeTab === TabsType.activity && (
        <picture>
          <source srcSet="/images/screenshots/screenshot-10@1x.gif 1x, /images/screenshots/screenshot-10@1x.gif 2x" />
          <img
            className={cn(s.mediaBlocks__image, s.mediaBlocks__image_shadow)}
            src="/images/screenshots/screenshot-10@1x.gif"
            alt=""
          />
        </picture>
      )}
      {activeTab === TabsType.subcontractor && (
        <picture>
          <source srcSet="/images/screenshots/screenshot-12@1x.gif 1x, /images/screenshots/screenshot-12@1x.gif 2x" />
          <img
            className={cn(s.mediaBlocks__image, s.mediaBlocks__image_shadow)}
            src="/images/screenshots/screenshot-12@1x.gif"
            alt=""
          />
        </picture>
      )}
      {activeTab === TabsType.assignees && (
        <picture>
          <source srcSet="/images/screenshots/screenshot-13@1x.gif 1x, /images/screenshots/screenshot-13@1x.gif 2x" />
          <img
            className={cn(s.mediaBlocks__image, s.mediaBlocks__image_shadow)}
            src="/images/screenshots/screenshot-13@1x.gif"
            alt=""
          />
        </picture>
      )}
    </div>
  );
};

export const realTimeMediaBlock = (
  <div data-aos="fade-down" data-aos-duration="300" className={cn(s.mediaBlocks, s.mediaBlocks__realTime)}>
    <picture>
      {/* <source type="image/webp" srcSet="/images/screenshots/screenshot-6@1x.webp, /images/screenshots/screenshot-6@2x.webp 2x" /> */}
      <source
        type="image/webp"
        srcSet="
          /images/screenshots/screenshot-6@220w.webp 220w,
          /images/screenshots/screenshot-6@280w.webp 280w,
          /images/screenshots/screenshot-6@400w.webp 400w,
          /images/screenshots/screenshot-6@440w.webp 440w,
          /images/screenshots/screenshot-6@500w.webp 500w,
          /images/screenshots/screenshot-6@560w.webp 560w,
          /images/screenshots/screenshot-6@750w.webp 750w,
          /images/screenshots/screenshot-6@800w.webp 800w,
          /images/screenshots/screenshot-6@830w.webp 830w,
        "
        sizes="
          (min-width: 1300px) 750px,
          (min-width: 1024px) 500px,
          (min-width: 576px) 400px,
          (min-width: 420px) 280px,
          220px
        "
      />
      <source
        type="image/png"
        srcSet="
          /images/screenshots/screenshot-6@220w.png 220w,
          /images/screenshots/screenshot-6@280w.png 280w,
          /images/screenshots/screenshot-6@400w.png 400w,
          /images/screenshots/screenshot-6@440w.png 440w,
          /images/screenshots/screenshot-6@500w.png 500w,
          /images/screenshots/screenshot-6@560w.png 560w,
          /images/screenshots/screenshot-6@750w.png 750w,
          /images/screenshots/screenshot-6@800w.png 800w,
          /images/screenshots/screenshot-6@830w.png 830w,
        "
        sizes="
          (min-width: 1300px) 750px,
          (min-width: 1024px) 500px,
          (min-width: 576px) 400px,
          (min-width: 420px) 280px,
          220px
        "
      />
      <img className={s.mediaBlocks__image} src="/images/screenshots/screenshot-6@830w.png" alt="" />
    </picture>

    <picture>
      <source
        type="image/webp"
        srcSet="
          /images/screenshots/screenshot-7@120w.webp 120w,
          /images/screenshots/screenshot-7@170w.webp 170w,
          /images/screenshots/screenshot-7@240w.webp 240w,
          /images/screenshots/screenshot-7@340w.webp 340w,
        "
        sizes="
          (min-width: 1024px) 170px,
          (min-width: 576px) 120px,
          90px
        "
      />
      <source
        type="image/png"
        srcSet="
          /images/screenshots/screenshot-7@120w.png 120w,
          /images/screenshots/screenshot-7@170w.png 170w,
          /images/screenshots/screenshot-7@240w.png 240w,
          /images/screenshots/screenshot-7@340w.png 340w,
        "
        sizes="
          (min-width: 1024px) 170px,
          (min-width: 576px) 120px,
          90px
        "
      />
      <img
        data-aos="fade-up-right"
        data-aos-duration="300"
        data-aos-delay="300"
        className={s.mediaBlocks__image_qrcode}
        src="/images/screenshots/screenshot-7@340w.png"
        alt=""
      />
    </picture>

    <picture
      data-aos="fade-right"
      data-aos-duration="300"
      data-aos-delay="1200"
      className={s.mediaBlocks__image_notify}
    >
      <source
        type="image/webp"
        srcSet="/images/screenshots/screenshot-8@1x.webp, /images/screenshots/screenshot-8@2x.webp 2x"
      />
      <source
        type="image/png"
        srcSet="/images/screenshots/screenshot-8@1x.png, /images/screenshots/screenshot-8@2x.png 2x"
      />
      <img className={s.mediaBlocks__imageItem} src="/images/screenshots/screenshot-8@1x.png" alt="" />
    </picture>
    <picture
      data-aos="fade-right"
      data-aos-duration="300"
      data-aos-delay="2400"
      className={cn(s.mediaBlocks__image_notify, s.mediaBlocks__image_notifyBottom)}
    >
      <source
        type="image/webp"
        srcSet="/images/screenshots/screenshot-9@1x.webp, /images/screenshots/screenshot-9@2x.webp 2x"
      />
      <source
        type="image/png"
        srcSet="/images/screenshots/screenshot-9@1x.png, /images/screenshots/screenshot-9@2x.png 2x"
      />
      <img className={s.mediaBlocks__imageItem} src="/images/screenshots/screenshot-9@1x.png" alt="" />
    </picture>
  </div>
);

export const streamlineWorkMediaBlock = (
  <div data-aos="fade-down" data-aos-duration="300" className={cn(s.mediaBlocks, s.mediaBlocks__streamlineWork)}>
    <picture className={s.mediaBlocks__streamlineWork__phone}>
      <source
        type="image/webp"
        srcSet="
          /images/screenshots/screenshot-7@120w.webp 120w,
          /images/screenshots/screenshot-7@170w.webp 170w,
          /images/screenshots/screenshot-7@240w.webp 240w,
          /images/screenshots/screenshot-7@340w.webp 340w,
          /images/screenshots/screenshot-7@480w.webp 480w,
        "
        sizes="
          (min-width: 1024px) 240px,
          (min-width: 576px) 170px,
          120px
        "
      />
      <source
        type="image/png"
        srcSet="
          /images/screenshots/screenshot-7@120w.png 120w,
          /images/screenshots/screenshot-7@170w.png 170w,
          /images/screenshots/screenshot-7@240w.png 240w,
          /images/screenshots/screenshot-7@340w.png 340w,
          /images/screenshots/screenshot-7@480w.png 480w,
        "
        sizes="
          (min-width: 1024px) 240px,
          (min-width: 576px) 170px,
          120px
        "
      />
      <img src="/images/screenshots/screenshot-7@480w.png" alt="" />
    </picture>

    <picture className={s.mediaBlocks__streamlineWork__checklist}>
      <source
        type="image/webp"
        srcSet="
          /images/other/checklist-1@120w.webp 120w,
          /images/other/checklist-1@160w.webp 160w,
          /images/other/checklist-1@240w.webp 240w,
          /images/other/checklist-1@310w.webp 310w,
        "
        sizes="
          (min-width: 1024px) 160px,
          120px
        "
      />
      <source
        type="image/png"
        srcSet="
          /images/other/checklist-1@120w.png 120w,
          /images/other/checklist-1@160w.png 160w,
          /images/other/checklist-1@240w.png 240w,
          /images/other/checklist-1@310w.png 310w,
        "
        sizes="
          (min-width: 1024px) 160px,
          120px
        "
      />
      <img src="/images/other/checklist-1@310w.png" alt="" />
    </picture>

    <picture className={s.mediaBlocks__streamlineWork__document}>
      <source
        type="image/webp"
        srcSet="
          /images/other/document-1@64w.webp 64w,
          /images/other/document-1@100w.webp 100w,
          /images/other/document-1@128w.webp 128w,
          /images/other/document-1@200w.webp 200w,
          /images/other/document-1@256w.webp 256w,
        "
        sizes="
          (min-width: 1024px) 128px,
          (min-width: 576px) 100px,
          64px
        "
      />
      <source
        type="image/png"
        srcSet="
          /images/other/document-1@64w.png 64w,
          /images/other/document-1@100w.png 100w,
          /images/other/document-1@128w.png 128w,
          /images/other/document-1@200w.png 200w,
          /images/other/document-1@256w.png 256w,
        "
        sizes="
          (min-width: 1024px) 128px,
          (min-width: 576px) 100px,
          64px
        "
      />
      <img src="/images/other/document-1@256w.png" alt="" />
    </picture>

    <picture className={s.mediaBlocks__streamlineWork__worker1}>
      <source
        type="image/webp"
        srcSet="
          /images/other/worker-1@80w.webp 80w,
          /images/other/worker-1@110w.webp 110w,
          /images/other/worker-1@160w.webp 160w,
          /images/other/worker-1@220w.webp 220w,
          /images/other/worker-1@312w.webp 312w,
        "
        sizes="
          (min-width: 1024px) 160px,
          (min-width: 576px) 110px,
          80px
        "
      />
      <source
        type="image/png"
        srcSet="
          /images/other/worker-1@80w.png 80w,
          /images/other/worker-1@110w.png 110w,
          /images/other/worker-1@160w.png 160w,
          /images/other/worker-1@220w.png 220w,
          /images/other/worker-1@312w.png 312w,
        "
        sizes="
          (min-width: 1024px) 160px,
          (min-width: 576px) 110px,
          80px
        "
      />
      <img src="/images/other/worker-1@312w.png" alt="" />
    </picture>

    <picture className={s.mediaBlocks__streamlineWork__worker2}>
      <source
        type="image/webp"
        srcSet="
          /images/other/worker-2@80w.webp 80w,
          /images/other/worker-2@100w.webp 100w,
          /images/other/worker-2@160w.webp 160w,
          /images/other/worker-2@200w.webp 200w,
          /images/other/worker-2@320w.webp 320w,
        "
        sizes="
          (min-width: 1024px) 160px,
          (min-width: 576px) 100px,
          80px
        "
      />
      <source
        type="image/png"
        srcSet="
          /images/other/worker-2@80w.png 80w,
          /images/other/worker-2@100w.png 100w,
          /images/other/worker-2@160w.png 160w,
          /images/other/worker-2@200w.png 200w,
          /images/other/worker-2@320w.png 320w,
        "
        sizes="
          (min-width: 1024px) 160px,
          (min-width: 576px) 100px,
          80px
        "
      />
      <img src="/images/other/worker-2@320w.png" alt="" />
    </picture>

    <picture className={s.mediaBlocks__streamlineWork__worker3}>
      <source
        type="image/webp"
        srcSet="
          /images/other/worker-3@60w.webp 60w,
          /images/other/worker-3@90w.webp 90w,
          /images/other/worker-3@112w.webp 112w,
          /images/other/worker-3@120w.webp 120w,
          /images/other/worker-3@180w.webp 180w,
          /images/other/worker-3@224w.webp 224w,
        "
        sizes="
          (min-width: 1024px) 112px,
          (min-width: 576px) 90px,
          60px
        "
      />
      <source
        type="image/png"
        srcSet="
          /images/other/worker-3@60w.png 60w,
          /images/other/worker-3@90w.png 90w,
          /images/other/worker-3@112w.png 112w,
          /images/other/worker-3@120w.png 120w,
          /images/other/worker-3@180w.png 180w,
          /images/other/worker-3@224w.png 224w,
        "
        sizes="
          (min-width: 1024px) 112px,
          (min-width: 576px) 90px,
          60px
        "
      />
      <img src="/images/other/worker-3@224w.png" alt="" />
    </picture>
  </div>
);
