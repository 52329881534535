import {FunctionComponent, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useLocation} from 'react-router';

import Button from 'shared/components/Button';
import Confirmation from 'shared/components/Confirmation';
import HelpModal from 'shared/components/HelpModal';
import Icon from 'shared/components/Icon';
import MetaTags from 'shared/components/MetaTags';
import Permission from 'shared/components/Permission';
import {META_KEYWORDS} from 'shared/constants/common';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {sortAlphabetically} from 'shared/helpers/common';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {usePrevious} from 'shared/hooks/core/usePrevious';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useEffectWithCompanyId} from 'shared/hooks/useEffectWithCompany';
import {useProfile} from 'shared/hooks/useProfile';
import {ScreenGreed} from 'shared/layout/admin';
import {ProjectModel} from 'shared/models/project';
import {useRootDispatch, useRootSelector} from 'store';
import {selectAllProjects} from 'store/projects';
import {getAllProjects} from 'store/projects/actions';

import {profileActions} from '../../store/profile';

import {ProjectsFilterAndSearch, ProjectsList} from './components';
import {getDefaultValues, ProjectFilterParams} from './components/ProjectsFilterAndSearch/helpers';

const Projects: FunctionComponent = () => {
  const history = useHistory();
  const routes = useLocalizedRoutes();
  const location = useLocation<{autoCreateProject: boolean}>();
  const dispatch = useRootDispatch();
  const company = useCompany();
  const profile = useProfile();
  const prevCompany = usePrevious(company);
  const {t} = useTranslation(['common', 'projects']);

  const projects = useRootSelector(selectAllProjects);
  const showAccessDeniedPopup = useRootSelector((state) => state.profile.showAccessDeniedPopup);

  const [searchInput, setSearchInput] = useState('');
  const [filterParams, setFilterParams] = useState<ProjectFilterParams>(getDefaultValues());
  const filledFilterParams = Object.entries(filterParams ?? {}).filter(([, value]) => value.length > 0);

  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events.projects;

  const hideAccessDeniedPopup = () => {
    dispatch(profileActions.showAccessDeniedPopup(false));
  };

  useEffectWithCompanyId(async (id) => {
    const res = await dispatch(getAllProjects(id));
    if (getAllProjects.fulfilled.match(res) && !res.payload.length && location.state?.autoCreateProject) {
      history.push(generatePath(routes.project, {id: 'new'}));
    }
  });

  useEffectOnce(() => {
    mixpanel.track(mixpanelEvents.screen);
  });

  const filterProject = (project: ProjectModel): boolean => {
    if (!filledFilterParams.length) return true;
    return filledFilterParams.every(([field, value]) => project?.[field] === value);
  };

  const filteredAndSortedProjects = useMemo(
    () =>
      projects
        ? sortAlphabetically(
            searchInput || filterParams
              ? projects
                  .filter(filterProject)
                  .filter(({name}) => name.toLowerCase().includes(searchInput.toLowerCase()))
              : projects,
            'name',
          )
        : [],
    [projects, searchInput, filterParams],
  );

  const openProject = useCallback((id: string) => {
    history.push({
      pathname: generatePath(routes.project, {id}),
      state: {from: location.search},
    });
  }, []);

  const openNewProject = useCallback(() => {
    if (searchInput) setSearchInput('');
    history.push(generatePath(routes.project, {id: 'new'}), {
      from: location.search,
    });
  }, [searchInput]);

  useEffect(() => {
    if (prevCompany && company !== prevCompany) setSearchInput('');
  }, [company, prevCompany, setSearchInput]);

  return (
    <>
      {profile && company && (
        <MetaTags
          title={`${profile.fullName} ${t('common:meta.title', 'C4 at')} ${company.companyName}`}
          description={`${t('common:meta.description', 'Crews by Core PRO for')} ${company.companyName}`}
          keywords={META_KEYWORDS}
        />
      )}
      <div className="panel screen__panel">
        <h1 className="panel__title">{t('common:title', 'Projects')}</h1>
        <div className="panel__actions">
          <Permission roles={['company_admin']}>
            <div>
              <Button
                data-cy="btnNewProject"
                className="panel__button"
                icon={<Icon colorFill name="add-circle-outlined" size={24} />}
                onClick={() => mixpanel.trackWithAction(openNewProject, mixpanelEvents.newProjectBtn)}
              >
                {t('common:buttons.new', 'New Project')}
              </Button>
            </div>
          </Permission>
        </div>
      </div>
      <ScreenGreed
        content={
          <ProjectsList
            projects={filteredAndSortedProjects}
            openProject={openProject}
            openNewProject={openNewProject}
          />
        }
        aside={
          <ProjectsFilterAndSearch
            isActiveFilter={!!filledFilterParams.length}
            searchInput={searchInput}
            updateSearchInput={setSearchInput}
            updateFilterParams={setFilterParams}
          />
        }
      />
      <Permission roles={['company_admin']}>
        <HelpModal
          title={t('common:help.title', 'Projects')}
          text={t(
            'common:help.text',
            'Projects are how different activities are categorized. Each Project you create can have a list of Activities and Subtasks that you assign to Workers & Subs.',
          )}
          buttonText={t('common:help.buttons.create', 'Create my project')}
          buttonAction={() => mixpanel.trackWithAction(openNewProject, mixpanelEvents.buttons.createMyProject)}
          iconName="add_circle_outlined"
          onClick={() => mixpanel.track(mixpanelEvents.buttons.questionMark)}
        />
      </Permission>
      <Confirmation
        visible={showAccessDeniedPopup}
        title={t('common:access_denied_popup.title', 'You no longer have access')}
        description={<div dangerouslySetInnerHTML={{__html: t('common:access_denied_popup.body')}}></div>}
        onAccept={hideAccessDeniedPopup}
        acceptButton={t('common:access_denied_popup.button')}
        onClose={hideAccessDeniedPopup}
      />
    </>
  );
};

export default Projects;
