import axios from 'axios';

import {
  DocumentTransferStatusResponse,
  TaskImportPrepResponse,
  TaskImportResponse,
  AsyncCommitResult,
} from 'shared/components/TasksImport/utils/types';
import {ExternalServices} from 'shared/constants/common';
import {CompletePercentageFunc, pollResults} from 'shared/helpers/api';
import {compressFile} from 'shared/helpers/common';
import {DocumentTransferStartResponse, S3UploadResponse, TaskImportPayload} from 'shared/models/import';

import ApiAxios from './axios';

type StartImportRequestBody =
  | {
      filename: string;
      type: 'file_upload';
    }
  | {type: 'procore'};

type StartDocumentTransferRequestBody = {
  service: 'procore';
  serviceId: string;
  fileId: string;
  fileName: string;
};

class ImportService {
  private static getBasePath(projectId: string) {
    return `/projects/${projectId}/bulktaskimport`;
  }

  startDocumentTransfer(projectId: string, serviceId: string, fileId: string, fileName: string) {
    const requestBody: StartDocumentTransferRequestBody = {
      service: ExternalServices.Procore,
      serviceId,
      fileId,
      fileName,
    };

    return ApiAxios.post<DocumentTransferStartResponse>(`/projects/${projectId}/externalfiletransfer`, requestBody);
  }

  transferStatus(projectId: string, importId: string) {
    return ApiAxios.get<DocumentTransferStatusResponse>(
      `projects/${projectId}/externalfiletransfer/${importId}/start/status`,
    );
  }

  pollTransferResponse(projectId: string, importId: string, maxWaitSec: number) {
    return pollResults({
      importId,
      maxWaitSec,
      fetcher: () => this.transferStatus(projectId, importId),
    });
  }

  startImport(projectId: string, filename?: string) {
    const requestBody: StartImportRequestBody = filename ? {filename, type: 'file_upload'} : {type: 'procore'};
    return ApiAxios.post<S3UploadResponse>(ImportService.getBasePath(projectId), requestBody);
  }

  async uploadFileToS3(file: File, url: string) {
    const compressed = await compressFile(file);
    return axios.put(url, compressed, {headers: {'Content-Encoding': 'gzip'}});
  }

  prepRun(projectId: string, importId: string) {
    return ApiAxios.post(`${ImportService.getBasePath(projectId)}/${importId}/prep`);
  }

  prepStatus(projectId: string, importId: string) {
    return ApiAxios.get<TaskImportPrepResponse>(`${ImportService.getBasePath(projectId)}/${importId}/prep/status`);
  }

  async pollPrepResults(projectId: string, importId: string, maxWaitSec: number) {
    return pollResults({
      importId,
      maxWaitSec,
      fetcher: () => this.prepStatus(projectId, importId),
    });
  }

  dryRun(projectId: string, importId: string, payload: Partial<TaskImportPayload>) {
    return ApiAxios.post(`${ImportService.getBasePath(projectId)}/${importId}/dryrun`, payload);
  }

  dryRunStatus(projectId: string, importId: string) {
    return ApiAxios.get<TaskImportResponse>(`${ImportService.getBasePath(projectId)}/${importId}/dryrun/status`);
  }

  async pollDryDunResults(
    projectId: string,
    importId: string,
    maxWaitSec: number,
    completePercentage: CompletePercentageFunc,
  ) {
    return pollResults({
      importId,
      maxWaitSec,
      completePercentage,
      fetcher: () => this.dryRunStatus(projectId, importId),
    });
  }

  commit(projectId: string, importId: string, payload: Partial<TaskImportPayload>) {
    return ApiAxios.post(`${ImportService.getBasePath(projectId)}/${importId}/commit`, payload);
  }

  commitStatus(projectId: string, importId: string) {
    return ApiAxios.get<TaskImportResponse>(`${ImportService.getBasePath(projectId)}/${importId}/commit/status`);
  }

  async pollCommitResults(
    projectId: string,
    importId: string,
    maxWaitSec: number,
    completePercentage: CompletePercentageFunc,
  ) {
    return pollResults({
      importId,
      maxWaitSec,
      completePercentage,
      fetcher: () => this.commitStatus(projectId, importId),
    });
  }

  extract(projectId: string, importId: string, sourceId: string) {
    return ApiAxios.post(`${ImportService.getBasePath(projectId)}/${importId}/extract`, {
      service: 'procore',
      sourceIdentifier: sourceId,
    });
  }

  extractStatus(projectId: string, importId: string) {
    return ApiAxios.get<AsyncCommitResult<Record<string, unknown>>>(
      `${ImportService.getBasePath(projectId)}/${importId}/extract/status`,
    );
  }

  pollExtractResult(projectId: string, importId: string) {
    return pollResults({
      importId,
      maxWaitSec: 100,
      fetcher: () => this.extractStatus(projectId, importId),
    });
  }
}

export default new ImportService();
