import {FC, useMemo} from 'react';
import * as React from 'react';

import {useRootSelector} from 'store';
import {getWorkerRolesCurrentCompany} from 'store/profile';

import {CompanyWorkerRole} from '../../models/worker';

type Props = {
  children: React.ReactElement;
  roles: CompanyWorkerRole[];
};

const Permission: FC<Props> = ({children, roles}: Props) => {
  const workerRoles = useRootSelector(getWorkerRolesCurrentCompany);
  const isAllowed = useMemo(() => {
    if (!workerRoles) return null;
    return roles.some((role) => workerRoles.includes(role));
  }, [workerRoles, roles]);

  if (isAllowed) return children;
  return null;
};

export default Permission;
