import ApiAxios from './axios';

class TimezoneService {
  private getBasePath() {
    return '/anon/timezone_lookup';
  }

  timezoneLookup(longitude: number, latitude: number) {
    return ApiAxios.get<{timezone: string}>(this.getBasePath(), {
      params: {
        latitude,
        longitude,
      },
    }).then((res) => res.data);
  }
}
export default new TimezoneService();
