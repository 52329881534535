import dayjs from 'dayjs';

import {safeParseDate} from 'shared/helpers/dates';

type TaskDates = {
  startDate: string | Date;
  endDate: string | Date;
};

export const calculateDate = (dates: TaskDates) => {
  const today = dayjs();
  const {startDate, endDate} = dates;
  const schedStart = safeParseDate(startDate);
  const schedEnd = safeParseDate(startDate);
  if (today.isBefore(schedStart)) {
    return {
      start: schedStart,
      end: schedStart,
    };
  }
  if (dayjs().isAfter(schedStart) && dayjs().isBefore(endDate)) {
    return {
      start: today.toDate(),
      end: today.toDate(),
    };
  }
  if (dayjs().isAfter(endDate)) {
    return {
      start: schedEnd,
      end: schedEnd,
    };
  }
};
