export default {
  auth: {
    phoneNotExist: "User with that phone number doesn't exist!",
    emailNotExist: "User with that email address doesn't exist!",
  },
  registration: {
    userAlreadyRegistered: 'User with that phone number already exist',
    notCompletedRegistration: 'User does not complete registration process',
  },
} as const;
