import {FC} from 'react';

import Button from 'shared/components/Button';

import {ActionButtonConfig} from '../useConfirm/state';

interface Props extends ActionButtonConfig {
  onClick: () => void;
  isDisabled?: boolean;
  dataCy: string;
  className?: string;
}

const ActionButton: FC<Props> = ({title, onClick, isDisabled, className, dataCy, type = 'default'}: Props) => {
  const getStyles = () => {
    // TODO: need to move CSS module
    if (type === 'danger') {
      return {background: '#FA380D', borderColor: '#FA380D'};
    }
    if (type === 'success') {
      return {
        borderColor: '#01964E',
        backgroundColor: '#01964E',
        color: '#fff',
      };
    }
  };
  return (
    <Button className={className} onClick={onClick} style={getStyles()} disabled={isDisabled} data-cy={dataCy}>
      {title}
    </Button>
  );
};
export default ActionButton;
