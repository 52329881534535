import {useCallback, useMemo, useState} from 'react';

export function usePasswordVisibility() {
  const [visible, setVisible] = useState(false);
  const toggleVisibility = useCallback(() => {
    setVisible((visible) => !visible);
  }, [setVisible]);
  const inputType = useMemo(() => (visible ? 'text' : 'password'), [visible]);

  return {
    visible,
    toggleVisibility,
    inputType,
  };
}
