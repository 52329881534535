import * as Sentry from '@sentry/browser';
import cn from 'classnames';
import {onAuthStateChanged, User} from 'firebase/auth';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from 'react-query';
import {generatePath, useHistory, useParams} from 'react-router';

import ProjectsApi from 'api/projects';
import WorkerApi from 'api/worker';
import FirebaseService from 'services/Firebase/Firebase';
import Logo from 'shared/components/Logo';
import MetaTags from 'shared/components/MetaTags';
import {META_KEYWORDS} from 'shared/constants/common';
import env from 'shared/constants/env';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useParsedQuery} from 'shared/hooks/useParsedQuery';
import {useProfile} from 'shared/hooks/useProfile';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useRootDispatch} from 'store';
import {fullLogout} from 'store/ducks/auth/actions';
import {profileActions} from 'store/profile';
import {loadWorker, loadWorkerCompanies, loadWorkerCompanyWorkers} from 'store/profile/actions';
import {getProject} from 'store/projects/actions';

import cl from './CollabLanding.module.scss';

const CollabLanding = () => {
  const {workerId, collabId} = useParams<{workerId: string; collabId: string}>();
  const {projectid: projectId, token} = useParsedQuery<{projectid: string; token: string}>({
    defaultParams: {projectid: null, token: null},
    schema: {
      projectid: 'string',
      token: 'string',
    },
  });
  const history = useHistory();
  const worker = useProfile();
  const dispatch = useRootDispatch();
  const loginStarted = useRef<boolean>(false);
  const [fbSigninComplete, setFbSigninComplete] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const company = useCompany();
  const project = useProjectSelector(projectId);
  const [currentUser, setCurrentUser] = useState<User>(undefined); // currentUser is not-ready/null/value
  const [isLoading, setIsLoading] = useState(true);
  const {t} = useTranslation('shared_task');
  const routes = useLocalizedRoutes();

  const {mixpanel} = useAnalyticsService({extraMeta: {collabProjectId: projectId, collabUserId: workerId}});
  const mixpanelEvents = mixpanel.events.projectCollabLanding;

  const loadCollabInfo = () => {
    return ProjectsApi.getCollaborationInfo(projectId, collabId);
  };

  const {data: collabInfo, isLoading: isLoadingCollabInfo} = useQuery(
    ['collaborationRequest', collabId],
    loadCollabInfo,
    {enabled: !!collabId && !!projectId && !!worker},
  );

  useLayoutEffect(() => {
    return onAuthStateChanged(FirebaseService.auth, (user) => {
      setCurrentUser(user);
    });
  }, []);

  /**
   * 1. Already signed in
   *    a. Stored creds worker id === link worker id (tested)
   *       - use stored creds, proceed to /projects/pid/tasks
   *    b. Stored creds worker id !== link worker id (logout, send to login page, tested)
   *       i. Stored creds can access link project
   *           - use stored creds, proceed to /projects/pid/tasks
   *       ii. Stored creds cannot access link project
   *           - log out current account, log in with collab creds, proceed to /projects/pid/tasks
   * 2. Not already signed in
   *    a. link worker has status 'invited' (tested)
   *       - log in with collab creds, proceed to /projects/pid/tasks
   *    b. link worker has status 'open' (tested)
   *       - redirect to login screen with email/phone pre-filled in
   *    c. link worker has status 'closed' (not so tested, treated like 1b.)
   *       - Display error message
   */

  // get credentials sorted out.  By the end of this code,
  // should either be logged in and can query api or redirected to login page
  useLayoutEffect(() => {
    if (!workerId || !projectId || !token || !collabId) {
      setErrorMessage(t('collaboration.error.missing'));
      setIsLoading(false);
      return;
    }

    // Login only called here, might as well be inside useLayoutEffect
    const login = async () => {
      const redirectToLogin = (loginResp) => {
        const [prefillField] = Object.keys(loginResp.authPrefill);
        history.replace({
          pathname: routes.logIn,
          search: `cbId=${collabId}&${prefillField}=${encodeURIComponent(
            loginResp.authPrefill[prefillField],
          )}&pid=${projectId}&wrkrId=${workerId}`,
        });
      };

      try {
        const loginResp = await WorkerApi.loginWithToken(workerId, token);
        if (currentUser) {
          // Branch 1. Already signed in
          if (loginResp.errors?.[0] === 'existing_worker') {
            // Branch 1a. Signed in with matching worker id as link.  Leave
            // currently signed in firebase user alone
            if (FirebaseService.getWorkerIdFromLS() === workerId) {
              setFbSigninComplete(true);
            } else {
              // Log out current user, turns it into Branch 2b. Send them to login screen.
              mixpanel.track(mixpanelEvents.notSignedIn);
              await FirebaseService.signOut();
              redirectToLogin(loginResp);
            }
            return;
          }

          // Case 1b.  Stored creds worker id !== worker id.  Case 2b already handled,
          // Short term, just log out and proceed to use collab token to get access.
          // Longer term add different support for case 1bi vs. 1bii, maybe add a warning or
          // ask if it's ok to log out previous account
          await dispatch(fullLogout());
        } else if (loginResp.errors?.[0] === 'existing_worker') {
          // case 2b. link worker has status 'open'
          mixpanel.track(mixpanelEvents.notSignedIn);
          redirectToLogin(loginResp);
          return;
        }

        // Get signed in with custom token
        await FirebaseService.signInWithCustomToken(loginResp.firebaseToken);
        await FirebaseService.setWorkerIdToLS(workerId);
        setFbSigninComplete(true);
      } catch (err) {
        Sentry.captureException(err);
        setErrorMessage(t('collaboration.error.login'));
        setIsLoading(false);
      }
    };

    // Sign in with firebase
    if (workerId && token && currentUser !== undefined && !loginStarted.current) {
      loginStarted.current = true;
      login();
    }
  }, [collabId, currentUser, projectId, token, workerId]);

  // With fbSigninComplete, can now fetch required data for things to work properly
  useEffect(() => {
    // Load all the important data for being able to render the tasks screen
    if (fbSigninComplete && workerId && projectId) {
      Promise.all([
        dispatch(loadWorker(workerId)),
        dispatch(loadWorkerCompanies(workerId)),
        dispatch(loadWorkerCompanyWorkers(workerId)),
        dispatch(getProject(projectId)),
      ]).catch(() => {
        setErrorMessage(t('collaboration.error.fetching'));
        setIsLoading(false);
      });
    }
  }, [dispatch, fbSigninComplete, projectId, t, workerId]);

  // With project and all worker data loaded, can pick correct company
  useEffect(() => {
    if (project && worker) {
      dispatch(profileActions.setActiveCompany(project.companyId));
    }
  }, [dispatch, project, worker]);

  // Data loaded, ready to make final decision about error vs redirect to project/{pid}/tasks
  useEffect(() => {
    // Now fully signed in, can decide what to do
    if (!company || !project || !collabInfo || !worker) {
      return;
    }

    if (worker.status === 'closed') {
      setErrorMessage(t('collaboration.error.closed', 'This account has been closed.'));
      setIsLoading(false);
      return;
    }

    // Benefit of tracking the event here is that profile & company are loaded, so default
    // metadata like companyName, worker full name included
    if (worker.status === 'open') {
      mixpanel.track(mixpanelEvents.openSignedIn);
    } else if (worker.status === 'invited') {
      mixpanel.track(mixpanelEvents.invitedWorker);
    }

    // redirect to tasks view
    const viewFilterParams = collabInfo?.viewFilters || '';
    history.replace({
      pathname: generatePath(routes.tasks, {projectId: project.id}),
      search: viewFilterParams,
    });
  }, [company, project, worker, collabInfo]);

  return (
    <>
      <MetaTags
        title={project ? `${project.name} has been shared with you.` : t('label.default', 'Crews by Core Pro')}
        description={t('description', 'Click this link to see the details and update progress for the team.')}
        keywords={META_KEYWORDS}
        ogUrl={window.location.origin + window.location.pathname}
      >
        <meta name="apple-itunes-app" content={`app-id=${env.appleStoreAppID}`} />
      </MetaTags>
      <div className="screen-app">
        <header className="app-header">
          <Logo className="app-header__logo" />
          <div className="app-actions app-header__actions"></div>
        </header>
        {(isLoading || isLoadingCollabInfo) && <div className={cn(cl.logging_in, cl.loading)}>Logging in</div>}
        {!isLoading && errorMessage && <div className={cn(cl.error)}>{errorMessage}</div>}
      </div>
    </>
  );
};

export default CollabLanding;
