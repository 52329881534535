import {FC, HTMLAttributes} from 'react';

type ExternalLinkProps = {
  href: string;
  className?: string;
  target?: string;
  rel?: string;
  text: string;
} & HTMLAttributes<HTMLAnchorElement>;

const ExternalLink: FC<ExternalLinkProps> = (props: ExternalLinkProps) => {
  const {text} = props;
  return <a {...props}>{text}</a>;
};
export default ExternalLink;
