import cn from 'classnames';
import {ReactNode} from 'react';

import s from './GalleryEmpty.module.scss';

type GalleryAddProps = {
  className?: string;
  children?: ReactNode;
  onCLick?: () => void;
};

const GalleryEmpty = ({className, children, onCLick}: GalleryAddProps) => {
  return (
    <div className={cn(s.galleryEmpty, children && s.galleryEmpty_placeholder, className)} onClick={onCLick}>
      {children}
    </div>
  );
};

export default GalleryEmpty;
