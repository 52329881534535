import cn from 'classnames';
import {Field, useFormikContext} from 'formik';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams, useRouteMatch} from 'react-router';

import CtrlColorPreview from 'modules/Subcontractors/components/CtrlColorPreview';
import {useSimilarSubcontractors} from 'modules/Subcontractors/components/SubcontractorsPanel/hooks/useSimilarSubcontractors';
import {detectNameCollisions} from 'modules/Subcontractors/components/SubcontractorsPanel/utils/detectNameCollisions';
import {useGetSubcontractor} from 'modules/Subcontractors/hooks/useGetSubcontractor';
import {SubcontractorFormValues} from 'modules/Subcontractors/utils/utils';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import SkeletonPreloader from 'shared/components/SkeletonPreloader';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useResponsibleOrgColors} from 'shared/hooks/useResponsibleOrgColors';

import SubcontractorSimilar from './components/SubcontractorSimilar';
import s from './SubcontractorForm.module.scss';

type SubcontractorCreatingProps = {
  className?: string;
};

const SubcontractorForm = ({className}: SubcontractorCreatingProps) => {
  const routes = useLocalizedRoutes();
  const {projectId} = useParams<{projectId: string}>();
  const match = useRouteMatch<{id: string}>(routes.subcontractor);
  const {
    values: {lookaheadColor, abbrev, name},
    initialValues: {name: initialName},
    errors,
  } = useFormikContext<SubcontractorFormValues>();
  const {lookaheadColors} = useResponsibleOrgColors();
  const {projectSub} = useGetSubcontractor(projectId, match?.params?.id);
  const {t} = useTranslation('panel');

  const {subcontractorNames} = useSimilarSubcontractors(name, projectId);
  const subcontractorNameSuggestions = useMemo(
    () =>
      subcontractorNames
        .filter((name) => name !== initialName)
        .sort((a, b) => a.localeCompare(b))
        .slice(0, 5),
    [subcontractorNames, initialName],
  );
  const hasNameCollision = detectNameCollisions(subcontractorNameSuggestions, name);
  const isNewSubcontractor = match?.params?.id === 'new';

  return (
    <div className={cn(s.subcontractorCreating, className)}>
      <div className={s.subcontractorCreating__title}>
        {isNewSubcontractor
          ? t('tab.subcontractors.title.creating', 'Creating')
          : t('tab.subcontractors.title.editing', 'Editing')}{' '}
        {t('tab.subcontractors.title.ending', 'company')}
      </div>
      <div className={s.subcontractorCreating__previewBox}>
        <SkeletonPreloader when={!projectSub && !isNewSubcontractor} height={68}>
          <CtrlColorPreview color={lookaheadColors?.[lookaheadColor]?.fill} abbr={abbrev} />
        </SkeletonPreloader>
        <div className={s.subcontractorCreating__abbr}>
          <SkeletonPreloader when={!projectSub && !isNewSubcontractor} height={40}>
            <FormControl
              label={t('tab.subcontractors.form.abbrev.label', 'Abbreviation')}
              name="abbrev"
              hint={t('tab.subcontractors.form.abbrev.error', 'Must be at most 3 characters')}
              isError={!!errors['abbrev']}
            >
              <Field
                className="ctrl-textfield"
                as="input"
                placeholder={t('tab.subcontractors.form.abbrev.placeholder', 'Abbr')}
              />
            </FormControl>
          </SkeletonPreloader>
        </div>
      </div>
      <div className={s.subcontractorCreating__name}>
        <SkeletonPreloader when={!projectSub && !isNewSubcontractor} height={40}>
          <FormControl
            label={t('tab.subcontractors.form.name.label', `Company name`)}
            name="name"
            isError={hasNameCollision || !!errors['name']}
            hint={
              hasNameCollision
                ? t('tab.subcontractors.form.name.errors.collision_error', 'This name is already taken. Change it.')
                : errors['name']
            }
          >
            <Field
              className="ctrl-textfield"
              as="input"
              placeholder={t('tab.subcontractors.form.name.placeholder', 'Enter Name')}
            />
          </FormControl>
        </SkeletonPreloader>
      </div>
      <SubcontractorSimilar
        className={s.subcontractorCreating__similar}
        subcontractorNames={subcontractorNameSuggestions}
        name={name}
      />
    </div>
  );
};

export default SubcontractorForm;
