import {array, mixed, number, object, ref, string} from 'yup';

import {CompletionUnits} from 'shared/constants/completionUnits';

export const ONE_HUNDRED_PERCENT = 100;
export const SUPPORTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png'];
export const FILE_SIZE_LIMIT = 52428800; // 50MB

const isNumeric = (value: string) => !isNaN(Number(value));
const isGreaterThanZero = (value: string) => Number(value) > 0;
const isLessThanOrEqualTo100 = (value: string) => isNumeric(value) && Number(value) <= ONE_HUNDRED_PERCENT;

export const validationSchema = object().shape({
  completionUnit: mixed().oneOf(Object.values(CompletionUnits)),
  completionTarget: string()
    .required('common:validation.required')
    .when(['completionUnit'], {
      is: (completionUnit: CompletionUnits) => completionUnit === CompletionUnits.Percent,
      then: string().test(
        'isNumeric',
        'dailies:progress_report.header.error.completion_amount_error',
        isLessThanOrEqualTo100,
      ),
      otherwise: string().test(
        'isGreaterThanZero',
        'dailies:progress_report.header.error.completion_amount_error_zero',
        isGreaterThanZero,
      ),
    }),
  dailyCompletionAmount: number()
    .nullable()
    .max(ref('completionTarget'), 'dailies:progress_report.header.error.daily_completion_amount_error'),
  imageFiles: array().of(
    mixed()
      .test(
        'fileType',
        'dailies:progress_report.comments_form.errors.unsupported_file_format',
        (value) => value instanceof File && SUPPORTED_FORMATS.includes(value?.type),
      )
      .test(
        'fileSize',
        'dailies:progress_report.comments_form.errors.file_size_too_large',
        (value) => value instanceof File && value?.size <= FILE_SIZE_LIMIT,
      ),
  ),
});
