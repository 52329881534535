import {SortingRule} from 'react-table';

import {CompanyWorker, CompanyWorkerFilterParams} from 'shared/models/worker';

export const DEFAULT_SORT_RULE: SortingRule<CompanyWorker>[] = [{id: 'firstName', desc: false}];
export const DEFAULT_FILTER_PARAMS: CompanyWorkerFilterParams = {
  wildcard: '',
  trade: '',
  blendedStatus: null,
  orgList: [],
};
export const EXCLUDED_FIELDS_FROM_FILTER = ['wildcard'];
