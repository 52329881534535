import {TaskObjectType} from 'shared/models/task/const';
import {FormValues} from './types';

export const AVAILABLE_FIELDS_IN_READONLY_VIEW = new Set<string>(['completionAmount', 'status']);

export const DISABLED_FIELDS_BY_TYPE: {[key in TaskObjectType]?: Set<keyof FormValues>} = {
  [TaskObjectType.summary]: new Set(['startDate', 'endDate', 'actualStart', 'actualEnd', 'duration']),
  [TaskObjectType.milestone]: new Set([
    'location',
    'responsibleOrgId',
    'completionAmount',
    'completionTarget',
    'completionUnit',
  ]),
};
