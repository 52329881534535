import * as Sentry from '@sentry/browser';
import {GanttStatic} from 'dhtmlx-gantt';
import {camelizeKeys} from 'humps';
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {refreshTask} from 'modules/Tasks/utils/functions';
import AsyncProjectWorkerSelect from 'shared/components/CoreForm/Select/AsyncProjectWorkerSelect';
import {RouteParams} from 'shared/constants/routes';
import {getPreparedMember} from 'shared/helpers/task';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {toGanttTaskModel} from 'shared/mapping/task';
import {TaskObjectType, GroupMemberRole} from 'shared/models/task/const';
import {GroupMember} from 'shared/models/task/member';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {CompanyWorker} from 'shared/models/worker';

import Confirmation from '../Confirmation';

type Props = {
  gantt: GanttStatic;
  onClose: () => void;
};

const TasksBulkAssignPopup: FC<Props> = ({gantt, onClose}: Props) => {
  const [selectedResponsible, setSelectedResponsible] = useState<CompanyWorker[]>(null);
  const {projectId} = useParams<RouteParams['tasks']>();
  const {mixpanel} = useAnalyticsService();
  const mixpanelEvents = mixpanel.events[gantt.name];
  const {t} = useTranslation('gantt');

  const onSaveChanges = async () => {
    mixpanel.track(mixpanelEvents?.bulkAssigneesSelect);
    const switchResponsibleToWatcher = [];
    const addNewResponsibleMember = [];
    gantt.eachSelectedTask(function (taskId: string) {
      const task = gantt.getTask(taskId);
      if (task.responsible?.length && task.object_type !== TaskObjectType.milestone) {
        const activeResponsible = camelizeKeys(task.responsible[0]) as GroupMember;
        if (activeResponsible?.memberId === selectedResponsible[0].workerId) return;
        // switch current responsible worker to watcher role
        switchResponsibleToWatcher.push({taskId: taskId, memberId: activeResponsible.memberId});
      }
      addNewResponsibleMember.push({taskId, memberId: selectedResponsible[0].workerId});
    });

    let promises = [];
    try {
      promises = switchResponsibleToWatcher.map(({taskId, memberId}) =>
        TasksApi.addAssignee(taskId, getPreparedMember(memberId, GroupMemberRole.assignee)),
      );
      await Promise.all(promises);

      promises = addNewResponsibleMember.map(({taskId, memberId}) =>
        TasksApi.addAssignee(taskId, getPreparedMember(memberId, GroupMemberRole.responsible)),
      );
      const res = await Promise.allSettled(promises);
      const resolvedPromises = res.filter(
        ({status}) => status === 'fulfilled',
      ) as PromiseFulfilledResult<TaskDetailsModelDTO>[];

      gantt.batchUpdate(function () {
        gantt.eachSelectedTask(function (taskId: string) {
          const updatedTask = resolvedPromises.find((promise) => promise.value.id === taskId)?.value;
          if (updatedTask) {
            refreshTask(gantt, taskId, toGanttTaskModel(updatedTask));
          }
        });
      });
      toast.success(t('toast.success.bulk_assign', 'The changes has been saved.'));
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
    }
  };

  return (
    <Confirmation
      visible={true}
      title={t('bulk_assign.confirmation.title', 'Assignee')}
      description={t('bulk_assign.confirmation.description', 'Responsible to Multiple Tasks')}
      onClose={onClose}
      onAccept={onSaveChanges}
      onReject={onClose}
      acceptButton={t('bulk_assign.confirmation.buttons.accept', 'Assign')}
      cancelButton={t('bulk_assign.confirmation.buttons.cancel', 'Cancel')}
      isDisabledAcceptBtn={!selectedResponsible}
      field={
        <AsyncProjectWorkerSelect
          projectId={projectId}
          onSelectChange={setSelectedResponsible}
          placeholder={t('bulk_assign.confirmation.select.placeholder', 'Select assignee')}
          loadingPlaceholder={t('bulk_assign.confirmation.select.loading_placeholder', 'Loading...')}
        />
      }
    />
  );
};
export default TasksBulkAssignPopup;
