import cn from 'classnames';

import s from './CtrlColor.module.scss';

type CtrlColorProps = {
  className?: string;
  color?: string;
  isActive?: boolean;
  onClick?: () => void;
};

const CtrlColor = ({className, color, isActive, onClick}: CtrlColorProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(s.ctrlColor, !color && s.ctrlColor_reset, isActive && s.ctrlColor_isActive, className)}
      type="button"
    >
      <span className={s.ctrlColor__color} style={{backgroundColor: color}}></span>
      {!color && <span className={s.ctrlColor__text}>Reset color</span>}
    </button>
  );
};

export default CtrlColor;
