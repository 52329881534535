import cn from 'classnames';

import styles from './styles.module.scss';

interface PillProps {
  /**
   * Text content to render.
   */
  children: string;
  /**
   * "Tiny" size icon to render.
   */
  icon?: React.ReactNode;
  /**
   * Color of the pill text and background.
   */
  color?: 'green' | 'red' | 'blue';
  /**
   * OnClick handler.
   */
  onClick?: () => void;
  /**
   * size of the pill
   */
  size?: 'xs' | 'default';
  /**
   * className to apply to the root element.
   */
  className?: string;
}

export function Pill({color, icon, children, className, onClick, size}: PillProps): JSX.Element {
  return (
    <div
      className={cn(
        {
          [styles.root]: true,
          [styles.xs]: size === 'xs',
          [styles.pillGreen]: color === 'green',
          [styles.pillRed]: color === 'red',
          [styles.pillBlue]: color === 'blue',
        },
        className,
      )}
    >
      <span className={styles.content}>
        <span className={styles.pillContent}>{children}</span>
        {icon ? (
          <span onClick={onClick} className={styles.iconWrap}>
            {icon}
          </span>
        ) : null}
      </span>
    </div>
  );
}
