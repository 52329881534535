import {useRouteMatch} from 'react-router';

import {ROUTES} from 'shared/constants/routes';

import CompanyWorker from './CompanyWorker';
import ProjectWorker from './ProjectWorker';

const Worker = () => {
  const {path} = useRouteMatch();
  const isProjectWorker = path === ROUTES.projectWorker;
  return isProjectWorker ? <ProjectWorker /> : <CompanyWorker />;
};

export default Worker;
