import {useRef} from 'react';
import {useQueryClient} from 'react-query';

import {QueryCacheHelper} from './QueryCacheHelper';

export function useQueryCache() {
  const queryClient = useQueryClient();
  const queryCacheHelper = useRef(new QueryCacheHelper(queryClient));
  return {queryClient, cacheHelper: queryCacheHelper.current};
}
