import cn from 'classnames';
import dayjs from 'dayjs';
import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {getLookaheadWeeksFilter, getGanttWeeksFilter} from 'modules/Tasks/components/Filters/utils/constants';
import {isBaseLineMode} from 'modules/Tasks/Views/Gantt/utils/baselineHandlers';

import s from './TimelineBadge.module.scss';

type TimelineBadgeProps = {
  className?: string;
  range: string;
  appliedBaselineDate: string;
  isGanttView?: boolean;
};

const TimelineBadge = ({className, range, appliedBaselineDate, isGanttView}: TimelineBadgeProps) => {
  const {t} = useTranslation(['filters', 'common', 'gantt']);

  const weeksFilterFormat = useMemo(() => {
    const values = [...getLookaheadWeeksFilter(t), ...getGanttWeeksFilter(t)];
    return values.reduce((acc, item) => {
      acc[String(item.value)] = item.title;
      return acc;
    }, {} as {[k: string]: string});
  }, []);

  return (
    <>
      {weeksFilterFormat[range] && (
        <div className={cn(s.timelineBadge, className)}>
          {t('common:timeline', 'Timeline') + ' : ' + weeksFilterFormat[range]}
        </div>
      )}
      {isBaseLineMode() && isGanttView && (
        <div className={cn(s.timelineBadge, className)}>
          {t('gantt:baseline.title', 'Baseline') + ' : ' + dayjs(appliedBaselineDate).format('MMM D, YYYY')}
        </div>
      )}
    </>
  );
};

export default TimelineBadge;
