import * as Sentry from '@sentry/browser';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {butchUpdateIssues} from 'modules/Tasks/components/IssuesActionsBar/components/utils';
import {BulkProps, TasksBulkTypes} from 'modules/Tasks/utils/constants';
import Confirmation from 'shared/components/Confirmation';
import TaskStatusSelect from 'shared/components/CoreForm/Select/TaskStatusSelect';
import {runAllSettled} from 'shared/helpers/api';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {TaskStatusType} from 'shared/models/task/taskStatus';

const BulkStatus = ({onClose, gantt, projectId}: BulkProps) => {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const {t} = useTranslation('gantt');

  async function update(status: TaskStatusType) {
    const {fulfilled, rejected} = await runAllSettled(
      gantt.getSelectedTasks().map((id) => TasksApi.updateIssue({id: id, projectId, status})),
    );
    if (fulfilled.length) {
      butchUpdateIssues(gantt, fulfilled as any);
      toast.success(t('toast.success.issues.status', 'The changes has been saved.'));
    }

    if (rejected.length) {
      const reason = rejected[0].reason;
      const error = isAxiosError(reason) ? extractAxiosError(reason) : reason;
      toast.error(error);
      Sentry.captureException(error);
    }
  }

  return (
    <Confirmation
      visible
      title={t('bulk_status.confirmation.title', 'Status')}
      description={t('bulk_status.confirmation.description', 'Set status for multiple activities')}
      cancelButton={t('bulk_assign.confirmation.buttons.cancel', 'Cancel')}
      acceptButton={t('bulk_assign.confirmation.buttons.accept', 'Submit')}
      onAccept={async () => update(selectedStatus)}
      onClose={onClose}
      isDisabledAcceptBtn={!selectedStatus}
      field={
        <TaskStatusSelect
          allowedStatuses={[TaskStatusType.inProgress, TaskStatusType.closed]}
          onChange={setSelectedStatus}
          projectId={projectId}
          value={selectedStatus}
          name={TasksBulkTypes.status}
          placeholder={t('bulk_status.confirmation.title', 'Select Status')}
          menuPosition="fixed"
        />
      }
    />
  );
};

export default BulkStatus;
