import {GanttStatic} from 'dhtmlx-gantt';

import {useBulkActions} from 'modules/Tasks/components/ActionsBar/hooks/useBulkActions';
import {useTasksActions} from 'modules/Tasks/hooks/useTasksActions';
import {TasksBulkTypes} from 'modules/Tasks/utils/constants';

import {useDeleteOneSelectedIssue} from './components/useDeleteOneSelectedIssue';

export function useIssuesBulkActions({gantt, projectId}: {gantt: GanttStatic; projectId: string}) {
  const {selected, bulkType, setBulkType, openBulkModal, clearSelection, closeBulkModal} =
    useBulkActions<TasksBulkTypes>({gantt, projectId});

  const setAssignee = () => openBulkModal(TasksBulkTypes.responsible);
  const setStatus = () => openBulkModal(TasksBulkTypes.status);

  const {indentSelectedTasks, outdentSelectedTasks, deleteSelectedTasks} = useTasksActions(gantt);
  const deleteOneSelectedIssue = useDeleteOneSelectedIssue(gantt);

  return {
    bulkType,
    selected,
    actions: {
      clearSelection,
      closeBulkModal,
      setBulkType,
      indentSelectedTasks,
      outdentSelectedTasks,
      deleteSelectedTasks,
      deleteOneSelectedIssue,
      setStatus,
      setAssignee,
    },
  };
}
