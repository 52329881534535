import cn from 'classnames';
import {CellProps} from 'react-table';

import {CompanyOrgs} from 'shared/models/company';

import CtrlColorPreview from '../CtrlColorPreview';

import s from './SubcontractorInfo.module.scss';

const SubcontractorInfo = ({value: {value, color}}: CellProps<CompanyOrgs>) => {
  return (
    <div className={cn(s.infoSubcontractor)}>
      <CtrlColorPreview color={color} abbr={value.abbrev} size="s" />
      <span className={s.infoSubcontractor__title}>{value?.group?.name}</span>
    </div>
  );
};

export default SubcontractorInfo;
