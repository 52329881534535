import {GanttStatic} from 'dhtmlx-gantt';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {refreshTask, updateCollapseIcon} from 'modules/Tasks/utils/functions';
import {findActivityIndexByOutlineNumber} from 'shared/helpers/gantt';
import {toGanttTaskModel} from 'shared/mapping/task';
import Gantt from 'shared/models/Gantt';
import {TaskDetailsModelDTO} from 'shared/models/task/task';

import {TaskCacheHandlerConfig, TasksCacheHelperMethod, TasksCacheHelperMethodConfig} from './types';

const getGanttInstances = (exclude?: TasksCacheHelperMethodConfig['exclude']) => {
  return Gantt.list().filter((inst) => {
    return !exclude?.[`gantt:${inst.name}`];
  });
};

export function createCacheHandler<P>(handlers: TaskCacheHandlerConfig<P>): TasksCacheHelperMethod<P> {
  return function (payload: P, config: TasksCacheHelperMethodConfig = {exclude: {}}) {
    if (!config.exclude.gantt) {
      handlers.gantt &&
        getGanttInstances(config.exclude).forEach((inst) => {
          handlers.gantt(inst, payload, config);
        });
    }
    if (!config.exclude.currentTask) {
      handlers.currentTask?.(payload, config);
    }
  };
}

export const updateGanttTask = (gantt: GanttStatic, task: Partial<TaskDetailsModelDTO>) => {
  const isTaskExists = gantt.isTaskExists(task.id);
  if (isTaskExists) {
    const ganttTask: GanttTask = gantt.getTask(task.id);
    const {id, meta, parent: newParentId, outline_sort_key: newOutlineSortKey, ...updates} = toGanttTaskModel(task);
    ganttTask.datesIsPristine = false;
    if (ganttTask.subtask_count !== updates.subtask_count) {
      gantt.open(ganttTask.id);
    }
    // * issues do not need an end_date and will return an error (NaN) when trying to calculateDuration()
    // * tasks will have a start_date & end_date
    const shouldCalculateDuration = ganttTask.start_date && ganttTask.end_date;
    const duration = shouldCalculateDuration ? gantt.calculateDuration(ganttTask) : 1;
    refreshTask(gantt, task.id, {...updates, duration});
    const isParentValid = gantt.isTaskExists(newParentId) || newParentId === gantt.config.root_id;
    const parentChanged = ganttTask.parent !== newParentId;
    const outlineCodeChanged = ganttTask.outline_sort_key !== newOutlineSortKey;
    if (!gantt.isFlatList && isParentValid && (parentChanged || outlineCodeChanged)) {
      ganttTask.outline_sort_key = newOutlineSortKey;
      const index = findActivityIndexByOutlineNumber({
        gantt: gantt,
        taskId: ganttTask.id,
        parent: newParentId,
        outlineKey: ganttTask.outline_sort_key,
      });
      if (parentChanged || gantt.getTaskIndex(ganttTask.id) !== index) {
        gantt.moveTask(ganttTask.id, index, newParentId);
      }
    }
  }
};

export function addTask(gantt: GanttStatic, task: TaskDetailsModelDTO, asPristine: boolean, index?: number) {
  const ganttTask = toGanttTaskModel(task);
  ganttTask.datesIsPristine = asPristine;
  const parentId = task?.parentTask?.id;
  const parentExist = gantt.isTaskExists(parentId) || parentId === gantt.config.root_id;
  gantt.silent(() => {
    if (parentExist) {
      gantt.addTask(
        ganttTask,
        parentId,
        typeof index === 'number'
          ? index
          : findActivityIndexByOutlineNumber({
              gantt,
              taskId: task.id,
              parent: parentId,
              outlineKey: task.outlineSortKey,
            }),
      );

      /* in either way call open method
               if parent already has children it will do nothing overwise it will load remote data
            */
      gantt.open(parentId);
    } else {
      gantt.addTask(ganttTask);
    }
  });
  updateCollapseIcon(gantt, parentId, 1);
}

export function moveTask(inst: GanttStatic, model: TaskDetailsModelDTO, parent: string, index: number) {
  {
    const parentId = parseInt(`${parent}`) === 0 ? 0 : parent;
    const parentExist = inst.isTaskExists(parent) || parentId === 0;
    if (!parentExist) {
      // TODO: handle other cases that cause data out of sync
      inst.needReload = true;
    } else {
      const isTaskExist = inst.isTaskExists(model.id);
      const currentIndex = inst.getTaskIndex(model.id);
      const currentParent = inst.getParent(model.id);
      if (index !== currentIndex || currentParent !== parentId) {
        // if parent is loaded(for gantt view with expandable branches)
        if (isParentTaskReady(inst, parentId) && isTaskExist) {
          const newModel = Object.keys(model).length > 1 ? toGanttTaskModel(model) : null;
          const actualIndex = findActivityIndexByOutlineNumber({
            gantt: inst,
            taskId: newModel.id,
            parent: newModel.parent,
            index,
            outlineKey: newModel.outline_sort_key,
          });
          if (newModel) {
            if (!isTaskExist) {
              inst.silent(() => {
                inst.addTask(newModel, actualIndex);
              });
              updateCollapseIcon(inst, parent, 1);
            } else {
              inst.moveTask(model.id, actualIndex, parentId);
              // move api operation returns not full model of task details model,
              // TODO: update full gantt model base on partial api response
              inst.getTask(model.id).outline_sort_key = newModel.outline_sort_key;
              inst.refreshTask(model.id);
            }
          }
        } else {
          // if we cannot move child to the new parent and that task exist, delete it from view
          if (isTaskExist) {
            inst.silent(() => {
              inst.deleteTask(model.id);
            });
            updateCollapseIcon(inst, parent, -1);
          }
        }
      }
    }
  }
}

/*
 * Check does parent ready for add/move/remove operations with children
 * returns false if parent not exist or children not loaded yet
 * */
function isParentTaskReady(gantt: GanttStatic, taskId: string | number) {
  if (parseInt(`${taskId}`) === 0) return true;
  const parent = gantt.isTaskExists(taskId) && gantt.getTask(taskId);
  const loaded = !!parent && !!gantt.getChildren(taskId).length;
  return parent?.$has_child ? loaded : true;
}
