import cn from 'classnames';
import {ChangeEventHandler, InputHTMLAttributes} from 'react';

import s from './CtrlTextfield.module.scss';

type CtrlTextfieldProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  type?: string;
  placeholder?: string;
  id?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string;
};

const CtrlTextfield = ({
  className,
  type = 'text',
  placeholder = 'Placeholder',
  id,
  onChange,
  value,
  ...props
}: CtrlTextfieldProps) => {
  return (
    <input
      className={cn(s.ctrlTextfield, className)}
      onChange={onChange}
      value={value}
      type={type}
      id={id}
      placeholder={placeholder}
      {...props}
    />
  );
};

export default CtrlTextfield;
