import produce, {castDraft} from 'immer';
import {InfiniteData, Query, QueryClient, QueryKey} from 'react-query';

import {CompanyWorker} from 'shared/models/worker';

// eslint-disable-next-line
type ItemWithId = {id?: any};

export class QueryCacheHelper {
  constructor(public queryClient: QueryClient) {}
  findRecentPagedQuery<D extends Record<string, unknown>>(key: QueryKey) {
    if (!key) return null;
    return this.queryClient
      .getQueryCache()
      .findAll(key, {
        exact: false,
      })
      .sort((a, b) => (a.state.dataUpdatedAt < b.state.dataUpdatedAt ? 1 : -1))[0] as Query<InfiniteData<D[]>>;
  }

  findActivePagedQuery<D extends Record<string, unknown>>(key: QueryKey) {
    if (!key) return null;
    return this.queryClient.getQueryCache().find<InfiniteData<D[]>>(key, {
      exact: false,
      active: true,
    });
  }

  updatePagedData<D extends ItemWithId>(state: InfiniteData<D[]>, newItem: D, replace?: boolean) {
    return state?.pages
      ? produce(state, (draft: InfiniteData<D[]>) => {
          for (const page of draft.pages) {
            this.updateDraftArrayItem(page, newItem, replace);
          }
        })
      : state;
  }

  prependToPagedData<D extends Record<string, unknown>>(state: InfiniteData<D[]>, newItem: D) {
    return state?.pages
      ? produce(state, (draft: InfiniteData<D[]>) => {
          if (draft.pages[0]) {
            draft.pages[0] = [newItem, ...draft.pages[0]];
          }
        })
      : null;
  }

  findInPagedData<D extends Record<string, unknown>>(state: InfiniteData<D[]>, predicate: (item: D) => boolean) {
    if (!state?.pages) return null;
    for (const page of state.pages) {
      const element = page.find(predicate);
      if (element) return element;
    }
  }

  removeItemFromPagedData<D extends Record<string, unknown>>(state: InfiniteData<D[]>, itemToRemove: D | string) {
    const id = typeof itemToRemove === 'string' ? itemToRemove : itemToRemove.id;
    return state?.pages
      ? produce(state, (draft: InfiniteData<D[]>) => {
          draft.pages = draft.pages.map((page) => page.filter((pageItem) => pageItem.id !== id));
        })
      : state;
  }

  updateItem<D extends ItemWithId>(key: QueryKey, updatedItem: D) {
    if (!key) return null;
    return this.queryClient
      .getQueryCache()
      .find<D[]>(key)
      ?.setData((oldData) => produce(oldData, (draft: D[]) => this.updateDraftArrayItem(draft, updatedItem)));
  }

  removeItem<D extends ItemWithId>(key: QueryKey, itemToDelete: D | string) {
    if (!key) return null;
    return this.queryClient
      .getQueryCache()
      .find<D[]>(key)
      ?.setData((oldData) =>
        produce(oldData, (draft) => {
          const index = draft.findIndex((item) => {
            const targetId =
              typeof itemToDelete === 'string' || typeof itemToDelete === 'number' ? itemToDelete : itemToDelete.id;
            return targetId === item.id;
          });
          if (index !== -1) {
            draft.splice(index, 1);
          }
        }),
      );
  }

  prependItem<D extends Record<keyof D, unknown>>(key: QueryKey, newItem: D) {
    this.queryClient
      .getQueryCache()
      .find<D[]>(key)
      ?.setData((oldData) =>
        produce(oldData, (draft) => {
          draft.unshift(castDraft(newItem));
        }),
      );
  }

  draftPrepend<D extends ItemWithId>(draft: InfiniteData<D[]>, state, newWorker: D) {
    if (draft.pages[0]) {
      draft.pages[0] = [newWorker, ...draft.pages[0]];
      return draft.pages[0];
    }
  }

  clearWorkerNumber<D extends CompanyWorker>(draft: InfiniteData<D[]>, state, newWorker: D) {
    if (draft.pages[0]) {
      return (draft.pages[0] = draft.pages[0].map<D>((worker) => {
        if (worker.workerFull.mobileNumber === newWorker.workerFull.mobileNumber) {
          return newWorker;
        }
        return worker;
      }));
    }
  }

  prependOrUpdateCompanyWorkers<D extends CompanyWorker>(state: InfiniteData<D[]>, newWorker: D) {
    return state?.pages
      ? produce(state, (draft: InfiniteData<D[]>) => {
          !this.hasWorker(state.pages[0], newWorker) && this.draftPrepend(draft, state, newWorker);
          this.hasRemovedWorker(state.pages[0], newWorker) && this.clearWorkerNumber(draft, state, newWorker);
        })
      : null;
  }

  private updateDraftArrayItem<D extends ItemWithId>(data: D[], updatedItem: D, replace?: boolean) {
    const index = data.findIndex((pageItem) => pageItem.id === updatedItem.id);
    if (index !== -1) {
      data.splice(index, 1, replace ? updatedItem : Object.assign({}, data[index], updatedItem));
    }
  }

  private hasWorker<D extends CompanyWorker>(workers: D[], newWorker: D) {
    return workers.some((worker) => {
      return worker.id === newWorker.id;
    });
  }

  private hasRemovedWorker<D extends CompanyWorker>(workers: D[], newWorker: D) {
    return workers.some((worker) => {
      return worker.id === newWorker.id && worker.status === 'removed';
    });
  }
}
