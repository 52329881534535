import {useMemo} from 'react';

import {useRootSelector} from 'store';
import {getAdminProjectsSelector, isCompanyAdminSelector} from 'store/profile';

export function useCompanyWorkerRoles(projectId?: string) {
  const adminProjectIds = useRootSelector(getAdminProjectsSelector);
  const isCompanyAdmin = useRootSelector(isCompanyAdminSelector);

  const isProjectAdmin = useMemo(
    () => projectId !== undefined && adminProjectIds.includes(projectId),
    [projectId, adminProjectIds],
  );

  return {isCompanyAdmin, isProjectAdmin, hasAnyAdminRole: isCompanyAdmin || isProjectAdmin};
}
