/* eslint-disable new-cap */
// import MapboxClient from "@mapbox/mapbox-sdk/lib/classes/mapi-client";
import mbxGeocoding, {
  GeocodeQueryType,
  GeocodeMode,
  GeocodeRequest,
  GeocodeService,
  GeocodeFeature,
} from '@mapbox/mapbox-sdk/services/geocoding';
import {ChangeEvent, FC, useCallback, useEffect, useRef, useState} from 'react';

import {MAPBOX_COUNTRY_MAPPING} from 'shared/constants/common';
import {debounce} from 'shared/helpers/debounce';
import {useEventListener} from 'shared/hooks/core/useEventListener';
import {useOutsideClick} from 'shared/hooks/core/useOusideClick';

type Props = {
  timeout?: number;
  onChanged: (item: string, longitude?: number, latitude?: number) => void;
  hideOnSelect?: boolean;
  value: string;
  mapboxQueryTypes?: Array<GeocodeQueryType>;
  updateInputOnSelect?: boolean;
  limit?: number;
  formatItem?: (item: GeocodeFeature) => any; // eslint-disable-line @typescript-eslint/no-explicit-any
  className?: string;
  placeholder?: string;
  name?: string;
  allowCustom?: boolean;
  selectedCountry?: string;
  selectedState?: string;
  disableInput?: boolean;
};

const Geocoder: FC<Props> = ({
  timeout,
  hideOnSelect,
  value,
  limit,
  mapboxQueryTypes,
  formatItem,
  onChanged,
  className,
  placeholder,
  name,
  allowCustom,
  selectedCountry,
  selectedState,
  disableInput,
}: Props) => {
  const [results, setResults] = useState<GeocodeFeature[]>([]);
  const [inputValue, setInputValue] = useState<string>(value || '');
  const [selectedItem, setSelectedItem] = useState<string>('');
  const [showResults, setShowResults] = useState<boolean>(false);
  const container = useRef<HTMLDivElement>(null);
  const inputElement = useRef<HTMLInputElement>();
  const geoClient = useRef<GeocodeService>(mbxGeocoding({accessToken: process.env.REACT_APP_MAPBOX_TOKEN}));

  const reset = useCallback(() => {
    onChanged('');
    setInputValue('');
    setResults([]);
  }, [onChanged, setInputValue, setResults]);

  function toggleResults(showResults: boolean) {
    setShowResults(showResults);
    if (!showResults) {
      container.current.querySelector('input').blur();
      setResults([]);
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const params = {
      countries: [MAPBOX_COUNTRY_MAPPING[selectedCountry]],
      mode: 'mapbox.places-permanent' as GeocodeMode,
      limit: limit,
      types: mapboxQueryTypes,
    };
    const queryString = event.target.value;

    setInputValue(queryString);

    if (!queryString) {
      return reset();
    }
    if (params.limit > 0 && queryString) {
      search({
        ...params,
        ...{
          query:
            (selectedCountry ? selectedCountry + ', ' : '') + (selectedState ? selectedState + ', ' : '') + queryString,
        },
      });
    }
  };

  const search = debounce((params: GeocodeRequest) => {
    if (params.limit > 0 && params.query) {
      geoClient.current
        .forwardGeocode(params)
        .send()
        .then((res) => {
          if (selectedState) {
            setResults(res.body.features.filter((feature) => feature.context[1].text === selectedState));
          } else {
            setResults(res.body.features);
          }
        });
    }
  }, timeout);
  function handleSelect(index: number) {
    const result = results[index];
    setInputValue(formatItem(result));
    onChanged(formatItem(result), result.center[0], result.center[1]);
    setSelectedItem(formatItem(result));

    if (hideOnSelect) {
      toggleResults(false);
    }
  }

  const hideResults = () => {
    toggleResults(false);
  };

  useEffect(() => {
    setSelectedItem(value);
    if (value !== inputElement.current.value) {
      setInputValue(value);
    }
  }, [value, setInputValue]);

  useOutsideClick({
    ref: container,
    callback: () => {
      if (inputValue && selectedItem !== inputValue && results.length) {
        handleSelect(0);
      } else if (allowCustom && inputValue && !results.length) {
        onChanged(inputValue);
      }
      hideResults();
    },
    capture: true,
  });

  useEventListener('keydown', (e) => {
    const resultsElement = container.current.querySelector('.react-geocoder-results');
    const active: HTMLDivElement = resultsElement && resultsElement.querySelector('.react-geocoder-item:focus');
    if (resultsElement && e.keyCode == 40) {
      e.preventDefault();
      const first = resultsElement.firstChild as HTMLDivElement;
      const next = active && (active.nextElementSibling as HTMLDivElement);
      (next || first).focus();
    }
    if (resultsElement && e.keyCode == 38) {
      e.preventDefault();
      const last = resultsElement.lastChild as HTMLDivElement;
      const prev = active && (active.previousElementSibling as HTMLDivElement);
      (prev || last).focus();
    }
  });

  return (
    <div ref={container} style={{position: 'relative'}} className={`react-geocoder ${className}`}>
      <input
        ref={inputElement}
        onChange={handleChange}
        disabled={disableInput}
        onKeyDown={(e) => {
          if (e.keyCode === 13 && inputValue) {
            if (results.length) {
              handleSelect(0);
            } else {
              onChanged(inputValue);
              toggleResults(false);
            }
          }
        }}
        onFocus={() => {
          toggleResults(true);
        }}
        value={inputValue}
        name={name}
        placeholder={placeholder}
        autoComplete="off"
      />
      {showResults && !!results.length && (
        <div className="react-geocoder-results">
          {results.map((item, index) => (
            <div
              key={index}
              tabIndex={0}
              onMouseEnter={(e) => (e.target as HTMLDivElement).focus()}
              className="react-geocoder-item"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  handleSelect(index);
                }
              }}
              onClick={() => handleSelect(index)}
            >
              {formatItem(item)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
Geocoder.displayName = 'Geocoder';

Geocoder.defaultProps = {
  timeout: 300,
  hideOnSelect: false,
  updateInputOnSelect: true,
  formatItem: (item) => item.place_name,
  mapboxQueryTypes: ['place', 'postcode'],
  disableInput: false,
  limit: 5,
  allowCustom: false,
  className: '',
};

export default Geocoder;
