import {useQuery} from 'react-query';

import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

import {loadInitialData} from '../utils/apiHelpers';

export function useProgressReport(id: string) {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.task, id],
    queryFn: () => loadInitialData(id),
    enabled: !!id,
  });
}
