import {FC, ReactElement, ReactNode} from 'react';
import * as React from 'react';
import {useTranslation} from 'react-i18next';

import {IconProps} from 'shared/components/Icon';

type AvatarProps = {
  src: string;
  className?: string;
  style?: React.CSSProperties;
  icon?: ReactElement<IconProps> | ReactNode;
};

const Avatar: FC<AvatarProps> = ({src, style, className, icon}) => {
  const {t} = useTranslation('common');
  return (
    <picture className={`avatar ${className}`}>
      <img
        className="avatar__image"
        src={src || '/images/user.svg'}
        style={style}
        alt={t('alt.worker_avatar', 'worker avatar')}
      />
      {icon}
    </picture>
  );
};
export default Avatar;
