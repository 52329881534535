import {useTranslation} from 'react-i18next';

import MetaTags from 'shared/components/MetaTags';
import {useFacebookTracking} from 'shared/hooks/analytics/useFacebookTracking';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {useMount} from 'shared/hooks/core/useMount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useClassName} from 'shared/hooks/useClassName';

import Companies from './components/Companies/Companies';
import ContentSection from './components/ContentSection/ContentSection';
import EasyToUse from './components/EasyToUse/EasyToUse';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import {
  FixProblemsMediaBlock,
  realTimeMediaBlock,
  streamlineWorkMediaBlock,
} from './components/MediaBlocks/MediaBlocks';
import Reviews from './components/Reviews/Reviews';

import './style.css';
import 'aos/dist/aos.css';

const Landing = () => {
  const {t} = useTranslation('landing2');
  const {mixpanel, useZoomInfo} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.home;

  useClassName(document.documentElement, 'ultraRoot', {clearInitialClasses: false});

  async function initAos() {
    try {
      const AOS = await import('aos');
      AOS.init();
    } catch (e) {
      console.warn('Failed to initialize aos library');
    }
  }
  useFacebookTracking();
  useZoomInfo();

  useMount(() => {
    const init = async () => {
      await initAos();
    };
    init();
  });

  useEffectOnce(() => {
    mixpanel.track(mixpanelEvents.screen);
  });

  return (
    <>
      <MetaTags
        title={t('meta_tags.title', 'Crews by Core PRO: Turn Schedules into Action')}
        description={t(
          'meta_tags.description',
          'Crews by Core is the construction platform that bridges the office and field, turning complicated plans into simple actions in the field. Your work, your way.',
        )}
        keywords={t(
          'meta_tags.keywords',
          'Construction Management Software, Project Management, Subcontractor, Foreman, Construction Technology, job scheduling, workload automation, project scheduling, work management, site management',
        )}
      />
      <Header />
      <main>
        <Hero />
        <Companies />
        <ContentSection
          title={t('section_one.title', 'Fix Scheduling Problems')}
          description={t('section_one.description')}
          mediaBlock={<FixProblemsMediaBlock />}
          reverse
        />
        <ContentSection
          title={t('section_two.title', 'Real Time')}
          description={t('section_two.description')}
          mediaBlock={realTimeMediaBlock}
        />
        <ContentSection
          title={t('section_three.title', 'Streamline Work')}
          description={t('section_three.description')}
          mediaBlock={streamlineWorkMediaBlock}
          reverse
        />
        <EasyToUse />
        <Reviews />
        <Footer />
      </main>
    </>
  );
};

export default Landing;
