import {useParams} from 'react-router';

import {useLocalizedRoutes} from 'shared/constants/routes';

export const useWorkerPath = () => {
  const routes = useLocalizedRoutes();
  const {projectId} = useParams<{projectId: string}>();

  const workerPath = projectId ? routes.projectWorker : routes.worker;
  const workersPath = projectId ? routes.projectWorkers : routes.workers;

  return {workerPath, workersPath};
};
