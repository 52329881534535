import cn from 'classnames';

import s from './CommentsDate.module.scss';

type CommentsDateProps = {
  className?: string;
  date: string;
  textAfterDate?: string;
};

const CommentsDate = ({className, date, textAfterDate}: CommentsDateProps) => {
  return (
    <div className={cn(s.CommentsDate, className)}>
      {textAfterDate && <span className={s.CommentsDate__textAfterDate}>{textAfterDate}</span>}
      <span className={s.CommentsDate__date}>{date}</span>
    </div>
  );
};

export default CommentsDate;
