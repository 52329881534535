/* eslint-disable @typescript-eslint/naming-convention */
import xss, {IFilterXSSOptions} from 'xss';

import {FeedbackModel} from 'shared/models/task/comments';
import {ExtChatEventMentionActivites} from 'shared/models/task/task';

export function getUniqueTags(commentContentList: FeedbackModel[][]): string[] {
  const tagSet = new Set<string>();
  const allTags = commentContentList.flatMap((list) => (list || []).flatMap((item) => item.tags.flat()));

  allTags.forEach((tag: string) => {
    if (!tagSet.has(tag)) {
      tagSet.add(tag);
    }
  });

  return Array.from(tagSet);
}

export function formatTaskHierarchy(task: ExtChatEventMentionActivites) {
  const wbsChainNames = task.wbsChain?.map((item) => item.name) || [];
  const fullChain = [...wbsChainNames, task.name];
  return fullChain.join('/');
}

export const sanitizedHTML = (html: string) => {
  const options: IFilterXSSOptions = {
    whiteList: {
      span: ['data-bycore-issue-id', 'data-bycore-task-id'],
      a: ['href', 'data-matrix-mention'],
    },
  };
  return xss(html, options);
};
