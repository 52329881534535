import cn from 'classnames';
import {cloneElement} from 'react';

import CtrlButton, {CtrlButtonProps} from 'shared/components/CoreNewUI/CtrlButton/CtrlButton';

import {ActionItem} from '../ActionsBar/types';
import Tooltip from 'shared/components/Tooltip';

import t from './Toolbar.module.scss';

export const ToolbarItem = ({
  actionType = 'bulk',
  className,
  color = 'action',
  customButton,
  disabled,
  hide,
  icon,
  iconSize,
  isDisabled,
  name,
  onClick,
  selected,
  size = 'xs',
  text,
  tooltip = true,
  tooltipPlacement = 'bottom',
}: ActionItem & Pick<CtrlButtonProps, 'size'> & {selected: string[]; className?: string}) => {
  const disableSingle = name !== 'baseline' && actionType === 'single' && selected?.length !== 1;
  const disableMultiple = name !== 'baseline' && actionType === 'bulk' && !selected?.length;
  const disable = disabled?.(selected) || disableSingle || disableMultiple || isDisabled;

  const button = customButton ? (
    cloneElement(customButton, {disabled: disable, className: className})
  ) : (
    <CtrlButton
      data-action-name={name}
      className={cn(className, t.item, {[t.item__hidden]: hide})}
      color={color}
      size={size}
      icon={icon}
      iconSize={iconSize}
      iconOnly={true}
      onClick={onClick}
      disabled={disable}
    >
      {text}
    </CtrlButton>
  );
  return tooltip ? (
    <Tooltip text={text} placement={tooltipPlacement} key={name}>
      {button}
    </Tooltip>
  ) : (
    button
  );
};
