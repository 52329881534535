import equal from 'fast-deep-equal';
import {observer} from 'mobx-react-lite';
import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useLocation, useParams} from 'react-router';

import {
  getDefaultFilterParamsByViewMode,
  getFilterNumberFilledFields,
} from 'modules/Tasks/components/Filters/utils/functions';
import {useLoadRowNumbersAndLinks} from 'modules/Tasks/components/Gantt/hooks/useLoadRowNumbersAndLinks';
import IssuesActionsBar from 'modules/Tasks/components/IssuesActionsBar/IssuesActionsBar';
import NoDataBubble from 'modules/Tasks/components/NoDataBubble';
import TasksActionsBar from 'modules/Tasks/components/TasksActionsBar/TasksActionsBar';
import {DailiesView} from 'modules/Tasks/Views/Dailies/Dailies';
import {GanttDefaultView} from 'modules/Tasks/Views/Gantt/GanttDefaultView';
import {GanttVisualView} from 'modules/Tasks/Views/GanttVisual/GanttVisual';
import {GanttIssuesView} from 'modules/Tasks/Views/Issues/Issues';
import {GanttLookaheadView} from 'modules/Tasks/Views/Lookahead/GanttLookaheadView';
import {useWorkerPath} from 'modules/Worker/hooks/useWorkerPath';
import {ObserverActionSource, ObserverAction} from 'services/TasksObserver/const';
import {useTasksSubscription} from 'services/TasksObserver/UseTasksObserver';
import {groupByEventType} from 'services/TasksObserver/utils';
import ErrorBoundary from 'shared/components/ErrorBoundary';
import HelpModal from 'shared/components/HelpModal';
import {ProgressReportingPopup} from 'shared/components/ProgressReportingPopup/ProgressReportingPopup';
import ProgressReportPopupProvider from 'shared/components/ProgressReportingPopup/ProgressReportPopupProvider';
import ProjectNameInlineEdit from 'shared/components/ProjectNameInlineEdit';
import TimelineBadge from 'shared/components/TimelineBadge/TimelineBadge';
import WorkspaceSwitcher from 'shared/components/WorkspaceSwitcher';
import {TasksViewMode} from 'shared/constants/common';
import {GanttNames} from 'shared/constants/gantt';
import {RouteParams, useLocalizedRoutes} from 'shared/constants/routes';
import {toTitleCase} from 'shared/helpers/common';
import {useDebounce} from 'shared/hooks/core/useDebounce';
import {usePrevious} from 'shared/hooks/core/usePrevious';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useQueryKeys} from 'shared/hooks/useQueryKeys';
import {useResponsibleOrgColors} from 'shared/hooks/useResponsibleOrgColors';
import {TasksCacheHelperMethodConfig, ToggleArchiveAction} from 'shared/hooks/useTaskCacheHlper/types';
import {useTasksCacheHelper} from 'shared/hooks/useTaskCacheHlper/useTaskCacheHelper';
import {useTasksUrlState} from 'shared/hooks/useTasksUrlState';
import {Panel, ScreenGreed} from 'shared/layout/admin';
import {toTaskModelRawDTO} from 'shared/mapping/task';
import Gantt from 'shared/models/Gantt';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskProjection} from 'shared/models/task/const';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {useInjectStore} from 'shared/providers/injection';
import {GanttStore} from 'shared/stores/GanttStore';
import {TasksStoreType} from 'shared/stores/TasksStore';
import {getWorkerMixpanelLabel} from 'shared/utils/inviteWorker';
import {useRootSelector} from 'store';
import {selectAllProjectEntities} from 'store/projects';
import {getAppliedBaselineDate} from 'store/tasks/selectors';

import {useFilterContext} from '../components/Filters/FilterProvider';
import {resetFrozenColumnsOffset} from '../components/Gantt/utils/functions';
import {GanttSimpleLock} from '../utils/simpleLock';

const View = () => {
  const {queryParams, viewMode, updateViewMode} = useFilterContext();
  const prevViewMode = usePrevious(viewMode);
  const {projectId} = useParams<RouteParams['tasks']>();
  const projects = useRootSelector(selectAllProjectEntities);
  const gantt = Gantt.getInstance(GanttNames[viewMode]);
  const simpleLock = GanttSimpleLock.getInstance(gantt);
  const appliedBaselineDate = useRootSelector((state) => getAppliedBaselineDate(state));
  const history = useHistory();
  const location = useLocation();
  const routes = useLocalizedRoutes();
  const [queryKey] = useQueryKeys('tasks');
  const {
    mixpanel: {events: mixpanelEvents, track, trackWithAction},
  } = useAnalyticsService();
  const tasksState = useTasksUrlState();
  const {lookaheadColors} = useResponsibleOrgColors();
  const {t} = useTranslation(['workers', 'gantt']);
  const {workersPath} = useWorkerPath();
  const {isProjectAdmin, hasAnyAdminRole: hasAdminRole} = useCompanyWorkerRoles(projectId);
  const tasksStore = useInjectStore<TasksStoreType>(IOC_TYPES.TasksStore);
  const ganttStore = useInjectStore<GanttStore>(IOC_TYPES.GanttStore);
  const [currentTaskId, updateCurrentTaskId] = useState<string>(null);
  const cacheHelper = useTasksCacheHelper();

  if (viewMode && viewMode !== prevViewMode) {
    track(mixpanelEvents.tasks.screen[viewMode], Object.assign({}, {projectId, isReadOnlyMode: !isProjectAdmin}));
  }

  useEffect(() => simpleLock.unlock(), [simpleLock, projectId]);

  useEffect(() => {
    resetFrozenColumnsOffset();
  }, [viewMode]);

  const {refetchData: refetchRowsAndLinks} = useLoadRowNumbersAndLinks(gantt, projectId);
  const debouncedRefetch = useDebounce(refetchRowsAndLinks, 1000);

  useTasksSubscription(
    (changes) => {
      if (changes.type === 'load') {
        tasksStore.updateTasks(changes.payload.map(({data}) => toTaskModelRawDTO(data)));
        GanttSimpleLock.getInstance(gantt).unlock('load');
      } else {
        const grouped = groupByEventType(changes.payload);
        for (const [action, events] of Object.entries(grouped)) {
          const first = events[0]; // todo, refactor, make one global meta for all events in transaction
          const cacheConfig: TasksCacheHelperMethodConfig = {exclude: {}};
          if (first.source && first.source === ObserverActionSource.gantt) {
            cacheConfig.exclude[`gantt:${first.sourceName}`] = true;
          }

          switch (action) {
            case ObserverAction.create:
            case ObserverAction.restore:
              cacheHelper.add(
                events.map((event) => ({
                  task: event.data as TaskDetailsModelDTO,
                  isPristine: event.meta?.create?.isPristine,
                  index: event.meta?.create?.index,
                })),
                cacheConfig,
              );
              tasksStore.addTasks(events.map(({data}) => toTaskModelRawDTO(data)));
              break;

            case ObserverAction.update:
              cacheHelper.update(events.map((event) => event.data) as TaskDetailsModelDTO[], cacheConfig);
              tasksStore.updateTasks(events.map(({data}) => toTaskModelRawDTO(data)));
              break;

            case ObserverAction.archive:
              cacheHelper.toggleArchived(
                {tasks: events.map((event) => event.data) as TaskDetailsModelDTO[], action: ToggleArchiveAction.Add},
                cacheConfig,
              );
              break;

            case ObserverAction.remove:
              cacheHelper.remove(
                events.map((event) => event.data.id),
                cacheConfig,
              );
              tasksStore.removeTasks(events.map(({data}) => data.id));
              break;

            case ObserverAction.move:
            default:
              break;
          }
        }
      }
      debouncedRefetch();
    },
    {projection: [TaskProjection.taskDetail]},
  );

  useEffect(() => {
    resetFrozenColumnsOffset();
  }, [viewMode]);

  const openWorkersPage = () => history.push(generatePath(workersPath, {projectId}));

  const filledFiltersCount = useMemo(() => getFilterNumberFilledFields(queryParams, viewMode), [queryParams, viewMode]);

  const isActiveFilter = useMemo(() => {
    return !equal(queryParams, getDefaultFilterParamsByViewMode(viewMode)) && !!filledFiltersCount;
  }, [queryParams, viewMode, filledFiltersCount]);

  const openNewTask = () => {
    trackWithAction(() => {
      history.push(generatePath(routes.task, {id: 'new', projectId}), {
        from: location.pathname + location.search,
        queryKey,
      });
    }, mixpanelEvents.tasks[viewMode === TasksViewMode.gantt ? 'addNewTaskBtn' : 'addNewTaskLookaheadBtn'].replace('{status}', toTitleCase(tasksState)));
  };

  const noDataElement = (
    <NoDataBubble
      hasFilters={isActiveFilter}
      status={tasksState}
      openNewTask={openNewTask}
      isGanttView={viewMode === TasksViewMode.gantt || viewMode === TasksViewMode.lookahead}
      t={t}
    />
  );

  const getView = (viewMode: TasksViewMode) => {
    const commonParams = {
      projectId,
      queryParams,
      noDataElement,
      isActiveFilter,
      lookaheadColors,
      hasAdminRole,
    };
    switch (viewMode) {
      case TasksViewMode.gantt:
        return (
          <>
            <TasksActionsBar gantt={gantt} projectId={projectId} showTodayButton />
            <ScreenGreed content={<GanttDefaultView {...commonParams} />} />
          </>
        );
      case TasksViewMode.ganttVisual:
        return (
          <>
            <TasksActionsBar gantt={gantt} projectId={projectId} />
            <ScreenGreed content={<GanttVisualView {...commonParams} />} />
          </>
        );
      case TasksViewMode.issues:
        return (
          <>
            <IssuesActionsBar projectId={projectId} gantt={gantt} />
            <ScreenGreed content={<GanttIssuesView {...commonParams} />} />
          </>
        );
      case TasksViewMode.dailies:
        return (
          <>
            <TasksActionsBar gantt={gantt} projectId={projectId} showTodayButton />
            <DailiesView />
          </>
        );
      default:
        return (
          <>
            <TasksActionsBar gantt={gantt} projectId={projectId} showTodayButton />
            <ScreenGreed content={<GanttLookaheadView {...commonParams} />} />
          </>
        );
    }
  };

  useEffect(() => {
    if (viewMode && viewMode !== prevViewMode) {
      ganttStore.setGanttInstance(viewMode);
    }
  }, [viewMode, ganttStore]);

  return (
    <>
      <Panel
        title={<ProjectNameInlineEdit project={projects[projectId]} />}
        actions={<WorkspaceSwitcher viewMode={viewMode} updateViewMode={updateViewMode} projectId={projectId} />}
        timelineBadge={
          (queryParams?.schedWeeks || appliedBaselineDate) && (
            <TimelineBadge
              range={queryParams?.schedWeeks}
              appliedBaselineDate={appliedBaselineDate as string}
              isGanttView={viewMode === TasksViewMode.gantt}
            />
          )
        }
      />
      <ErrorBoundary>
        <ProgressReportPopupProvider updateCurrentTaskId={updateCurrentTaskId} currentTaskId={currentTaskId}>
          {getView(viewMode)}
        </ProgressReportPopupProvider>
      </ErrorBoundary>
      {viewMode === TasksViewMode.table && (
        <HelpModal
          title="Activities"
          text="After you create a Project, break down the work using Activities. You can even break a Activities down into sub-activities to be assigned to different Workers & Subs. You can add files from any URLs, track % completion and more."
          buttonText="Add Workers!"
          buttonAction={() =>
            trackWithAction(openWorkersPage, mixpanelEvents.workers.screen(getWorkerMixpanelLabel(!!projectId)))
          }
          iconName="add_circle_outlined"
        />
      )}
      <ProgressReportingPopup
        gantt={gantt}
        key={currentTaskId}
        onClose={() => updateCurrentTaskId(null)}
        taskId={currentTaskId}
      />
    </>
  );
};

export default observer(View);
