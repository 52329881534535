import {useQuery} from 'react-query';

import FeedbackService from 'api/feedback';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

type Props = {
  projectId?: string;
  taskId: string;
};

export function useFetchFeedback(params: Props) {
  const whichFeedbackFn = () => {
    if (params.projectId) {
      return FeedbackService.getIssueFeedback(params.taskId, params.projectId);
    }
    return FeedbackService.getFeedback(params.taskId);
  };
  return useQuery([QUERY_CACHE_KEYS.feedback, params.taskId], whichFeedbackFn, {
    enabled: Boolean(params.taskId),
    staleTime: 0,
  });
}
