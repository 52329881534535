import {CheckboxColumnPlugin} from 'modules/Tasks/components/Gantt/plugins/checkboxColumnPlugin';
import {ContextMenuPlugin} from 'modules/Tasks/components/Gantt/plugins/contextMenuPlugin';
import {CopyPastePlugin} from 'modules/Tasks/components/Gantt/plugins/copyPaste';
import {StatusIconPluginFact} from 'modules/Tasks/components/Gantt/plugins/statusIcon';

export const ganttPlugins = {
  checkbox: CheckboxColumnPlugin,
  contextMenu: ContextMenuPlugin,
  copyPaste: CopyPastePlugin,
  statusIcon: StatusIconPluginFact,
};
