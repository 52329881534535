import {GanttStatic} from 'dhtmlx-gantt';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import TaskContextMenu from 'modules/Tasks/components/Gantt/components/TaskContextMenu/TaskContextMenu';
import GanttComponent from 'modules/Tasks/components/Gantt/GanttView';
import {createDataProcessor} from 'modules/Tasks/components/Gantt/utils/dataProcessors';
import {getFocusTodayDate} from 'modules/Tasks/components/Gantt/utils/date';
import {useGanttEventStore} from 'modules/Tasks/components/Gantt/utils/eventStore';
import {GanttEventNameUnion} from 'modules/Tasks/components/Gantt/utils/gantt';
import {loadActivityIds} from 'modules/Tasks/components/Gantt/utils/load';
import {addLookaheadTimelineLayer} from 'modules/Tasks/Views/Lookahead/utils/handlers';
import {TasksViewMode} from 'shared/constants/common';
import {GanttNames} from 'shared/constants/gantt';
import {useUnmount} from 'shared/hooks/core/useUnmount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

import SidebarPanel, {SidebarType} from '../../components/SidebarPanel/SidebarPanel';
import './GanttLookaheadView.scss';
import {GanttViewCommonProps} from '../types';

import configure, {configureProjectSpecificSettings} from './utils/config';

export const GanttLookaheadView = ({
  projectId,
  noDataElement,
  isActiveFilter,
  queryParams,
  lookaheadColors,
  hasAdminRole,
}: GanttViewCommonProps) => {
  const {updateSearchParams} = useFilterContext();
  const clearLookaheadListener = useRef<() => void>();
  const eventsStore = useGanttEventStore<GanttEventNameUnion>();
  const {t} = useTranslation(['gantt', 'lookahead']);
  const {mixpanel} = useAnalyticsService();

  useUnmount(() => {
    clearLookaheadListener.current?.();
    eventsStore.detachAll();
  });

  function registerInlineEditorsListeners(gantt: GanttStatic) {
    eventsStore.setEventProvider(gantt);
    eventsStore.attach('toolbarAction', (action) => {
      if (action === 'focusToday') {
        const fromDate = getFocusTodayDate();
        if (gantt.name === GanttNames.lookahead && queryParams.schedEndFirst > fromDate) {
          updateSearchParams({...queryParams, schedEndFirst: new Date()});
        }
      }
    });
  }

  return (
    <GanttComponent
      name={GanttNames.lookahead}
      viewMode={TasksViewMode.lookahead}
      load={loadActivityIds}
      projectId={projectId}
      noDataElement={noDataElement}
      isActiveFilter={isActiveFilter}
      queryParams={queryParams}
      lookaheadColors={lookaheadColors}
      createDataProcessor={createDataProcessor}
      contextMenu={TaskContextMenu}
      configure={({gantt, cacheHelper}) => {
        configure({
          gantt,
          t,
          projectId,
          cacheHelper,
          hasAdminRole,
        });
        gantt.config.placeholder_task = hasAdminRole;
      }}
      afterProjectChange={({gantt}) => {
        configureProjectSpecificSettings(gantt, t, projectId);
      }}
      dataCy="lookahead-container"
      afterInit={({gantt}) => {
        clearLookaheadListener.current = addLookaheadTimelineLayer(gantt, mixpanel, t);
        registerInlineEditorsListeners(gantt);
      }}
      getPanelActivity={({taskId, onClose, updateActivityDetailsTask, gantt}) => (
        <SidebarPanel
          sidebarType={SidebarType.activity}
          taskId={taskId}
          onClose={onClose}
          updateActivityDetailsTask={updateActivityDetailsTask}
          gantt={gantt}
        />
      )}
    />
  );
};
