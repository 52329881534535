import {ComponentProps, FC} from 'react';
import {useTranslation} from 'react-i18next';
import {components} from 'react-select';

import Select from 'shared/components/CoreForm/Select/Select';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import WorkerStatus from 'shared/components/WorkerStatus';
import {getCompanyWorkersStatuses} from 'shared/constants/statuses';

import {defaultReactSelectStyles} from '../styles';

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <WorkerStatus value={props.data.value} label={props.data.label} />
    </components.SingleValue>
  );
};

const WorkerStatusSelect: FC<ComponentProps<typeof Select>> = ({value, ...props}) => {
  const {t} = useTranslation('common');
  return (
    <Select
      isClearable
      className="react-select"
      classNamePrefix="react-select"
      options={getCompanyWorkersStatuses(t)}
      styles={defaultReactSelectStyles}
      isSearchable={false}
      components={{SingleValue}}
      formatOptionLabel={({label, value}: CoreOptionType, {context}) =>
        context === 'menu' ? <WorkerStatus value={value} label={label} /> : <>{label}</>
      }
      value={value}
      {...props}
    />
  );
};

export default WorkerStatusSelect;
