import {
  ReactElement,
  cloneElement,
  useCallback,
  ClipboardEventHandler,
  isValidElement,
  KeyboardEventHandler,
} from 'react';

export function Trim({
  children,
}: {
  children: ReactElement<{onPaste: ClipboardEventHandler; onKeyDown: KeyboardEventHandler}>;
}) {
  const onPasteHandler: ClipboardEventHandler = useCallback((e) => {
    const data = e.clipboardData.getData('text');
    if (data && (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement)) {
      const inputEvent = new Event('input', {bubbles: true, cancelable: true});
      e.target.setRangeText(data.trim());
      // manually dispatch build-in event to trigger formik validation or other event-handlers
      e.target.dispatchEvent(inputEvent);
      e.preventDefault();
    }
  }, []);
  const onKeyDownHandler: KeyboardEventHandler = useCallback((e) => {
    if (e.code === 'Space') e.preventDefault();
  }, []);

  return isValidElement(children) && cloneElement(children, {onPaste: onPasteHandler, onKeyDown: onKeyDownHandler});
}
