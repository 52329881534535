import cn from 'classnames';
import {MouseEventHandler} from 'react';

import s from './CtrlButton.module.scss';

type CtrlButtonProps = {
  className?: string;
  text?: string;
  textView?: 'uppercase';
  view?: 'fill' | 'stroke';
  color?: 'second';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit';
};

const CtrlButton = ({
  className,
  text = 'Button',
  textView,
  view = 'fill',
  color,
  onClick,
  type = 'button',
}: CtrlButtonProps) => {
  return (
    <button
      type={type}
      className={cn(
        s.ctrlButton,
        view && s[`ctrlButton_view_${view}`],
        color && s[`ctrlButton_color_${color}`],
        textView && s[`ctrlButton_textView_${textView}`],
        className,
      )}
      onClick={onClick}
    >
      <span className={s.ctrlButton__text}>{text}</span>
    </button>
  );
};

export default CtrlButton;
