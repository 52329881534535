import cn from 'classnames';
import {
  Children,
  createContext,
  isValidElement,
  ReactElement,
  ReactNode,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';

import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import Dropdown, {AllowedViewPortPosition} from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import {IconsMap} from 'shared/constants/icons';
import {throttle} from 'shared/helpers/throttle';
import ResizeObserver from 'shared/utils/resizeObserver';

import styles from './Toolbar.module.scss';
import {ToolbarItem} from './ToolbarItem';
import ToolbarMoreActions from './ToolbarMoreActions';
import {ToolbarSection, ToolbarSectionProps} from './ToolbarSection';

interface ToolbarContextType {
  hiddenSeparatorIndices: Set<number>;
}

interface ToolbarProps {
  children: ReactNode;
  className?: string;
  onStaticItemsWrappedChange?: (isWrapped: boolean) => void;
  staticItems?: ReactNode;
  readonly?: boolean;
  viewportPosition?: AllowedViewPortPosition;
  useItemWidth?: boolean;
}

export const ToolbarContext = createContext<ToolbarContextType | undefined>(undefined);

export const Toolbar = ({
  children,
  className,
  onStaticItemsWrappedChange,
  staticItems,
  readonly,
  viewportPosition,
  useItemWidth,
}: ToolbarProps) => {
  const {t} = useTranslation('gantt');
  const toolbarRef = useRef<HTMLDivElement>(null);
  const staticItemsRef = useRef<HTMLDivElement>(null);

  const [toolbarState, setToolbarState] = useState({
    visibleItems: children,
    overflowItems: [],
    hiddenSeparatorIndices: new Set<number>(),
    hasStaticItemsWrapped: false,
    toolbarWidth: 0,
  });

  const childrenArray = useMemo(() => Children.toArray(children), [children]);

  const calculateVisibleAndOverflowItems = useCallback(() => {
    if (!toolbarRef.current) return null;

    const toolbar = toolbarRef.current;
    const toolbarWidth = toolbar.offsetWidth;
    const safeBreakpointThreshhold = 100;
    let totalWidth = 0;
    const visibleItemsList: ReactNode[] = [];
    const overflowItemsList: ReactElement<ToolbarSectionProps>[] = [];
    const separatorIndices = new Set<number>();
    const sectionWidths = Array.from(toolbar.children).map((child: Element) => (child as HTMLElement).offsetWidth);

    childrenArray.forEach((section, index) => {
      if (!isValidElement(section)) return;

      const isLastItem = index === childrenArray.length - 1;
      const sectionWidth = sectionWidths[index];
      totalWidth += useItemWidth ? sectionWidth + 10 : safeBreakpointThreshhold;

      if (totalWidth > toolbarWidth) {
        overflowItemsList.push(section as ReactElement<ToolbarSectionProps>);
        if (!isLastItem) separatorIndices.add(index);
      } else {
        visibleItemsList.push(section);
      }
    });

    if (staticItems) {
      visibleItemsList.push(
        <ToolbarSection
          className={cn(styles['static-items-section'], {[styles.has_wrapped]: toolbarState.hasStaticItemsWrapped})}
          contentClassName={styles['static-items-content']}
          key="staticItems"
          ref={staticItemsRef}
        >
          {staticItems}
        </ToolbarSection>,
      );
    }

    return {
      visibleItems: visibleItemsList,
      overflowItems: overflowItemsList,
      hiddenSeparatorIndices: separatorIndices,
      toolbarWidth,
    };
  }, [childrenArray, staticItems, useItemWidth, toolbarState.hasStaticItemsWrapped]);

  const throttleTimeoutRef = useRef<number | null>(null);

  const updateToolbarState = useCallback(() => {
    const newState = calculateVisibleAndOverflowItems();
    if (newState) {
      setToolbarState((prevState) => ({
        ...prevState,
        ...newState,
      }));
    }
  }, [calculateVisibleAndOverflowItems]);

  useLayoutEffect(() => {
    const throttledUpdate = throttle(() => {
      throttleTimeoutRef.current = window.setTimeout(updateToolbarState, 100);
    }, 100);

    const resizeObserver = new ResizeObserver(throttledUpdate);
    const currentToolbarRef = toolbarRef.current;
    if (currentToolbarRef) {
      resizeObserver.observe(currentToolbarRef);
    }

    throttledUpdate();

    return () => {
      if (currentToolbarRef) {
        resizeObserver.unobserve(currentToolbarRef);
      }
      if (throttleTimeoutRef.current !== null) {
        clearTimeout(throttleTimeoutRef.current);
      }
    };
  }, [updateToolbarState]);

  useLayoutEffect(() => {
    if (staticItemsRef.current && toolbarRef.current) {
      const toolbarTopMargin = parseInt(window.getComputedStyle(toolbarRef.current).paddingTop, 10);
      const staticItemRect = staticItemsRef.current.getBoundingClientRect().top - toolbarTopMargin;
      const toolbarRect = toolbarRef.current.getBoundingClientRect().top;
      const isWrapped = staticItemRect > toolbarRect;
      if (isWrapped !== toolbarState.hasStaticItemsWrapped) {
        setToolbarState((prevState) => ({
          ...prevState,
          hasStaticItemsWrapped: isWrapped,
        }));
      }
    }
  }, [toolbarState.visibleItems, toolbarState.hasStaticItemsWrapped]);

  useLayoutEffect(() => {
    if (onStaticItemsWrappedChange) {
      onStaticItemsWrappedChange(toolbarState.hasStaticItemsWrapped);
    }
  }, [toolbarState.hasStaticItemsWrapped, onStaticItemsWrappedChange]);

  const contextValue = useMemo(
    () => ({
      hiddenSeparatorIndices: toolbarState.hiddenSeparatorIndices,
    }),
    [toolbarState.hiddenSeparatorIndices],
  );

  return (
    <ToolbarContext.Provider value={contextValue}>
      {readonly ? (
        <div className={styles['toolbar__read_only-container']}>
          <div className={styles['toolbar__read_only-chip']}>{t('contextMenu.read_only')}</div>
        </div>
      ) : null}
      <div className={cn(styles.toolbar, className)} ref={toolbarRef}>
        {!!toolbarState.overflowItems.length ? (
          <Dropdown
            className={styles.dropdown}
            toggleElement={
              <CtrlButton color="action" size="xs" icon={IconsMap.more_vertical} iconOnly={true}>
                More
              </CtrlButton>
            }
            viewportPosition={viewportPosition}
          >
            {toolbarState.overflowItems}
          </Dropdown>
        ) : null}
        {toolbarState.visibleItems}
      </div>
    </ToolbarContext.Provider>
  );
};

Toolbar.Section = ToolbarSection;
Toolbar.Item = ToolbarItem;
Toolbar.MoreActions = ToolbarMoreActions;
