import {FC} from 'react';
import './styles.scss';

type ProgressBarProps = {
  progress: number;
  showProgressComplete?: boolean;
};

const ProgressBar: FC<ProgressBarProps> = ({progress, showProgressComplete}) => {
  const currentProgress = `${progress || 0}%`;
  return (
    <>
      <div className="progressbar">
        <span className="progressbar__container">
          <span className="progressbar__line" style={{width: currentProgress}} />
        </span>
      </div>
      {showProgressComplete ? <span className="progressbar__progress">{currentProgress}</span> : null}
    </>
  );
};
export default ProgressBar;
