import {OptionsType} from 'react-select';

import {Option, OptionType} from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkWatcher/BulkWatcher';
import {filterDuplicatedProjectWorkers} from 'shared/helpers/worker';
import {LookaheadColors} from 'shared/hooks/useResponsibleOrgColors';
import {CompanyOrgs} from 'shared/models/company';
import {CompanyWorker} from 'shared/models/worker';

export const getWorkersWithoutSubcontractors = (selected: OptionsType<Option>, workers: CompanyWorker[]) => {
  return selected.reduce((acc, cur) => {
    if (cur.type === OptionType.worker) {
      const fullWorkerModel = workers.find((w) => w.workerId === cur.id);
      fullWorkerModel && acc.push(fullWorkerModel);
      return acc;
    }
    return acc;
  }, [] as CompanyWorker[]);
};

export const getWorkersOfSubcontractors = (subcontractorIds: string[], workers: CompanyWorker[]) => {
  const workersOfSubcontractors = subcontractorIds.reduce((acc, cur) => {
    const workersOfSubcontractor = workers.filter((w) => w.orgMappingIds.includes(cur));
    return acc.concat(workersOfSubcontractor);
  }, [] as CompanyWorker[]);
  return filterDuplicatedProjectWorkers(workersOfSubcontractors);
};

export const deduplicationRemainingOptions = (subcontractorIds: string[], options: Option[]) => {
  if (!subcontractorIds.length) return options;
  return options.filter((option) => {
    if (option.type === OptionType.worker) {
      return !subcontractorIds.some((s) => option.orgMappingIds.includes(s));
    }
    return option;
  });
};

export const prepareSubcontractors = (subcontractors: CompanyOrgs[], lookaheadColors: LookaheadColors) => {
  if (subcontractors?.length) {
    return subcontractors.map(
      (sub) =>
        ({
          id: sub.id,
          type: OptionType.subcontractor,
          label: sub.group.name,
          value: sub.group.name,
          abbrev: sub.abbrev,
          lookaheadColor: lookaheadColors[sub.lookaheadColor].fill,
          nbWorkers: sub.nbWorkers,
        } as Option),
    );
  }
  return [];
};

export const prepareWorkers = (workers: CompanyWorker[]) => {
  if (workers?.length) {
    return workers.map(
      (worker) =>
        ({
          id: worker.workerId,
          type: OptionType.worker,
          label: worker.workerFull?.fullName,
          orgMappingIds: worker.orgMappingIds,
          value: worker.workerFull?.fullName,
          profilePicUrl: worker.workerFull?.profilePicUrl,
          trade: worker.workerFull?.trade,
        } as Option),
    );
  }
  return [];
};
