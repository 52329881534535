import {GanttStatic} from 'dhtmlx-gantt';
import {useRef} from 'react';

import {GanttTask} from '../../types';

type GanttTaskKeyName = keyof GanttTask;
export function useInlineSelectController(gantt: GanttStatic) {
  const prevEventIsKeydown = useRef(false);
  const mixpanelEvents = gantt.mixpanel.events.gantt.inlineEdit;

  const hideEditor = () => {
    if (gantt.ext.inlineEditors.isVisible()) {
      gantt.ext.inlineEditors.hide();
    }
  };

  const onKeyDown = (e: KeyboardEvent) => {
    prevEventIsKeydown.current = true;
    if (e.key === 'Escape') {
      hideEditor();
    }
  };

  const mixpanelTracking = (name: string) => {
    gantt.mixpanel.track(mixpanelEvents, {viewMode: gantt.name, column: name});
  };

  const updateValue = (task: GanttTask, name: GanttTaskKeyName, value: string | number) => {
    const _task = Object.assign(task, {});
    _task.lastChangedFields[name] = {newValue: value, oldValue: _task[name]};
    gantt.updateTask(task.id, {..._task, [name]: value});
    mixpanelTracking(name);
    focusOrHide();
  };

  const onKeyUp = () => setTimeout(() => (prevEventIsKeydown.current = false), 100);

  const onClose = () => {
    if (!prevEventIsKeydown.current) {
      hideEditor();
    }
  };

  const focusOrHide = () => {
    if (prevEventIsKeydown.current) {
      gantt.ext.inlineEditors.editNextCell(true);
    } else {
      hideEditor();
    }
  };

  return {
    hideEditor,
    onKeyDown,
    updateValue,
    onKeyUp,
    onClose,
    focusOrHide,
    prevEventIsKeydown,
  };
}
