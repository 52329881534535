import {TFunction} from 'i18next';
import {array, mixed, object, string, ValidationError} from 'yup';

import {SUPPORTED_FORMATS, FILE_SIZE_LIMIT} from 'shared/components/ProgressReport/utils/validationSchema';

export const commentFormValidationSchema = (t: TFunction) => {
  return object({
    comment: string().nullable(),
    images: array()
      .of(
        mixed().test(
          'fileSize',
          'File size is too large', // Default error message
          (file, context) => {
            if (
              file.size > FILE_SIZE_LIMIT ||
              !SUPPORTED_FORMATS.includes(
                // rare case since we only "accept" filetypes listed in SUPPORTED_FORMATS on the <input />
                file.type,
              )
            ) {
              // Throw a validation error with a custom message
              return context.createError({
                message: file.name,
              });
            }
            return true; // The file is valid
          },
        ),
      )
      .nullable(),
  }).test('comments:omment-or-files-required', 'Comment or files are required', (values) => {
    const commentIsNotEmpty = values.comment && values.comment.trim() !== '';
    const filesAreProvided = values.images && values.images.length > 0;
    if (!commentIsNotEmpty && !filesAreProvided) {
      // If neither comment nor files are provided, throw a validation error
      return new ValidationError(
        t('comments:activity_panel.comment_form.validation.comment_or_image_required'),
        undefined,
        'comment-or-files-required',
      );
    }
    return true; // The form is valid
  });
};
