import {useEffect, useRef} from 'react';

export function useEffectOnce(fn: () => void, deps: ReadonlyArray<unknown> = [], predicate = true) {
  const called = useRef(false);
  useEffect(() => {
    if (!called.current && predicate) {
      called.current = true;
      fn();
    }
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
