import * as Yup from 'yup';
import {object, string} from 'yup';

const IMPORT_SIZE = 153600 * 1024;
const IMPORT_SUPPORTED_EXTENSIONS = ['csv', 'mpp', 'xer'];

export const DEFAULT_REQUIRED_MESSAGE = 'This field is required';

export const ImportFileSchema = Yup.object().shape({
  importFile: Yup.mixed()
    .nullable(true)
    .test(
      'fileSize',
      'File size is larger than 150Mb. If you need any assistance, please contact us at C4@bycore.com.',
      function (value) {
        if (!!value) {
          return value.size <= IMPORT_SIZE;
        }
        return true;
      },
    )
    .test(
      'fileFormat',
      'Please import using file types: .csv or .mpp or .xer. If you need any assistance, please contact us at C4@bycore.com.',
      function (value) {
        if (!!value) {
          const extension = value.name.slice(((value.name.lastIndexOf('.') - 1) >>> 0) + 2);
          return IMPORT_SUPPORTED_EXTENSIONS.includes(extension ? extension.toLowerCase() : '');
        }
        return true;
      },
    ),
});

export const ContactUsSchema = Yup.object().shape({
  emailAddress: Yup.string()
    .required('Email address is required!')
    .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, {
      message: 'Incorrect email address',
      excludeEmptyString: true,
    }),
  name: Yup.string()
    .min(1, 'Too short Contact Name!')
    .max(200, 'Too long Contact Name!')
    .required('Contact Name is required!')
    .test('onlySpaces', 'Contact Name is required field', (value) =>
      value?.replaceAll(' ', '').length === 0 ? false : true,
    ),
  message: Yup.string()
    .min(3, 'Too short Message!')
    .max(1000, 'Too long Message!')
    .required('Message is required!')
    .test('onlySpaces', 'Message is required field', (value) =>
      value?.replaceAll(' ', '').length === 0 ? false : true,
    ),
  companyName: Yup.string()
    .min(3, 'Too short Company Name')
    .max(100, 'Too long Company Name')
    .required('Company Name is required!')
    .test('onlySpaces', 'Company Name is required field', (value) =>
      value?.replaceAll(' ', '').length === 0 ? false : true,
    ),
  mobileNumber: Yup.string().max(15, 'Too long Phone Number').required('Phone Number is required!'),
});

const urlRegex =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export const EditCompanyProfileSchema = object({
  companyName: string()
    .min(3, 'Minimum 3 characters')
    .max(50, 'Maximum 50 characters')
    .required('This is required field'),
  businessUrl: string().matches(urlRegex, 'This is not a valid URL').nullable(),
  companyAddress: string().nullable(),
  pitchText: string().min(3, 'Minimum 3 characters').max(200, 'Maximum 200 characters').nullable(),
});

export const CreateCompanyProfileSchema = EditCompanyProfileSchema.concat(
  object().shape(
    {
      companySize: Yup.string().required('Number of Workers is required field.'),
      companyCountry: Yup.string()
        .required('Company Country is required field.')
        .when('companyState', {
          is: (companyState) => !!companyState,
          then: Yup.string().required('Company Country is required field.'),
          otherwise: Yup.string(),
        })
        .when('companyCity', {
          is: (companyCity) => !!companyCity,
          then: Yup.string().required('Company Country is required field.'),
          otherwise: Yup.string(),
        }),
      companyState: Yup.string()
        .when('companyCountry', {
          is: (companyCountry) => !!companyCountry,
          then: Yup.string().required('Company State is required field.'),
          otherwise: Yup.string(),
        })
        .when('companyCity', {
          is: (companyCity) => !!companyCity,
          then: Yup.string().required('Company State is required field.'),
          otherwise: Yup.string(),
        }),
      companyCity: Yup.string()
        .when('companyState', {
          is: (companyState) => !!companyState,
          then: Yup.string().required('Company City is required field.'),
          otherwise: Yup.string(),
        })
        .when('companyCountry', {
          is: (companyCountry) => !!companyCountry,
          then: Yup.string().required('Company City is required field.'),
          otherwise: Yup.string(),
        }),
    },
    [
      ['companyCountry', 'companyState'],
      ['companyCountry', 'companyCity'],
      ['companyState', 'companyCity'],
    ],
  ),
);
