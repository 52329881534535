import {cloneElement, FC, isValidElement} from 'react';

import NewConfirmation from 'shared/components/Confirmation/NewConfirmation';

import Confirmation from '../Confirmation';
import ConfirmationCleanLayout from '../ConfirmationCleanLayout';

import {ConfirmConfig} from './state';
import {useConfirm} from './useConfirm';

const ConfirmationWrapper: FC<ConfirmConfig> = ({component, useNewLayout, ...props}) => {
  const {accept, cancel, resolveValue} = useConfirm();

  if (component && isValidElement(component)) {
    return (
      <ConfirmationCleanLayout title={props.title} onClose={cancel}>
        {cloneElement(component, {resolve: resolveValue, ...component.props})}
      </ConfirmationCleanLayout>
    );
  }

  const Component = useNewLayout ? NewConfirmation : Confirmation;

  return <Component {...props} onAccept={accept} onReject={cancel} />;
};
export default ConfirmationWrapper;
