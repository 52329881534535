import {useRouteMatch} from 'react-router';

import {useRootSelector} from 'store';

export function useTask(taskId?: string) {
  const match = useRouteMatch<{id: string}>();
  const id = match?.params?.id || taskId;
  const task = useRootSelector((state) => (state.tasks.currentTask?.id === id ? state.tasks.currentTask : null));

  return {task, id, loading: !task && id !== 'new'};
}
