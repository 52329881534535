import {GanttStatic} from 'dhtmlx-gantt';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';

import Tooltip from 'shared/components/Tooltip';

type TodayButtonProps = {
  gantt: GanttStatic;
};

const TodayButton = ({gantt}: TodayButtonProps) => {
  const onClick = () => {
    gantt.callEvent('toolbarAction', ['focusToday']);
  };

  return (
    <Tooltip text="Today" placement="bottom-end">
      <CtrlButton color="action" icon="calendar-month" iconOnly iconSize={20} onClick={onClick} />
    </Tooltip>
  );
};

export default TodayButton;
