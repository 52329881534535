import {useQuery} from 'react-query';

import TasksApi from 'api/tasks';
import Autocomplete, {Props, SearchResultOptionsType} from 'shared/components/CoreNewUI/Autocomplete/Autocomplete';
import {SortOrder} from 'shared/constants/common';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {addStatusToFilters} from 'shared/helpers/tasks';
import {TaskStates, TaskProjection} from 'shared/models/task/const';
import {TaskModelRawDTO} from 'shared/models/task/task';

interface WBSAutocompleteProps<IsMulti extends boolean, Values = IsMulti extends true ? string[] : string>
  extends Omit<Props<IsMulti, Values>, 'items'> {
  projectId: string;
  taskState: TaskStates;
}
export function WBSAutocomplete<IsMulti extends boolean>({
  projectId,
  taskState,
  ...props
}: WBSAutocompleteProps<IsMulti>) {
  const {data} = useQuery(
    QUERY_CACHE_KEYS.rootLevelWbs(projectId, taskState),
    () => {
      return TasksApi.getProjectTasks({
        projection: TaskProjection.task,
        limit: 500,
        sortField: 'outline_sort_key',
        sortOrder: SortOrder.ASC,
        params: addStatusToFilters(taskState, {
          objectTypeList: ['summary'],
          projectId: projectId,
          outlineSortKeyRootOnly: true,
        }),
      });
    },
    {
      select: (data) =>
        (data.data as TaskModelRawDTO[]).map(
          (task) =>
            ({
              value: task.outline_sort_key?.split('.')?.[0],
              label: task.name,
            } as SearchResultOptionsType),
        ),
    },
  );

  return <Autocomplete {...props} items={data} />;
}
