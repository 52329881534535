import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect} from 'react';

import {useResponsibleOrgColors} from 'shared/hooks/useResponsibleOrgColors';

export function useGanttSubcontractorColors(gantt: GanttStatic) {
  const {lookaheadColors} = useResponsibleOrgColors();
  useEffect(() => {
    if (lookaheadColors) {
      gantt.subcontractorColors = lookaheadColors;
    }
  }, [lookaheadColors]);
}
