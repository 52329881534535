import classnames from 'classnames';
import {CSSProperties, FC} from 'react';

import {useTabsContext} from './TabsContext';
import s from './TabsHeader.module.scss';

type TabsHeaderProps = {
  className?: string;
  style?: CSSProperties;
};
const TabsHeader: FC<TabsHeaderProps> = ({className = '', style = {}}) => {
  const {tabs, setActiveTab, activeTab} = useTabsContext();
  return (
    <div className={`ctrl-tabs ${className} ${s.ctrlTabs}`} style={style}>
      <ul className="ctrl-tabs__list">
        {[
          tabs.map((tab, index) => (
            <li className="ctrl-tabs__item" key={index}>
              <button
                className={classnames('ctrl-tabs__button', {
                  'is-active': activeTab === index,
                })}
                type="button"
                onClick={() => setActiveTab(index)}
                tabIndex={index}
                disabled={tab.disabled}
                onFocus={() => setActiveTab(index)}
              >
                {tab.title}
              </button>
            </li>
          )),
        ]}
      </ul>
    </div>
  );
};
export default TabsHeader;
