import {FC} from 'react';

import Icon from 'shared/components/Icon';

import s from './Suggestions.module.scss';

type Props = {
  orgs: string[];
  onClick: (org: string) => void;
  onClose: () => void;
};

const Suggestions: FC<Props> = ({orgs, onClick, onClose}: Props) => {
  return (
    <div className={s.suggestion}>
      <div className={s.suggestion__header}>
        <span className={s.suggestion__text}>Organization suggestions</span>
        <Icon className={s.suggestion__close} name="clear" size={16} colorFill onClick={onClose} />
      </div>
      <div className={s.suggestion__orgWrap}>
        {orgs.map((org) => (
          <div key={org} className={s.suggestion__org} onClick={() => onClick(org)}>
            {org}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Suggestions;
