import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import Button from 'shared/components/Button';
import Popup from 'shared/components/Popup';
import Tooltip from 'shared/components/Tooltip';
import {TasksViewMode} from 'shared/constants/common';
import {useMixpanel} from 'shared/hooks/analytics/useMixpanel';

import TasksImportFileSelector from '../../components/TasksImportSource/TasksImportFileSelector';
import {useTasksImportContext} from '../../TasksImportContext/TasksImportContext';
import {TaskImportStep} from '../../utils/constants';

import TasksImportProcoreSelector from './TasksImportProcoreSelector';

type Props = {
  mixpanelEvents: {[name: string]: string};
  viewMode: TasksViewMode;
};

const TasksImportSource: FC<Props> = ({mixpanelEvents, viewMode}) => {
  const [{sourceType}, actions] = useTasksImportContext();
  const mixpanel = useMixpanel({});
  const {t} = useTranslation('import');

  function onNext() {
    if (!sourceType) return;
    mixpanel.trackWithAction(() => actions.setCurrentStep(TaskImportStep.Mapping), mixpanelEvents.nextBtn);
  }

  return (
    <Popup.Body>
      <div className="compare-grid">
        <div className="compare-grid__content">
          <form className="form-compare">
            <TasksImportFileSelector viewMode={viewMode} />
            <>
              <span className="form-compare__divider">{t('source.divider', 'or')}</span>
              <TasksImportProcoreSelector />
            </>
            <Popup.Footer>
              <Tooltip text="Please choose the source to import from" type="error" isHide={!!sourceType}>
                <Button data-cy="btnImportNext" className="popup__button" type="button" onClick={onNext}>
                  {t('source.buttons.next', 'Next')}
                </Button>
              </Tooltip>
            </Popup.Footer>
          </form>
        </div>
      </div>
    </Popup.Body>
  );
};

export default TasksImportSource;
