import cn from 'classnames';
import {ButtonHTMLAttributes, CSSProperties, forwardRef} from 'react';

import Icon from 'shared/components/Icon';

import s from './CtrlButton.module.scss';

export type ButtonColor = 'default' | 'second' | 'action' | 'tertiary' | 'actionWarning' | 'clear';
export type IconPosition = 'default' | 'second' | 'action' | 'tertiary' | 'end' | 'after';

export interface CtrlButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonColor;
  icon?: string;
  iconDropdown?: string;
  iconPosition?: IconPosition;
  iconOnly?: boolean;
  positionInGroup?: 'first' | 'center' | 'last';
  view?: 'selector' | 'description' | 'link';
  size?: 'xs' | 's' | 'l';
  circleView?: boolean;
  selected?: boolean;
  active?: boolean;
  activeDot?: boolean;
  dataCy?: string;
  type?: 'submit' | 'reset' | 'button';
  shadow?: boolean;
  closeIconStyle?: CSSProperties;
  iconSize?: number;
}

const CtrlButton = forwardRef<HTMLButtonElement, CtrlButtonProps>(
  (
    {
      className = '',
      color = 'default',
      icon,
      iconDropdown,
      iconPosition,
      iconOnly,
      positionInGroup,
      view,
      size,
      onClick,
      selected = false,
      active = false,
      activeDot = false,
      circleView = false,
      shadow,
      children,
      dataCy,
      type = 'button',
      closeIconStyle,
      iconSize,
      ...props
    },
    ref,
  ) => {
    const iconElement = icon && (
      <div className={cn(s.ctrlBtn__iconWrapper, activeDot && s.ctrlBtn__iconWrapper_activeDot)}>
        <Icon
          name={icon}
          className={cn(s.ctrlBtn__icon, !iconSize && s.ctrlBtn__iconSize)}
          colorFill
          size={iconSize}
          style={closeIconStyle}
        />
      </div>
    );

    return (
      <button
        className={cn(
          s.ctrlBtn,
          s[`ctrlBtn_color_${color}`],
          iconOnly && s.ctrlBtn_iconOnly,
          iconPosition && s[`ctrlBtn_iconPosition_${iconPosition}`],
          positionInGroup && s[`ctrlBtn_positionInGroup_${positionInGroup}`],
          view && s[`ctrlBtn_view_${view}`],
          size && s[`ctrlBtn_size_${size}`],
          circleView && s[`ctrlBtn_circleView`],
          selected && s[`ctrlBtn_isSelected`],
          active && s[`ctrlBtn_active`],
          !icon && activeDot && s.ctrlBtn_activeDot,
          size && s[`ctrlBtn_size_${size}`],
          shadow && s[`ctrlBtn_shadow`],
          className,
        )}
        type={type}
        data-cy={dataCy}
        onClick={onClick}
        {...props}
        ref={ref}
      >
        {/* Render icon before text if not 'after' position */}
        {icon && iconPosition !== 'after' && iconElement}

        <span className={s.ctrlBtn__text}>{children}</span>

        {/* Render icon after text if 'after' position */}
        {icon && iconPosition === 'after' && iconElement}

        {iconDropdown && (
          <Icon
            name={iconDropdown}
            className={cn(s.ctrlBtn__icon, !iconSize && s.ctrlBtn__iconDropdownSize)}
            colorFill
            size={iconSize}
          />
        )}
      </button>
    );
  },
);

export default CtrlButton;
CtrlButton.displayName = 'CtrlButton';
