// eslint-disable @typescript-eslint/naming-convention
import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router';
import {useReactToPrint} from 'react-to-print';

import IconSpriteSVG from 'assets/images/svg-sprite.svg';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {getTaskOpenIssuesIds} from 'modules/Tasks/utils/functions';
import {generateTaskLayer} from 'modules/Tasks/utils/generateTaskLayer';
import {TasksViewMode} from 'shared/constants/common';
import {toTitleCase} from 'shared/helpers/common';
import {debounce} from 'shared/helpers/debounce';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {TaskObjectType} from 'shared/models/task/const';

import {isBaseLineMode} from '../../../../Views/Gantt/utils/baselineHandlers';
import {GANTT_COLUMNS_NAMES, GanttZoomLevels} from '../../utils/constants';

import {DATE_COLUMNS_NAMES, DISABLED_COLUMN_TEMPLATES, PRINT_CSS_PATHS} from './constants';
import {
  excludeCollapsedTasks,
  generateMilestoneForGanttView,
  generateTaskLineForGanttView,
  generateWeekendLine,
  getTasks,
  getTimelineBoundaryDates,
  getTimescaleBotLevelHeaders,
  getTimescaleTopLevelHeaders,
  getWBSColor,
} from './functions';

type ColumnsConfigType = {
  name: string;
  label: string;
  width: number;
};

export function usePrint(gantt: GanttStatic, dailiesRef?: React.RefObject<HTMLElement>) {
  const handlePrint = useReactToPrint({
    content: () => dailiesRef?.current,
  });

  const {projectId} = useParams<{projectId: string}>();
  const project = useProjectSelector(projectId);
  const [dataRendered, setDataRendered] = useState(false);
  const [ganttRendered, setGanttRendered] = useState(false);
  const opened = useRef<Window>();
  const {viewMode} = useFilterContext();
  const columns = useRef<{
    rendered: ColumnsConfigType[];
    hidden: ColumnsConfigType[];
    gridShift: number;
  }>({
    rendered: [],
    hidden: [],
    gridShift: 0,
  });

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [dataRendered, viewMode, project]);

  useEffect(() => {
    const events = [];
    const dataRendered = debounce(() => setDataRendered(true), 1000);
    const ganttRendered = debounce(() => setGanttRendered(true), 1000);
    events.push(gantt?.attachEvent('onDataRender', dataRendered, undefined));
    events.push(gantt?.attachEvent('onGanttRender', ganttRendered, undefined));

    return () => {
      events.forEach((eventId) => gantt.detachEvent(eventId));
    };
  }, [gantt]);

  const includeHTML = () => {
    columns.current = getColumnsToPrint();
    opened.current.document.write(`<!DOCTYPE html>` + getHeadSection() + getTableLayout());
  };

  const getWindowTitle = () => {
    const projectName = project?.name || '';
    return `${projectName + ' - '} ${toTitleCase(gantt.name)} Printing Preview`;
  };

  const openPrintWindow = () => {
    if (dailiesRef?.current) {
      handlePrint();
      return;
    }
    if (opened.current && !opened.current.closed) {
      opened.current.close();
    }
    if (dataRendered) {
      opened.current = window.open(
        'about:blank',
        'Lookahead Print',
        `width=${window.screen.width}, height=${window.screen.height}`,
      );
      includeHTML();
      opened.current.onload = () => opened.current.print();
      opened.current.document.close();
    }
  };

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.key === 'p') {
        e.preventDefault();
        openPrintWindow();
      }
    },
    [dataRendered, viewMode, project],
  );

  const getHeadSection = (): string => {
    const title = `<title>${getWindowTitle()}</title>`;
    const cssLinks = PRINT_CSS_PATHS.map(
      (link) => `<link rel="stylesheet" href="${window.location.origin}/${link}?v=${+new Date()}" />`,
    ).join('');
    return title + cssLinks;
  };

  // get columns settings from DOM
  const getColumnsToPrint = () => {
    const frozenColumns = parseInt(gantt.$grid.dataset.frozenColumns ?? '0');
    const frozenWidth = Array.from(document.querySelectorAll('.gantt_grid_head_cell[data-column-name]'))
      .slice(0, frozenColumns)
      .reduce((acc, el) => acc + el.clientWidth, 0);

    const columns = document.querySelectorAll('.gantt_grid_head_cell[data-column-name]');
    const groups = {
      rendered: [] as ColumnsConfigType[],
      gridShift: 0,
      hidden: [] as ColumnsConfigType[], // hidden by scroll
    };

    let total = 0;
    let scrollHandled = false;
    columns.forEach((el) => {
      const colName = el.attributes['data-column-name'].value;
      const col = {name: colName, width: el.clientWidth, label: el.textContent};
      total += el.clientWidth;
      if (![GANTT_COLUMNS_NAMES.customAdd, GANTT_COLUMNS_NAMES.checkbox].includes(colName)) {
        // let width = el.clientWidth;
        if (frozenColumns && !scrollHandled && gantt.$grid.scrollLeft) {
          if (total > gantt.$grid.scrollLeft + frozenWidth) {
            const newWidth = total - frozenWidth - gantt.$grid.scrollLeft;
            scrollHandled = true;
            if (newWidth / el.clientWidth < 0.5) {
              groups.hidden.push(col);
              return (groups.gridShift -= newWidth);
            } else {
              groups.gridShift += el.clientWidth - newWidth;
            }
          } else if (total > frozenWidth) {
            return groups.hidden.push(col);
          }
        }
        groups.rendered.push(col);
      } else if (total < gantt.$grid.clientWidth) {
        groups.gridShift -= col.width;
      }
    });
    return groups;
  };

  // columns & months names
  const getGridHeaders = (): string => {
    const columnsHeaders = columns.current.rendered.reduce((acc, column) => {
      return (acc += `<th class="print-table__cell print-table__cell--th" style="max-width: ${
        column.width
      }px;min-width: ${column.width}px" rowspan="2">${column.label.trim()}</th>`);
    }, '');

    return columnsHeaders;
  };

  const getIssueIcon = (task: GanttTask): string => {
    const openIssues = getTaskOpenIssuesIds(task?.status_issue_task_ids_pairs);
    if (openIssues?.length) {
      return `
        <svg data-issue-icon=true class="icon">
          <title>${task.issue_task_ids.length > 1 ? 'Show Issues' : 'Show Issue'}</title>
          <use xlink:href="${IconSpriteSVG}#clockdelay"} />
        </svg>
      `;
    }
    return '';
  };

  const getRiskIcon = (task: GanttTask): string => {
    return `<span class="risk_flag">${gantt.statusIcon?.getHasFlagsIconHTML(task)}</span>`;
  };

  const getGridRowCellsData = (task: GanttTask) => {
    const ganttColumns = gantt.config.columns;
    const taskData = columns.current.rendered
      .map((column) => {
        const ganttColumn = ganttColumns.find((col) => col.name === column.name);
        const colName = ganttColumn.name;
        let styles = '';
        let content = task[colName] ?? '';
        if (DATE_COLUMNS_NAMES.includes(colName)) {
          const isMilestone = task.object_type === TaskObjectType.milestone;
          const isMilestoneStart =
            isMilestone && task.object_subtype === 'start' && ganttColumn.name === GANTT_COLUMNS_NAMES.startDate;
          const isMilestoneEnd =
            isMilestone && task.object_subtype === 'end' && ganttColumn.name === GANTT_COLUMNS_NAMES.endDate;
          if (!isMilestone || isMilestoneStart || isMilestoneEnd) {
            content = gantt.templates.date_grid(content, task, ganttColumn.name);
          } else {
            content = '';
          }
        } else if (ganttColumn.name === GANTT_COLUMNS_NAMES.comments) {
          content = `<p>${task.comment_count}</p>`;
        } else if (ganttColumn.name === GANTT_COLUMNS_NAMES.icons) {
          content = `
            <div class="task-icons">
              ${getIssueIcon(task)}
              ${getRiskIcon(task)}
            </div>
          `;
        } else if (typeof ganttColumn.template === 'function' && !DISABLED_COLUMN_TEMPLATES.includes(colName)) {
          content = ganttColumn.template(task);
        } else if (ganttColumn.name === GANTT_COLUMNS_NAMES.name) {
          content = `<div class="print-table__name print-table__name--line-multi">
            ${task[ganttColumn.name]}
          </div>`;
          const indent = (gantt.calculateTaskLevel(task) + 1) * 18;
          styles = `padding-left: ${indent}\px; text-align: left; overflow: hidden;`;
        }

        return `<td data-column-name="${colName ?? ''}" class="${getWBSColor(
          task,
        )} print-table__cell print-table__cell--td" style="min-width: ${column.width}px; max-width: ${
          column.width
        }px; ${styles}"><div class="print-table__cell--td-fixed-height">${content ?? ''}</div></td>`;
      })
      .join('');
    return taskData;
  };

  const getGridRowTimeline = (task: GanttTask, colspan: number): string => {
    return `
      <td class="print-table__cell print-table__cell--td print-table__cell--line" colspan="${colspan}">
        <div class="${getWBSColor(task)} print-table__line" style="position: relative; width: ${
      gantt.$task_scale.clientWidth
    }px">
          ${getTimelineContentByView(task)}
        </div>
      </td>`;
  };

  const getGridRows = () => {
    const tasks = getTasks(gantt);
    const {startDate, endDate} = getTimelineBoundaryDates(gantt);
    const timelineLength = dayjs(endDate).diff(startDate, 'days') + 1;
    const tasksToDraw = tasks.filter((task) => excludeCollapsedTasks(gantt, task));
    const gridCells = tasks.reduce(
      (acc, task) => Object.assign(acc, {[task.id]: getGridRowCellsData(task)}),
      {} as Record<string, string>,
    );
    return {
      data: tasksToDraw
        .map((task) => {
          return `
          <tr class="print-table__cell print-table__cell--td print-table__cell-grid">${gridCells[task.id]}</tr>`;
        })
        .join(''),
      timeline: tasksToDraw
        .map(
          (task) =>
            `<tr class="print-table__cell print-table__cell--td" style="height: ${gantt.config.row_height}px">
              ${gridCells[task.id]}
              ${getGridRowTimeline(task, timelineLength)}
            </tr>`,
        )
        .join(''),
    };
  };

  const getTimelineContentByView = (task: GanttTask) => {
    const isWBS = task.object_type === TaskObjectType.summary;
    const ganttZoomLevel =
      gantt.ext?.zoom.getLevels()?.[gantt.ext?.zoom?.getCurrentLevel()]?.name || GanttZoomLevels.DAY;
    const isMilestone = task.object_type === TaskObjectType.milestone;
    if (viewMode === TasksViewMode.gantt) {
      const isBaseline = isBaseLineMode();
      if (isMilestone) {
        return (
          (ganttZoomLevel === GanttZoomLevels.DAY ? generateWeekendLine(gantt) : '') +
          generateMilestoneForGanttView(gantt, task, isBaseline)
        );
      }
      if (isWBS || ganttZoomLevel !== GanttZoomLevels.DAY) {
        return generateTaskLineForGanttView(gantt, task, isBaseline);
      }
      return generateWeekendLine(gantt) + generateTaskLineForGanttView(gantt, task, isBaseline);
    }

    if (viewMode === TasksViewMode.lookahead) {
      if (task.object_type === TaskObjectType.milestone) {
        return generateWeekendLine(gantt) + generateMilestoneForGanttView(gantt, task);
      }
      const boxesWrapper = generateTaskLayer(gantt, task, false) as HTMLDivElement;
      if (boxesWrapper) {
        boxesWrapper.style.top = boxesWrapper.classList.contains('wbs_container') ? boxesWrapper.style.top : '0px';
      }
      if (!isWBS) {
        return generateWeekendLine(gantt) + (boxesWrapper?.outerHTML || '');
      }
      return boxesWrapper?.outerHTML || '';
    }
  };

  const getTableLayout = (): string => {
    // генерить только реально видимые даты?
    // const startDate = gantt.dateFromPos(Math.abs(gantt.$task.scrollLeft));
    // const endDate = gantt.dateFromPos(Math.abs(gantt.$task.scrollLeft) + gantt.$task.clientWidth);

    const ganttZoomLevel =
      gantt.ext?.zoom.getLevels()?.[gantt.ext?.zoom?.getCurrentLevel()]?.name || GanttZoomLevels.DAY;
    const {data, timeline} = getGridRows();
    const dataWidth = columns.current.rendered.reduce((acc, col) => acc + col.width, 0);

    const timelineTopHeaders = getTimescaleTopLevelHeaders(gantt, ganttZoomLevel);
    const timelineBottomHeaders = getTimescaleBotLevelHeaders(gantt, ganttZoomLevel);
    const gridHeaders = getGridHeaders();
    // TODO: optimize
    // for left table we can generate only one week timeline
    const thead = `<thead>
                    <tr>${gridHeaders + timelineTopHeaders}</tr>
                    <tr>${timelineBottomHeaders}</tr>
                  </thead>`;

    return `
      <div class="print-tables">
        <div class="print-tables__part" style="flex: 0 0 ${gantt.$grid.clientWidth + columns.current.gridShift}px">
        <table class="print-table">
        ${thead}
        <tbody>
          ${data}
        </tbody>
        </table>
        </div>
        <div class="print-tables__part" style="flex: 0 0 ${gantt.$task.clientWidth}px">
        <table class="print-table" style="transform: translateX(${gantt.$task.scrollLeft * -1 - dataWidth}px)">
        ${thead}
        <tbody>
          ${timeline}
        </tbody>
        </table></div>
      </div>
    `;
  };

  return {openPrintWindow, isReadyForPrint: dataRendered && ganttRendered};
}
