import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

import box from '../Box/Box.module.scss';
import CtrlButton from '../CtrlButton/CtrlButton';

import s from './EasyToUse.module.scss';
import SvgPoint from './SvgPoint';

const EasyToUse = () => {
  const {t} = useTranslation('landing2');
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.home;

  return (
    <section className={s.easyToUse}>
      <div className={box.box}>
        <h2 className={s.easyToUse__title}>{t('section_four.title', 'Easy To Use')}</h2>
        <div className={s.easyToUse__description}>{t('section_four.description')}</div>
        <div className={s.easyToUse__actions}>
          <CtrlButton
            text={t('links.button', 'Get Started')}
            color="second"
            onClick={() => mixpanel.trackWithAction(() => history.push(routes.getStarted), mixpanelEvents.getStarted)}
          />
        </div>
        <div className={s.easyToUse__scheme__wrap}>
          <div className={s.easyToUse__scheme}>
            <div>
              <div>
                <picture>
                  <source
                    type="image/webp"
                    srcSet={`/images/other/oracle-1@1x.webp, /images/other/oracle-1@2x.webp 2x`}
                  />
                  <source type="image/png" srcSet={`/images/other/oracle-1@1x.png, /images/other/oracle-1@2x.png 2x`} />
                  <img src="/images/other/oracle-1@1x.png" alt="P6 Oracle" className={s.easyToUse__scheme__oracle} />
                </picture>
                <SvgPoint className={s.easyToUse__scheme__oraclePoint} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 2.4"
                  width="160px"
                  height="8.5px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_wave, s.easyToUse__scheme__secondLine)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      className={s.easyToUse__line_1}
                      d="M 1 1 H 45"
                      stroke="#000"
                      strokeWidth="1.2"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 2.4"
                  width="160px"
                  height="8.5px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_bg, s.easyToUse__scheme__secondLine)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 1 1 H 45"
                      stroke="#bbb"
                      strokeWidth="0.7"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
              </div>

              <div>
                <picture>
                  <source
                    type="image/webp"
                    srcSet={`/images/other/procore-1@1x.webp, /images/other/procore-1@2x.webp 2x`}
                  />
                  <source
                    type="image/png"
                    srcSet={`/images/other/procore-1@1x.png, /images/other/procore-1@2x.png 2x`}
                  />
                  <img src="/images/other/procore-1@1x.png" alt="Procore" className={s.easyToUse__scheme__procore} />
                </picture>
                <SvgPoint className={s.easyToUse__scheme__procorePoint} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 46.4"
                  width="160px"
                  height="160px"
                  style={{width: '160px', position: 'absolute', left: '175px', top: '8px'}}
                  className={cn(s.easyToUse__path, s.easyToUse__path_wave)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      className={s.easyToUse__line_2}
                      d="M 1 45 H 25 V 1 H 45"
                      stroke="#000"
                      strokeWidth="1.2"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 46.4"
                  width="160px"
                  height="160px"
                  style={{width: '160px', position: 'absolute', left: '175px', top: '8px'}}
                  className={cn(s.easyToUse__path, s.easyToUse__path_bg)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 1 45 H 25 V 1 H 45"
                      stroke="#bbb"
                      strokeWidth="0.7"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
              </div>

              <div>
                <picture>
                  <source type="image/webp" srcSet={`/images/other/p-1@1x.webp, /images/other/p-1@2x.webp 2x`} />
                  <source type="image/png" srcSet={`/images/other/p-1@1x.png, /images/other/p-1@2x.png 2x`} />
                  <img src="/images/other/p-1@1x.png" alt="MS Project" className={s.easyToUse__scheme__p} />
                </picture>
                <SvgPoint className={s.easyToUse__scheme__fourthLine} />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 86.4"
                  width="160px"
                  height="298px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_wave, s.easyToUse__scheme__firdLine)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      className={s.easyToUse__line_3}
                      d="M 1 85 H 22 Q 25 85 25 83 V 1 H 45"
                      stroke="#000"
                      strokeWidth="1.2"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 86.4"
                  width="160px"
                  height="298px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_bg, s.easyToUse__scheme__firdLine)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 1 85 H 22 Q 25 85 25 83 V 1 H 45"
                      stroke="#bbb"
                      strokeWidth="0.7"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
              </div>

              <div className={s.easyToUse__scheme__dataSync}>
                <div className={s.easyToUse__text}>
                  <span className={s.easyToUse__scheme__dataSync__text}>DATA SYNC</span>
                </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 9.4"
                  width="160px"
                  height="32.5px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_wave, s.easyToUse__scheme__dataSync__topBorder)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 1 8 Q 1 1 7 1 H 39 Q 45 1 45 8"
                      stroke="#ff0000"
                      strokeWidth="1.2"
                      fill="none"
                      className={s.easyToUse__wave_1}
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 9.4"
                  width="160px"
                  height="32.5px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_bg, s.easyToUse__scheme__dataSync__topBorder)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 1 8 Q 1 1 7 1 H 39 Q 45 1 45 8"
                      stroke="#bbb"
                      strokeWidth="0.7"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 9.4"
                  width="160px"
                  height="32.5px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_wave, s.easyToUse__scheme__dataSync__bottomBorder)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      className={s.easyToUse__wave_1}
                      d="M 1 1 Q 1 8 7 8 H 39 Q 45 8 45 1"
                      stroke="#ff0000"
                      strokeWidth="1.2"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 46.4 9.4"
                  width="160px"
                  height="32.5px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_bg, s.easyToUse__scheme__dataSync__bottomBorder)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 1 1 Q 1 8 7 8 H 39 Q 45 8 45 1"
                      stroke="#bbb"
                      strokeWidth="0.7"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
              </div>

              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 65.4 18.4"
                  width="256px"
                  height="72px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_wave, s.easyToUse__scheme__fifthLine)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      className={s.easyToUse__totalLine_1}
                      d="M 1 1 H 47 Q 50 1 50 3 V 15 Q 50 17 53 17 H 64"
                      stroke="#000"
                      strokeWidth="1.2"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 65.4 18.4"
                  width="256px"
                  height="72px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_bg, s.easyToUse__scheme__fifthLine)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 1 1 H 47 Q 50 1 50 3 V 15 Q 50 17 53 17 H 64"
                      stroke="#bbb"
                      strokeWidth="0.7"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
                <SvgPoint className={s.easyToUse__scheme__desktopPoint} />
                <SvgPoint className={cn(s.easyToUse__scheme__desktopPoint, s.easyToUse__scheme__oraclePoint_animate)} />
              </div>

              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 49.4 51.4"
                  width="192px"
                  height="200px"
                  className={cn(s.easyToUse__path, s.easyToUse__path_wave, s.easyToUse__scheme__sixthLine)}
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      className={s.easyToUse__totalLine_2}
                      d="M 1 1 V 48 Q 1 50 4 50 H 48"
                      stroke="#000"
                      strokeWidth="1.2"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
                <svg
                  className={cn(s.easyToUse__path, s.easyToUse__path_bg, s.easyToUse__scheme__sixthLine)}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="-1.2 -0.2 49.4 51.4"
                  width="192px"
                  height="200px"
                >
                  <g transform="translate(0,0)">
                    <path
                      xmlns="http://www.w3.org/2000/svg"
                      d="M 1 1 V 48 Q 1 50 4 50 H 48"
                      stroke="#bbb"
                      strokeWidth="0.7"
                      fill="none"
                      style={{
                        fillOpacity: 1,
                        fillRule: 'nonzero',
                        strokeLinejoin: 'round',
                        strokeMiterlimit: 4,
                        strokeOpacity: 1,
                      }}
                    />
                  </g>
                </svg>
                <SvgPoint className={s.easyToUse__scheme__notebookPoint} />
                <SvgPoint
                  className={cn(s.easyToUse__scheme__notebookPoint, s.easyToUse__scheme__oraclePoint_animate)}
                />
              </div>
              <picture>
                <source
                  type="image/webp"
                  srcSet={`/images/screenshots/screenshot-11@1x.webp, /images/screenshots/screenshot-11@2x.webp 2x`}
                />
                <source
                  type="image/png"
                  srcSet={`/images/screenshots/screenshot-11@1x.png, /images/screenshots/screenshot-11@2x.png 2x`}
                />
                <img
                  src="/images/screenshots/screenshot-11@1x.png"
                  alt="Sync devices"
                  className={s.easyToUse__scheme__devices}
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EasyToUse;
