import classes from './spacer.module.scss';

type SpacerSize = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12' | '13' | '14';

export type SpacerProps = {
  size?: SpacerSize;
};

export const HSpacer = ({size = '2'}: SpacerProps) => {
  const className = `h-spacer-${size}`;
  return <span className={classes[className]} />;
};
