import {useMemo} from 'react';
import {useQuery} from 'react-query';

import WorkersApi from 'api/workers';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {filterDuplicatedCompanyWorkers} from 'shared/helpers/worker';

export function useResponsibleOptions(projectId: string) {
  const {data: workers} = useQuery(
    QUERY_CACHE_KEYS.projectWorkers(projectId),
    () => {
      return WorkersApi.getAllProjectWorkers(projectId, {
        filterParams: {
          blendedStatus: ['active', 'invited'],
        },
        offset: 0,
        limit: 1000,
        sortOrder: 'ASC',
      });
    },
    {
      enabled: !!projectId,
      refetchOnWindowFocus: false,
    },
  );

  return useMemo(() => {
    if (!workers?.length) return [];
    return filterDuplicatedCompanyWorkers(workers)?.map((worker) => ({
      label: worker.workerFull.fullName,
      value: worker.workerId,
    }));
  }, [workers]);
}
