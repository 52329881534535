import cn from 'classnames';
import {Field} from 'formik';
import {FieldProps} from 'formik/dist/Field';
import {cloneElement, CSSProperties, isValidElement, MouseEventHandler, PropsWithChildren, ReactNode} from 'react';

import s from './FormControl.module.scss';

export type CtrlFormProps = {
  onClick?: MouseEventHandler;
  className?: string;
  label?: string;
  placeholder?: string;
  iconName?: string;
  icon?: ReactNode;
  iconPosition?: 'start';
  button?: ReactNode;
  name?: string;
  isError?: boolean;
  isSuccess?: boolean;
  hint?: string;
  errorClassName?: string;
  error?: string;
  showError?: boolean;
  errorWidth?: string;
  height?: 'h36';
  required?: boolean;
  value?: string | string[];
  labelIcon?: ReactNode;
};

const FormControl = ({
  className,
  label,
  icon,
  iconPosition,
  button,
  children,
  name,
  isError,
  isSuccess,
  hint,
  errorClassName,
  error,
  showError = true,
  errorWidth,
  height,
  onClick,
  required,
  value,
  labelIcon,
}: PropsWithChildren<CtrlFormProps>) => {
  const hideErrorText = required && error ? !value || (Array.isArray(value) && !value.length) : false;
  const errorStyles: CSSProperties = errorWidth
    ? {
        width: errorWidth,
        maxWidth: 'unset',
      }
    : null;

  return (
    <div
      onClick={onClick}
      className={cn(
        s.ctrlForm,
        !label && s.ctrlForm_label_isHidden,
        icon && s.ctrlForm_withIcon,
        iconPosition && s[`ctrlForm_icon_${iconPosition}`],
        button && s.ctrlForm_withButton,
        height && s[`ctrlForm_height_${height}`],
        !hint && s.ctrlForm_withoutValidation,
        isError && s.ctrlForm_isError,
        isSuccess && s.ctrlForm_isSuccess,
        className,
      )}
    >
      <div className={s.ctrlForm__header}>
        <label className={s.ctrlForm__label}>{label}</label>
        {required && <span className={s.ctrlForm__labelStar}>*</span>}
        {isValidElement(labelIcon)
          ? cloneElement(labelIcon, {...labelIcon.props, className: cn(labelIcon.props.className)})
          : null}
      </div>
      <div className={cn(s.ctrlForm__body, {[`${s.ctrlForm__body_error}`]: hideErrorText})}>
        {isValidElement(children)
          ? cloneElement(children, {
              ...children.props,
              name,
              className: cn(children.props.className),
            })
          : children}
        {isValidElement(icon) &&
          cloneElement(icon, {...icon.props, className: cn(s.ctrlForm__icon, icon.props.className)})}
        {isValidElement(button) &&
          cloneElement(button, {...button.props, className: cn(button.props.className, s.ctrlForm__button)})}
        {(isError || isSuccess) && <div className={s.ctrlForm__validation}>{hint}</div>}
        {hideErrorText ||
          (error && showError && (
            <div className={cn('ctrl-form__validation', errorClassName)} style={errorStyles}>
              {error}
            </div>
          ))}
      </div>
    </div>
  );
};

// eslint-disable-next-line
const FormControlFormik = <V extends any = any>({
  name,
  children,
  ...props
}: CtrlFormProps & {name: string; children: (props: FieldProps<V>) => ReactNode}) => {
  return (
    <Field name={name}>
      {(formikProps) => {
        const meta = formikProps.meta;
        const mataError = meta.error?.length && meta.error;
        const error = meta.touched ? mataError || props.error || null : null;
        return (
          <FormControl {...props} name={name} error={error}>
            {children(formikProps)}
          </FormControl>
        );
      }}
    </Field>
  );
};

FormControl.Formik = FormControlFormik;

export default FormControl;
