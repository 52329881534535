import {components, GroupTypeBase, OptionProps, SingleValueProps} from 'react-select';

import InfoMember from '../../../CoreNewUI/InfoMember/InfoMember';

import {MemberOptionType} from './AsyncCompanyWorkerSelect';

export const MemberOption = (props: OptionProps<MemberOptionType, false>) => {
  return (
    <components.Option {...props}>
      <InfoMember
        memberName={props.data.value.workerFull.fullName}
        memberImage={props.data.value.workercard?.profilePicUrl}
        size="m"
      />
    </components.Option>
  );
};

export const SingleMemberOption = (props: SingleValueProps<MemberOptionType, GroupTypeBase<MemberOptionType>>) => {
  return (
    <components.SingleValue {...props}>
      <InfoMember
        memberName={props.data.value.workerFull.fullName}
        memberImage={props.data.value.workercard?.profilePicUrl}
        size="m"
      />
    </components.SingleValue>
  );
};
