import {useMemo} from 'react';

import {useTaskStatusOptions} from 'shared/hooks/UseTaskStatusOptions';
import {TaskStatusType} from 'shared/models/task/taskStatus';

const EXCLUDED_STATUSES = [TaskStatusType.tba, TaskStatusType.archived, TaskStatusType.closed];

export function useSharedTaskStatusOptions(taskId: string) {
  const {options, workflowStatuses} = useTaskStatusOptions({getById: taskId, shared: true});
  const visibleOptions = useMemo(
    () =>
      options.filter(
        (option) =>
          !EXCLUDED_STATUSES.includes(option.value as TaskStatusType) && workflowStatuses.includes(option.value),
      ),
    [status, EXCLUDED_STATUSES, workflowStatuses],
  );
  return [options, visibleOptions] as const;
}
