import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import env from 'shared/constants/env';
import {getLocalizedLink} from 'shared/helpers/common';

import box from '../Box/Box.module.scss';
import Social from '../Social/Social';
import Subscribe from '../Subscribe/Subscribe';

import s from './Footer.module.scss';

type FooterProps = {
  className?: string;
};

const Footer = ({className}: FooterProps) => {
  const {
    t,
    i18n: {language},
  } = useTranslation(['landing2', 'footer']);

  return (
    <footer className={cn(s.footer, className)}>
      <div className={box.box}>
        <Subscribe className={s.footer__form} />
        <nav className={s.footer__nav}>
          <ul className={s.footer__navList}>
            <li className={s.footer__navItem}>
              <a className={s.footer__navLink} href={getLocalizedLink(env.legalPages.aboutCore, language)}>
                {t('footer:about_core', 'About Core')}
              </a>
            </li>
            <li className={s.footer__navItem}>
              <a className={s.footer__navLink} href={getLocalizedLink(env.legalPages.contactUs, language)}>
                {t('footer:contact_us', 'Contact Us')}
              </a>
            </li>
          </ul>
        </nav>
        <Social
          className={s.footer__social}
          links={[
            {
              icon: 'social-facebook-line',
              text: t('footer:follow_facebook', 'Follow us on Facebook'),
              url: process.env.REACT_APP_FACEBOOK_LINK,
            },
            {
              icon: 'social-twitter-line',
              text: t('footer:follow_twitter', 'Follow us on Twitter'),
              url: process.env.REACT_APP_TWITTER_LINK,
            },
            {
              icon: 'social-linkedin-line',
              text: t('footer:follow_linkedin', 'Follow us on LinkedIn'),
              url: process.env.REACT_APP_LINKEDIN_LINK,
            },
            {
              icon: 'social-instagram-line',
              text: t('footer:follow_instagram', 'Follow us on Instagram'),
              url: process.env.REACT_APP_INSTAGRAM_LINK,
            },
          ]}
        />
        <div className={s.footer__copy}>
          {t('landing2:footer.copyright', '© 2022 Crews By Core, Inc. All rights reserved.')}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
