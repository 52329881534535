import {AuthError} from 'firebase/auth';
import {TFunction} from 'i18next';

type FirebaseErrorMessage = {
  errorCode: string;
  errorMessage: string;
};

type FirebaseError = AuthError;

const getFirebaseError = (t: TFunction): FirebaseErrorMessage[] => {
  return [
    {
      errorCode: 'auth/too-many-requests',
      errorMessage: 'Too many login attempts. Please try again later.',
    },
    {
      errorCode: 'auth/captcha-check-failed',
      errorMessage: 'reCAPTCHA response was invalid, expired or this method was called from a non-whitelisted domain.',
    },
    {
      errorCode: 'auth/missing-phone-number',
      errorMessage: "The phone number wasn't provided.",
    },
    {
      errorCode: 'auth/invalid-phone-number',
      errorMessage: 'Invalid phone number format.',
    },
    {
      errorCode: 'auth/user-disabled',
      errorMessage: 'Your phone number has been blocked.',
    },
    {
      errorCode: 'auth/operation-not-allowed',
      errorMessage: "Seems like that authorization method hasn't allowed.",
    },
    {
      errorCode: 'auth/missing-verification-code',
      errorMessage: 'Verification code is missing.',
    },
    {
      errorCode: 'auth/missing-verification-id',
      errorMessage: 'Seems like missing some auth parts.',
    },
    {
      errorCode: 'auth/provider-already-linked',
      errorMessage: 'Seems like another authorization method linked to that account.',
    },
    {
      errorCode: 'auth/invalid-credential',
      errorMessage: 'Seems like we have some problem with your credentials.',
    },
    {
      errorCode: 'auth/credential-already-in-use',
      errorMessage: 'Seems like that user already exists, please login.',
    },
    {
      errorCode: 'auth/invalid-verification-code',
      errorMessage: "You've entered invalid verification code.",
    },
    {
      errorCode: 'auth/invalid-verification-id',
      errorMessage: 'Seems like we have some problem with your credentials.',
    },
    {
      errorCode: 'auth/user-not-found',
      errorMessage: 'Seems like that user is not found.',
    },
    {
      errorCode: 'auth/wrong-password',
      errorMessage: t('errors:firebase_errors.invalid_password', 'The password is invalid.'),
    },
    {
      errorCode: 'auth/code-expired',
      errorMessage: t('errors:firebase_errors.code_expired', 'The code has expired. Please, try to resend.'),
    },
    {
      errorCode: 'auth/quota-exceeded',
      errorMessage: t(
        'errors:firebase_errors.quota_exceeded',
        'You have reached your quota of attempts. Please wait and then try again.',
      ),
    },
    {
      errorCode: 'auth/unverified-email',
      errorMessage: t('errors:firebase_errors.unverified_email', 'You have to verify your email address.'),
    },
    {
      errorCode: 'auth/too-many-requests',
      errorMessage: t('errors:firebase_errors.too_many_requests', 'Too many requests. Please, wait and try again.'),
    },
    {
      errorCode: 'auth/multi-factor-auth-required',
      errorMessage: t('errors:firebase_errors.multi_factor_auth_required', 'You must confirm login via 2FA.'),
    },
  ];
};

export function isFirebaseError(error: unknown): error is FirebaseError {
  return typeof error === 'object' && 'code' in error;
}

export function isFirebaseAuthError(error: unknown): error is FirebaseError {
  return isFirebaseError(error) && typeof error['code'] === 'string' && error['code'].startsWith('auth/');
}

export function isAuthMultiFactorError(error: unknown): error is AuthError {
  return isFirebaseAuthError(error) && error['code'] === 'auth/multi-factor-auth-required';
}

export function isAuthRecentLoginError(error): error is AuthError {
  return isFirebaseAuthError(error) && error['code'] === 'auth/requires-recent-login';
}

export function getHumanizedFbError(error: any, t: TFunction) {
  if (isFirebaseAuthError(error)) {
    return (
      getFirebaseError(t).find((e) => e.errorCode === (error as FirebaseError).code)?.errorMessage || error.message
    );
  }
  return null;
}
