import cn from 'classnames';
import {HTMLAttributes} from 'react';

import s from './CtrlChip.module.scss';

interface CtrlChipProps extends HTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  name: string;
  checked?: boolean;
  value?: string | number;
}

const CtrlChip = ({className, label, name, checked, ...inputProps}: CtrlChipProps) => {
  return (
    <label className={cn(s.CtrlChip, className)}>
      <input type="radio" className={s.CtrlChip__field} name={name} checked={checked} {...inputProps} />
      <span className={s.CtrlChip__label}>{label}</span>
    </label>
  );
};

export default CtrlChip;
