import cn from 'classnames';
import {CellProps} from 'react-table';

import {CompanyWorker} from 'shared/models/worker';

import s from './OrgsColumn.module.scss';

export const OrgsColumn = ({value}: CellProps<CompanyWorker, string[]>) => {
  return (
    <div className={cn(s.orgsColumn)}>
      <div className={s.orgsColumn__grid}>
        {value?.map((org, index) => (
          <div className={s.orgsColumn__item} key={index}>
            {org}
          </div>
        ))}
      </div>
    </div>
  );
};
