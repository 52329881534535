import {FC, forwardRef, InputHTMLAttributes, useCallback, useMemo, useRef} from 'react';
import * as React from 'react';
import ReactDatePicker, {ReactDatePickerProps} from 'react-datepicker';

import {KEYCODE} from 'shared/constants/common';
import {useCompanyLocale} from 'shared/hooks/useCompanyLocale';

import {DatePickerProps} from '../DatePicker';

const popperModifiers = {
  arrow: {
    fn: (data) => Object.assign({}, data, {arrowStyles: null, styles: {minWidth: '120px'}}),
  },
};

type DatePickerCustomInputProps = InputHTMLAttributes<HTMLInputElement> & {
  closeByTab?: (e: React.KeyboardEvent) => void;
  inputStyle?: React.CSSProperties;
};

const TimePickerCustomInput = forwardRef<HTMLInputElement, DatePickerCustomInputProps>(
  ({closeByTab, inputStyle, ...props}, ref) => {
    const onKeyDown = (e) => {
      closeByTab(e);
      props.onKeyDown?.(e);
    };

    return (
      <input
        ref={ref}
        type="text"
        {...props}
        style={inputStyle}
        autoComplete="off"
        className="ctrl-textfield"
        onKeyDown={onKeyDown}
      />
    );
  },
);
TimePickerCustomInput.displayName = 'TimePickerCustomInput';

type TimePickerProps = DatePickerProps & ReactDatePickerProps;
const TimePicker: FC<TimePickerProps> = ({...props}) => {
  const companyLocale = useCompanyLocale();
  const timepickerRef = useRef<ReactDatePicker>();

  const timeFormat = useMemo(() => {
    return companyLocale === 'BR' ? 'HH:mm' : 'hh:mm a';
  }, [companyLocale]);

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === KEYCODE.TAB) {
      timepickerRef.current.setOpen(false);
    }
  }, []);

  return (
    <ReactDatePicker
      {...props}
      ref={timepickerRef}
      showTimeSelect
      showTimeSelectOnly
      popperModifiers={popperModifiers}
      portalId="date-timepicker-portal"
      timeIntervals={15}
      dateFormat={timeFormat}
      timeFormat={timeFormat}
      customInput={<TimePickerCustomInput closeByTab={onKeyDown} />}
    />
  );
};
TimePicker.displayName = 'TimePicker';
export default TimePicker;
