import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {useAssigneesTabContext} from '../AssigneesTabContext';
import SearchWorkers from '../SearchWorkers';

type Props = {
  onClose: () => void;
};

const ReassignmentResponsible: FC<Props> = ({onClose}: Props) => {
  const {t} = useTranslation('task');
  const {responsible, updateResponsible, onMemberSelect} = useAssigneesTabContext();

  return (
    <SearchWorkers
      isResponsibleSearch
      title={t('responsible_panel.choosing_responsible', 'Choosing Responsible')}
      taskAssignees={responsible}
      updateSelectedWorkers={updateResponsible}
      onClose={onClose}
      onMemberSelect={onMemberSelect}
    />
  );
};

export default ReassignmentResponsible;
