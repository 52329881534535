class ScrollToService {
  private _offsetTop: number;
  private header: HTMLDivElement;

  calculateOffsetTop() {
    if (!this.header) {
      this.header = document.querySelector('header.landing-header') as HTMLDivElement;
      this._offsetTop = this.header ? this.header.clientHeight : 0;
    }
  }

  get offsetTop() {
    this.calculateOffsetTop();
    return this._offsetTop;
  }

  get scrolledHeight() {
    return (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
  }

  scrollToElement(element: HTMLElement) {
    if (typeof window === 'undefined') return;
    if (element) {
      const {top} = element.getBoundingClientRect();
      window.scrollTo({left: 0, top: top - this.offsetTop + this.scrolledHeight, behavior: 'smooth'});
    }
  }
}
export const scrollToService = new ScrollToService();
