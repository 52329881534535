import cn from 'classnames';
import {Formik, FormikProps} from 'formik';
import {useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams, useRouteMatch} from 'react-router';

import {useGetSubcontractor} from 'modules/Subcontractors/hooks/useGetSubcontractor';
import {SubcontractorFormValues} from 'modules/Subcontractors/utils/utils';
import {useConfirm} from 'shared/components/Confirmation';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {CompanyOrgs} from 'shared/models/company';

import SubcontractorTab from './components/SubcontractorTab';
import {subcontractorsSchema} from './constants/validation';
import {useHandleSubcontractors} from './hooks/useHandleSubcontractors';
import {useGetCashedSubcontractors} from './hooks/useSimilarSubcontractors';
import s from './SubcontractorsPanel.module.scss';
import {detectNameCollisions} from './utils/detectNameCollisions';
import {getSimilarNames} from './utils/getSimilarNames';

const defaultSubcontractorValues = {
  name: '',
  abbrev: '',
  lookaheadColor: '',
};

const ConfirmSaveChangesPayload = {
  description: 'Do you want to save your changes?',
  acceptButton: 'Save Task',
  cancelButton: "Don't Save",
};

type SubcontractorsProps = {
  className?: string;
  onClose?: () => void;
};

const getFormValues = (org: CompanyOrgs, isNewSubcontractor: boolean) => {
  if (org && !isNewSubcontractor) {
    return {
      name: org.group.name,
      abbrev: org.abbrev,
      lookaheadColor: org.lookaheadColor,
      contactWorkerId: org.contactWorkerId,
    };
  }
  return defaultSubcontractorValues;
};

const SubcontractorsPanel = ({className, onClose}: SubcontractorsProps) => {
  const routes = useLocalizedRoutes();
  const match = useRouteMatch<{id: string}>(routes.subcontractor);
  const {projectId} = useParams<{projectId: string}>();
  const formik = useRef<FormikProps<SubcontractorFormValues>>();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId}});
  const mixpanelEvents = mixpanel.events.projectOrgs;
  const {t} = useTranslation('panel');
  const subcontractors = useGetCashedSubcontractors(projectId);

  const {confirmAction} = useConfirm();
  const {projectSub} = useGetSubcontractor(projectId, match?.params?.id);

  const subcontractorId = match?.params?.id;
  const isNewSubcontractor = subcontractorId === 'new';
  const handleSubcontractor = useHandleSubcontractors(isNewSubcontractor);
  const defaultValues = useMemo(() => getFormValues(projectSub, isNewSubcontractor), [projectSub, subcontractorId]);

  const handleSubmit = async (values: SubcontractorFormValues) => {
    if (
      // TODO: move this logic to formik validation
      detectNameCollisions(
        getSimilarNames(values.name, subcontractors).filter(
          (subName) => subName !== formik.current?.initialValues.name,
        ),
        values.name,
      )
    ) {
      return;
    }
    const sub = await handleSubcontractor(values);
    formik.current?.resetForm({values: getFormValues(sub, isNewSubcontractor)});
  };

  function askToSaveBeforeLeave(onReject: () => void, onSubmit?: () => void) {
    return () =>
      confirmAction(
        ConfirmSaveChangesPayload,
        formik.current?.dirty,
        onSubmit || formik.current?.handleSubmit,
        onReject,
      );
  }

  return (
    <Formik<SubcontractorFormValues>
      enableReinitialize
      initialValues={defaultValues}
      onSubmit={handleSubmit}
      innerRef={formik}
      validationSchema={subcontractorsSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({submitForm, isSubmitting}) => {
        return (
          <section className={cn(s.SubcontractorsPanel, className)}>
            <div className={s.SubcontractorsPanel__viewport}>
              <header className={cn(s.SubcontractorsPanel__header)}>
                <div className={cn(s.SubcontractorsPanel__headerActions)}>
                  <CtrlButton
                    icon="clear_2"
                    color="second"
                    view="link"
                    onClick={() =>
                      mixpanel.trackWithAction(
                        askToSaveBeforeLeave(onClose),
                        mixpanelEvents.close(isNewSubcontractor ? 'Create' : 'Update'),
                      )
                    }
                  >
                    {t('header.buttons.close', 'Close Tab')}
                  </CtrlButton>
                  <CtrlButton
                    icon="check"
                    size="s"
                    onClick={() =>
                      mixpanel.trackWithAction(submitForm, mixpanelEvents.save(isNewSubcontractor ? 'Create' : 'Edit'))
                    }
                    disabled={isSubmitting}
                  >
                    {t('header.buttons.save', 'Save info')}
                  </CtrlButton>
                </div>
              </header>

              <div className={s.SubcontractorsPanel__body}>
                <SubcontractorTab />
              </div>
            </div>
          </section>
        );
      }}
    </Formik>
  );
};

export default SubcontractorsPanel;
