import {FC, MouseEventHandler, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory} from 'react-router';

import Icon from 'shared/components/Icon';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {ProjectModel} from 'shared/models/project';

type ProjectItemProps = {
  project: ProjectModel;
  openProject: (id: string) => void;
};

const ProjectItem: FC<ProjectItemProps> = ({project, openProject}) => {
  const {id, name, shortname, taskCount, country, state, city} = project;
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const {
    mixpanel: {
      events: {projects: mixpanelEvents},
      ...mixpanel
    },
  } = useAnalyticsService();
  const {t} = useTranslation('projects');

  const location = useMemo(() => {
    return !!country && [country, state, city].join(', ');
  }, [country, state, city]);

  const goToTasks: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    history.push(generatePath(routes.tasks, {projectId: id}));
  };

  const startEditProject: MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    openProject(id);
  };

  const {hasAnyAdminRole} = useCompanyWorkerRoles(project.id);

  return (
    <div
      onClick={(e) =>
        mixpanel.trackWithAction(() => goToTasks(e), mixpanelEvents.buttons.cardTasks, {
          'Project Name': project?.name,
        })
      }
      style={{cursor: 'pointer'}}
      className="project-preview projects-list__item"
      data-cy={`project_card_id_${project.id}`}
    >
      <div className="project-preview__container">
        <div className="project-preview__actions">
          <div className="project-preview__tasks">
            <span className="project-preview__tasks-text">
              {taskCount} {t('project.activities_count', 'items')}
            </span>
            <Icon name="chevron-right" className="project-preview__tasks-icon" colorFill size={24} />
          </div>
          {hasAnyAdminRole ? (
            <div
              className="project-preview__edit"
              onClick={startEditProject}
              style={{cursor: 'pointer', minWidth: 'auto', marginRight: '1rem'}}
            >
              <span className="project-preview__edit-text">{t('project.edit', 'Edit')}</span>
              <Icon name="edit" className="project-preview__edit-icon" colorFill size={24} />
            </div>
          ) : null}
          <div className="project-preview__time-zone">{shortname}</div>
        </div>
        <h2 data-cy="project_card_title" className="project-preview__title">
          <Icon name="folder_labeled" className="project-preview__title-icon" colorFill size={24} />
          <span className="project-preview__title-text">{name}</span>
        </h2>
        <div className="project-preview__location">{location}</div>
      </div>
    </div>
  );
};

export default ProjectItem;
