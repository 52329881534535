export const EventTypeMap = {
  taskEvent: 'task_event',
} as const;

export const FeedbackDailyTypeMap = {
  DailyLabor: 'daily_labor',
  DailyCompletion: 'completion_amount',
} as const;

export type DailyType = (typeof FeedbackDailyTypeMap)[keyof typeof FeedbackDailyTypeMap];

type DailyPayload = {
  value_integer: number;
};

export const MessageTypeMap = {
  extChatEvent: 'ext_chat_event',
  image: 'm_image',
  message: 'm_message',
  pdf: 'm_map_pdf',
} as const;

export const ContentMessageTypeMap = {
  file: 'm.file',
  image: 'm.image',
  text: 'm.text',
} as const;

const MimeTypeMap = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  pdf: 'application/pdf',
} as const;

export const TagTypesMap = {
  PotentialIssues: 'potential_issues',
} as const;

export type TagType = (typeof TagTypesMap)[keyof typeof TagTypesMap];

export type MimeType = (typeof MimeTypeMap)[keyof typeof MimeTypeMap];

type FeedbackMessage = {
  feedback_type: 'm_message';
  date?: string;
  payload: MatrixMessage;
  tags?: TagType[];
};

type FeedbackImage = {
  feedback_type: 'm_image';
  date?: string;
  payload: MatrixImage;
  tags?: TagType[];
};

type FeedbackDaily = {
  feedback_type: DailyType;
  date: string;
  payload: DailyPayload;
  tags?: TagType[];
};

// not used but available
type FeedbackExtChatEvent = {
  feedback_type: 'ext_chat_event';
  date: string;
  payload: MatrixExtChatEvent;
  tags?: TagType[];
};

type FeedbackPDFEvent = {
  feedback_type: 'm_map_pdf';
  date: string;
  payload: MatrixPDF;
  tags?: TagType[];
};

export type Feedback = FeedbackImage | FeedbackMessage | FeedbackDaily | FeedbackExtChatEvent | FeedbackPDFEvent;

interface AttachmentInfo {
  h: number;
  mimetype: MimeType;
  size: number;
  w: number;
}

interface Attachment {
  info: AttachmentInfo;
  msgtype: string;
  url: string;
}

interface Content {
  attachments?: Attachment[];
  body: string;
  displayname?: string;
  formatted_body?: string;
  info?: AttachmentInfo;
  m_relates_to?: Record<string, string>;
  msgtype: string;
  reply_attachments?: string[];
  state_key?: string;
  task_id?: string;
  url?: string;
}

interface Unsigned {
  additionalProp1?: string;
  additionalProp2?: string;
  additionalProp3?: string;
}

interface MatrixExtChatEvent {
  [MessageTypeMap.extChatEvent]: {
    age: number;
    content: Content;
    event_id: string;
    origin_server_ts: number;
    room_id: string;
    sender: string;
    state_key: string;
    type: 'm.room.alias';
    unsigned?: Unsigned;
    user_id: string;
  };
}

interface MatrixImage {
  [MessageTypeMap.image]: {
    content: {
      body: string;
      info: AttachmentInfo;
      msgtype: 'm.image';
      url: string;
    };
  };
}

interface MatrixMessage {
  [MessageTypeMap.message]: {
    content: {
      body: string;
      format: 'org.matrix.custom.html';
      formatted_body: string;
      msgtype: 'm.text';
    };
  };
}

interface MatrixPDF {
  [MessageTypeMap.pdf]: {
    content: {
      body: string;
      filename: string;
      info: {
        mimetype: string;
        size: number;
      };
      msgtype: 'm.file';
      url: string;
    };
  };
}
