import classNames from 'classnames';
import {FC} from 'react';
import * as React from 'react';

type SwitcherProps = {
  value: boolean;
  onChange: (checked: boolean) => void;
  label: string;
  tip?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  id?: string;
  name?: string;
  disabled?: boolean;
  reverse?: boolean;
  view?: 'default' | 'checkbox' | 'radio';
};

const Switcher: FC<SwitcherProps> = ({
  value,
  label,
  tip,
  onChange,
  disabled,
  onBlur,
  id,
  name,
  reverse,
  view = 'default',
}) => {
  return (
    <div
      data-value={JSON.stringify(value)}
      className={classNames('ctrl-switcher', {
        'ctrl-switcher--reverse': reverse,
        'ctrl-switcher--view-checkbox': view === 'checkbox',
        'ctrl-switcher--view-radio': view === 'radio',
      })}
    >
      <label className="ctrl-switcher__label" htmlFor={id}>
        <span className="ctrl-switcher__name">{label}</span>
        <span className="ctrl-switcher__description">{tip}</span>
      </label>
      <span className="ctrl-switcher__visual-box">
        <input
          id={id}
          name={name}
          className="ctrl-switcher__field"
          type="checkbox"
          checked={!!value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
          onBlur={onBlur}
        />
        <span className="ctrl-switcher__visual" />
      </span>
    </div>
  );
};
export default Switcher;
