import {Formik, FormikProps} from 'formik';
import {TFunction} from 'i18next';
import {FunctionComponent, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useParams} from 'react-router';
import {toast} from 'react-toastify';

import {getProjectValidationSchema} from 'modules/Project/ProjectForm/validationSchema';
import {useConfirm} from 'shared/components/Confirmation';
import Icon from 'shared/components/Icon';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {parseTime, safeFormatDate} from 'shared/helpers/dates';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {useUnmount} from 'shared/hooks/core/useUnmount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useProject} from 'shared/hooks/useProject';
import {SlidePanel} from 'shared/layout/admin';
import {ProjectModel} from 'shared/models/project';
import {useRootDispatch} from 'store';
import {projectActions} from 'store/projects';
import * as projectAsyncActions from 'store/projects/actions';
import {deleteProject} from 'store/projects/actions';

import {ProjectPreview} from './components';
import ProjectForm from './ProjectForm';

function prepareEmptyProject() {
  return {
    name: '',
    country: '',
    defaultTaskStart: parseTime('07:00'),
    defaultTaskEnd: parseTime('16:00'),
    state: '',
    city: '',
    timezone: '',
    architectCompanyGroupMappingId: '',
    engineerCompanyGroupMappingId: '',
    ownerCompanyGroupMappingId: '',
    subcontractorCompanyGroupMappingId: '',
  };
}

const getConfirmSaveChangesPayload = (t: TFunction) => ({
  description: t('project:confirm.save.description', 'Do you want to save your changes?'),
  acceptButton: t('project:confirm.save.save', 'Save Project'),
  cancelButton: t('project:confirm.save.not_save', "Don't Save"),
});

const Project: FunctionComponent = () => {
  const history = useHistory();
  const routes = useLocalizedRoutes();
  const dispatch = useRootDispatch();
  const {id} = useParams<{id: string}>();
  const company = useCompany();
  const {project, loading} = useProject(id);
  const formik = useRef<FormikProps<ProjectModel>>();
  const isNewProject = id === 'new';
  const {
    mixpanel: {
      events: {project: mixpanelEvents},
      ...mixpanel
    },
  } = useAnalyticsService();
  const {confirm, confirmAction} = useConfirm();
  const {t} = useTranslation(['project', 'common']);

  useEffectOnce(() => {
    !isNewProject && mixpanel.track(mixpanelEvents.editProject);
  });

  useEffect(() => {
    if (id && !isNewProject) {
      loadProject(id);
    }
  }, [dispatch, id, isNewProject]);

  useEffect(() => {
    if (project) {
      updateFormikValues(project);
    }
  }, [project]);

  useEffect(() => {
    if (isNewProject && company?.companyAddress) {
      const [city, state, country] = company.companyAddress.split(',').map((v) => v.trim());
      updateFormikValues({
        ...formik.current.values,
        city,
        state,
        country,
        timezone: '',
      });
    }
  }, [company, isNewProject]);

  // reset current project value
  useUnmount(() => dispatch(projectActions.setProject(null)));

  const loadProject = async (id: string) => {
    const res = await dispatch(projectAsyncActions.getProject(id));
    if (projectAsyncActions.getProject.rejected.match(res)) {
      toast.error(res.error.message);
      history.push(routes.projects);
    } else {
      dispatch(projectActions.setProject(res.payload));
    }
  };

  const updateFormikValues = (values: ProjectModel) => {
    formik.current?.resetForm({
      values: {
        ...values,
        defaultTaskStart: parseTime(values.defaultTaskStart),
        defaultTaskEnd: parseTime(values.defaultTaskEnd),
      },
    });
  };

  const saveProject = async (values: ProjectModel) => {
    const model = Object.assign({}, project, values, {
      defaultTaskStart: safeFormatDate(values.defaultTaskStart, 'HH:mm'),
      defaultTaskEnd: safeFormatDate(values.defaultTaskEnd, 'HH:mm'),
    });
    if (isNewProject) {
      await createProject(model);
      mixpanel.track(mixpanelEvents.addNew);
    } else {
      await updateProject(model);
      mixpanel.track(mixpanelEvents.save, {'Project Name': project.name});
    }
  };

  const updateProject = async (model: ProjectModel) => {
    const res = await dispatch(projectAsyncActions.updateProject(model));
    if (projectAsyncActions.updateProject.rejected.match(res)) {
      toast.error(res.error.message);
    } else {
      toast.success(t('project:toast.success.project.updated', 'Project updated'));
    }
  };

  const createProject = async (model: ProjectModel) => {
    const res = await dispatch(projectAsyncActions.createProject({companyId: company?.id, model}));

    if (projectAsyncActions.createProject.rejected.match(res)) {
      toast.error(res.error.message);
    } else {
      dispatch(projectActions.setProject(res.payload));
      toast.success(t('project:toast.success.project.created', 'Project created'));
      history.push(generatePath(routes.tasks, {projectId: res.payload.id}), {showTutorial: true});
    }
  };

  const askToDeleteProject = async () => {
    if (await confirm(t('project:confirm.project.deleted', 'Are you sure you want to Delete Project?'))) {
      const res = await dispatch(deleteProject(id));
      if (deleteProject.fulfilled.match(res)) {
        goToProjects();
        toast.success(t('project:toast.success.project.deleted', 'Project deleted'));
      } else {
        toast.error(res.error.message);
      }
    }
  };

  function goToProjects() {
    history.push(generatePath(routes.projects));
  }

  function askToSaveBeforeLeave(onReject: () => void) {
    return confirmAction(
      getConfirmSaveChangesPayload(t),
      formik.current?.dirty,
      formik.current?.handleSubmit,
      onReject,
    );
  }

  const {hasAnyAdminRole} = useCompanyWorkerRoles(id);

  return (
    <Formik<ProjectModel>
      initialValues={prepareEmptyProject()}
      onSubmit={saveProject}
      innerRef={formik}
      validateOnChange={true}
      validateOnMount={false}
      validationSchema={getProjectValidationSchema(t)}
    >
      {({isSubmitting, submitForm}) => {
        return (
          <>
            <SlidePanel
              onClose={() =>
                askToSaveBeforeLeave(() =>
                  mixpanel.trackWithAction(goToProjects, mixpanelEvents.exit, {}, isNewProject),
                )
              }
              content={
                <>
                  <SlidePanel.Header>
                    <button
                      data-cy="project_form_close_btn"
                      className="y-ctrl-btn slide-panel__button-back"
                      type="button"
                      onClick={() =>
                        hasAnyAdminRole &&
                        askToSaveBeforeLeave(() =>
                          mixpanel.trackWithAction(goToProjects, mixpanelEvents.backToProjects),
                        )
                      }
                    >
                      <Icon className="y-ctrl-btn__icon" colorFill size={24} name="arrow_backward" />
                      <span className="y-ctrl-btn__text">{t('project:buttons.back', 'Back to Projects')}</span>
                    </button>
                    <button
                      className="ctrl-btn slide-panel__button-main"
                      type="button"
                      data-cy="btnSaveProject"
                      onClick={() => submitForm()}
                      disabled={!hasAnyAdminRole || isSubmitting || loading}
                    >
                      <Icon className="ctrl-btn__icon" colorFill size={24} name="checkmark-outlined" />
                      <span className="ctrl-btn__text">
                        {isNewProject ? t('project:buttons.add', 'Add') : t('project:buttons.save', 'Save')}
                      </span>
                    </button>
                  </SlidePanel.Header>
                  <SlidePanel.Body>
                    <ProjectForm
                      isNewProject={isNewProject}
                      disabled={!hasAnyAdminRole}
                      onDelete={() =>
                        mixpanel.trackWithAction(askToDeleteProject, mixpanelEvents.delete, {
                          'Project Name': project.name,
                        })
                      }
                    />
                  </SlidePanel.Body>
                </>
              }
              aside={<ProjectPreview />}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default Project;
