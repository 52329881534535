import {Field, FieldProps, Form, useFormikContext} from 'formik';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import CountryCodeSelector from 'shared/components/CoreForm/CountryCodeSelector';
import PhoneInput from 'shared/components/CoreForm/PhoneInput';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import ExternalLink from 'shared/components/ExternalLink';
import env from 'shared/constants/env';
import {getLocalizedLink} from 'shared/helpers/common';

import {FormValues} from '../Contact';
import {getContactContentByKey} from '../contentHelper';

type ContactFormProps = {
  setCountryCode: (value: string) => void;
  countryCode: string;
  type: string;
};

const ContactForm: FC<ContactFormProps> = ({setCountryCode, countryCode, type}) => {
  const {submitForm} = useFormikContext<FormValues>();
  const {
    t,
    i18n: {language},
  } = useTranslation('contacts');
  const content = getContactContentByKey(type, t);

  return (
    <Form className="form-contacts">
      <div className="form-contacts__item">
        <div className="ctrl-form">
          <FormControl name="name" label={t('label.name', 'Full Name')}>
            <Field className="ctrl-textfield" name="name" placeholder={t('placeholder.name', 'Enter Full Name')} />
          </FormControl>
        </div>
      </div>
      <div className="form-contacts__item">
        <div className="ctrl-phone">
          <div className="ctrl-phone__item ctrl-phone__item--code">
            <FormControl name="countryCode" label={t('label.phone__country_code', 'Mobile Phone Number')}>
              <CountryCodeSelector onChange={setCountryCode} name="countryCode" />
            </FormControl>
          </div>
          <div className="ctrl-phone__item ctrl-phone__item--number">
            <FormControl name="mobileNumber" label={t('label.phone', 'Phone Number')}>
              <Field name="mobileNumber">
                {(props: FieldProps) => (
                  <PhoneInput
                    {...props.field}
                    countryCode={countryCode}
                    className="ctrl-textfield"
                    id="apply_onboarding_phone"
                    placeholder={t('placeholder.phone', 'Enter Phone Number')}
                  />
                )}
              </Field>
            </FormControl>
          </div>
        </div>
      </div>
      <div className="form-contacts__item">
        <FormControl name="emailAddress" label={t('label.email', 'Email')}>
          <Field
            className="ctrl-textfield"
            name="emailAddress"
            placeholder={t('placeholder.email', 'Enter Email Address')}
          />
        </FormControl>
      </div>
      <div className="form-contacts__item">
        <FormControl name="companyName" label={t('label.company_name', 'Company Name')}>
          <Field
            className="ctrl-textfield"
            name="companyName"
            placeholder={t('placeholder.company_name', 'Enter Company Name')}
          />
        </FormControl>
      </div>
      <div className="form-contacts__item">
        <FormControl name="message" label={t('label.message', 'Message')}>
          <Field as="textarea" className="ctrl-textarea" name="message" placeholder={content.messagePlaceholder} />
        </FormControl>
      </div>
      <div className="form-contacts__item form-contacts__item--text">
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: t('privacy_policy.1', 'By clicking &quot;Submit&quot;, I agree to Core&apos;s'),
            }}
          />{' '}
          <ExternalLink
            target="_blank"
            href={getLocalizedLink(env.legalPages.privacyPolicy, language)}
            text={t('privacy_policy.link', 'Privacy Policy')}
          />
          .
        </p>
        <p>{t('privacy_policy.2', 'We use your contact information to inform you about our products and services.')}</p>
      </div>
      <div className="form-contacts__item">
        <button
          className="ctrl-btn ctrl-btn--color-second ctrl-btn--shadow form-contacts__button"
          onClick={submitForm}
          type="button"
        >
          <span className="ctrl-btn__text">{t('button.submit', 'Submit')}</span>
        </button>
      </div>
    </Form>
  );
};
export default ContactForm;
