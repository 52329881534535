import anchorme from 'anchorme';
import dayjs from 'dayjs';
import {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import SkeletonFieldPreloader from 'shared/components/CoreForm/SkeletonFieldPreloader';
import {taskPastDueStatuses} from 'shared/constants/statuses';
import {safeFormatDate, safeParseDate} from 'shared/helpers/dates';
import {useToggle} from 'shared/hooks/core/useToggle';
import {TaskDetailsModelDTO} from 'shared/models/task/task';

import {AdditionalInfoPart} from '../index';

type Props = {
  task: TaskDetailsModelDTO;
  clonedTask: TaskDetailsModelDTO;
  onUpdateTask: (task: Partial<TaskDetailsModelDTO>) => void;
};

const TaskMainInfoPart: FC<Props> = ({task, clonedTask, onUpdateTask}: Props) => {
  const {t} = useTranslation('shared_task');
  const {isToggled, toggle} = useToggle();
  const taskOverdue = useMemo(() => {
    const dueDate = safeParseDate(task?.schedEndDate);
    if (dueDate) {
      const deltaInDays = dayjs(dueDate).diff(dayjs().endOf('day'), 'day');
      if (deltaInDays >= 0 || taskPastDueStatuses.indexOf(task?.status) === -1) {
        return false;
      }

      const absDeltaInDays = Math.abs(deltaInDays);
      if (absDeltaInDays === 1) return '1 day overdue';

      return `${absDeltaInDays} days overdue`;
    }
    return false;
  }, [task?.schedEndDate, task?.status]);

  const preparedDescription = task?.description ? anchorme(task?.description) : 'N/A';

  return (
    <div className="app-task-info app-task__item">
      <SkeletonFieldPreloader when={!task}>
        <header className="app-task-info__header">
          <div className="app-task-info__project">{task?.projectName}</div>
          <div className="app-task-info__company">{task?.companyName}</div>
        </header>
      </SkeletonFieldPreloader>
      <SkeletonFieldPreloader when={!task}>
        <div className="app-task-info__id">{task?.uniqueId}</div>
      </SkeletonFieldPreloader>
      <SkeletonFieldPreloader when={!task}>
        <h2 className="app-task-info__name">{task?.name}</h2>
      </SkeletonFieldPreloader>
      <SkeletonFieldPreloader when={!task}>
        <time className="app-task-info__date">
          {safeFormatDate(task?.schedStartDate, 'll')} &mdash; {safeFormatDate(task?.schedEndDate, 'll')}
        </time>
        {!!taskOverdue && <div className="app-task-info__due">{taskOverdue}</div>}
      </SkeletonFieldPreloader>
      <SkeletonFieldPreloader when={!task}>
        <div className="app-task-info__location">{task?.location}</div>
      </SkeletonFieldPreloader>
      <SkeletonFieldPreloader when={!task}>
        <div className="app-task-info__description" style={{overflowWrap: 'anywhere'}}>
          <h3 className="app-task-info__description-title">Description</h3>
          <div dangerouslySetInnerHTML={{__html: preparedDescription}} className="app-task-info__description-text" />
          <button className="ctrl-btn-more-app app-task-info__button-more" type="button" onClick={() => toggle()}>
            {!isToggled ? t('buttons.more', 'Show more') : t('buttons.less', 'Show less')}
          </button>
        </div>
      </SkeletonFieldPreloader>
      {isToggled && <AdditionalInfoPart task={clonedTask} updateTask={onUpdateTask} />}
    </div>
  );
};
export default TaskMainInfoPart;
