import {useEffect, useState} from 'react';

import {useCompanyLocale} from 'shared/hooks/useCompanyLocale';

import {useBrowserLocale} from './useBrowserLocale';

type DateFormatPlaces = {
  default: string;
  filter: string;
  reportsPrimary: string;
  reportsSecondary: string;
};

const mappings = new Map<string[], DateFormatPlaces>([
  [
    ['EN', 'US'],
    {
      default: 'MM/DD/YYYY',
      filter: 'MM/DD/YY',
      reportsPrimary: 'MM/DD/YYYY',
      reportsSecondary: 'MM/DD/YYYY hh:mm A',
    },
  ],
  [
    ['PT', 'BR'],
    {default: 'DD/MM/YYYY', filter: 'DD/MM/YY', reportsPrimary: 'DD/MM/YYYY', reportsSecondary: 'DD/MM/YYYY HH:mm'},
  ],
]);

export function usePreferDateFormat(place: keyof DateFormatPlaces = 'default', fallback = 'MM/DD/YYYY') {
  const browserLocale = useBrowserLocale();
  const companyLocale = useCompanyLocale();
  const [dateFormat, setDateFormat] = useState(fallback);
  const [locale, setLocale] = useState(browserLocale);

  useEffect(() => {
    if (companyLocale) {
      setLocale(companyLocale || browserLocale);
    }
  }, [companyLocale, browserLocale]);

  useEffect(() => {
    mappings.forEach((value, key) => {
      if (key.includes(locale)) {
        setDateFormat(value[place] ?? value.default);
      }
    });
  }, [locale, place]);

  return dateFormat;
}
