import classnames from 'classnames';
import {CSSProperties, FC} from 'react';

import SpriteSVG from 'assets/images/svg-sprite.svg';

export type IconProps = {
  name: string;
  className?: string;
  fill?: boolean;
  colorFill?: boolean;
  width?: number | string;
  height?: number | string;
  size?: number;
  iconset?: string;
  titleText?: string;
  onClick?: (e) => void;
  style?: CSSProperties;
  origin?: string;
};

const Icon: FC<IconProps> = ({
  name,
  className,
  titleText = null,
  colorFill = false,
  width = 16,
  height = 16,
  size,
  onClick,
  style,
}) => {
  return (
    <svg
      className={classnames('icon', className, {
        'icon--color-fill': colorFill,
      })}
      width={size || width}
      height={size || height}
      onClick={onClick}
      style={style}
    >
      {titleText ? <title>{titleText}</title> : null}
      <use xlinkHref={`${origin}${SpriteSVG}#${name}`} />
    </svg>
  );
};
export default Icon;
