import {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';

import ExternalLink from 'shared/components/ExternalLink';
import Logo from 'shared/components/Logo';
import env from 'shared/constants/env';
import {getLocalizedLink} from 'shared/helpers/common';

const Footer: FunctionComponent = () => {
  const {
    t,
    i18n: {language},
  } = useTranslation('footer');
  return (
    <div>
      <footer className="footer">
        <div className="box">
          <div className="footer__container">
            <Logo className="footer__log" />
            <ul className="footer__nav">
              <li className="footer__nav-item">
                <ExternalLink
                  className="footer__nav-link"
                  target="_blank"
                  href={getLocalizedLink(env.legalPages.aboutCore, language)}
                  text={t('about_core', 'About Core')}
                />
              </li>
              <li className="footer__nav-item">
                <ExternalLink
                  className="footer__nav-link"
                  target="_blank"
                  href={getLocalizedLink(env.legalPages.contactUs, language)}
                  text={t('contact_us', 'Contact Us')}
                />
              </li>
            </ul>
            <div className="social footer__social">
              <a
                className="ctrl-btn-clear social__item"
                rel="noopener noreferrer"
                target="_blank"
                href={process.env.REACT_APP_FACEBOOK_LINK}
              >
                <span className="ctrl-btn-clear__text">{t('follow_facebook', 'Follow us on Facebook')}</span>
                <svg className="icon icon--color-fill ctrl-btn-clear__icon" width={24} height={24}>
                  <use xlinkHref="images/svg-sprite.svg#social-facebook-line" />
                </svg>
              </a>
              <a
                className="ctrl-btn-clear social__item"
                rel="noopener noreferrer"
                target="_blank"
                href={process.env.REACT_APP_TWITTER_LINK}
              >
                <span className="ctrl-btn-clear__text">{t('follow_twitter', 'Follow us on Twitter')}</span>
                <svg className="icon icon--color-fill ctrl-btn-clear__icon" width={24} height={24}>
                  <use xlinkHref="images/svg-sprite.svg#social-twitter-line" />
                </svg>
              </a>
              <a
                className="ctrl-btn-clear social__item"
                rel="noopener noreferrer"
                target="_blank"
                href={process.env.REACT_APP_LINKEDIN_LINK}
              >
                <span className="ctrl-btn-clear__text">{t('follow_linkedin', 'Follow us on LinkedIn')}</span>
                <svg className="icon icon--color-fill ctrl-btn-clear__icon" width={24} height={24}>
                  <use xlinkHref="images/svg-sprite.svg#social-linkedin-line" />
                </svg>
              </a>
              <a
                className="ctrl-btn-clear social__item"
                rel="noopener noreferrer"
                target="_blank"
                href={process.env.REACT_APP_INSTAGRAM_LINK}
              >
                <span className="ctrl-btn-clear__text">{t('follow_instagram', 'Follow us on Instagram')}</span>
                <svg className="icon icon--color-fill ctrl-btn-clear__icon" width={24} height={24}>
                  <use xlinkHref="images/svg-sprite.svg#social-instagram-line" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
