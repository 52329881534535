import {useCallback, useMemo, useState} from 'react';

import {replaceOwnerWithAssignee} from 'shared/helpers/task';
import {useProfile} from 'shared/hooks/useProfile';
import {GroupMemberRole} from 'shared/models/task/const';
import {TaskAssignees, TaskAssigneeWorker} from 'shared/models/task/member';
import {TaskDetailsModelDTO} from 'shared/models/task/task';

export function useActivityMembers(task: TaskDetailsModelDTO) {
  const members = useMemo(() => replaceOwnerWithAssignee(task?.assignees), [task?.assignees]);
  const [localMembers, setLocalMembers] = useState<TaskAssignees[]>([]);
  const profile = useProfile();

  const dummyMembers = useMemo(
    () =>
      profile
        ? [
            {
              memberId: profile.id,
              memberRole: GroupMemberRole.responsible,
              memberName: `${profile.firstName} ${profile.lastName}`,
              memberProfilePicUrl: profile.profilePicUrl,
              memberTrade: profile.trade,
            },
          ]
        : ([] as TaskAssigneeWorker[]),
    [profile],
  );

  const onMemberSelected = useCallback(
    (members: TaskAssigneeWorker[]) => {
      const preparedMembers = members.map((m) => ({memberId: m.workerId, memberRole: m.memberRole}));
      setLocalMembers(preparedMembers);
    },
    [localMembers, task],
  );

  return {
    members,
    localMembers,
    dummyMembers,
    onMemberSelected,
    setLocalMembers,
  };
}
