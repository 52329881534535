import dayjs, {ConfigType} from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect, useMemo} from 'react';

import {TasksViewMode} from 'shared/constants/common';
import {addDays, safeParseDate} from 'shared/helpers/dates';

import {useFilterContext} from '../../Filters/FilterProvider';
import {getVisualFilterDateRange, getFilterDateRange} from '../../Filters/utils/dateRange';

export function useWeeksRange(gantt: GanttStatic, dateFrom?: ConfigType, weeks?: number) {
  const {viewMode} = useFilterContext();
  const dateRange = useMemo(() => {
    if (!dateFrom) return null;

    switch (viewMode) {
      case TasksViewMode.ganttVisual:
        return !Number.isNaN(weeks) ? getVisualFilterDateRange(dateFrom, weeks) : null;
      default:
        return !Number.isNaN(weeks) ? getFilterDateRange(dateFrom, weeks) : null;
    }
  }, [dateFrom, weeks, viewMode]);

  useEffect(() => {
    if (dateRange) {
      gantt.config.start_date = dateRange[0];
      gantt.config.end_date = addDays(
        safeParseDate(dateRange[1]),
        viewMode === TasksViewMode.ganttVisual && weeks !== 0 ? 1 : 0,
      ).toDate();
      gantt.weekRangeEnabled = true;
    } else if (gantt.weekRangeEnabled) {
      gantt.config.start_date = undefined;
      gantt.config.end_date = undefined;
      gantt.weekRangeEnabled = false;
    }
    gantt.dRender();
  }, [gantt, dateRange, viewMode, weeks]);
  return dateRange;
}

const MIN_TIMELINE_WEEKS = 3;
const MIN_TIMELINE_DAYS = MIN_TIMELINE_WEEKS * 7;
const MIN_WEEKS_BEFORE = 1;
const MIN_WEEKS_AFTER = 1;

export function ensureMinTimelineRange(gantt: GanttStatic) {
  const {start_date: start, end_date: end} = gantt.getSubtaskDates();
  const timelineEnd = gantt.config.end_date;
  const timelineStart = gantt.config.start_date;
  if (!gantt.weekRangeEnabled) {
    const hasActualDates = !!start && !!end;
    const hasTimelineBoundary = !!timelineStart && !!timelineEnd;
    const actualDataDuration = hasActualDates ? dayjs(end).diff(start, 'day') : 0;
    const timelineDuration = hasTimelineBoundary ? dayjs(timelineEnd).diff(timelineStart, 'day') : 0;
    // we always adjust first date as second day(monday fo us), because of that we decrease min days before by 1
    const hasValidLeftOffset = dayjs(start).diff(timelineStart, 'day') >= MIN_WEEKS_BEFORE * 7 - 1;
    const hasValidRightOffset = dayjs(timelineEnd).diff(end, 'day') >= MIN_WEEKS_AFTER * 7;
    const needAdjustTimeline = actualDataDuration && timelineDuration && !(hasValidLeftOffset && hasValidRightOffset);

    if ((actualDataDuration < MIN_TIMELINE_DAYS && timelineDuration < MIN_TIMELINE_DAYS) || needAdjustTimeline) {
      const rangeStart = getFilterDateRange(start || new Date(), MIN_TIMELINE_WEEKS - 1);
      const rangeEnd = getFilterDateRange(end || new Date(), MIN_TIMELINE_WEEKS - 1);
      gantt.config.start_date = rangeStart[0];
      gantt.config.end_date = rangeEnd[1];
      gantt.dRender();
    } else if (timelineDuration && actualDataDuration > MIN_TIMELINE_DAYS) {
      gantt.config.start_date = undefined;
      gantt.config.end_date = undefined;
      gantt.dRender();
    }
  }
}
