import {ChangeEvent, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router';
import {generatePath} from 'react-router-dom';
import {CellProps, Column} from 'react-table';

import InfoMember, {InfoMemberProps} from 'shared/components/CoreNewUI/InfoMember/InfoMember';
import {CenteredColumnSubcontractors} from 'shared/components/Table/components/Columns/Centered';
import {RouteParams, useLocalizedRoutes} from 'shared/constants/routes';
import {LookaheadColors} from 'shared/hooks/useResponsibleOrgColors';
import {CompanyOrgs} from 'shared/models/company';

import SubcontractorInfo from '../components/SubcontractorInfo';
import {CompanyOrgsExtends} from '../types';

const InfoMemberCell = ({value: {memberImage, memberName}}: CellProps<InfoMemberProps>) => {
  if (!memberImage && !memberName) return null;
  return <InfoMember memberName={memberName} memberImage={memberImage} />;
};

export const useSubcontractorsColumns = (lookaheadColors: LookaheadColors) => {
  const {t} = useTranslation('panel');
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const {projectId} = useParams<RouteParams['tasks']>();

  const goToWorkers = (name: string) => (e: ChangeEvent) => {
    e.stopPropagation();
    history.push({
      pathname: generatePath(routes.projectWorkers, {projectId}),
      search: `?orgList=${name}`,
    });
  };

  return useMemo(
    () =>
      [
        {
          Header: t('subcontractor_table.column.subcontractor', 'Company'),
          id: 'subcontractor',
          accessor: (value: CompanyOrgs) => ({value, color: lookaheadColors?.[value?.lookaheadColor].fill}),
          Cell: SubcontractorInfo,
          minWidth: 200,
          width: 200,
        },
        {
          Header: t('subcontractor_table.column.designated_person', 'Designated Person'),
          id: 'contactWorkerId',
          accessor: (value: CompanyOrgsExtends) => ({
            memberName: value?.designatedPerson?.memberName,
            memberImage: value?.designatedPerson?.memberImage,
          }),
          Cell: InfoMemberCell,
          minWidth: 200,
          width: 200,
        },
        {
          Header: t('subcontractor_table.column.workers_count', 'Number of Workers'),
          id: 'nbWorkers',
          accessor: (value: CompanyOrgsExtends) => ({
            nbWorkers: value.nbWorkers,
            onClick: goToWorkers(value.group.name),
          }),
          Cell: CenteredColumnSubcontractors,
          minWidth: 100,
          width: 100,
        },
      ] as Column<CompanyOrgs>[],
    [lookaheadColors],
  );
};
