import {logoutUser} from 'store/actions';

export const LOGIN_SUCCESS = 'auth/login-success';
export const LOGIN_FAILURE = 'auth/login-failure';
export const LOGIN_INPROGRESS = 'auth/login-inprogress';
export const REQUESTING_PHONE_CODE_INPROGRESS = 'auth/requesting-phone-code-inprogress';
export const REQUESTING_PHONE_CODE_SUCCESS = 'auth/requesting-phone-code-success';
export const REQUESTING_PHONE_CODE_FAILED = 'auth/requesting-phone-code-failed';

interface LoginSuccessed {
  type: typeof LOGIN_SUCCESS;
  payload?: {
    token: string;
    workerId: string;
  };
}

interface LoginFailure {
  type: typeof LOGIN_FAILURE;
  payload: string;
}

interface LoginInProgress {
  type: typeof LOGIN_INPROGRESS;
}

interface RequestingPhoneCodeInProgress {
  type: typeof REQUESTING_PHONE_CODE_INPROGRESS;
}

interface RequestingPhoneCodeSuccess {
  type: typeof REQUESTING_PHONE_CODE_SUCCESS;
}

interface RequestingPhoneCodeFailed {
  type: typeof REQUESTING_PHONE_CODE_FAILED;
}

export interface AuthStore {
  isLoggedIn: boolean;
  loginError: string;
  loginInProgress: boolean;
  isRequestingPhoneCode: boolean;
}

export type LoginActions =
  | LoginSuccessed
  | LoginInProgress
  | LoginFailure
  | RequestingPhoneCodeInProgress
  | RequestingPhoneCodeSuccess
  | RequestingPhoneCodeFailed
  | ReturnType<typeof logoutUser>;
