import {InfiniteData} from 'react-query';

import {CompanyOrgs} from 'shared/models/company';

export const getSimilarNames = (name: string, subs: InfiniteData<CompanyOrgs[]>) => {
  if (subs?.pages?.length && name) {
    const filtered = subs?.pages?.map((page) => {
      return page
        .filter((sub: CompanyOrgs) => sub?.group?.name?.toLowerCase()?.includes(name.toLowerCase().trim()))
        .map((el: CompanyOrgs) => el?.group?.name);
    });
    return filtered?.flat();
  } else {
    return [];
  }
};

export const removeDuplicateNames = (names: string[]) => [...Array.from(new Set(names))];
