import {useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router';

import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {TasksLocationState} from 'modules/Tasks/types/location';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useDistinctProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {TaskActiveTab} from 'shared/models/task/const';

import {DailiesReportCard} from '../DailiesReportCard';
import {useDailiesReports} from '../hooks/useDailiesReports';
import {redirectToGanttView} from '../utils/handler';

export function DailyTasksWithReports() {
  const history = useHistory<TasksLocationState>();
  const location = useLocation<{taskId: string}>();
  const {projectId} = useParams<{projectId: string}>();
  const {queryParams} = useFilterContext();
  const {projectWorkers} = useDistinctProjectWorkers(projectId);
  const {taskWithReportTitle, oskMap, tasksWithReports} = useDailiesReports();
  const {
    mixpanel: {events, trackWithAction},
  } = useAnalyticsService();

  useEffect(() => {
    if (location.state.taskId) {
      const el = document.querySelector(`[data-task-id="${location.state.taskId}"]`);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, [location.state?.taskId]);

  return (
    <>
      <h3 className="daily-report-report-title">{taskWithReportTitle}</h3>

      {tasksWithReports?.map((task) => (
        <DailiesReportCard key={task.id} taskId={task.id}>
          <DailiesReportCard.Header
            name={task.name}
            oskMap={oskMap.current}
            outlineSortKey={task.outlineSortKey}
            companyName={task.responsibleOrg?.group?.name}
            startDate={task.formatedStartDate}
            endDate={task.formatedEndDate}
            displayMode="full"
          />
          <DailiesReportCard.Content
            feedbackByDate={task.feedbackByDate}
            displayMode="full"
            projectWorkers={projectWorkers}
            projectedLabor={task.projectedLabor}
            completionTarget={task.completionTarget}
            completionUnit={task.completionUnit}
            handleRedirectToGanttView={() =>
              trackWithAction(
                () =>
                  redirectToGanttView({
                    taskId: task.id,
                    activeTab: TaskActiveTab.chat,
                    history,
                    schedEndFirst: queryParams.schedEndFirst,
                  }),
                events.dailies.dailiesActivityReportClockButton,
              )
            }
            taskId={task.id}
          />
          <DailiesReportCard.Comments taskId={task.id} comments={task.comments} />
        </DailiesReportCard>
      ))}
    </>
  );
}
