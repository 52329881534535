import {GroupMemberRole} from 'shared/models/task/const';
import {CompanyWorker} from 'shared/models/worker';

export const companyWorkerToTaskAssignee = (worker: CompanyWorker, memberRole: GroupMemberRole) => {
  return {
    workerId: worker.workerId,
    memberId: worker.workerId,
    memberName: worker.workerFull.fullName,
    memberTrade: worker.workerFull.trade,
    memberProfilePicUrl: worker.workerFull.profilePicUrl,
    memberRole,
    orgs: worker.orgs,
  };
};
