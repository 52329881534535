import {ComponentType} from 'react';

import {BulkProps, IssuesBulkTypes} from 'modules/Tasks/utils/constants';

import {BulkActionsProps} from '../ActionsBar/types';

import BulkResponsible from './components/bulkResponsible';
import BulkStatus from './components/bulkStatus';

const components: {[key in IssuesBulkTypes]?: ComponentType<BulkProps>} = {
  [IssuesBulkTypes.status]: BulkStatus,
  [IssuesBulkTypes.responsible]: BulkResponsible,
};
export function IssuesBulkActions({gantt, bulkType, closePopup, projectId}: BulkActionsProps) {
  const Component = components[bulkType];
  return Component ? <Component onClose={closePopup} gantt={gantt} projectId={projectId} /> : null;
}
