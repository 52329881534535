import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import CtrlButton from 'shared/components/CoreNewUI/CtrlButton/CtrlButton';
import {useProfile} from 'shared/hooks/useProfile';
import {useRootSelector} from 'store';

import env from '../../constants/env';
import Icon from '../Icon';

import s from './LaptopSteps.module.scss';

const LaptopSteps = () => {
  const {t} = useTranslation('signup');
  const workerProfile = useProfile();
  const onboardingWorker = useRootSelector((state) => state.onboarding.worker);

  const openBookDemo = () => {
    window.open(env.hubspotMeeting);
  };

  return (
    <div className={s.laptopSteps}>
      <div className={s.laptopSteps__steps}>
        <article className={s.laptopSteps__step}>
          <h2 className={s.laptopSteps__stepText}>{t('computer_start.step1', 'On your desktop or laptop, go to')}</h2>
          <div className={cn(s.laptopSteps__address, s.laptopSteps__address_web)}>
            <a
              target="_blank"
              href={env.byCoreBaseLink}
              rel="noreferrer"
              style={{color: 'inherit', textDecoration: 'none'}}
            >
              {t('computer_start.address', 'crews.bycore.com')}
            </a>
          </div>
        </article>
        <Icon className={s.laptopSteps__next} name="arrow_backward" size={24} colorFill />
        <article className={s.laptopSteps__step}>
          <h2 className={s.laptopSteps__stepText}>{t('computer_start.step2', 'Then login with email')}</h2>
          <div className={cn(s.laptopSteps__address, s.laptopSteps__address_email)}>
            {workerProfile?.email || onboardingWorker?.email}
          </div>
        </article>
      </div>
      <div className={s.laptopSteps__note}>
        {t(
          'computer_start.note',
          'Or check your email on your laptop or PC and click on the link we sent you to log-in',
        )}
      </div>
      <div className={s.laptopSteps__actions}>
        <CtrlButton className={s.laptopSteps__button} onClick={openBookDemo}>
          {t('computer_start.buttonBook', 'Book a Demo')}
        </CtrlButton>
      </div>
    </div>
  );
};

export default LaptopSteps;
