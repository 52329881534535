import {FC, useEffect} from 'react';
import {generatePath, useHistory, useRouteMatch} from 'react-router';

import FirebaseDefault from 'services/Firebase';
import {useAuth} from 'shared/components/AuthUserProvider';
import Loader from 'shared/components/Loader';
import MetaTags from 'shared/components/MetaTags';
import {META_KEYWORDS} from 'shared/constants/common';
import {ROUTES, useLocalizedRoutes} from 'shared/constants/routes';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {useScrollToTopOnMount} from 'shared/hooks/core/useScrollToTopOnMount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useClassName} from 'shared/hooks/useClassName';
import {useLandingStyles} from 'shared/hooks/useLandingStyles';
import {OnboardingLayout} from 'shared/layout/base/OnboardingLayout';
import {useRootDispatch, useRootSelector} from 'store';
import {reset} from 'store/onboarding';

import AlternativeSignUp from './Alternative';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';

export type OnboardingStep = {
  number: number;
  name: string;
};

const onboardingSteps: OnboardingStep[] = [
  {
    number: 1,
    name: 'Account Info',
  },
  {
    number: 2,
    name: 'Company Info',
  },
  {
    number: 3,
    name: 'Finish',
  },
];

const SignUp: FC = () => {
  const dispatch = useRootDispatch();
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const matchAltSignUp = useRouteMatch(ROUTES.signUpAltA);
  const {currentStep, isLoading, signupInProgress} = useRootSelector((state) => state.onboarding);
  const {user} = useAuth();
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.signup;

  useScrollToTopOnMount();

  useLandingStyles();
  useClassName(document.body, 's-page', {clearInitialClasses: false});

  useEffectOnce(() => {
    mixpanel.track(mixpanelEvents.page);
  });

  useEffect(() => {
    const resetStore = () => {
      dispatch(reset());
    };
    document.addEventListener('onbeforeunload', resetStore);
    return () => {
      document.removeEventListener('onbeforeunload', resetStore);
      resetStore();
    };
  }, []);

  useEffect(() => {
    if (!user) {
      FirebaseDefault.signInAnonymously();
    }
  }, [user]);

  if (user && !user.isAnonymous && !signupInProgress && currentStep === 1) {
    history.push(generatePath(routes.projects));
    return null;
  }

  return (
    <>
      <MetaTags
        title="Welcome to Crews by Core! Construction Crew & Jobsite Management"
        description="Sign up with Crews by Core-the construction platform to manage crews, track activities & communicate. Stay on schedule with collaborative task management for all."
        keywords={META_KEYWORDS}
      />
      <OnboardingLayout
        onboardingSteps={!matchAltSignUp?.isExact && onboardingSteps}
        currentStep={!matchAltSignUp?.isExact && currentStep}
      >
        {matchAltSignUp?.isExact ? (
          <AlternativeSignUp />
        ) : (
          <>
            {currentStep === 1 && <FirstStep />}
            {currentStep === 2 && <SecondStep />}
            {currentStep === 3 && <ThirdStep />}
          </>
        )}
        {isLoading && <Loader />}
      </OnboardingLayout>
    </>
  );
};

export default SignUp;
