import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import TaskStatusIcon from 'shared/components/TaskStatusIcon';
import {getPrettyTaskStatuses} from 'shared/constants/statuses';
import {TaskStatusType} from 'shared/models/task/taskStatus';

export function useTaskStatusOptions(status: string, state: string) {
  const {t} = useTranslation(['filters', 'gantt']);
  const statuses = getPrettyTaskStatuses(t);
  return useMemo(
    () =>
      Object.keys(statuses)
        .filter((key) => (state === 'active' && !status?.includes(TaskStatusType.archived) ? key !== 'archived' : key))
        .map((key) => ({
          label: statuses[key],
          value: key,
          icon: <TaskStatusIcon name={key as TaskStatusType} />,
        })),
    [state, status],
  );
}
