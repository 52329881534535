import {createSelector} from 'reselect';

import {RootState} from 'store/index';

import {selectAllProjectEntities, selectAllProjects, selectProjectById} from '.';

export const getProjectsLocations = createSelector(selectAllProjects, (projects) => {
  const allLocations = projects.reduce<Array<string>>((locations, project) => {
    return locations.concat(project.locations);
  }, []);
  return Array.from(new Set(allLocations));
});

export const getProjectLocations = createSelector(
  (state: RootState, projectId: string) => selectProjectById(state, projectId),
  (project) => {
    return project?.locations ?? [];
  },
);

export const getProjectResponsibleParties = createSelector(
  (state: RootState, projectId: string) => projectId,
  selectAllProjectEntities,
  (projectId, projects) => {
    return projects[projectId]?.responsibleParties || [];
  },
);
