import cn from 'classnames';
import {cloneElement, isValidElement, ReactNode} from 'react';

import s from './MembersClear.module.scss';

type MembersClearProps = {
  className?: string;
  title: string;
  description: string;
  action: ReactNode;
  view?: 'responsible';
};

const MembersClear = ({className, title, description, action, view}: MembersClearProps) => {
  return (
    <section className={cn(s.MembersClear, view && s[`MembersClear_view_${view}`], className)}>
      <h2 className={s.MembersClear__title}>{title}</h2>
      <div className={s.MembersClear__description}>{description}</div>
      <div className={s.MembersClear__actions}>
        {isValidElement(action) &&
          cloneElement(action, {
            ...action.props,
            className: cn(action.props.className),
          })}
      </div>
    </section>
  );
};

export default MembersClear;
