import {forwardRef} from 'react';
import {useLocation, useRouteMatch} from 'react-router';
import {Redirect, Route, RouteProps} from 'react-router-dom';

import {useAuth} from 'shared/components/AuthUserProvider/AuthUserProvider';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useCompany} from 'shared/hooks/useCompany';
import {useHandleMentionRedirects} from 'shared/hooks/useHandleMentionRedirects';
import {CompanyStatus} from 'shared/models/company';
import {CompanyWorkerRole} from 'shared/models/worker';
import {useRootDispatch, useRootSelector} from 'store';
import {
  activeCompanyWorkerRolesSelector,
  companyWorkersLoaded,
  companyWorkersRolesSelector,
  profileActions,
} from 'store/profile';
import {profileDataLoaded} from 'store/selectors';

type ProtectedRouteProps = RouteProps & {
  roles?: CompanyWorkerRole | CompanyWorkerRole[];
  checkAppRoles?: boolean;
};

const ProtectedRoute = forwardRef<Redirect & Route, ProtectedRouteProps>(({roles, checkAppRoles, ...props}, ref) => {
  const location = useLocation();
  const {isAuthenticated} = useAuth();
  const dispatch = useRootDispatch();
  const routes = useLocalizedRoutes();
  const company = useCompany();

  const {cworkersLoaded, profileReady, workersRoles, showAccessDeniedPopup} = useRootSelector((state) => ({
    cworkersLoaded: companyWorkersLoaded(state),
    profileReady: profileDataLoaded(state),
    workersRoles: checkAppRoles ? companyWorkersRolesSelector(state) : activeCompanyWorkerRolesSelector(state),
    showAccessDeniedPopup: state.profile.showAccessDeniedPopup,
  }));

  const allowedRoles = [].concat(roles || []);
  const isProjectsPage = useRouteMatch(routes.projects)?.isExact;

  const redirectToMentions = useHandleMentionRedirects();

  if (!isAuthenticated()) {
    return <Redirect ref={ref} to={{pathname: routes.logIn, state: {redirectUrl: location?.pathname}}} />;
  }

  if (allowedRoles.length && profileReady && cworkersLoaded) {
    const hasPermission = allowedRoles.some((allowedRole) => workersRoles.includes(allowedRole));
    if (!hasPermission) {
      if (checkAppRoles) {
        return <Redirect ref={ref} to={routes.companyProfile} />;
      } else {
        return <Redirect ref={ref} to={routes.projects} />;
      }
    }
  }

  if (profileReady && !isProjectsPage && company?.status === CompanyStatus.Disabled) {
    if (!showAccessDeniedPopup) {
      dispatch(profileActions.showAccessDeniedPopup(true));
    }
    return <Redirect ref={ref} to={{pathname: routes.projects}} />;
  }

  if (redirectToMentions) {
    return <Redirect to={redirectToMentions} />;
  }

  return <Route ref={ref} {...props} />;
});
ProtectedRoute.displayName = 'ProtectedRoute';
export default ProtectedRoute;
