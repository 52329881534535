import {FormattedProPlanTiers} from 'shared/models/checkout';

export type CalcMonthlyPaymentResult = {
  amount: number;
  seatCount: number;
  matched: FormattedProPlanTiers;
};

export const calcMonthlyPayment = (countWorkers: number, tiers: FormattedProPlanTiers[]): CalcMonthlyPaymentResult => {
  const result = {matched: null, amount: 0, seatCount: countWorkers};
  if (countWorkers < 1) return result;

  const matched = tiers.find((t, i) => {
    // we've made it this far and this would be the last plan, so let's just choose it.
    if (!t.upTo && tiers.length >= 1) {
      return tiers[tiers.length - 1];
    }
    const isExceedLimit = countWorkers <= +t.upTo;
    return tiers[i - 1] ? isExceedLimit && countWorkers > +tiers[i - 1].upTo : isExceedLimit;
  });

  if (matched) {
    return Object.assign(result, {
      matched,
      amount: matched.flatAmountDecimal / 100 + (matched.unitAmountDecimal / 100) * countWorkers,
    });
  }
  return result;
};
