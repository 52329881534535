import cs from 'classnames';
import {FC, useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';

import EventManager from 'services/EventManager';
import Icon from 'shared/components/Icon';
import MonthlyPaymentCalculator from 'shared/components/MonthlyPaymentCalculator';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {CalcMonthlyPaymentResult} from 'shared/helpers/subscriptions';
import {useFormattedProPlanTiers} from 'shared/hooks/useFormattedProPlanTiers';
import {ProPlan} from 'shared/models/checkout';

import './index.scss';

type Props = {
  proPlan: ProPlan;
  onSelectPlan: (quantity: number) => void;
};

const UpgradePlanView: FC<Props> = ({proPlan, onSelectPlan}) => {
  const routes = useLocalizedRoutes();
  const formattedProPlanTiers = useFormattedProPlanTiers(proPlan, 'short');
  const [calcResult, setCalcResult] = useState<CalcMonthlyPaymentResult>(null);
  const {t} = useTranslation('common');

  const onCountWorkersChange = useCallback(
    (res: number) => {
      onSelectPlan(res);
    },
    [onSelectPlan],
  );

  const onCalcResultChange = useCallback((res: CalcMonthlyPaymentResult) => {
    setCalcResult(res);
  }, []);

  return (
    <>
      <MonthlyPaymentCalculator
        tiers={formattedProPlanTiers}
        onInputChange={onCountWorkersChange}
        onResultChange={onCalcResultChange}
        className="form-plan__calc"
      />
      <table className="plan-table form-plan__plans">
        <thead>
          <tr className="plan-table__row">
            <th className="plan-table__cell plan-table__cell--th plan-table__cell--qty">
              {t('tiers.columns.user_numbers', 'Number of Users')}
            </th>
            <th className="plan-table__cell plan-table__cell--th plan-table__cell--qty">Monthly Fee Per Project</th>
          </tr>
        </thead>
        <tbody>
          {formattedProPlanTiers.map((tier, i) => (
            <tr key={i} className={cs('plan-table__row', tier.upTo === calcResult?.matched?.upTo && 'is-active')}>
              <td className="plan-table__cell">
                <div className="plan-table__main">
                  <Icon name="group_equal" colorFill className="plan-table__main-icon" />
                  <div className="plan-table__main-info">
                    <span className="plan-table__main-qty">{tier.seatRange}</span>
                  </div>
                </div>
              </td>
              <>
                <td className="plan-table__cell plan-table__cell--money">
                  <span className="plan-table__money">{tier.formattedFlatPrice}</span>
                </td>
              </>
            </tr>
          ))}
          <td className="plan-table__cell plan-table__cell--contacts" colSpan={3}>
            <a
              className="plan-table__link"
              href={routes.enterpriseContact}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                EventManager.pushToGtmDataLayer({event: 'c4-contacts-enterprise'});
              }}
            >
              {t('contact_sales', 'Contact Sales for custom bundle')}
            </a>
          </td>
        </tbody>
      </table>
    </>
  );
};
export default UpgradePlanView;
