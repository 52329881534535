import {History, Location} from 'history';
import {useEffect} from 'react';

import {TaskActiveTab} from 'shared/models/task/const';

export const useDetectNewTask = <H extends {activeTab?: number; createNewTask?: boolean}>(
  history: History,
  location: Location<H>,
  setActiveTask: (value: string) => void,
) => {
  useEffect(() => {
    if (location?.state?.activeTab === TaskActiveTab.info && location?.state?.createNewTask) {
      setActiveTask('new');
      history.replace({
        pathname: location.pathname,
        search: location.search,
        state: {},
      });
    }
  }, [location?.state?.activeTab, location?.state?.createNewTask]);
};
