import {createAsyncThunk} from '@reduxjs/toolkit';

import WorkersApi from 'api/workers';
import {useCompany} from 'shared/hooks/useCompany';
import {CompanyWorkerRequestParams} from 'shared/models/worker';

export const getCompanyWorkers = createAsyncThunk('workers/getAll', (params: CompanyWorkerRequestParams) => {
  const companyId = useCompany()?.id;
  return WorkersApi.getAll(companyId, params);
});
