import {TFunction} from 'react-i18next';
import {object, string} from 'yup';

import {SelectWorkerType} from './ProjectCollabPopup';

export function isNewAddedWorker({value}: SelectWorkerType) {
  return !isNaN(parseInt(value.workerFull.id));
}

export const validationSchema = (t: TFunction) =>
  object().shape({
    email: string().email((inst) =>
      t('collaboration:popup.validation.email', '{{email}} is not a valid email address', {email: inst.value}),
    ),
    phoneNumber: string().matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, {
      message: (inst) =>
        t('collaboration:popup.validation.phoneNumber', '{{phoneNumber}} is not a valid phone number', {
          phoneNumber: inst.value,
        }),
    }),
  });
