import {IssueModel} from 'shared/models/task/issue';

export const getChangedFields = (formValues: Partial<IssueModel>, issue: IssueModel) => {
  const result = {};
  for (const key of Object.keys(formValues)) {
    if (formValues[key] !== issue[key] && formValues[key] !== undefined) {
      result[key] = formValues[key];
    }
  }

  return result;
};
