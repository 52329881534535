import {useEffect} from 'react';
import {useParams} from 'react-router';

import {LS_LAST_SELECTED_PROJECT_ID} from 'shared/constants/localStorageKeys';
import {RouteParams} from 'shared/constants/routes';
import {setLocalStorageValue} from 'shared/hooks/useLocalStorage';
import {useProfile} from 'shared/hooks/useProfile';
import {useProjectIdValidation} from 'shared/hooks/useProjectIdValidation';

export function useSaveLastSelectedProjectId(path: string) {
  const worker = useProfile();
  const {projectId} = useParams<RouteParams['tasks']>();
  const isProjectIdValid = useProjectIdValidation(path);

  useEffect(() => {
    if (isProjectIdValid) {
      setLocalStorageValue(LS_LAST_SELECTED_PROJECT_ID, projectId, worker?.id);
    }
  }, [isProjectIdValid, projectId]);
}
