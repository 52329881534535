import {
  MatrixConnectionToken,
  MatrixSessionBody,
  MatrixSessionResponse,
  Unread,
  UnreadInfo,
} from 'shared/models/task/chat';

import ApiAxios from './axios';
import {createMatrixAxiosInstance} from './matrix';

class ChatService {
  private static instance: ChatService | null = null;
  private mediaEndpoint = '/_matrix/media/v3';
  private clientEndpoint = '/_matrix/client/v3';

  public static getInstance(): ChatService {
    if (!ChatService.instance) {
      ChatService.instance = new ChatService();
    }
    return ChatService.instance;
  }

  private async getMatrixConnectionToken(workerId: string) {
    const res = await ApiAxios.get<MatrixConnectionToken>(`/workers/${workerId}/chatauth`);
    return res.data;
  }

  async getMatrixServerConfig(accessToken: string) {
    const ChatServiceAxios = await createMatrixAxiosInstance(accessToken);
    const res = await ChatServiceAxios.get<{'m.upload.size': number}>(`${this.mediaEndpoint}/config`);
    return res.data;
  }

  async initializeMatrixSession(workerId: string) {
    const credentials = await this.getMatrixConnectionToken(workerId);
    const body: MatrixSessionBody = {
      type: 'm.login.password',
      identifier: {
        type: 'm.id.user',
        user: credentials.id,
      },
      password: credentials.password,
    };
    const ChatServiceAxios = await createMatrixAxiosInstance();
    const res = await ChatServiceAxios.post<MatrixSessionResponse>(`${this.clientEndpoint}/login`, body);
    return res.data;
  }

  async uploadAssetToMatrix(accessToken: string, file: File) {
    const body = await file.arrayBuffer();
    const ChatServiceAxios = await createMatrixAxiosInstance(accessToken, file.type);
    const res = await ChatServiceAxios.post<{contentUri: string}>(
      `${this.mediaEndpoint}/upload?filename=${file.name}`,
      body,
    );
    return res.data;
  }

  async getJoinedRooms(accessToken: string) {
    const ChatServiceAxios = await createMatrixAxiosInstance(accessToken);

    const res = await ChatServiceAxios.get<{joinedRooms: string[]}>(`${this.clientEndpoint}/joined_rooms`);

    return res.data.joinedRooms;
  }

  async getUnreadCount(accessToken: string, roomIds: string[]) {
    const ChatServiceAxios = await createMatrixAxiosInstance(accessToken);
    if (!roomIds?.length) {
      return;
    }

    const unreadInfos: UnreadInfo[] = [];

    const syncFilter = {
      room: {
        timeline: {limit: 1},
        includeLeave: false,
      },
    };

    const syncResponse = await ChatServiceAxios.get(
      `${this.clientEndpoint}/sync?filter=${encodeURIComponent(JSON.stringify(syncFilter))}&timeout=0`,
    );
    for (const roomId of roomIds) {
      if (syncResponse.data.rooms?.join?.[roomId]) {
        const roomInfo = syncResponse.data.rooms.join[roomId];
        if (roomInfo.unreadNotifications) {
          unreadInfos.push({
            roomId,
            total: roomInfo.unreadNotifications.notificationCount || 0,
            highlight: roomInfo.unreadNotifications.highlightCount || 0,
          });
        }
      }
    }

    const unread: Unread = {
      total: 0,
      highlight: 0,
      from: new Set(),
    };

    unreadInfos.forEach((info) => {
      if (info.total > 0) {
        unread.total += info.total;
        unread.highlight += info.highlight;
        unread.from?.add(info.roomId);
      }
    });

    return unread;
  }
}

export default ChatService.getInstance();
