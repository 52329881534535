import {useEffect, useRef} from 'react';

import {useCompany} from './useCompany';

export function useEffectWithCompanyId(callback: (companyId: string) => void) {
  const companyId = useCompany()?.id;
  const callbackRef = useRef(callback);

  callbackRef.current = callback;

  useEffect(() => {
    if (companyId) {
      callbackRef.current(companyId);
    }
  }, [companyId]);
}
