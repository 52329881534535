import {createSelector} from 'reselect';

import {RootState} from 'store';

export const companyAndProjectsReady = createSelector(
  (state: RootState) => state.profile.worker,
  (state: RootState) => state.profile.activeCompanyId,
  (state: RootState) => state.projects.loaded,
  (worker, activeCompany, projectsLoaded) => worker && activeCompany && projectsLoaded,
);

export const profileDataLoaded = createSelector(
  (state: RootState) => state.profile.worker,
  (state: RootState) => state.profile.companyWorkers,
  (state: RootState) => state.profile.companies,
  (state: RootState) => state.profile.activeCompanyId,
  (worker, companyWorkers, companies, active) => worker && companyWorkers && companies && active,
);
