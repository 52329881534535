import cn from 'classnames';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

import box from '../Box/Box.module.scss';
import CtrlButton from '../CtrlButton/CtrlButton';

import s from './ContentSection.module.scss';

type ContentSectionProps = {
  className?: string;
  title?: string;
  description?: string;
  mediaBlock?: React.ReactElement;
  reverse?: boolean;
};

const ContentSection = ({
  className,
  title = 'Title',
  description = 'Description',
  mediaBlock,
  reverse,
}: ContentSectionProps) => {
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.home;
  const {t} = useTranslation('landing2');
  const routes = useLocalizedRoutes();
  const history = useHistory();

  return (
    <section className={cn(s.contentSection, className)}>
      <div className={box.box}>
        <div className={cn(s.contentSection__container, {[`${s.contentSection__container_reverse}`]: reverse})}>
          <>
            <h2
              data-aos="fade-right"
              data-aos-duration="300"
              className={cn(s.contentSection__title, s.contentSection__title_hideOnDesktop)}
            >
              {title}
            </h2>
            {mediaBlock}
          </>
          <div
            className={cn(s.contentSection__descriptionBlock, {
              [`${s.contentSection__descriptionBlock_reverse}`]: reverse,
            })}
          >
            <>
              <h2
                data-aos="fade-right"
                data-aos-duration="300"
                className={cn(s.contentSection__title, s.contentSection__title_hideOnMobile)}
              >
                {title}
              </h2>
              <div
                data-aos="fade-right"
                data-aos-duration="300"
                data-aos-delay="300"
                className={s.contentSection__description}
              >
                {description}
              </div>
              <div
                data-aos="fade-right"
                data-aos-duration="300"
                data-aos-delay="600"
                className={s.contentSection__actions}
              >
                <CtrlButton
                  text={t('links.button', 'Get Started')}
                  color="second"
                  onClick={() =>
                    mixpanel.trackWithAction(() => history.push(routes.getStarted), mixpanelEvents.getStarted)
                  }
                />
              </div>
            </>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContentSection;
