import {Dispatch, SetStateAction, useEffect, useState} from 'react';

export type HeaderControls = {
  isOpen: boolean;
  onToggle?: () => void;
  open?: () => void;
  close?: () => void;
};

export const useHeaderControls = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSidebarOpenHover, setIsSidebarOpenHover] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSwitcherOpen, setIsSwitcherOpen] = useState(false);
  const [isWorkspaceMenuOpen, setWorkspaceMenuOpen] = useState(false);
  const [isHelperOpen, setIsHelperOpen] = useState(false);

  const toggleMenu = (open: Dispatch<SetStateAction<boolean>>) => {
    open((isOpen) => !isOpen);
  };

  const openHelper = () => {
    setIsHelperOpen(true);
  };

  const closeHelper = () => {
    setIsHelperOpen(false);
  };

  useEffect(() => {
    if (!isSidebarOpen && !isSidebarOpenHover) {
      setIsMenuOpen(false);
      setWorkspaceMenuOpen(false);
      setIsSwitcherOpen(false);
      setIsHelperOpen(false);
    }
  }, [isSidebarOpen, isSidebarOpenHover]);

  useEffect(() => {
    if (isSwitcherOpen) setIsMenuOpen(false);
  }, [isSwitcherOpen]);

  return {
    state: {
      isSidebarOpen,
      isSidebarOpenHover,
      isMenuOpen,
      isSwitcherOpen,
      isHelperOpen,
      isWorkspaceMenuOpen,
    },
    actions: {
      setIsMenuOpen,
      setWorkspaceMenuOpen,
      setIsSidebarOpenHover,
      setIsSwitcherOpen,
      setIsSidebarOpen,
      setIsHelperOpen,
    },
    toggleMenu,
    openHelper,
    closeHelper,
  };
};
