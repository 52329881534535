/* eslint-disable import/no-named-as-default, import/no-named-as-default-member*/
import mixpanel from 'mixpanel-browser';
import ReactPixel from 'react-facebook-pixel';

import env from 'shared/constants/env';

export default {
  pushToGtmDataLayer(...args: unknown[]) {
    if (typeof window.dataLayer !== 'undefined') {
      window.dataLayer.push(...args);
    }
  },
  trackPixelCustomEvent(...params: Parameters<typeof ReactPixel.trackCustom>) {
    ReactPixel.trackCustom(...params);
  },
  trackPixelEvent(...params: Parameters<typeof ReactPixel.track>) {
    ReactPixel.track(...params);
  },
  initPixel() {
    if (env.social.pixelId) {
      // eslint-disable-next-line import/no-named-as-default-member
      ReactPixel.init(env.social.pixelId, null, {
        autoConfig: true,
        debug: false,
      });
      ReactPixel.pageView();
    } else {
      console.warn('PixelId was not provided');
    }
  },
  initMixpanel() {
    if (env.social.mixpanelToken) {
      mixpanel.init(env.social.mixpanelToken);
    } else {
      console.warn('Mixpanel token not provided');
    }
  },
};
