import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';

import MembersClear from '../../../MembersClear/MembersClear';

type Props = {
  onChooseResponsible: () => void;
  disabled?: boolean;
};

const ResponsibleEmpty: FC<Props> = ({disabled, onChooseResponsible}: Props) => {
  const {t} = useTranslation('task');

  return (
    <MembersClear
      title={t('responsible_panel.title', 'Responsible')}
      description={t('responsible_panel.responsible_empty', 'No responsible yet. You can choose somebody')}
      action={
        <CtrlButton disabled={disabled} view="link" onClick={onChooseResponsible}>
          {t('responsible_panel.choose_responsible', 'Choose Responsible')}
        </CtrlButton>
      }
      view="responsible"
    />
  );
};

export default ResponsibleEmpty;
