import {createContext} from 'react';

import {CompanyOrgsExtends} from '../../types';

type SubcontractorsContextType = {
  deleteOrgFromTable: (id: string) => void;
  getOrg: (id: string) => CompanyOrgsExtends;
};

const defaultSubcontractorsValues = {
  deleteOrgFromTable: () => null,
  getOrg: () => null,
};

export const SubcontractorsContext = createContext<SubcontractorsContextType>(defaultSubcontractorsValues);
