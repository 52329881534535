import {useQuery} from 'react-query';

import ProjectsApi from 'api/projects';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

export function useProjectSubcontractorOptions(projectId: string) {
  return useQuery(QUERY_CACHE_KEYS.projectSubcontractors(projectId), () => ProjectsApi.getOrgs(projectId), {
    enabled: !!projectId && projectId !== 'new',
    refetchOnWindowFocus: false,
    select: (orgs) => {
      return orgs?.map((sub) => ({
        value: sub.id,
        label: sub.group.name,
      }));
    },
  });
}
