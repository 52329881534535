import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {LandingLngSwitcher} from 'shared/components/LanguageSwitcher';
import Logo from 'shared/components/Logo';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

import box from '../Box/Box.module.scss';
import CtrlAppDetect from '../CtrlAppDetect/CtrlAppDetect';

import s from './Header.module.scss';

type HeaderProps = {
  className?: string;
};

const Header = ({className}: HeaderProps) => {
  const routes = useLocalizedRoutes();
  const {t} = useTranslation('header');
  const history = useHistory();
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const mixpanelEvents = mixpanel.events.home;

  const onLogin = () => {
    history.push(routes.logIn);
  };

  return (
    <header className={cn(s.header, className)}>
      <div className={box.box}>
        <div className={s.header__container}>
          <Logo className={cn(s.header__logo)} />
          <div className={s.header__actions}>
            <LandingLngSwitcher
              onBeforeChangeLanguage={(language) => mixpanel.track(mixpanelEvents.languageSwitch, {language: language})}
            />
            <button
              className={cn(s.header__login)}
              onClick={() => mixpanel.trackWithAction(onLogin, mixpanelEvents.login)}
            >
              {t('links.login', 'Log in')}
            </button>
            <CtrlAppDetect className={s.header__download} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
