import cn from 'classnames';
import dayjs from 'dayjs';
import {GanttStatic} from 'dhtmlx-gantt';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {useGanttColumns} from 'modules/Tasks/components/Gantt/hooks/useGanttColumns';
import {BASELINE_EXTRA_COLUMNS_NAMES} from 'modules/Tasks/components/Gantt/utils/constants';
import {isBaseLineMode, addBaseLineLayer} from 'modules/Tasks/Views/Gantt/utils/baselineHandlers';
import Button from 'shared/components/Button';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import {GanttNames} from 'shared/constants/gantt';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {safeParseFromLocalStorage} from 'shared/helpers/ls';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useProject} from 'shared/hooks/useProject';
import {BaselineSnapshot, CreateSnapshotPayload} from 'shared/models/task/task';
import {updateCurrentProject} from 'store/projects/actions';
import {updateAppliedBaselineDate} from 'store/tasks/actions';

import {
  getPreparedName,
  BaselineSettings,
  BASELINE_SETTINGS,
  removeBaselineExtraColumns,
  addBaselineExtraColumns,
  addToLocalstorage,
  removeFromLocalstorage,
  isExistExtraColumns,
} from '../../utils';
import DateControl from '../DateControl/DateControl';
import SnapshotsList from '../SnapshotsList/SnapshotsList';

import s from './Baseline.module.scss';

type Props = {
  gantt: GanttStatic;
  closeDropdown: () => void;
};

export enum ActiveControl {
  snapshotList = 'snapshotList',
  datePicker = 'datePicker',
}

const Baseline: FC<Props> = ({gantt, closeDropdown}: Props) => {
  const dispatch = useDispatch();
  const {projectId} = useParams<{projectId: string}>();
  const {project} = useProject(projectId);
  const {t} = useTranslation('gantt');
  const removeLayer = useRef<() => void>();
  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);
  const today = new Date();

  const {updateColumnConfig} = useGanttColumns(gantt, projectId);

  const [snapshots, setSnapshots] = useState<BaselineSnapshot[]>([]);
  const [activeSnapshotId, setActiveSnapshotId] = useState('');
  const [activeControl, setActiveControl] = useState(ActiveControl.datePicker);
  const [cutoff, setCutoff] = useState<string | null>(null); // dayjs().format()
  const [selectedDate, setSelectedDate] = useState<Date>(null);
  const [name, setName] = useState(getPreparedName(today));
  const [isDirtyName, setIsDirtyName] = useState(false);

  useEffect(() => {
    if (project?.baselines) {
      setSnapshots(project?.baselines);
    } else {
      setSnapshots([]);
    }
    if (isDirtyName) setIsDirtyName(false);
  }, [project?.baselines]);

  useEffect(() => {
    if (gantt.name === GanttNames.gantt) {
      if (isBaseLineMode() && !removeLayer.current) {
        const baselineData = safeParseFromLocalStorage<BaselineSettings>(BASELINE_SETTINGS);
        if (selectedDate && cutoff && activeSnapshotId) {
          setTimeout(() => {
            showBaseline();
          });
        }

        if (!selectedDate && dayjs(baselineData.cutoff).isValid()) {
          setSelectedDate(dayjs(baselineData.cutoff).toDate());
        }

        if (!cutoff && dayjs(baselineData.cutoff).isValid()) {
          setCutoff(baselineData.cutoff);
        }

        if (!activeSnapshotId) {
          setActiveSnapshotId(baselineData.activeSnapshotId);
        }
      }
    } else if (gantt.name === GanttNames.lookahead && removeLayer.current) {
      removeLayer.current();
      removeLayer.current = null;
    }
  }, [projectId, gantt.name, selectedDate, cutoff, activeSnapshotId]);

  const onChangeDateRangeValue = useCallback(
    (date: Date) => {
      if (activeControl !== ActiveControl.datePicker) setActiveControl(ActiveControl.datePicker);
      if (activeSnapshotId) setActiveSnapshotId(null);
      setName(getPreparedName(date));
      setSelectedDate(date);
      setCutoff(dayjs(date).toISOString());
    },
    [activeControl, activeSnapshotId],
  );

  const onChangeSnapshotId = useCallback(
    (id: string) => {
      if (activeControl !== ActiveControl.snapshotList) setActiveControl(ActiveControl.snapshotList);
      const snapshotDate = snapshots.find((s) => s.id === id);
      if (snapshotDate?.timestamp) {
        setCutoff(snapshotDate.timestamp);
      }
      setActiveSnapshotId(id);
    },
    [activeControl, snapshots],
  );

  const createSnapshot = async () => {
    const timestamp = selectedDate ? selectedDate : new Date();
    const newSnapshot = {name, timestamp: dayjs(timestamp).toISOString()};
    const payload: CreateSnapshotPayload = {
      id: projectId,
      baselines: [...snapshots, newSnapshot],
    };
    try {
      const res = await TasksApi.createSnapshot(payload);
      dispatch(updateCurrentProject({id: projectId, baselines: res.baselines}));
      setSnapshots(res.baselines);
      setName(getPreparedName(timestamp));
      if (isDirtyName) setIsDirtyName(false);
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(extractAxiosError(err));
      }
    }
  };

  const showBaseline = async () => {
    if (!dayjs(cutoff).isValid()) return;
    if (isBaseLineMode()) {
      removeBaselineExtraColumns(gantt);
      removeLayer.current?.();
    }
    try {
      const res = await TasksApi.getBaselineTasks(projectId, cutoff);
      gantt.baselineTasks = res.tasks;
      removeLayer.current = addBaseLineLayer(gantt);
      addBaselineExtraColumns(gantt, t);
      forceShowBaselineColumns();
      addToLocalstorage(BASELINE_SETTINGS, {cutoff, activeSnapshotId});
      closeDropdown();
      dispatch(updateAppliedBaselineDate({appliedBaselineDate: cutoff}));
      gantt.baselineCutoff = cutoff;
    } catch (err) {
      if (isAxiosError(err)) {
        toast.error(extractAxiosError(err));
      }
    }
  };

  const hideBaseline = () => {
    gantt.config.bar_height = 'full';
    removeFromLocalstorage(BASELINE_SETTINGS);
    removeBaselineExtraColumns(gantt);
    gantt.baselineTasks = [];
    removeLayer.current?.();
    dispatch(updateAppliedBaselineDate({appliedBaselineDate: null}));
    gantt.baselineCutoff = null;
  };

  const deleteSnapshot = useCallback(
    async (id: string) => {
      try {
        const filteredSnapshots = snapshots.filter((s) => s.id !== id);
        const payload = {id: projectId, baselines: filteredSnapshots};
        await TasksApi.deleteSnapshot(projectId, payload);
        if (removeLayer.current && activeSnapshotId === id) hideBaseline();
        dispatch(updateCurrentProject(payload));
        setSnapshots(filteredSnapshots);
      } catch (err) {
        if (isAxiosError(err)) {
          toast.error(extractAxiosError(err));
        }
      }
    },
    [projectId, activeSnapshotId, snapshots],
  );

  const onChangeName = (value: string) => {
    if (!isDirtyName) setIsDirtyName(true);
    setName(value);
  };

  const forceShowBaselineColumns = () => {
    if (!isExistExtraColumns(gantt)) {
      return;
    }
    BASELINE_EXTRA_COLUMNS_NAMES.forEach((name) => {
      updateColumnConfig(name, false);
    });
  };

  return (
    <div className={s.baselineSettings}>
      <div className={s.baselineSettings__group}>
        <div className={s.baselineSettings__item}>
          <DateControl
            selectedDate={selectedDate || today}
            onChangeDateRangeValue={onChangeDateRangeValue}
            projectStartDate={project?.timeCreated}
            activeControl={activeControl}
          />
        </div>
      </div>
      <div className={s.baselineSettings__group}>
        {hasAnyAdminRole ? (
          <>
            <div className={s.baselineSettings__item}>
              <p className={s.baselineSettings__title}>{t('baseline.snapshots', 'Snapshots')}</p>
            </div>
            <div className={s.baselineSettings__item}>
              <div className={s.baselineSettings__formName}>
                <FormControl>
                  <input
                    type="text"
                    className={s.baselineSettings__formNameInput}
                    placeholder={t('baseline.form.input_placeholder', 'Type snapshot name')}
                    value={name}
                    onChange={(e) => onChangeName(e.target.value)}
                  />
                </FormControl>
                <CtrlButton className={s.baselineSettings__formNameButton} color="second" onClick={createSnapshot}>
                  {t('baseline.form.create_snapshot', 'Create snapshot')}
                </CtrlButton>
              </div>
            </div>
          </>
        ) : null}
        <div className={s.baselineSettings__item}>
          <p className={s.baselineSettings__title}>{t('baseline.saved_snapshots', 'Saved Snapshots')}</p>
        </div>
        <div className={s.baselineSettings__item}>
          <SnapshotsList
            disabled={!hasAnyAdminRole}
            snapshots={snapshots}
            deleteSnapshot={deleteSnapshot}
            onChangeSnapshotId={onChangeSnapshotId}
            activeId={activeSnapshotId}
          />
        </div>
        <div className={cn(s.baselineSettings__item, s.baselineSettings__item_actions)}>
          <Button
            className={cn('ctrl-btn--view-border', s.baselineSettings__button, s.baselineSettings__buttonHide)}
            onClick={hideBaseline}
          >
            {t('baseline.form.hide_baseline', 'Hide Baseline')}
          </Button>
          <Button className={s.baselineSettings__button} onClick={showBaseline}>
            {t('baseline.form.show_baseline', 'Show Baseline')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Baseline;
