import {CompanyWorker} from 'shared/models/worker';

export const getCompanyWorkersByRole = (workers: CompanyWorker[]) => {
  return workers.reduce((map, worker) => {
    if (!map[worker.companyId]) map[worker.companyId] = {roles: [], id: '', companyName: ''};
    map[worker.companyId] = {
      roles: Array.from(new Set([...map[worker.companyId]?.roles, ...worker.roles])),
      id: worker.id,
      companyName: worker.companyName,
    };
    return map;
  }, {});
};
