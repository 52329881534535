/**
 *  matches -1, 1, -1.001, or 1.0001
 */
export const FLOATS_REGEX = /^-?\d*\.?\d*$/;

export const INTEGER_REGEX = /^-?\d*$/;

/**
 * matches -00001, -0000, 0000, of 00001
 */
export const ZEROS_REGEX = /^-?0{2,}[1-9]*$/;
