import LaptopSteps from 'shared/components/LaptopSteps/LaptopSteps';
import MobileAuthStep from 'shared/components/MobileAuthStep/MobileAuthStep';

const ComputerStart = () => {
  return (
    <MobileAuthStep>
      <LaptopSteps />
    </MobileAuthStep>
  );
};

export default ComputerStart;
