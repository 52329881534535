import {forwardRef, useCallback, useMemo} from 'react';
import StateManager, {components, SingleValueProps} from 'react-select';
import {Option} from 'react-select/src/filters';

import {taskStatusClassNameOverride} from 'shared/constants/statuses';
import {useTaskStatusOptions} from 'shared/hooks/UseTaskStatusOptions';
import {TaskStatusType} from 'shared/models/task/taskStatus';

import TaskStatusComponent from '../../../TaskStatus';
import Select from '../Select';
import {SelectProps} from '../types';

const STATUS_HEIGHT = 28;

const SingleValue = ({children, ...props}: SingleValueProps<{label: string; value: string}>) => {
  return (
    <components.SingleValue {...props}>
      <TaskStatusComponent value={props.data.value}>{children}</TaskStatusComponent>
    </components.SingleValue>
  );
};

type Props = {
  projectId?: string;
  allowedStatuses?: TaskStatusType[];
} & SelectProps;

const TaskStatusSelect = forwardRef<StateManager, Props>(
  ({className, allowedStatuses, filterOption, projectId, ...selectProps}, ref) => {
    const {workflowStatuses, isLoading, options} = useTaskStatusOptions({getById: projectId});
    const selectedValue = selectProps.value as string;

    const filterOptions = useCallback((option: Option, inputValue: string) => {
      return (
        (filterOption ? filterOption(option, inputValue) : true) &&
        (projectId ? workflowStatuses?.includes(option.value) : true)
        // option.value !== selectProps.value
      );
    }, []);

    const allowedOptions = useMemo(
      () =>
        allowedStatuses?.length
          ? options.filter((op) => allowedStatuses.includes(op.value as TaskStatusType))
          : options,
      [allowedStatuses],
    );

    const minMenuHeight = options.length * STATUS_HEIGHT;
    return (
      <Select
        className={`react-select react-select--status react-select--status-${
          taskStatusClassNameOverride[selectedValue] || selectedValue
        } ${className || ''}`}
        menuPlacement="auto"
        menuPosition="absolute"
        ref={ref}
        minMenuHeight={minMenuHeight}
        classNamePrefix="react-select"
        hideSelectedOptions
        isSearchable={false}
        filterOption={filterOptions}
        formatOptionLabel={({label, value}, {context}) =>
          context === 'menu' ? <TaskStatusComponent value={value} /> : <>{label}</>
        }
        options={allowedOptions}
        isLoading={isLoading}
        components={{SingleValue}}
        {...selectProps}
      />
    );
  },
);
TaskStatusSelect.displayName = 'TaskStatusSelect';
export default TaskStatusSelect;
