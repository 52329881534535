import {GanttStatic} from 'dhtmlx-gantt';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

import {useInlineSelectController} from 'modules/Tasks/components/Gantt/components/Editors/useInlineSelectController';
import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import AsyncTaskTypeSelect from 'shared/components/CoreForm/Select/AsyncTaskTypeSelect';

type Props = {
  gantt: GanttStatic;
  task: GanttTask;
};

const TaskTypeEditor: FC<Props> = ({gantt, task}: Props) => {
  const {t} = useTranslation('gantt');
  const {updateValue, onKeyUp, onKeyDown, onClose} = useInlineSelectController(gantt);

  const updateTaskType = (value: string) => {
    if (task.taskType !== value) {
      updateValue(task, 'taskType', value ?? null);
    }
  };

  return (
    <AsyncTaskTypeSelect
      isCreatable
      isFocusWhenLoading
      isClearable
      size="xs"
      onChange={updateTaskType}
      value={task?.taskType}
      name="type"
      placeholder={t('activity.type', 'Activity Type')}
      _projectId={task.project_id || task.projectId}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onMenuClose={onClose}
      menuIsOpen={true}
      closeMenuOnSelect={false}
      tabSelectsValue={false}
    />
  );
};

export default TaskTypeEditor;
