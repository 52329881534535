import {DependencyList, useEffect, useRef} from 'react';

export function useEffectAfterMount(effect: (() => void) | (() => () => void), deps: DependencyList) {
  const initialRun = useRef(false);

  useEffect(() => {
    if (initialRun.current) {
      return effect();
    }
    initialRun.current = true;
  }, deps);
}
