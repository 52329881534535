import dayjs from 'dayjs';
import {TFunction} from 'i18next';
import {object, string, date} from 'yup';

export const getProjectValidationSchema = (t: TFunction) =>
  object().shape(
    {
      name: string()
        .max(200, t('common:validation.max', {max: 200}))
        .required(t('common:validation.required'))
        .test('onlySpaces', t('common:validation.required'), (value) => value?.replaceAll(' ', '').length !== 0),
      timezone: string().nullable().required(t('common:validation.required')),
      country: string()
        .nullable()
        .when('state', {
          is: (state) => !!state,
          then: string().required(t('common:validation.required')),
          otherwise: string().nullable(),
        })
        .when('city', {
          is: (city) => !!city,
          then: string().required(t('common:validation.required')),
          otherwise: string().nullable(),
        }),
      state: string()
        .when('country', {
          is: (country) => !!country && country !== 'Singapore',
          then: string().required(t('common:validation.required')),
          otherwise: string(),
        })
        .when('city', {
          is: (city) => !!city,
          then: string().required(t('common:validation.required')),
          otherwise: string(),
        }),
      city: string()
        .when('state', {
          is: (state) => !!state,
          then: string().required(t('common:validation.required')),
          otherwise: string(),
        })
        .when('country', {
          is: (country) => !!country && country !== 'Singapore',
          then: string().required(t('common:validation.required')),
          otherwise: string(),
        }),
      defaultTaskStart: getProjectDateSchema(t, false),
      defaultTaskEnd: getProjectDateSchema(t, true),
    },
    [
      ['state', 'country'],
      ['state', 'city'],
      ['country', 'city'],
    ],
  );

function getProjectDateSchema(t: TFunction, isAfter: boolean) {
  return date()
    .nullable()
    .required(t('common:validation.required'))
    .test({
      name: 'same',
      message: t('project:validation.working_hours', 'Working hours end time must be later than start time.'),
      test: function (value) {
        return dayjs(value)[isAfter ? 'isAfter' : 'isBefore'](
          this.parent[isAfter ? 'defaultTaskStart' : 'defaultTaskEnd'],
          'm',
        );
      },
    });
}
