import {GanttStatic} from 'dhtmlx-gantt';

// TODO: refactor to hooks
export abstract class GanttPluginBase implements GanttPlugin {
  protected constructor(protected gantt: GanttStatic) {}
  abstract init();
  abstract clear(): void;
}

export interface GanttPlugin {
  init(): void;
  clear(): void;
}
export interface GanttPluginConstructor {
  new (gantt: GanttStatic): GanttPlugin;
}
