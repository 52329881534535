import {useEffect} from 'react';

export function useMount(fn: () => void | (() => void)) {
  useEffect(() => {
    const cleanup = fn();
    return () => {
      if (typeof cleanup === 'function') {
        cleanup();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
