import {Switch, useRouteMatch} from 'react-router';
import {Redirect, Route} from 'react-router-dom';

import ProcoreOAuth from 'modules/OAuth/ProcoreOAuth';
import {useLocalizedRoutes} from 'shared/constants/routes';

export default function OAuth() {
  const match = useRouteMatch();
  const routes = useLocalizedRoutes();

  return (
    <Switch>
      <Route path={[`${match.path}/procore`, `${match.path}/return`]} component={ProcoreOAuth} />
      <Route
        path="*"
        render={() => {
          window.close(); // will close unsupported modal window
          return <Redirect to={routes.projects} />;
        }}
      />
    </Switch>
  );
}
