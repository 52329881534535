import cn from 'classnames';

import s from './CommentsNew.module.scss';

type CommentsNewProps = {
  className?: string;
};

const CommentsNew = ({className}: CommentsNewProps) => {
  return <div className={cn(s.CommentsNew, className)}>New messages</div>;
};

export default CommentsNew;
