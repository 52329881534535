import {FC} from 'react';

import {AssignmentState} from '../../utils/constants';
import {useAssigneesTabContext} from '../AssigneesTabContext';

import AddNewWatchers from './AddNewWatchers';
import SelectedWatchersList from './SelectedWatchersList';
import WatchersEmpty from './WatchersEmpty';

type Props = {
  assignmentState: AssignmentState;
  disabled?: boolean;
};

const WatchersSection: FC<Props> = ({assignmentState, disabled}: Props) => {
  const {watchers, setAssignmentState} = useAssigneesTabContext();

  return (
    <>
      {!watchers.length && assignmentState !== AssignmentState.addWatcher && (
        <WatchersEmpty disabled={disabled} addWatcher={() => setAssignmentState(AssignmentState.addWatcher)} />
      )}
      {!!watchers.length && assignmentState !== AssignmentState.addWatcher && (
        <SelectedWatchersList disabled={disabled} addWatchers={() => setAssignmentState(AssignmentState.addWatcher)} />
      )}
      {assignmentState === AssignmentState.addWatcher && (
        <AddNewWatchers onClose={() => setAssignmentState(AssignmentState.empty)} />
      )}
    </>
  );
};
export default WatchersSection;
