import {InviteBy, WorkerFormValues} from '../models/worker';

import {DEFAULT_COUNTRY_CODES} from './common';

export const WORKER_UNSPECIFIED_TRADE = 'unspecified';

export const defaultValues: WorkerFormValues = {
  fullName: '',
  phoneCode: DEFAULT_COUNTRY_CODES[0].value,
  phoneNumber: '',
  workerEmail: '',
  trade: WORKER_UNSPECIFIED_TRADE,
  sendEmail: false,
  sendSms: false,
  roles: ['worker'],
  orgMappingIds: null,
  inviteBy: InviteBy.Email,
};
