import ApiAxios from 'api/axios';
import {ExternalServices} from 'shared/constants/common';

interface ExternalSources {
  service: ExternalServices;
  sources: {
    identifier: string;
    name: string;
    sourceSpecific: {
      [key: string]: string;
    };
  }[];
}

type ExternalFiles = {
  id: string;
  name: string;
  nameWithPath: string;
}[];

class ExternalSourcesService {
  get(workerId: string, service: ExternalServices = ExternalServices.Procore) {
    return ApiAxios.get<ExternalSources>(`workers/${workerId}/external_sources`, {params: {service}}).then(
      (res) => res.data,
    );
  }

  getFiles(workerId: string, sourceId: string, service: ExternalServices = ExternalServices.Procore) {
    return ApiAxios.get<ExternalFiles>(`workers/${workerId}/external_files`, {params: {service, sourceId}}).then(
      (res) => res.data,
    );
  }
}

export default new ExternalSourcesService();
