import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import ApiAxios from 'api/axios';
import {reloadProjectData} from 'modules/Tasks/utils/functions';
import {isAxiosError} from 'shared/helpers/axios';
import {useConfirm} from 'shared/components/Confirmation';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';

export function useTasksLockedInterceptor() {
  const {confirm} = useConfirm();
  const {t} = useTranslation();
  const {mixpanel} = useAnalyticsService();
  useEffect(() => {
    const intsp = ApiAxios.interceptors.response.use(undefined, (err) => {
      if (isAxiosError(err) && err.response?.status === 423) {
        confirm(t('gantt:toast.error.need_reload', 'Something has changed in the project, please reload')).then(
          (res) => {
            mixpanel.track(mixpanel.events.tasks.lockedOperation, {
              api: err.config.url,
            });
            res && reloadProjectData();
          },
        );
      }
      return Promise.reject(err);
    });
    return () => ApiAxios.interceptors.response.eject(intsp);
  }, []);
}
