import cn from 'classnames';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Review from '../Reviews/Review/Review';

import {getSlides, avatarPath} from './constants';
import s from './Slider.module.scss';

const Slider = () => {
  const {t} = useTranslation(['landing2']);
  const slides = getSlides(t);
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const prevSlideIndex = activeSlideIndex === 0 ? slides.length - 1 : activeSlideIndex - 1;
  const nextSlideIndex = activeSlideIndex === slides.length - 1 ? 0 : activeSlideIndex + 1;
  const activeSlide = useRef<HTMLDivElement>(null);

  const nextSlide = () => {
    const lastSlide = slides.length === activeSlideIndex + 1;
    setActiveSlideIndex((prev) => (lastSlide ? 0 : prev + 1));
  };

  const prevSlide = () => {
    const firstSlide = activeSlideIndex === 0;
    const lastSlideIndex = slides.length - 1;
    setActiveSlideIndex((prev) => (firstSlide ? lastSlideIndex : prev - 1));
  };

  useEffect(() => {
    let startX = 0;
    let startY = 0;

    const swipeStartHandler = (e) => {
      startX = e.changedTouches[0].screenX;
      startY = e.changedTouches[0].screenY;
    };

    const swipeEndHandler = (e) => {
      const diffX = e.changedTouches[0].screenX - startX;
      const diffY = e.changedTouches[0].screenY - startY;
      if (diffX > diffY) {
        prevSlide();
      }
      if (diffX < diffY) {
        nextSlide();
      }
    };
    activeSlide?.current?.addEventListener('touchstart', swipeStartHandler);
    activeSlide?.current?.addEventListener('touchend', swipeEndHandler);
    return () => {
      activeSlide?.current?.removeEventListener('touchstart', swipeStartHandler);
      activeSlide?.current?.removeEventListener('touchend', swipeEndHandler);
    };
  }, [activeSlideIndex]);

  return (
    <div className={s.slider}>
      <div className={s.slider__wrap}>
        <div className={cn(s.slider__prev)}>
          <Review
            text={slides[prevSlideIndex]?.text}
            authorName={slides[prevSlideIndex]?.authorName}
            authorPosition={slides[prevSlideIndex]?.authorPosition}
            // authorImage={slides[prevSlideIndex]?.authorImage}
            // authorImage2x={slides[prevSlideIndex]?.authorImage2x}
            authorImage={
              <picture>
                <source
                  type="image/webp"
                  srcSet={`
                    ${avatarPath}/${slides[prevSlideIndex]?.authorImage}@1x.webp,
                    ${avatarPath}/${slides[prevSlideIndex]?.authorImage}@2x.webp 2x
                  `}
                />
                <source
                  type="image/jpg"
                  srcSet={`
                    ${avatarPath}/${slides[prevSlideIndex]?.authorImage}@1x.jpg,
                    ${avatarPath}/${slides[prevSlideIndex]?.authorImage}@2x.jpg 2x
                  `}
                />
                <img
                  src={`${avatarPath}/${slides[prevSlideIndex]?.authorImage}@1x.jpg`}
                  alt={slides[prevSlideIndex]?.authorName}
                />
              </picture>
            }
          />
        </div>
        <div className={cn(s.slider__active, s.slider__controlContainer_show)} ref={activeSlide}>
          <Review
            text={slides[activeSlideIndex]?.text}
            authorName={slides[activeSlideIndex]?.authorName}
            authorPosition={slides[activeSlideIndex]?.authorPosition}
            // authorImage={slides[activeSlideIndex]?.authorImage}
            // authorImage2x={slides[activeSlideIndex]?.authorImage2x}
            authorImage={
              <picture>
                <source
                  type="image/webp"
                  srcSet={`
                    ${avatarPath}/${slides[activeSlideIndex]?.authorImage}@1x.webp,
                    ${avatarPath}/${slides[activeSlideIndex]?.authorImage}@2x.webp 2x
                  `}
                />
                <source
                  type="image/jpg"
                  srcSet={`
                    ${avatarPath}/${slides[activeSlideIndex]?.authorImage}@1x.jpg,
                    ${avatarPath}/${slides[activeSlideIndex]?.authorImage}@2x.jpg 2x
                  `}
                />
                <img
                  src={`${avatarPath}/${slides[activeSlideIndex]?.authorImage}@1x.jpg`}
                  alt={slides[activeSlideIndex]?.authorName}
                />
              </picture>
            }
          />
          <div className={s.slider__controlContainer}>
            <button className={s.slider__control} onClick={prevSlide}>
              <svg
                className={s.slider__control__icon}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                width="24px"
                height="24px"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
              </svg>
            </button>
            <button className={cn(s.slider__control)} onClick={nextSlide}>
              <svg
                className={cn(s.slider__control__icon, s.slider__control__icon_right)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                width="24px"
                height="24px"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </button>
          </div>
        </div>
        <div className={s.slider__next}>
          <Review
            text={slides[nextSlideIndex]?.text}
            authorName={slides[nextSlideIndex]?.authorName}
            authorPosition={slides[nextSlideIndex]?.authorPosition}
            // authorImage={slides[nextSlideIndex]?.authorImage}
            // authorImage2x={slides[nextSlideIndex]?.authorImage2x}
            authorImage={
              <picture>
                <source
                  type="image/webp"
                  srcSet={`
                    ${avatarPath}/${slides[nextSlideIndex]?.authorImage}@1x.webp,
                    ${avatarPath}/${slides[nextSlideIndex]?.authorImage}@2x.webp 2x
                  `}
                />
                <source
                  type="image/jpg"
                  srcSet={`
                    ${avatarPath}/${slides[nextSlideIndex]?.authorImage}@1x.jpg,
                    ${avatarPath}/${slides[nextSlideIndex]?.authorImage}@2x.jpg 2x
                  `}
                />
                <img
                  src={`${avatarPath}/${slides[nextSlideIndex]?.authorImage}@1x.jpg`}
                  alt={slides[nextSlideIndex]?.authorName}
                />
              </picture>
            }
          />
        </div>
      </div>
    </div>
  );
};
export default Slider;
