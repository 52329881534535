import {GanttStatic} from 'dhtmlx-gantt';
import {useEffect, useState} from 'react';

import {debounce} from 'shared/helpers/debounce';
import {TaskGanttLastChangedFields} from 'shared/models/task/task';

export function useUndo(gantt: GanttStatic) {
  const [canRedo, setCanRedo] = useState(false);
  const [cantUndo, setCantUndo] = useState(false);
  useEffect(() => {
    const events = [];
    const stackChanged = debounce(() => {
      setCanRedo(!!gantt.getRedoStack().length);
      setCantUndo(!!gantt.getUndoStack().length);
    }, 100);
    events.push(
      gantt.attachEvent(
        'onBeforeUndoStack',
        (action) => {
          for (const {value, oldValue, entity} of action.commands) {
            if (entity === 'task') {
              const changes = value.lastChangedFields;
              if (changes) {
                oldValue.lastChangedFields = Object.entries<TaskGanttLastChangedFields[string]>(changes).reduce(
                  (acc, [key, value]) => {
                    return Object.assign(acc, {
                      [key]: {
                        oldValue: value.newValue,
                        newValue: value.oldValue,
                      },
                    });
                  },
                  {} as TaskGanttLastChangedFields,
                );
              }
            }
          }
          stackChanged();
          return true;
        },
        undefined,
      ),
    );
    events.push(
      gantt.attachEvent(
        'onBeforeRedoStack',
        () => {
          stackChanged();
          return true;
        },
        undefined,
      ),
    );
    events.push(
      gantt.attachEvent(
        'onBeforeRedoStack',
        () => {
          stackChanged();
          return true;
        },
        undefined,
      ),
    );
    events.push(gantt.attachEvent('onAfterUndo', stackChanged, undefined));
    events.push(gantt.attachEvent('onAfterRedo', stackChanged, undefined));
    events.push(gantt.attachEvent('onGanttReady', stackChanged, undefined));
    return () => events.forEach((id) => gantt.detachEvent(id));
  }, [gantt, setCantUndo, setCanRedo]);

  return {
    canRedo,
    cantUndo,
  };
}
