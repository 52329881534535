import cn from 'classnames';
import dayjs from 'dayjs';
import {memo} from 'react';

import InfoWorker from 'shared/components/CoreNewUI/InfoWorker/InfoWorker';
import {BaselineSnapshot} from 'shared/models/task/task';
import {CompanyWorker} from 'shared/models/worker';

import s from './Snapshot.module.scss';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';

type Props = {
  snapshot: BaselineSnapshot;
  isActive?: boolean;
  onClick?: (id: string) => void;
  deleteSnapshot?: (id: string) => void;
  worker: CompanyWorker;
  disabled?: boolean;
};

const Snapshot = ({snapshot, onClick, deleteSnapshot, disabled, isActive, worker}: Props) => {
  const prepareDate = (date: string) => {
    return dayjs(date).format('ll, LT');
  };

  return (
    <div className={cn(s.snapshot, {[s.snapshot_isActive]: isActive})} onClick={() => onClick(snapshot.id)}>
      <div className={cn(s.snapshot__cell, s.snapshot__cell_date)}>{prepareDate(snapshot.timestamp)}</div>
      <div className={cn(s.snapshot__cell, s.snapshot__cell_worker)}>
        <InfoWorker size="xs" workerName={snapshot.createdByName} avatar={worker?.workerFull?.profilePicUrl} />
      </div>
      <div className={cn(s.snapshot__cell, s.snapshot__cell_name)}>{snapshot.name}</div>
      {disabled ? null : (
        <div className={cn(s.snapshot__cell, s.snapshot__cell_actions)}>
          <CtrlButton
            className={s.snapshot__buttonRemove}
            icon="remove_circle_outlined"
            iconOnly
            size="xs"
            color="actionWarning"
            onClick={(e) => {
              e.stopPropagation();
              deleteSnapshot(snapshot.id);
            }}
          >
            Remove Snapshot
          </CtrlButton>
        </div>
      )}
    </div>
  );
};

export default memo(Snapshot);
