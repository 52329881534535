import {nanoid} from 'nanoid';
import {useEffect, useRef} from 'react';

import {TasksObserver} from 'services/TasksObserver/TasksObserver';
import {useTasksObserver} from 'services/TasksObserver/TasksObserverProvider';

export function useTasksSubscription(
  ...[callback, config, id]: Parameters<InstanceType<typeof TasksObserver>['subscribe']>
) {
  const observer = useTasksObserver();
  const subscriptionId = useRef(id || nanoid());

  const callbackRef = useRef(callback);
  callbackRef.current = callback;

  useEffect(() => {
    return observer.subscribe((...args) => callbackRef.current(...args), config, subscriptionId.current);
  }, []);

  return [subscriptionId.current, observer] as const;
}
