import {useEffect, useRef, useState} from 'react';
import {generatePath, matchPath, useHistory, useLocation} from 'react-router';

import {useLocalizedRoutes} from 'shared/constants/routes';
import {useRootSelector} from 'store';
import {selectActiveCompany} from 'store/profile';
import {getActiveProjectFromLocalStorage, setActiveProjectToLocalStorage} from 'store/profile/utils';
import {selectAllProjects} from 'store/projects';

export function useProjectIdValidation(path: string | string[], projectIdParam = 'projectId') {
  const [valid, setValid] = useState(false);
  const verifiedProjectId = useRef<string>(null);
  const routes = useLocalizedRoutes();
  const location = useLocation();
  const history = useHistory();
  const {company, profile, projects, projectsLoaded} = useRootSelector((state) => ({
    company: selectActiveCompany(state),
    projects: selectAllProjects(state),
    profile: state.profile.worker,
    projectsLoaded: state.projects.loaded,
  }));

  useEffect(() => {
    const matched = matchPath<{id: string; projectId: string; status: string}>(location.pathname, {
      path,
    });
    if (matched && company?.id && projectsLoaded) {
      const projectId = matched.params?.[projectIdParam];
      if (!projects.some((project) => projectId === project.id)) {
        const previousProjectId = getActiveProjectFromLocalStorage(profile.id, company.id);
        const redirectToProjectId =
          (projects.some((project) => project.id === previousProjectId) && previousProjectId) || projects?.[0]?.id;
        setValid(false);
        history.push(
          redirectToProjectId
            ? generatePath(matched.path, {...matched.params, projectId: redirectToProjectId})
            : routes.projects,
        );
      } else if (verifiedProjectId.current !== projectId) {
        verifiedProjectId.current = projectId;
        setActiveProjectToLocalStorage(profile.id, company.id, projectId);
        setValid(true);
      }
    }
  }, [path, projectIdParam, location, company?.id, projects, profile?.id, setValid, history]);

  return valid;
}
