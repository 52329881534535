import cn from 'classnames';

import Icon from 'shared/components/Icon';

import s from './InfoWorker.module.scss';

type InfoWorkerProps = {
  className?: string;
  size?: 'xs';
  avatar?: string;
  workerName: string;
};

const InfoWorker = ({className, size = 'xs', avatar, workerName}: InfoWorkerProps) => {
  return (
    <div className={cn(s.infoWorker, size && s[`infoWorker_size_${size}`], className)}>
      <div className={cn(s.infoWorker__avatarBox, !avatar && s.infoWorker__avatarBox_noAvatar)}>
        {avatar ? (
          <img className={s.infoWorker__avatar} src={avatar} alt={workerName} />
        ) : (
          <Icon className={s.infoWorker__icon} name="user" colorFill />
        )}
      </div>
      <span className={s.infoWorker__box}>
        <span className={s.infoWorker__name}>{workerName}</span>
      </span>
    </div>
  );
};

export default InfoWorker;
