import cn from 'classnames';
import {ReactElement} from 'react';
import {useTranslation} from 'react-i18next';

import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';

import SelectGroup from './components/SelectGroup';
import s from './MembersList.module.scss';

type MembersListProps = {
  className?: string;
  membersCount: number;
  isMemberList?: boolean;
  view?: 'search';
  addWatchers?: () => void;
  selectAll?: () => void;
  deselectAll?: () => void;
  selectedAll?: boolean;
  children: ReactElement;
  disabled?: boolean;
};

const MembersList = ({
  className,
  view,
  membersCount,
  isMemberList,
  addWatchers,
  children,
  selectAll,
  selectedAll,
  deselectAll,
  disabled,
}: MembersListProps) => {
  const {t} = useTranslation('task');

  return (
    <section className={cn(s.MembersList, view && s[`MembersList_view_${view}`], className)}>
      <header className={s.MembersList__header}>
        <h2 className={s.MembersList__title}>
          {t('responsible_panel.members', 'Members')}
          <span className={s.MembersList__count}>{membersCount}</span>
        </h2>
        {isMemberList && view === 'search' && (
          <div className={s.MembersList__headerActions}>
            <CtrlButton view="link" onClick={selectedAll ? deselectAll : selectAll}>
              {selectedAll
                ? t('responsible_panel.deselect_all', 'Deselect all')
                : t('responsible_panel.select_all', 'Select all')}
            </CtrlButton>
          </div>
        )}
        {isMemberList && view !== 'search' && (
          <CtrlButton disabled={disabled} view="link" onClick={addWatchers}>
            {t('responsible_panel.watchers_add', 'Add Watchers')}
          </CtrlButton>
        )}
      </header>
      {isMemberList && view === 'search' && <SelectGroup />}
      <div className={s.MembersList__body}>{children}</div>
    </section>
  );
};

export default MembersList;
