import {ComponentProps, forwardRef, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery, useQueryClient} from 'react-query';
import {useParams} from 'react-router';
import StateManager, {createFilter} from 'react-select';

// eslint-disable-next-line import/no-named-as-default
import {toast} from 'react-toastify';

import ProjectsApi from 'api/projects';
import CoreSelectField from 'shared/components/CoreForm/Select/Select';
import {defaultReactSelectStyles} from 'shared/components/CoreForm/Select/styles';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {sortAlphabetically} from 'shared/helpers/common';
import {CompanyOrgs} from 'shared/models/company';

// override stringify option, because by default it filter also by ids
// stringify: option => `${option.label} ${option.value}`,
const filterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: (option) => option.label,
  trim: true,
});

type Props = {
  name?: string;
  value: string;
  onChange: (value: string) => void;
  loadingPlaceholder?: string;
  size?: 'xs';
  _projectId?: string;
  placeholder?: string;
  onAfterCreateOption?: (subcontractor: CompanyOrgs) => void;
} & Omit<ComponentProps<typeof CoreSelectField>, 'value'>;

export const AsyncProjectSubcontractorSelect = forwardRef<StateManager, Props>(
  (
    {
      name,
      value,
      onChange,
      loadingPlaceholder = 'Wait...',
      isDisabled,
      isCreatable,
      placeholder,
      size,
      _projectId,
      onAfterCreateOption,
      ...props
    },
    ref,
  ) => {
    const {projectId: paramsProjectId} = useParams<{projectId: string; id: string}>();
    const [selected, setSelected] = useState<string>(null);
    const queryClient = useQueryClient();
    const projectId = _projectId || paramsProjectId;
    const {t} = useTranslation('common');
    const [isCreating, setIsCreating] = useState(false);
    const {data: options, isLoading: isFetching} = useQuery(
      QUERY_CACHE_KEYS.projectSubcontractors(projectId),
      () => ProjectsApi.getOrgs(projectId),
      {
        enabled: !!projectId,
        refetchOnWindowFocus: false,
        select: (orgs) => {
          const preparedOrgs = orgs.map((sub) => ({value: sub.id, label: sub.group.name} as CoreOptionType));
          return sortAlphabetically(preparedOrgs, 'label');
        },
      },
    );

    const isLoading = isFetching || isCreating;

    useEffect(() => {
      const defaultSelectedValue = options?.find((sub) => sub.value === value)?.value;
      setSelected(defaultSelectedValue || null);
    }, [value, options]);

    const onSelectChange = (value: string) => {
      setSelected(value);
      onChange(value || null);
    };

    const onCreateOption = async (value: string) => {
      setIsCreating(true);
      try {
        const createdSubcontractor = await ProjectsApi.createOrg(projectId, {name: value});
        onAfterCreateOption(createdSubcontractor);
        queryClient.refetchQueries(QUERY_CACHE_KEYS.projectSubcontractors(projectId));
      } catch (error) {
        if (isAxiosError(error)) {
          toast.error(extractAxiosError(error));
        }
      } finally {
        setIsCreating(false);
      }
    };

    const SelectType = isCreatable ? CoreSelectField.Creatable : CoreSelectField;
    const noOptionsMsg = () =>
      isCreatable
        ? t('dropdown.no_options_create', 'No options. You can create one.')
        : t('dropdown.no_options', 'No options');

    return (
      <SelectType
        filterOption={filterOption}
        ref={ref as typeof SelectType}
        isSearchable={true}
        isClearable={true}
        menuPlacement="auto"
        isDisabled={isLoading || isDisabled}
        isLoading={isLoading}
        options={options}
        name={name}
        placeholder={isLoading ? loadingPlaceholder : placeholder}
        className={`react-select ${size ? 'react-select--size-xs' : ''}`}
        classNamePrefix="react-select"
        onChange={onSelectChange}
        noOptionsMessage={noOptionsMsg}
        styles={defaultReactSelectStyles}
        value={selected || null}
        onCreateOption={onCreateOption}
        {...props}
      />
    );
  },
);
AsyncProjectSubcontractorSelect.displayName = 'AsyncProjectSubcontractorSelect';
export default AsyncProjectSubcontractorSelect;
