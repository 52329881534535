import cn from 'classnames';
import {HTMLAttributes} from 'react';

import s from './CtrlSwitch.module.scss';

interface Props extends HTMLAttributes<HTMLInputElement> {
  className?: string;
  label: string;
  view?: 'control';
  onChange?: (value) => void;
  checked: boolean;
}

const CtrlSwitch = ({className, label, view, onChange, checked}: Props) => {
  return (
    <label className={cn(s.CtrlSwitch, view && s[`CtrlSwitch_view_${view}`], className)}>
      <input type="checkbox" className={s.CtrlSwitch__field} onChange={onChange} checked={checked} />
      <span className={s.CtrlSwitch__visual}></span>
      <span className={s.CtrlSwitch__label}>{label}</span>
    </label>
  );
};

export default CtrlSwitch;
