import {useEffect, useMemo, useCallback} from 'react';
import {useQuery, useQueryClient} from 'react-query';

import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useAppConstants} from 'shared/hooks/useAppConstants';
import {useProfile} from 'shared/hooks/useProfile';

import {getMatrixConnectionToken} from '../utils/apiHelpers';

export function useInitializeMatrixSession() {
  const queryClient = useQueryClient();
  const worker = useProfile();
  const appConstants = useAppConstants();
  const homeServer = appConstants?.locales?.uS.matrix.homeserver;

  const fetchMatrixToken = useCallback(
    () => getMatrixConnectionToken({workerId: worker.id, homeServer}),
    [homeServer, worker.id],
  );

  const MATRIX_TOKEN_CACHE = useMemo(() => [QUERY_CACHE_KEYS.matrixToken, worker.id], [worker.id]);

  useEffect(() => {
    if (!queryClient.getQueryData(MATRIX_TOKEN_CACHE)) {
      queryClient.prefetchQuery(MATRIX_TOKEN_CACHE, fetchMatrixToken);
    }
  }, [queryClient, MATRIX_TOKEN_CACHE, fetchMatrixToken]);

  const query = useQuery(MATRIX_TOKEN_CACHE, fetchMatrixToken);

  return query;
}
