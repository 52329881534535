import {ContactRequest} from 'shared/models/contactForm';

import ApiAxios from './axios';

const CONTACT_PATH = '/contactus';

export default {
  sendContactRequest(contactRequest: ContactRequest) {
    return ApiAxios.post(CONTACT_PATH, contactRequest)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  },
};
