import {useEffect, useRef} from 'react';

export interface UseOutsideClickParams<T extends Node> {
  ref: React.RefObject<T>;
  callback: (e: MouseEvent | TouchEvent) => void;
  ignore?: string | string[];
  capture?: boolean;
}

export function useOutsideClick<T extends Node>({
  ref,
  callback,
  ignore = '',
  capture = false,
}: UseOutsideClickParams<T>) {
  const cb = useRef(callback);

  // Update current callback reference whenever the callback changes
  useEffect(() => {
    cb.current = callback;
  }, [callback]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      if (!ref || !ref.current) {
        return;
      }

      const element = ref.current;

      if (element && event.target instanceof Element) {
        let shouldIgnore = false;
        const targetElement = event.target as Element;

        if (Array.isArray(ignore)) {
          const nonEmptySelectors = ignore.filter((selector) => selector.trim() !== '');
          shouldIgnore = nonEmptySelectors.some((selector) => targetElement.closest(selector));
        } else if (ignore.trim() !== '') {
          shouldIgnore = Boolean(targetElement.closest(ignore));
        }

        const isOutsideClick = !element.contains(event.target as Node);

        if (typeof cb.current === 'function' && isOutsideClick && !shouldIgnore) {
          if (event instanceof MouseEvent && event.button !== 0) {
            return;
          }
          cb.current(event);
        }
      }
    }

    document.addEventListener('mouseup', handleClickOutside, {capture});
    document.addEventListener('touchstart', handleClickOutside, {capture});

    return () => {
      document.removeEventListener('mouseup', handleClickOutside, {capture});
      document.removeEventListener('touchstart', handleClickOutside, {capture});
    };
  }, [ref, ignore, capture]);
}
