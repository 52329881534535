import cn from 'classnames';
import {Children, cloneElement, isValidElement, ReactElement, ReactNode} from 'react';

import s from './Comments.module.scss';
import CommentsAction from './components/CommentsAction/CommentsAction';
import CommentsClear from './components/CommentsClear/CommentsClear';
import CommentsDate from './components/CommentsDate/CommentsDate';
import CommentsNew from './components/CommentsNew/CommentsNew';

type CommentsProps = {
  className?: string;
  children?: ReactNode | ReactNode[];
};

const Comments = ({className, children}: CommentsProps) => {
  return (
    <div className={cn(s.Comments, className)}>
      {Children.toArray(children).map<ReactElement>(
        (child) =>
          isValidElement(child) &&
          cloneElement(child, {...child.props, className: cn(child.props.className, s.Comments__item)}),
      )}
    </div>
  );
};
Comments.Action = CommentsAction;
Comments.Clear = CommentsClear;
Comments.Date = CommentsDate;
Comments.CommentsNew = CommentsNew;

export {Comments};
