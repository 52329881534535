import cn from 'classnames';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {generatePath, useHistory, useRouteMatch} from 'react-router';

import {ROUTES} from '../../constants/routes';
import {HeaderControls} from '../../layout/admin/Header/useHeaderControls';
import Icon from '../Icon';

import {supportedLocales} from './constants';

const InternalLngSwitcher: FC<HeaderControls> = ({isOpen, onToggle}) => {
  const {
    i18n: {language, changeLanguage},
  } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch({
    path: Object.values(ROUTES),
    exact: true,
  });

  return (
    <div className="header__actions-item header__actions-item--lang">
      <div className={cn('ctrl-dropmenu ctrl-dropmenu--view-transparent header__menu', {'is-active': isOpen})}>
        <button className="ctrl-dropmenu__button ctrl-dropmenu__button--toggle" onClick={onToggle}>
          <span className="ctrl-dropmenu__button-content">
            <span className="lang-global">
              <Icon name="globe" className="lang-global__icon" size={24} colorFill />
              <span className="lang-global__text">{supportedLocales[language]}</span>
            </span>
          </span>
          <Icon name="chevron-top" className="ctrl-dropmenu__button-icon" colorFill />
        </button>
        {isOpen && (
          <div className="ctrl-dropmenu__viewport">
            <ul className="ctrl-dropmenu__list">
              {Object.keys(supportedLocales).map((locale) => (
                <li className="ctrl-dropmenu__list-item" key={locale}>
                  <button
                    className={cn('ctrl-dropmenu__button', {'is-active': locale === language})}
                    onClick={() => {
                      changeLanguage(locale);
                      const corLocale = locale !== 'en' ? locale : undefined;

                      history.replace(
                        `${generatePath(match?.path, {...match?.params, locale: corLocale})}${history.location.search}`,
                      );
                    }}
                  >
                    {supportedLocales[locale]}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
export default InternalLngSwitcher;
