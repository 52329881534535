import {ComponentType} from 'react';

import {BulkActionsProps} from 'modules/Tasks/components/ActionsBar/types';
import BulkLocation from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkLocation/BulkLocation';
import BulkResponsible from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkResponsible/BulkResponsible';
import BulkStatus from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkStatus/BulkStatus';
import BulkSubcontractor from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkSubcontractor/BulkSubcontractor';
import BulkType from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkType/BulkType';
import BulkWatcher from 'modules/Tasks/components/TasksActionsBar/bulkPopups/BulkWatcher/BulkWatcher';
import {BulkProps, TasksBulkTypes} from 'modules/Tasks/utils/constants';

const components: {[key in TasksBulkTypes]?: ComponentType<BulkProps>} = {
  [TasksBulkTypes.responsible]: BulkResponsible,
  [TasksBulkTypes.subcontractor]: BulkSubcontractor,
  [TasksBulkTypes.watcher]: BulkWatcher,
  [TasksBulkTypes.location]: BulkLocation,
  [TasksBulkTypes.type]: BulkType,
  [TasksBulkTypes.status]: BulkStatus,
};
export function TasksBulkActions({gantt, bulkType, closePopup, projectId}: BulkActionsProps) {
  const Component = components[bulkType];
  return Component ? <Component onClose={closePopup} gantt={gantt} projectId={projectId} /> : null;
}
