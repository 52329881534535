import {GanttStatic} from 'dhtmlx-gantt';

import {GanttNames} from '../../constants/gantt';

type TasksGanttCache = 'gantt' | `gantt:${GanttNames}` | 'currentTask';

export enum ToggleArchiveAction {
  Add,
  Remove,
}

export interface TasksCacheHelperMethodConfig {
  exclude: {
    [K in TasksGanttCache]?: boolean;
  };
}

export interface TasksCacheHelperMethod<P> {
  (actionPayload: P, config?: TasksCacheHelperMethodConfig): void;
}

export type TaskCacheHandlerConfig<P> = {
  gantt?: (inst: GanttStatic, actionPayload: P, config?: TasksCacheHelperMethodConfig) => void;
} & {[K in Exclude<TasksGanttCache, 'gantt'>]?: TasksCacheHelperMethod<P>};
