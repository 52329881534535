import cn from 'classnames';

import Avatar from 'shared/components/Avatar';

import s from './InfoMember.module.scss';

export type InfoMemberProps = {
  className?: string;
  memberImage?: string;
  memberName: string;
  size?: 'm';
};

const InfoMember = ({className, memberImage, memberName, size}: InfoMemberProps) => {
  return (
    <div className={cn(s.infoMember, size && s[`infoMember_size_${size}`], className)}>
      <Avatar src={memberImage} className={s.infoMember__avatar} />
      <span className={s.infoMember__name}>{memberName}</span>
    </div>
  );
};

export default InfoMember;
