import * as Sentry from '@sentry/browser';
import {Component, ReactNode} from 'react';

import css from './ErrorBoundary.module.scss';

interface State {
  error: Error;
}

interface Props {
  fallback?: ReactNode;
  children: ReactNode;
}

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {error: null};
  }
  componentDidCatch(error: Error) {
    Sentry.captureException(error);
  }

  public static getDerivedStateFromError(error: Error): State {
    return {error};
  }

  getDefaultFallback() {
    return (
      <div className={css['error-boundary__wrapper']}>
        <p className={css['error-boundary__inner']}>
          <span>Sorry...something went wrong</span>
        </p>
      </div>
    );
  }

  render() {
    return this.state.error ? this.props.fallback || this.getDefaultFallback() : this.props.children;
  }
}
