import {EmailAuthProvider, PhoneMultiFactorGenerator, UserCredential} from 'firebase/auth';
import {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

import FirebaseService from 'services/Firebase';
import {useAuth} from 'shared/components/AuthUserProvider';
import Button from 'shared/components/Button';
import {useConfirm} from 'shared/components/Confirmation';
import {ConfirmConfig} from 'shared/components/Confirmation/useConfirm/state';
import FormControl from 'shared/components/CoreForm/FormControl';
import {usePasswordVisibility} from 'shared/components/CoreForm/hooks/usePasswordVisibility';
import {Trim} from 'shared/components/CoreForm/Trim';
import Loader from 'shared/components/Loader';
import MultiFactor from 'shared/components/MultiFactorDialog';
import {getHumanizedFbError, isAuthMultiFactorError} from 'shared/constants/firebase';

import s from './ReAuthPopup.module.scss';

type Props = {
  onSuccessAuth?: () => void;
};

const ReAuthUserPopup: FC<Props> = ({onSuccessAuth}: Props) => {
  const {user} = useAuth();
  const {t} = useTranslation(['sign_in']);
  const passwordVisibility = usePasswordVisibility();
  const {confirm} = useConfirm();

  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const submit = async () => {
    const provider = await EmailAuthProvider.credential(user.email, password);
    try {
      setIsLoading(true);
      await FirebaseService.reauthenticateWithCredential(provider);
      onSuccessAuth();
    } catch (e) {
      if (isAuthMultiFactorError(e)) {
        const resolver = FirebaseService.getMultiFactorResolverByError(e);
        const phoneResolver = resolver.hints.find(({factorId}) => factorId === PhoneMultiFactorGenerator.FACTOR_ID);
        if (phoneResolver) {
          const payload: ConfirmConfig = {
            title: '2FA SMS',
            acceptButton: null,
            cancelButton: null,
            useNewLayout: false,
            component: <MultiFactor multiFactorResolver={resolver} />,
          };
          const userCreds = (await confirm(payload)) as UserCredential;
          if (userCreds) {
            onSuccessAuth();
          }
        }
      } else {
        toast.error(getHumanizedFbError(e, t));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={`${s.ReAuthPopup__box} loader-container`}>
      {isLoading && <Loader />}
      <div className="form-onboarding-z__item">
        <FormControl name="email" label={t('sign_in:fields.email.label', 'Email')}>
          <Trim>
            <input value={user.email} type="text" className="ctrl-textfield" readOnly={true} />
          </Trim>
        </FormControl>
      </div>
      <div className="form-onboarding-z__item">
        <FormControl
          name="password"
          label={t('sign_in:fields.password.label', 'Password')}
          iconName={passwordVisibility.visible ? 'visible_outlined' : 'hidden_outlined'}
          onIconClick={passwordVisibility.toggleVisibility}
        >
          <input
            name="password"
            onChange={(e) => setPassword(e.target.value)}
            type={passwordVisibility.visible ? 'text' : 'password'}
            className="ctrl-textfield"
            placeholder={t('sign_in:fields.password.placeholder', 'Enter password')}
            autoComplete="off"
          />
        </FormControl>
      </div>
      <div className="form-onboarding-z__item form-onboarding-z__item--actions">
        <Button onClick={submit}>Submit</Button>
      </div>
    </div>
  );
};
export default ReAuthUserPopup;
