import cn from 'classnames';
import dayjs from 'dayjs';
import {Field} from 'formik';
import {useTranslation} from 'react-i18next';

import CoreNativeDatePicker from 'shared/components/CoreForm/CoreNativeDatePicker';
import {AsyncProjectSubcontractorSelect} from 'shared/components/CoreForm/Select/AsyncProjectSubcontractorSelect';
import Select from 'shared/components/CoreForm/Select/Select';
import SkeletonFieldPreloader from 'shared/components/CoreForm/SkeletonFieldPreloader';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import {getTaskIssueImpactOptions, getTaskIssueTypeOptions} from 'shared/constants/statuses';
import {safeParseDate} from 'shared/helpers/dates';
import {IssueMinModel} from 'shared/models/task/issue';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {CompanyWorker} from 'shared/models/worker';

import {IssueFormErrors} from './Issue';
import s from './IssueForm.module.scss';

type IssueFormProps = {
  className?: string;
  data: IssueMinModel;
  disabled?: boolean;
  errors?: IssueFormErrors;
  hasAdminRole?: boolean;
  loading: boolean;
  onChange: (data: Partial<IssueMinModel>) => void;
  parent?: TaskDetailsModelDTO;
  workers: CompanyWorker[];
};

const IssueForm = ({
  className,
  data,
  disabled,
  errors,
  hasAdminRole,
  loading,
  onChange,
  parent,
  workers,
}: IssueFormProps) => {
  const {t} = useTranslation(['task']);

  const onChangeStartDate = (value) => {
    const nextDate = dayjs(value?.target?.value).toDate();
    onChange({startDate: nextDate});
    if (data.endDate < nextDate) {
      onChange({endDate: nextDate});
    }
  };

  const issueTypeOptions = getTaskIssueTypeOptions(t);
  const issueImpactOptions = getTaskIssueImpactOptions(t);

  return (
    <div className={cn(s.issueForm, className)}>
      <div className={cn(s.issueForm__row)}>
        <FormControl
          required
          value={data.description}
          error={errors?.description}
          name="name"
          label={t('task:task_form.form.description.label', 'Description')}
        >
          <Field
            className="ctrl-textfield"
            name="description"
            as="textarea"
            placeholder={t('task:task_form.form.description.placeholder', 'Click here to add a description...')}
            value={data.description}
            disabled={disabled}
            onChange={(e) => onChange({description: e.target.value})}
          />
        </FormControl>
      </div>

      <div className={cn(s.issueForm__row)}>
        <div className={cn(`${s.issueForm__item} ${s.issueForm__item_asignee}`)}>
          <FormControl
            value={data.responsible}
            error={errors?.responsible}
            label={t('task:activity_issues.action.responsible.label', 'Assignee')}
          >
            <Select
              isDisabled={disabled}
              className="react-select"
              placeholder={t('task:activity_issues.action.responsible.placeholder', 'Unassigned')}
              classNamePrefix="react-select"
              menuPlacement="auto"
              value={data.responsible}
              options={
                workers?.map((w) => ({
                  label: w.workerFull.fullName || w.workerFull.email || w.workerFull.mobileNumber,
                  value: w.workerId,
                })) || []
              }
              onChange={(value) => onChange({responsible: value})}
            />
          </FormControl>
        </div>
      </div>

      <div className={cn(s.issueForm__row)}>
        <FormControl.Formik
          name="responsibleOrgId"
          label={t('task:task_form.form.fixing_sub.label', 'Resolving Company')}
        >
          {({field: {value, name, ...fieldProps}}) => (
            <SkeletonFieldPreloader when={loading}>
              <AsyncProjectSubcontractorSelect
                {...fieldProps}
                isCreatable={hasAdminRole}
                isDisabled={disabled}
                onChange={(value) => {
                  onChange({[name]: value});
                  // mixpanel.trackWithAction(
                  //   () => setFieldValue(name, value),
                  //   mixpanelEvents.formFields[name],
                  //   mixpanelMeta,
                  // )
                }}
                onAfterCreateOption={(newSub) => onChange({[name]: newSub.id})}
                value={data.responsibleOrgId}
                placeholder={t('task:task_form.form.subcontractor.placeholder', 'Choose Company')}
              />
            </SkeletonFieldPreloader>
          )}
        </FormControl.Formik>
      </div>

      <div className={cn(s.issueForm__row)}>
        <FormControl.Formik name="culpableOrgId" label={t('task:task_form.form.culpable_sub.label', 'Liable Company')}>
          {({field: {value, name, ...fieldProps}}) => (
            <SkeletonFieldPreloader when={loading}>
              <AsyncProjectSubcontractorSelect
                {...fieldProps}
                isCreatable={hasAdminRole}
                isDisabled={disabled}
                onChange={(value) => {
                  onChange({culpableOrgId: value});
                  // mixpanel.trackWithAction(
                  //   () => setFieldValue(name, value),
                  //   mixpanelEvents.formFields[name],
                  //   mixpanelMeta,
                  // )
                }}
                onAfterCreateOption={(newSub) => onChange({culpableOrgId: newSub.id})}
                value={data.culpableOrgId}
                placeholder={t('task:task_form.form.subcontractor.placeholder', 'Choose Company')}
              />
            </SkeletonFieldPreloader>
          )}
        </FormControl.Formik>
      </div>

      <div className={cn(s.issueForm__row)}>
        <div className={cn(`${s.issueForm__item} ${s.issueForm__item_date}`)}>
          <FormControl label={t('task:activity_issues.issue.startdate.label', 'Start Date')}>
            <CoreNativeDatePicker
              disabled={disabled}
              minDate={parent.startDate ? safeParseDate(parent.startDate) : new Date()}
              value={data.startDate}
              onChange={onChangeStartDate}
            />
          </FormControl>
        </div>
        <div className={cn(`${s.issueForm__item} ${s.issueForm__item_date}`)}>
          <FormControl label={t('task:activity_issues.issue.enddate.label', 'End Date')}>
            <CoreNativeDatePicker
              disabled={disabled}
              minDate={data.startDate}
              value={data.endDate}
              onChange={(value) => onChange({endDate: dayjs(value?.target?.value).toDate()})}
            />
          </FormControl>
        </div>
      </div>

      <div className={cn(s.issueForm__row)}>
        <div className={cn(s.issueForm__item)}>
          <FormControl
            required
            value={data.issueType}
            error={errors?.issueType}
            label={t('task:activity_issues.issue.issuetype.label', 'Issue Type')}
          >
            <Select
              isDisabled={disabled}
              options={issueTypeOptions}
              value={data.issueType}
              onChange={(value) => onChange({issueType: value})}
            />
          </FormControl>
        </div>
        <div className={cn(s.issueForm__item)}>
          <FormControl
            required
            value={data.impact}
            error={errors?.impact}
            label={t('task:activity_issues.issue.impact.label', 'Issue Impact')}
          >
            <Select
              isDisabled={disabled}
              options={issueImpactOptions}
              value={data.impact}
              onChange={(value) => onChange({impact: value})}
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default IssueForm;
