import {useEffect} from 'react';
import {useQuery, useQueryClient} from 'react-query';

import ChatService from 'api/chat';
import {useInitializeMatrixSession} from 'shared/components/ProgressReport/hooks/useInitializeMatrixSession';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';

const REFETCH_INTERVAL = 30 * 1000;
const STALE_TIME = 30 * 1000;

export const useUnreadMessageCount = () => {
  const queryClient = useQueryClient();
  const {data: matrixSessionRes} = useInitializeMatrixSession();
  const accessToken = matrixSessionRes?.accessToken;
  const userId = matrixSessionRes?.userId;

  const {data: roomIds} = useQuery({
    queryKey: QUERY_CACHE_KEYS.joinedRooms(accessToken, userId),
    queryFn: () => ChatService.getJoinedRooms(matrixSessionRes?.accessToken),
    enabled: !!accessToken,
    initialData: [],
    keepPreviousData: true,
    refetchOnMount: true,
    staleTime: STALE_TIME,
  });

  const {data: unreadInfo} = useQuery({
    queryKey: QUERY_CACHE_KEYS.unreadCount(accessToken, userId, roomIds),
    queryFn: () => ChatService.getUnreadCount(matrixSessionRes?.accessToken, roomIds),
    enabled: !!accessToken && !!roomIds && roomIds.length > 0,
    keepPreviousData: true,
    refetchInterval: REFETCH_INTERVAL,
    refetchOnMount: true,
    staleTime: STALE_TIME,
  });

  useEffect(() => {
    if (userId) {
      queryClient.invalidateQueries(QUERY_CACHE_KEYS.joinedRooms(accessToken, userId));
      queryClient.invalidateQueries(QUERY_CACHE_KEYS.unreadCount(accessToken, userId, roomIds));
    }
  }, [userId, queryClient, accessToken, roomIds]);

  return unreadInfo;
};
