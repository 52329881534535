import {GanttStatic} from 'dhtmlx-gantt';

import {toGanttTaskModel} from 'shared/mapping/task';
import {IssueModelDTO} from 'shared/models/task/task';

export function butchUpdateIssues(gantt: GanttStatic, data: PromiseFulfilledResult<IssueModelDTO>[]) {
  gantt.batchUpdate(function () {
    gantt.eachSelectedTask(function (taskId: string) {
      const updatedTask = data.find((promise) => promise.value.id === taskId)?.value;
      const task = gantt.getTask(taskId);
      if (updatedTask) {
        Object.assign(task, toGanttTaskModel(updatedTask), {
          cost_days: updatedTask.costDays,
          cost_tracking_number: updatedTask.costTrackingNumber,
          culpable_org_id: updatedTask.culpableOrgId,
          impact: updatedTask.impact,
          issueType: updatedTask.issueType,
          taskIds: updatedTask.taskIds,
        });
        gantt.refreshTask(taskId);
      }
    });
  });
}
