import cn from 'classnames';
import {cloneElement, isValidElement, PropsWithChildren, ReactNode} from 'react';

import Icon from 'shared/components/Icon';

import s from './CtrlCheckOption.module.scss';

type CtrlCheckOptionProps = {
  className?: string;
  label: string;
  hide?: boolean;
  icon?: ReactNode;
};

const CtrlCheckOption = ({className, label, hide, children, icon}: PropsWithChildren<CtrlCheckOptionProps>) => {
  return (
    <label className={cn(s.CtrlCheckOption, {[`${s.CtrlCheckOption_hidden}`]: hide}, className)}>
      {isValidElement<HTMLInputElement>(children) &&
        cloneElement<HTMLInputElement>(children, {
          ...children.props,
          className: cn(s.CtrlCheckOption__field, children.props.className),
          type: 'checkbox',
        })}
      <span className={s.CtrlCheckOption__visualBox}>
        <span className={s.CtrlCheckOption__visual}>
          <Icon className={s.CtrlCheckOption__visualIcon} name="checkmark-outlined" colorFill />
        </span>
      </span>
      <span className={s.CtrlCheckOption__label}>{label}</span>
      {icon}
    </label>
  );
};

export default CtrlCheckOption;
