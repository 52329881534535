/* eslint-disable import/no-duplicates, import/no-named-as-default, import/no-named-as-default-member*/
import {initializeApp} from 'firebase/app';
import {
  applyActionCode,
  Auth,
  browserLocalPersistence,
  inMemoryPersistence,
  AuthCredential,
  ConfirmationResult,
  EmailAuthProvider,
  getAuth,
  getMultiFactorResolver,
  linkWithCredential,
  multiFactor,
  MultiFactorInfo,
  PhoneAuthCredential,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInAnonymously,
  signInWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
} from 'firebase/auth';

import {splitPhoneByCountryCode} from 'shared/constants/common';

import {FirebaseTokenProvider} from './FirebaseTokenProvider';

const FirebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const LS_WORKER_ID_KEY = 'workerId';

class Firebase implements FirebaseTokenProvider {
  public auth!: Auth;
  private testPhone = process.env.REACT_APP_TEST_PHONE;
  constructor(config: typeof FirebaseConfig) {
    initializeApp(config);
    this.auth = getAuth();
  }
  get phoneMultiFactorGenerator() {
    return PhoneMultiFactorGenerator;
  }

  toggleRecapchaAutoVerificationForTesting(phone?: string) {
    const phoneWithoutFormatting = phone ? phone.replace(/[\(\)\s-]/g, '') : phone;
    this.auth.settings.appVerificationDisabledForTesting =
      phoneWithoutFormatting && this.testPhone
        ? phoneWithoutFormatting === this.testPhone || `+1${phoneWithoutFormatting}` === this.testPhone
        : false;
  }

  initRecaptchaVerifier = (container: string, recaptchaSize: string) => {
    return new RecaptchaVerifier(container, {size: recaptchaSize}, this.auth);
  };

  signInWithPhone = (phone: string, recaptchaVerifier: RecaptchaVerifier) => {
    return signInWithPhoneNumber(this.auth, phone, recaptchaVerifier);
  };

  signInWithCredential = async (credential: AuthCredential) => {
    return signInWithCredential(this.auth, credential);
  };

  signInWithCustomToken = async (token) => {
    await setPersistence(this.auth, inMemoryPersistence);
    return signInWithCustomToken(this.auth, token);
  };

  turnOnPersistence = async () => {
    await setPersistence(this.auth, browserLocalPersistence);
  };

  confirmSignInWithMobile = async (confirmation: string, confirmationResult: ConfirmationResult) => {
    return confirmationResult.confirm(confirmation);
  };

  signInAnonymously = async () => {
    return signInAnonymously(this.auth);
  };

  linkWithPhoneCredential = async (confirmResult: ConfirmationResult, verificationCode: string) => {
    const credential: PhoneAuthCredential = await PhoneAuthProvider.credential(
      confirmResult.verificationId,
      verificationCode,
    );

    return linkWithCredential(this.auth.currentUser, credential)
      .then((usercred) => usercred.user)
      .catch((error) => Promise.reject(error));
  };

  createUserWithEmailAndPassword = async (email: string, password: string) => {
    const credential = await EmailAuthProvider.credential(email, password);

    return linkWithCredential(this.auth.currentUser, credential)
      .then((usercred) => usercred.user)
      .catch((error) => Promise.reject(error));
  };

  signInWithEmailAndPassword = async (email: string, password: string) => {
    return signInWithEmailAndPassword(this.auth, email, password);
  };

  getUserIdToken = async () => {
    if (this.auth.currentUser) {
      return await this.auth.currentUser.getIdToken();
    }
  };

  getCurrentUser = async () => {
    return this.auth.currentUser;
  };

  signOut = async () => {
    localStorage.removeItem(LS_WORKER_ID_KEY);
    await this.auth.signOut();
    return true;
  };

  setWorkerIdToLS(workerId: string) {
    localStorage.setItem(LS_WORKER_ID_KEY, workerId);
  }

  getWorkerIdFromLS() {
    return localStorage.getItem(LS_WORKER_ID_KEY);
  }

  deleteUser = async () => {
    if (this.auth.currentUser) {
      return this.auth.currentUser.delete();
    }
    return null;
  };

  sendPasswordResetEmail = async (emailAddress: string) => {
    return sendPasswordResetEmail(this.auth, emailAddress);
  };

  sendVerificationEmailLink = (): Promise<void> => {
    return sendEmailVerification(this.auth.currentUser);
  };

  applyActionCode = async (oobCode: string) => {
    return await applyActionCode(this.auth, oobCode);
  };

  reauthenticateWithCredential = async (credentials: AuthCredential) => {
    return reauthenticateWithCredential(this.auth.currentUser, credentials);
  };

  getPhoneAuthProvider = () => {
    return new PhoneAuthProvider(this.auth);
  };

  getMultiFactorResolverByError = (error) => {
    return getMultiFactorResolver(this.auth, error);
  };

  getPhoneMultiFactorResolver = (hints: MultiFactorInfo[]): MultiFactorInfo => {
    return hints.find(({factorId}) => factorId === PhoneMultiFactorGenerator.FACTOR_ID);
  };

  getPhoneNumberFromEnrolled2Fa = (splitByCode = true): string[] => {
    const enrolledFactor = multiFactor(this.auth.currentUser).enrolledFactors.find(
      ({factorId}) => factorId === PhoneMultiFactorGenerator.FACTOR_ID,
    );
    const phoneNumber = enrolledFactor?.['phoneNumber'] ?? '';
    if (phoneNumber) {
      return splitByCode ? splitPhoneByCountryCode(phoneNumber) : phoneNumber;
    }
    return enrolledFactor?.['phoneNumber'] ? splitPhoneByCountryCode(enrolledFactor['phoneNumber']) : null;
  };
}

const service = new Firebase(FirebaseConfig);

export default service;
