import {isFulfilled} from '@reduxjs/toolkit';
import cn from 'classnames';
import {GanttStatic} from 'dhtmlx-gantt';
import {Formik, FormikProps} from 'formik';
import {TFunction} from 'i18next';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from 'react-query';
import {generatePath, useHistory, useLocation, useParams, useRouteMatch} from 'react-router';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {useActivityMembers} from 'modules/Tasks/components/SidebarPanel/hooks/useActivityMembers';
import {getMembersForAdd} from 'modules/Tasks/components/SidebarPanel/utils/helpers';
import {useTask} from 'modules/Tasks/components/SidebarPanel/utils/hooks';
import {prepareModelBeforeSave} from 'modules/Tasks/components/SidebarPanel/utils/methods';
import {validationSchema} from 'modules/Tasks/components/SidebarPanel/utils/validationSchema';
import {TasksLocationState} from 'modules/Tasks/types/location';
import {updateSearchQuery} from 'modules/Tasks/utils/updateSearchQuery';
import {ObserverAction, ObserverActionSource} from 'services/TasksObserver/const';
import {useTasksObserver} from 'services/TasksObserver/TasksObserverProvider';
import {useConfirm} from 'shared/components/Confirmation';
import {CoreOptionType} from 'shared/components/CoreForm/Select/types';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import FormikChangeWatcher from 'shared/components/FormikChangeWatcher';
import ShareTask from 'shared/components/ShareTask';
import {Tab, Tabs, TabsContainer, TabsHeader} from 'shared/components/Tabs';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError} from 'shared/helpers/axios';
import {formatDate, isSameDay} from 'shared/helpers/dates';
import {isChangedTaskAssigneesList} from 'shared/helpers/task';
import {useEffectOnce} from 'shared/hooks/core/useEffectOnce';
import {useMount} from 'shared/hooks/core/useMount';
import {useOutsideClick} from 'shared/hooks/core/useOusideClick';
import {usePrevious} from 'shared/hooks/core/usePrevious';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';
import {useProjectSelector} from 'shared/hooks/useProjectSelector';
import {useTaskState} from 'shared/hooks/useTaskState';
import {TaskObjectType, TaskActiveTab, TaskProjection} from 'shared/models/task/const';
import {TaskDetailsModelDTO} from 'shared/models/task/task';
import {TaskStatusType} from 'shared/models/task/taskStatus';
import {prepareAssignees} from 'shared/utils/prepareAssignees';
import {useRootDispatch, useRootSelector} from 'store';
import {getProject} from 'store/projects/actions';
import {getProjectsLocations} from 'store/projects/selectors';
import * as taskAsyncActions from 'store/tasks/actions';
import {deleteTask, getTask, restoreTask} from 'store/tasks/actions';

import {useFilterContext} from '../../Filters/FilterProvider';
import {handleConfirmOnOutsideClick} from '../../Gantt/utils/handlers';
import PanelInfo from '../components/PanelInfo/PanelInfo';
import {SidebarType, StackOpenPanels} from '../SidebarPanel';

import AssigneesTab from './../components/AssigneesTab';
import CommentsTab from './../components/CommentsTab';
import {FormValues, getDefaultValues} from './../utils/types';
import s from './ActivityPanel.module.scss';
import {getPreparedFormikValues} from './utils/getPreparedFormikValues';

/**
 * ! DO NOT DELETE
 * t("common:task_activity_type.activity")
 * t("common:task_activity_type.action")
 * t("common:task_activity_type.milestone")
 * t("common:task_activity_type.summary")
 * t("common:task_activity_type.task")
 */

type PanelActivityProps = {
  className?: string;
  taskId: string;
  onClose: () => void;
  updateActivityDetailsTask: (activityDetailsTask: string) => void;
  gantt?: GanttStatic;
  onOpenNextPanel?: (panelInfo: StackOpenPanels) => void;
  onBackToParent?: () => void;
  stackOpenPanels?: StackOpenPanels[];
};

const getSaveConfirmationPayload = (t: TFunction) => ({
  description: t('task:confirmation.beforeSave.text', 'Do you want to save your changes?'),
  acceptButton: t('task:confirmation.beforeSave.accept', 'Save Task'),
  cancelButton: t('task:confirmation.beforeSave.reject', "Don't Save"),
});

const ActivityPanel = ({
  className,
  taskId,
  onClose,
  updateActivityDetailsTask,
  gantt,
  onOpenNextPanel,
  onBackToParent,
  stackOpenPanels,
}: PanelActivityProps) => {
  const history = useHistory<Partial<TasksLocationState>>();
  const routes = useLocalizedRoutes();
  const [parentIsLoading, setParentIsLoading] = useState(false);
  const location = useLocation<{from: string; activeTab: number; parent: string}>();
  const {projectId} = useParams<{projectId: string}>();
  const projectLocations = useRootSelector(getProjectsLocations);
  const dispatch = useRootDispatch();
  const observer = useTasksObserver();
  const match = useRouteMatch({path: routes.task, exact: true});

  const [shareModalIsVisible, setShareModalIsVisible] = useState(false);
  const openShareTaskModal = () => setShareModalIsVisible(true);

  const currentTaskId = taskId;
  const isNewTask = currentTaskId === 'new';
  const parentRef = useRef<TaskDetailsModelDTO>(); // Keep parent task when creating subtask
  // clear parent reference if it's not a 'create subtask' flow
  if (!isNewTask && !location.state?.parent) {
    parentRef.current = null;
  }

  const {task, loading: taskIsLoading} = useTask(currentTaskId);
  const {members, onMemberSelected, dummyMembers, localMembers, setLocalMembers} = useActivityMembers(task);
  const [previewModel, setPreviewModel] = useState(task);
  const {confirm, confirmAction} = useConfirm();
  const formik = useRef<FormikProps<FormValues>>();
  const prevTaskId = usePrevious(currentTaskId);
  const project = useProjectSelector(projectId);
  const backUrlRef = useRef(location.state?.from);
  const {viewMode} = useFilterContext();
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.task;
  const mixpanelMeta = useMemo(
    () => Object.assign({}, {'Project Name': project?.name, 'Project ID': project?.id, place: 'side_panel'}),
    [project],
  );
  const loading = taskIsLoading || parentIsLoading;
  const queryClient = useQueryClient();
  const taskState = useTaskState(task);
  const {t} = useTranslation(['task', 'common']);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleOutsideClick = handleConfirmOnOutsideClick(gantt, formik, currentTaskId, askToSaveBeforeLeave);
  const {hasAnyAdminRole} = useCompanyWorkerRoles(projectId);

  const activeTab = Number(new URLSearchParams(location.search).get('activeTab'));

  useEffect(() => {
    const targetTab = location?.state?.activeTab;
    if (targetTab && activeTab !== targetTab) {
      onTabChanged(targetTab);
    }
  }, [location?.state?.activeTab]);

  useEffect(() => {
    if (task?.objectType === TaskObjectType.milestone && task?.duration === 0 && activeTab !== TaskActiveTab.info) {
      onTabChanged(TaskActiveTab.info);
    }
  }, [task?.duration]);

  useMount(() => {
    formik.current?.resetForm({values: {...formik.current?.values, projectId: projectId}});
  });

  useOutsideClick({ref: containerRef, callback: (e) => handleOutsideClick(e, saveTask, onReject)});

  const onReject = () =>
    mixpanel.trackWithAction(
      onClose,
      isNewTask
        ? mixpanelEvents.actions.backToTasks.replace('{status}', taskState)
        : mixpanelEvents.actions.editBackToTasks,
      mixpanelMeta,
    );

  useEffectOnce(
    async () => {
      try {
        setParentIsLoading(true);
        parentRef.current = await TasksApi.getTask(location.state?.parent);
      } catch {
      } finally {
        setParentIsLoading(false);
      }
    },
    [location.state?.parent],
    !!location.state?.parent,
  );

  const onTabChanged = (tab: number) => {
    history.push({pathname: location.pathname, search: updateSearchQuery(location.search, {activeTab: tab})});
  };

  // hide side panel if anyone open details tab, now it's conflicting because they use the same store.task object
  // TODO: make components independent
  useEffect(() => {
    if (match) {
      onClose();
    }
  }, [match]);

  useEffect(() => {
    setLocalMembers(isNewTask ? dummyMembers : members);
  }, [members, dummyMembers, isNewTask, task]);

  useEffect(() => {
    if (currentTaskId) {
      if (!isNewTask && typeof currentTaskId !== 'number') {
        dispatch(taskAsyncActions.getTask(currentTaskId));
      }
      if (isNewTask && currentTaskId !== prevTaskId) {
        formik.current.resetForm({values: getDefaultValues({projectId, name: 'New Activity'})});
      }
    }
  }, [dispatch, currentTaskId, isNewTask, task?.id]);

  useEffect(() => {
    if (parentIsLoading) return;
    // update formik values either from current task model or with parent.current model for subtask
    let model: TaskDetailsModelDTO = task || parentRef.current;

    // do not inherit specific fields from parent
    if (parentRef.current && isNewTask) {
      model = {
        ...model,
        name: `Sub-Task of ${model.name}`,
        description: '',
        completionTarget: '100',
        completionUnit: '%',
        completionAmount: '',
        status: TaskStatusType.assigned,
        actualStart: null,
        actualEnd: null,
      };
      setPreviewModel(model);
    }

    if (['', '%'].includes(model?.completionUnit) && model.completionTarget === '') {
      model = {...model, completionTarget: '100'};
    }

    if (model) {
      if (typeof model.description === 'undefined') model = {...model, description: ''};
      updateFormikValues(model);
    }
  }, [project?.timezoneOffset, task, parentIsLoading]);

  const updateFormikValues = (initialValues: Partial<TaskDetailsModelDTO>) => {
    const values: FormValues = getPreparedFormikValues(initialValues);
    formik.current.resetForm({values});
  };

  const showConfirmOnSaveChanges = useMemo(() => {
    if (task && task.createdUsing === 'importer' && previewModel) {
      const {name, description, location, startDate, endDate, responsibleParty, type} = previewModel;
      return (
        task.name !== name ||
        task.description !== description ||
        task.location !== location ||
        !isSameDay(task.startDate, startDate) ||
        !isSameDay(task.endDate, endDate) ||
        task?.responsibleParty !== responsibleParty ||
        task?.type !== type
      );
    }
    return false;
  }, [task, previewModel, project?.timezoneOffset]);

  const saveTask = async (values: FormValues) => {
    const prepareStatus = (status: TaskStatusType): TaskStatusType => {
      if (status === TaskStatusType.tba && (members.length || localMembers.length || isNewTask))
        return TaskStatusType.assigned;
      return status;
    };
    values.status = prepareStatus(values.status);
    const model = prepareModelBeforeSave({
      values: values,
      task: task,
      project: project,
      parentId: parentRef.current?.id,
    });
    if (isNewTask) {
      // start + default duration = 1
      if (!values.endDate && values.duration === undefined) {
        delete model.endDate;
        model.duration = 1;
      }
      await createTask(model);
    } else {
      if (showConfirmOnSaveChanges) {
        const confirmed = await confirm({
          description: t(
            'task:confirmation.overwrite.text',
            'Warning: You have edited a field that may be overwritten the next time the project file is imported',
          ),
          title: t('task:confirmation.overwrite.title', 'Are you sure?'),
        });
        if (!confirmed) {
          return;
        }
      }
      await updateTask(model);
    }

    // update items of task types selector
    if (model.type) {
      updateTaskTypes(model.type);
    }

    if (!projectLocations.includes(values.location) && values.projectId) {
      dispatch(getProject(values.projectId));
    }
  };

  async function updateTask(model: TaskDetailsModelDTO) {
    const res = await dispatch(
      taskAsyncActions.updateTask({
        model,
        currentTask: task,
        members: prepareAssignees(localMembers),
        isScopedUpdate: !hasAnyAdminRole,
      }),
    );
    if (taskAsyncActions.updateTask.rejected.match(res)) {
      toast.error(res.error.message);
    } else if (res.payload) {
      toast.success(
        t('task:notifications.updated', 'Activity updated', {
          type: `$t(common:task_activity_type.${res.payload.objectType})`,
        }),
      );
      const taskWasArchived = task.status === 'archived' && model.status !== TaskStatusType.archived;
      if (task.status !== model.status) {
        queryClient.refetchQueries([QUERY_CACHE_KEYS.feedback, taskId]);
      }

      /**
       * api doesn't return wcZoneId || wcCrewId when values get unset
       * manually clear them when they are not present
       */
      if (!res.payload.wcCrewId) {
        res.payload.wcCrewId = null;
      }
      if (!res.payload.wcZoneId) {
        res.payload.wcZoneId = null;
      }

      observer.emit([{data: res.payload}], {
        projection: TaskProjection.taskDetail,
        projectId: res.payload.projectId,
        action: taskWasArchived ? ObserverAction.archive : ObserverAction.update,
        source: ObserverActionSource.taskDetails,
      });
    }
  }

  const createTask = async (model: TaskDetailsModelDTO) => {
    const res = await dispatch(
      taskAsyncActions.createTask({
        model,
        ...getMembersForAdd(members, localMembers),
      }),
    );
    if (taskAsyncActions.createTask.rejected.match(res)) {
      const error = extractAxiosError(res.payload);
      typeof error === 'string' ? toast.error(error) : formik.current.setErrors(error);
    } else {
      const createdTask = res.payload as TaskDetailsModelDTO;
      updateActivityDetailsTask(createdTask.id);
      toast.success(
        t('task:notifications.created', 'Activity created', {type: `$t(common:${createdTask.objectType})`}),
      );
      observer.emit([{data: res.payload}], {
        projection: TaskProjection.taskDetail,
        projectId: res.payload.projectId,
        action: ObserverAction.create,
        source: ObserverActionSource.taskDetails,
      });
      return res.payload;
    }
  };

  const askToDeleteTask = async () => {
    const confirmText = task.subtasks.length
      ? t(
          'task:confirmation.delete_with_child.text',
          'This is a parent activity. Deleting this activity will also delete all its sub-activities. Are you sure?',
        )
      : t('task:confirmation.delete.text', 'Are you sure you want to delete the Activity?', {
          objectType: `$t(common:${task.objectType})`,
        });
    const confirmTitle = t('task:confirmation.delete.title', 'Are you sure you want to Delete Activity?', {
      activityName: task.name,
    });

    if (
      await confirm({
        title: confirmTitle,
        description: confirmText,
        useNewLayout: true,
        acceptButton: {
          title: t('task:confirmation.delete.accept_button'),
          type: 'danger',
        },
      })
    ) {
      const res = await dispatch(deleteTask(currentTaskId));
      if (deleteTask.fulfilled.match(res)) {
        // TODO: call inside actions component on parent change
        queryClient.refetchQueries(['lookaheadTaskActions', task?.parentTask?.id || parentRef.current?.id]);
        observer.emit([{data: {id: currentTaskId}}], {
          projection: TaskProjection.taskDetail,
          projectId: projectId,
          action: ObserverAction.remove,
          source: ObserverActionSource.taskDetails,
        });
        goToTasks();
        toast.success(t('task:notifications.delete', 'Activity deleted', {type: `$t(common:${task.objectType})`}));
        onClose();
      } else {
        toast.error(res.error.message);
      }
    }
  };

  const onTaskRestore = async () => {
    if (await confirm(t('task:confirmation.undelete.text', 'Are you sure you want to Undelete Activity?'))) {
      const restoreRes = await dispatch(restoreTask(currentTaskId));
      const fullTaskRes = await dispatch(getTask(currentTaskId));
      if (isFulfilled(restoreRes)) {
        if (isFulfilled(fullTaskRes)) {
          observer.emit([{data: fullTaskRes.payload}], {
            projection: TaskProjection.taskDetail,
            projectId: fullTaskRes.payload.projectId,
            action: ObserverAction.create,
            source: ObserverActionSource.taskDetails,
          });
        }
        toast.success(t('task:notifications.undelete', 'Activity undeleted', {type: `$t(common:${task.objectType})`}));
      } else {
        toast.error(restoreRes.error.message);
      }
    }
  };

  const onFormikValueChanged = ({startDate, endDate, actualStart, actualEnd, ...values}: FormValues) => {
    setPreviewModel({
      ...task,
      ...values,
      startDate: startDate ? formatDate(startDate) : null,
      endDate: endDate ? formatDate(endDate) : null,
      actualStart: actualStart ? formatDate(actualStart) : null,
      actualEnd: actualEnd ? formatDate(actualEnd) : null,
    });
  };

  function goToTasks() {
    backUrlRef.current
      ? history.push(backUrlRef.current)
      : history.push(generatePath(routes.tasks, {projectId: project?.id}));
  }

  function askToSaveBeforeLeave(onReject: () => void, onSubmit?: () => void) {
    return () => {
      return confirmAction(
        getSaveConfirmationPayload(t),
        formik.current?.dirty || isChangedTaskAssigneesList(isNewTask ? dummyMembers : members, localMembers),
        onSubmit || formik.current?.handleSubmit,
        onReject,
      );
    };
  }

  function updateTaskTypes(taskType: string) {
    const cachedTaskTypesList = (queryClient.getQueryData(QUERY_CACHE_KEYS.taskTypes(projectId)) ||
      []) as CoreOptionType[];
    if (!cachedTaskTypesList.length || !cachedTaskTypesList.find((option) => option.value === taskType)) {
      return queryClient.refetchQueries(QUERY_CACHE_KEYS.taskTypes(projectId));
    }
  }

  function openActionDetails(id: string) {
    return askToSaveBeforeLeave(
      () => {
        onOpenNextPanel({id, type: SidebarType.activity});
      },
      async () => {
        await formik.current?.submitForm();
        onOpenNextPanel({id, type: SidebarType.activity});
      },
    )();
  }

  function openIssueDetails(id: string) {
    return askToSaveBeforeLeave(
      () => {
        onOpenNextPanel({id, type: SidebarType.issue});
      },
      async () => {
        await formik.current?.submitForm();
        onOpenNextPanel({id, type: SidebarType.issue});
      },
    )();
  }

  const backToParentWithSubmit = async () => {
    await saveTask(formik.current.values);
    onBackToParent();
  };

  useEffect(() => {
    if (taskId && gantt && gantt.isTaskExists(taskId)) {
      const task = gantt.getTask(taskId);
      if (task.datesIsPristine) {
        task.datesIsPristine = false;
        gantt.refreshTask(taskId);
      }
    }
  }, [taskId, gantt]);

  return (
    <>
      <Formik<FormValues>
        initialValues={getDefaultValues()}
        onSubmit={saveTask}
        innerRef={formik}
        validateOnChange={true}
        validationSchema={validationSchema(t)}
      >
        {({values, submitForm, isSubmitting, dirty}) => {
          return (
            <Tabs activeTab={activeTab} onTabChanged={onTabChanged}>
              <FormikChangeWatcher debounce={200} onChange={onFormikValueChanged} />
              <section className={cn(s.ActivityPanel, className)} ref={containerRef}>
                <div className={s.ActivityPanel__viewport}>
                  <header className={s.ActivityPanel__header}>
                    <div className={s.ActivityPanel__headerActions}>
                      {stackOpenPanels.length === 1 ? (
                        <CtrlButton
                          data-cy="panel-activity-close-tab-btn"
                          icon="clear_2"
                          color="second"
                          view="link"
                          onClick={askToSaveBeforeLeave(
                            () =>
                              mixpanel.trackWithAction(
                                onClose,
                                isNewTask
                                  ? mixpanelEvents.actions.backToTasks.replace('{status}', taskState)
                                  : mixpanelEvents.actions.editBackToTasks,
                                mixpanelMeta,
                              ),
                            () => {
                              submitForm().then(onClose);
                            },
                          )}
                        >
                          {t('task:buttons.close', 'Close Tab')}
                        </CtrlButton>
                      ) : (
                        <CtrlButton
                          color="second"
                          view="link"
                          icon="arrow_2_left"
                          onClick={askToSaveBeforeLeave(onBackToParent, backToParentWithSubmit)}
                        >
                          {t('task:buttons.back_to_parent', 'Back to parent')}
                        </CtrlButton>
                      )}
                      <CtrlButton
                        data-cy="panel-activity-save-btn"
                        icon="check"
                        iconPosition="after"
                        size="s"
                        onClick={() =>
                          mixpanel.trackWithAction(
                            submitForm,
                            isNewTask
                              ? mixpanelEvents.actions.create.replace('{status}', taskState)
                              : mixpanelEvents.actions.saveInfo,
                            mixpanelMeta,
                          )
                        }
                        disabled={
                          (!dirty && !hasAnyAdminRole) ||
                          isSubmitting ||
                          loading ||
                          !!task?.timeRemoved ||
                          (task?.status === 'archived' && values.status === TaskStatusType.archived)
                        }
                      >
                        {isNewTask ? t('task:buttons.create', 'Create') : t('task:buttons.save', 'Save Info')}
                      </CtrlButton>
                    </div>
                    <TabsHeader />
                  </header>

                  <div className={`panel-activity__body ${s.ActivityPanel__body}`}>
                    <TabsContainer>
                      <Tab title={t('task:tab.title.details', 'Details')}>
                        <PanelInfo
                          gantt={gantt}
                          loading={loading}
                          activity={task}
                          parent={task?.parentTask || parentRef.current}
                          onDelete={askToDeleteTask}
                          onRestore={onTaskRestore}
                          onShare={() =>
                            mixpanel.trackWithAction(openShareTaskModal, mixpanelEvents.actions.share, mixpanelMeta)
                          }
                          project={project}
                          onOpenAction={openActionDetails}
                          onOpenIssue={openIssueDetails}
                        />
                      </Tab>
                      <Tab
                        title={t('task:tab.title.assignees', 'Assignees')}
                        className={s.ActivityPanel__tab}
                        disabled={
                          !task || [TaskObjectType.milestone, TaskObjectType.summary].includes(task?.objectType)
                        }
                      >
                        <AssigneesTab
                          disabled={!hasAnyAdminRole}
                          isNewTask={isNewTask}
                          task={task}
                          loading={loading}
                          members={localMembers}
                          onMemberSelect={onMemberSelected}
                        />
                      </Tab>
                      <Tab
                        title={t('task:tab.title.chat', 'Comments')}
                        disabled={!task || task?.objectType === TaskObjectType.milestone}
                      >
                        <CommentsTab task={task} projectId={projectId} />
                      </Tab>
                    </TabsContainer>
                  </div>
                </div>
              </section>
            </Tabs>
          );
        }}
      </Formik>
      <ShareTask
        projectId={projectId}
        viewMode={viewMode}
        taskId={currentTaskId}
        visible={shareModalIsVisible}
        onClose={() => setShareModalIsVisible(false)}
        objectType={task?.objectType}
      />
    </>
  );
};

export default ActivityPanel;
