import {t, TFunction} from 'i18next';

import {CoreOptionType} from 'shared/components/CoreForm/Select/types';

export const GANTT_PREFERENCES_KEY = 'ganttPreferences';

export const META_KEYWORDS = t(
  'meta_keywords:main',
  'Construction Productivity, Task Management, Crew Management, Jobsite, Foreman, Project Delivery, Construction Management, Collaboration, Construction Technology, Punchlists',
);

export enum TasksViewMode {
  dailies = 'dailies',
  gantt = 'gantt',
  ganttVisual = 'ganttVisual',
  issues = 'issues',
  lookahead = 'lookahead',
  table = 'table',
}

export type CountryCodeOption = {
  label: string;
  value: string;
  regExp: RegExp;
};

export const KEYCODE = {
  ENTER: 'Enter',
  ESC: 'Escape',
  TAB: 'Tab',
  DELETE: 'Delete',
  UP: 'ArrowUp',
  DOWN: 'ArrowDown',
};

export const DEFAULT_COUNTRY_CODES: CountryCodeOption[] = [
  {value: '+1', label: 'United States', regExp: /(\d{3})(\d{3})(\d{4})/},
  {value: '+55', label: 'Brazil', regExp: /(\d{2})(\d{8,9})/},
  {value: '+7', label: 'Russia', regExp: /(\d{3})(\d{3})(\d{4})/},
  {value: '+81', label: 'Japan', regExp: /(\d{2})(\d{4})(\d{4})/},
  {value: '+65', label: 'Singapore', regExp: /(\d{4}){2}/},
  {value: '+966', label: 'Saudi Arabia', regExp: /(\d{2})(\d{3})(\d{4})/},
];

export const MAPBOX_COUNTRY_MAPPING = {
  USA: 'us',
  Brazil: 'br',
  Japan: 'jp',
  Singapore: 'sg',
  SaudiArabia: 'sa',
};

export const COUNTRY_CODES_MAPPING = {
  '+1': 'USA',
  '+55': 'Brazil',
  '+7': 'Russia',
  '+81': 'Japan',
  '+65': 'Singapore',
  '+966': 'Saudi Arabia',
};

export const PHONE_MASKS = [
  {code: '+1', mask: '(999) 999-9999'},
  {code: '+7', mask: '(999) 999-9999'},
  {
    code: '+55',
    mask: '(99) 9999-9999?',
    alterMask: '(99) 99999-9999',
    alterPredicate: (value: string) => value && value.length > 10,
  },
  {code: '+81', mask: '99-9999-9999'},
  {code: '+65', mask: '9999-9999'},
  {code: '+966', mask: '99 999 9999'},
];

export const COUNTRY_OPTIONS: CoreOptionType[] = [
  {label: 'USA', value: 'USA'},
  {label: 'Brazil', value: 'Brazil'},
  {label: 'Japan', value: 'Japan'},
];

export const getCountryOptions = (t: TFunction): CoreOptionType[] => {
  return [
    {label: t('constants:country.USA', 'USA'), value: 'USA'},
    {label: t('constants:country.BRAZIL', 'Brazil'), value: 'Brazil'},
    {label: t('constants:country.JAPAN', 'Japan'), value: 'Japan'},
  ];
};

export const COMPANY_SIZE_OPTIONS: CoreOptionType[] = [
  {label: '1-10', value: '1-10'},
  {label: '11-50', value: '11-50'},
  {label: '51-250', value: '51-250'},
  {label: '251-1000', value: '251-1000'},
  {label: '1001+', value: '1001+'},
];

export const PHONE_MASKS_BY_CODE: Record<string, (typeof PHONE_MASKS)[number]> = PHONE_MASKS.reduce(
  (all, cur) => Object.assign(all, {[cur.code]: cur}),
  {},
);

export function splitPhoneByCountryCode(phone: string) {
  const codes = DEFAULT_COUNTRY_CODES.map((code) => '\\' + code.value).join('|');
  const regExp = new RegExp(`(${codes})(\\d+)`);
  const match = phone.match(regExp);
  return match ? [match[1], match[2]] : [];
}

export function formatPhoneNumber(phone: string) {
  if (!phone) return phone;
  const [code, number] = splitPhoneByCountryCode(phone);
  const config = DEFAULT_COUNTRY_CODES.find(({value}) => value === code);
  if (!config) return phone;
  const match = number.match(config.regExp);
  if (!match) return phone;
  return `${code} (${match[1]}) ` + match.splice(2).join('-');
}

export enum ExternalServices {
  Procore = 'procore',
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum TaskLabelCategory {
  safety = 'safety',
  quality = 'quality',
  planning = 'planning',
  externalFactors = 'external_factors',
}
