import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';

function mockResource(resource, path) {
  if (Array.isArray(resource)) {
    resource.forEach((resItem, index) =>
      typeof resItem === 'object'
        ? mockResource(resItem, `${path}.[${index}]`)
        : (resource[index] = `t(${resource[index]})`),
    );
  } else {
    for (const key of Object.keys(resource)) {
      if (typeof resource[key] === 'object') {
        mockResource(resource[key], `${path}.${key}`);
      } else {
        resource[key] = `t(${resource[key]})`;
      }
    }
  }
  return resource;
}

export function initI18n() {
  return i18n
    .use(LanguageDetector)
    .use({
      type: 'backend',
      init(services, backendOptions, i18nextOptions) {
        /* use services and options */
      },
      read(language, namespace, callback) {
        import(`../public/locales/${language}/${namespace}.json`).then(({default: resources}) => {
          if (localStorage.getItem('i18n:debug') === 'true') {
            callback(null, mockResource(resources, `${namespace}:`));
          } else {
            callback(null, resources);
          }
        });
      },
    })
    .use(initReactI18next)
    .init({
      detection: {
        order: ['localStorage', 'path', 'navigator', 'htmlTag', 'subdomain'],
      },
      supportedLngs: ['en', 'es', 'ja'],
      fallbackLng: 'en',
      fallbackNS: 'common',
      ns: 'common',
      interpolation: {
        escapeValue: false, // react already safes from xss
        skipOnVariables: false,
      },
      react: {
        useSuspense: false,
      },
    });
}
