import {useFormikContext} from 'formik';
import {useCallback, useEffect, useRef} from 'react';

import {debounce as debounceFn} from 'shared/helpers/debounce';
import {useUnmount} from 'shared/hooks/core/useUnmount';

type FormikChangeWatcherProps<T extends Record<string, unknown>> = {
  onChange: (values: T) => void;
  debounce?: number;
};
const FormikChangeWatcher = <T extends Record<string, unknown>>({
  onChange,
  debounce = 500,
}: FormikChangeWatcherProps<T>) => {
  const {values} = useFormikContext<T>();
  const callback = useRef<typeof onChange>(() => undefined);

  useEffect(() => {
    callback.current = onChange;
  }, [onChange]);

  const notifier = useCallback(
    debounceFn((values) => {
      callback.current && callback.current(values as T);
    }, debounce),
    [],
  );

  useEffect(() => {
    notifier(values);
  }, [values, notifier]);

  useUnmount(() => {
    notifier.cancel();
  });

  return null;
};
export default FormikChangeWatcher;
