import {AxiosResponse} from 'axios';
import {GanttStatic} from 'dhtmlx-gantt';
import {toast} from 'react-toastify';

import TasksApi from 'api/tasks';
import {container} from 'IocContainer';
import {refreshTask} from 'modules/Tasks/utils/functions';
import {GanttSimpleLock} from 'modules/Tasks/utils/simpleLock';
import {ObserverActionSource, ObserverAction} from 'services/TasksObserver/const';
import {TasksObserver} from 'services/TasksObserver/TasksObserver';
import {extractAxiosError, fetchAllBlocking, isAxiosError} from 'shared/helpers/axios';
import {subtract, toShortIso} from 'shared/helpers/dates';
import {toGanttTaskModel} from 'shared/mapping/task';
import {IOC_TYPES} from 'shared/models/ioc';
import {TaskProjection} from 'shared/models/task/const';
import {TaskRelativeDirection, TaskModelRawDTO, TaskModelDTO} from 'shared/models/task/task';
import {TasksStoreType} from 'shared/stores/TasksStore';

import {GANTT_COLUMNS_NAMES} from '../components/Gantt/utils/constants';
import {isPlaceholderTask, deselectAll} from '../components/Gantt/utils/gantt';

interface MoveTaskParams {
  taskId: string; // task to move
  gantt: GanttStatic;
  projectId: string;
  index?: number;
  parentId?: string;
}

/*
 * Resolve relative task id base on relation with relative task
 * if relative task is a placeholder need to get previous task id
 */
function getMoveRelativeTaskId(gantt: GanttStatic, taskId: string, parentId: string) {
  const insertInside = gantt.getChildren(parentId).length === 1;
  const prev = gantt.getPrevSibling(taskId);
  const next = gantt.getNextSibling(taskId);

  if (insertInside) {
    return {
      relativeDir: TaskRelativeDirection.Into,
      relativeToId: parentId,
    };
  }

  if (next && !isPlaceholderTask(gantt, gantt.getTask(next))) {
    return {
      relativeDir: TaskRelativeDirection.Before,
      relativeToId: next as string,
    };
  }

  return {
    relativeDir: TaskRelativeDirection.After,
    relativeToId: (isPlaceholderTask(gantt, gantt.getTask(prev)) ? gantt.getPrevSibling(prev) : prev) as string,
  };
}

export const moveTask = async ({taskId, gantt, parentId}: MoveTaskParams) => {
  const _parentId = parentId ?? (gantt.getParent(taskId) as string);
  const {relativeDir, relativeToId} = getMoveRelativeTaskId(gantt, taskId, _parentId);
  const selected = gantt.getSelectedTasks() as string[];
  const tasksStore = container.get<TasksStoreType>(IOC_TYPES.TasksStore);

  try {
    // For multiple move
    // we need always sort selected array because actually moved activity(taskId) always must be at first position
    const ids = selected.length > 1 ? {taskIds: selected.sort((a) => (a === taskId ? -1 : 0))} : {taskId: taskId};
    const {affected_task_ids, ...task} = await TasksApi.taskRelativeMove({relativeDir, relativeToId, ...ids});
    const updates = [task as TaskModelRawDTO];
    if (affected_task_ids?.length) {
      const affectedTasks = await fetchAllBlocking((offset: number, take: number) => {
        return TasksApi.getProjectTasks({
          params: {ids: affected_task_ids, projectId: tasksStore.projectId},
          offset,
          limit: take,
          projection: TaskProjection.task,
        }) as Promise<AxiosResponse<TaskModelRawDTO[]>>;
      });
      updates.push(...(affectedTasks as TaskModelRawDTO[]));
    }

    tasksStore.updateTasks(updates);

    return task;
  } catch (err) {
    toast.error(extractAxiosError(err));
    // Updating tasksStore here above place of axios handling of affected_task_ids,
    // so need to fire an unlock manually
    GanttSimpleLock.getInstance(gantt).unlock('load');
  } finally {
    deselectAll(gantt);
  }
};

export async function toggleActualizeActivity(taskId: string, toggleColumn: GANTT_COLUMNS_NAMES, gantt: GanttStatic) {
  const task = gantt.getTask(taskId);
  const preEditTask = {...task};
  const payload: Partial<TaskModelDTO> = {id: taskId};
  if (toggleColumn === GANTT_COLUMNS_NAMES.startDate) {
    if (task.actual_start) {
      payload.actualStart = null;
      payload.actualEnd = null;
      gantt.updateTask(task.id, {...task, actual_start: null, actual_end: null});
    } else {
      payload.actualStart = toShortIso(task.start_date);
      gantt.updateTask(task.id, {...task, actual_start: task.start_date});
    }
  } else if (toggleColumn === GANTT_COLUMNS_NAMES.endDate) {
    if (task.actual_end) {
      payload.actualEnd = null;
      gantt.updateTask(task.id, {...task, actual_end: null});
    } else {
      const inclusiveEndDate = subtract(task.end_date, 1);
      payload.actualEnd = toShortIso(inclusiveEndDate);
      const updTask = {...task, actual_end: task.end_date};
      if (!task.actual_end) {
        payload.actualStart = toShortIso(task.start_date);
        updTask.actual_start = task.start_date;
      }
      // Two calls to updateTask to separtely update actual_start/actual_end seems to not
      // work properly.  Combine them.
      gantt.updateTask(task.id, updTask);
    }
  } else {
    throw new Error('Unsupported actualize column');
  }

  try {
    // immediately refresh task before api call complete to improve UI responsiveness
    refreshTask(gantt, taskId, task);

    const updTask = await TasksApi.updateTask(payload);

    TasksObserver.getInstance().emit([{data: updTask}], {
      projectId: task.projectId,
      source: ObserverActionSource.gantt,
      projection: TaskProjection.taskDetail,
      action: ObserverAction.update,
      sourceName: gantt.name,
    });
  } catch (err) {
    if (isAxiosError(err)) {
      toast.error(extractAxiosError(err));
      refreshTask(gantt, taskId, preEditTask);
    }
    // make undo, because of optimistic update
    TODO: return null;
  }
}

export async function restoreActivity(
  id: string,
  gantt: GanttStatic,
  observer: TasksObserver,
  timezoneOffset: string,
  parent?: string,
  index?: number,
) {
  try {
    await TasksApi.restoreTask(id);
    const fullTaskRes = await TasksApi.getTask(id);
    observer.emit([{data: fullTaskRes}], {
      projection: TaskProjection.taskDetail,
      source: ObserverActionSource.gantt,
      sourceName: gantt.name,
      action: ObserverAction.restore,
      projectId: fullTaskRes.projectId,
    });
    gantt.silent(() => {
      const ganttTask = {...toGanttTaskModel(fullTaskRes), readonly: false};
      gantt.addTask(ganttTask, parent, index);
    });
    gantt.dRender();
    toast.success('Task undeleted');
  } catch (err) {
    if (isAxiosError(err)) {
      toast.error(extractAxiosError(err));
    }
  }
}
