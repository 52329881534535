import s from './LaptopMedia.module.scss';

const LaptopMedia = () => {
  return (
    <div className={s.laptopMedia}>
      <picture className={s.laptopMedia__desktop}>
        <source
          type="image/webp"
          srcSet={`/images/screenshots/screenshot-14@1x.webp, /images/screenshots/screenshot-14@2x.webp 2x`}
        />
        <source
          type="image/png"
          srcSet={`/images/screenshots/screenshot-14@1x.png, /images/screenshots/screenshot-14@2x.png 2x`}
        />
        <img className={s.laptop__screenshot} src={`/images/screenshots/screenshot-14@1x.png`} alt="" />
      </picture>
      <picture className={s.laptopMedia__mobile}>
        <source
          type="image/webp"
          srcSet={`/images/screenshots/screenshot-15@1x.webp, /images/screenshots/screenshot-15@2x.webp 2x`}
        />
        <source
          type="image/png"
          srcSet={`/images/screenshots/screenshot-15@1x.png, /images/screenshots/screenshot-15@2x.png 2x`}
        />
        <img className={s.laptop__screenshot} src={`/images/screenshots/screenshot-15@1x.png`} alt="" />
      </picture>
    </div>
  );
};

export default LaptopMedia;
