import * as Sentry from '@sentry/browser';

import {DailyType} from 'shared/models/feedback';
import {FeedbackByDate, FeedbackByDateTask} from 'shared/models/task/task';

export const findFeedBackByDate = (field: FeedbackByDate[], date: string): FeedbackByDate => {
  if (field?.length) {
    return field.find((fbd) => fbd.date === date);
  }
  return null;
};

export const extractFeedbackByDate = (feedbackByDate: FeedbackByDateTask[], field: DailyType) => {
  return feedbackByDate?.find((fbd) => fbd.field === field).feedbackByDate;
};

export const extractFeedbackHistory = (data: FeedbackByDate | undefined) => {
  if (data) {
    return {
      date: data.date,
      value: data.value,
      worker_id: data?.workerId,
    };
  }
  return undefined;
};

export function parseAndValidateNumber(input: string): string {
  const parsedValue = parseInt(input, 10);
  return parsedValue < 0 || isNaN(parsedValue) ? '' : parsedValue.toString();
}

export const onInput = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');
};

export function getImageDimensions(file: File): Promise<{width: number; height: number}> {
  return new Promise((resolve, reject) => {
    const imageUrl = URL.createObjectURL(file);

    const img = new Image();

    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      URL.revokeObjectURL(imageUrl);

      resolve({width, height});
    };

    img.onerror = (error) => {
      URL.revokeObjectURL(imageUrl);
      Sentry.captureException(error);
      reject(error);
    };

    img.src = imageUrl;
  });
}
