import {mapTaskProjectionToTaskGanttModel} from 'modules/Tasks/components/Gantt/utils/functions';
import {OSKMap, traverseTasks} from 'modules/Tasks/components/Gantt/utils/gantt';
import {TaskModelRawDTO, TaskGanttModel} from 'shared/models/task/task';
import {ChangeSet} from 'shared/prediction/types';
import {TasksStoreType} from 'shared/stores/TasksStore';

import {getIndexInParentFromOSK} from './calculations';

export function tasksToGanttTasks(
  tasksToRefresh: TaskModelRawDTO[],
  tasks: TaskModelRawDTO[],
  projectId: string,
): TaskGanttModel[] {
  const oskMap: OSKMap = traverseTasks(tasks, new Map());

  return tasksToRefresh.map((taskModel) => {
    const children = oskMap.get(taskModel.outline_sort_key)?.children_count;
    const parentOSK = oskMap.get(taskModel.outline_sort_key)?.parent;
    const parent = parentOSK ? oskMap.get(parentOSK).id : '0';
    const model = mapTaskProjectionToTaskGanttModel({
      taskModel,
      projectId: projectId,
      flatList: false,
      inclusiveEndDate: true,
    });
    model.subtask_count = children;
    model.has_child = children;
    model.$has_child = children;
    model.parent = parent;
    model.index = getIndexInParentFromOSK(tasks, taskModel.outline_sort_key);
    model.open = true; // appears that gantt internal state will track and maintain this
    return model;
  });
}

export function applyChangeset(tasksStore: TasksStoreType, changeSet: ChangeSet) {
  const updates: Partial<TaskModelRawDTO>[] = Object.entries(changeSet).map(([id, task]) => {
    if (isNaN(Number(id))) {
      return {...task, id} as TaskModelRawDTO;
    } else {
      return {...task, id: Number(id)} as unknown as TaskModelRawDTO;
    }
  });
  if (updates.length) {
    tasksStore.updateTasks(updates);
  }
}
