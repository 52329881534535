import cn from 'classnames';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {useLocalizedRoutes} from 'shared/constants/routes';

import box from '../Box/Box.module.scss';
import CtrlButton from '../CtrlButton/CtrlButton';

import s from './Hero.module.scss';

type HeroProps = {
  className?: string;
};

const Hero = ({className}: HeroProps) => {
  const routes = useLocalizedRoutes();
  const history = useHistory();
  const {t} = useTranslation(['landing2']);

  return (
    <section className={cn(s.hero, className)}>
      <div className={box.box}>
        <div className={s.hero__wrap}>
          <div className={cn(s.hero__container, s.hero__container_hide, s.hero__container_mr32)}>
            <div className={s.hero__imagesBlock}>
              <picture>
                <source
                  type="image/webp"
                  srcSet={`/images/screenshots/screenshot-2@1x.webp, /images/screenshots/screenshot-2@2x.webp 2x`}
                />
                <source
                  type="image/png"
                  srcSet={`/images/screenshots/screenshot-2@1x.png, /images/screenshots/screenshot-2@2x.png 2x`}
                />
                <img
                  data-aos="zoom-in"
                  data-aos-duration="300"
                  className={s.hero__screenshot}
                  src={`/images/screenshots/screenshot-2@1x.png`}
                  alt=""
                />
              </picture>
              <picture>
                <source
                  type="image/webp"
                  srcSet={`/images/screenshots/screenshot-4@1x.webp, /images/screenshots/screenshot-4@2x.webp 2x`}
                />
                <source
                  type="image/png"
                  srcSet={`/images/screenshots/screenshot-4@1x.png, /images/screenshots/screenshot-4@2x.png 2x`}
                />
                <img
                  data-aos="zoom-in"
                  data-aos-duration="300"
                  data-aos-delay="300"
                  className={s.hero__screenshot__mobile}
                  src={`/images/screenshots/screenshot-4@1x.png`}
                  alt=""
                />
              </picture>
              <picture
                data-aos="zoom-in"
                data-aos-duration="300"
                data-aos-delay="600"
                className={s.hero__screenshot__members}
              >
                <source
                  type="image/webp"
                  srcSet={`/images/screenshots/screenshot-3@1x.webp, /images/screenshots/screenshot-3@2x.webp 2x`}
                />
                <source
                  type="image/png"
                  srcSet={`/images/screenshots/screenshot-3@1x.png, /images/screenshots/screenshot-3@2x.png 2x`}
                />
                <img className={s.hero__screenshotItem} src={`/images/screenshots/screenshot-3@1x.png`} alt="" />
              </picture>
              <picture
                data-aos="zoom-in"
                data-aos-duration="300"
                data-aos-delay="900"
                className={s.hero__screenshot__share}
              >
                <source
                  type="image/webp"
                  srcSet={`/images/screenshots/screenshot-5@1x.webp, /images/screenshots/screenshot-5@2x.webp 2x`}
                />
                <source
                  type="image/png"
                  srcSet={`/images/screenshots/screenshot-5@1x.png, /images/screenshots/screenshot-5@2x.png 2x`}
                />
                <img className={s.hero__screenshotItem} src={`/images/screenshots/screenshot-5@1x.png`} alt="" />
              </picture>
            </div>
          </div>
          <div className={cn(s.hero__container, s.hero__descriptionBlock)}>
            <h1 data-aos="fade-down" data-aos-duration="150" className={s.hero__title}>
              {t('landing2:hero.title', 'One Platform For Field Execution')}
            </h1>
            <div data-aos="fade-down" data-aos-duration="150" data-aos-delay="150" className={s.hero__description}>
              {t('landing2:hero.description', 'Instant schedules, lookaheads and reports that integrate your tools')}
            </div>
            <div data-aos="fade-down" data-aos-duration="150" data-aos-delay="300" className={s.hero__actions}>
              <CtrlButton
                text={t('landing2:links.button', 'Get Started')}
                view="stroke"
                color="second"
                textView="uppercase"
                onClick={() => history.push(routes.getStarted)}
              />
            </div>
            <div data-aos="fade-down" data-aos-duration="150" data-aos-delay="450" className={s.hero__note}>
              {t('landing2:hero.note', 'Try it out now for free')}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
