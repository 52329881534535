import cn from 'classnames';
import {GanttStatic} from 'dhtmlx-gantt';
import {ChangeEvent, FC, UIEvent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router';

import CtrlDates from 'modules/Tasks/components/ActionsBar/components/CtrlDates/CtrlDates';
import {useGanttZoom} from 'modules/Tasks/components/ActionsBar/hooks/useGanttZoom';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import {
  getCommonWeeksFilter,
  getGanttWeeksFilter,
  getLookaheadWeeksFilter,
} from 'modules/Tasks/components/Filters/utils/constants';
import DatePicker from 'shared/components/CoreForm/DatePicker';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import CtrlCheck from 'shared/components/CoreNewUI/CtrlCheck/CtrlCheck';
import CtrlChip from 'shared/components/CoreNewUI/CtrlChip/CtrlChip';
import CtrlChipGroup from 'shared/components/CoreNewUI/CtrlChipGroup/CtrlChipGroup';
import Dropdown from 'shared/components/CoreNewUI/CtrlDrop/CtrlDrop';
import {TasksViewMode} from 'shared/constants/common';
import {RouteParams} from 'shared/constants/routes';
import {addDays, formatDate, subtract} from 'shared/helpers/dates';
import {debounce} from 'shared/helpers/debounce';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompanyWorkerRoles} from 'shared/hooks/useCompanyWorkerRoles';

import {DropdownListItem} from '../../../Gantt/components/DropdownList/DropdownList';

import s from './DateDropdown.module.scss';

type Props = {
  className?: string;
  active?: boolean;
  gantt?: GanttStatic;
  navigationType?: 'time' | 'scale';
  onOpenDateFilter?: () => void;
};

const DEFAULT_SCHED_WEEKS = '3';

const DateDropdown: FC<Props> = ({className, gantt, active, navigationType = 'time', onOpenDateFilter}: Props) => {
  const {projectId} = useParams<RouteParams['tasks']>();
  const {queryParams, updateSearchParams, reset} = useFilterContext();
  const {viewMode} = useFilterContext();
  const [dateRange, setDateRange] = useState('');
  const {zoomInOrOut, isDisabledZoomOut, isDisabledZoomIn} = useGanttZoom(gantt);
  const {t} = useTranslation(['filters', 'gantt']);
  const {isProjectAdmin} = useCompanyWorkerRoles(projectId);
  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, isReadOnlyMode: !isProjectAdmin, viewMode}});
  const mixpanelEvents = mixpanel.events.tasks.toolbar;
  const selectedDate = useMemo(
    () => (queryParams.schedEndFirst ? new Date(queryParams.schedEndFirst) : null),
    [queryParams.schedEndFirst],
  );
  const schedWeeks = queryParams.schedWeeks;
  const onChangeDate = (date: Date) => {
    const newSearchParams = {...queryParams, schedEndFirst: date};
    // TODO: may be in the future both params (schedEndFirst & schedWeeks) must be independent
    if (!newSearchParams.schedWeeks) {
      newSearchParams.schedWeeks = DEFAULT_SCHED_WEEKS;
    }
    updateSearchParams(newSearchParams);
    mixpanel.track(mixpanelEvents.dateDropdown.selectDate);
  };

  const onChangeWeeks = (e: ChangeEvent<HTMLInputElement>) => {
    updateSearchParams({...queryParams, schedWeeks: e.target.value});
    mixpanel.track(mixpanelEvents.dateDropdown.selectWeeks, {weeks: e.target.value});
  };

  const toggleShowPastDue = () => {
    updateSearchParams({...queryParams, showPastDue: !queryParams?.showPastDue});
  };

  const weekOptions = useMemo((): DropdownListItem[] => {
    switch (viewMode) {
      case TasksViewMode.gantt: {
        return getGanttWeeksFilter(t);
      }
      case TasksViewMode.lookahead: {
        return getLookaheadWeeksFilter(t);
      }
      default: {
        return getCommonWeeksFilter(t);
      }
    }
  }, [t, viewMode]);

  const goToWeek = (direction: 'backward' | 'forward') => {
    const NUMBER_DAYS = 7;
    const nextDate: Date =
      direction === 'forward'
        ? addDays(queryParams.schedEndFirst, NUMBER_DAYS).toDate()
        : subtract(queryParams.schedEndFirst, NUMBER_DAYS).toDate();
    updateSearchParams({...queryParams, schedEndFirst: nextDate});
  };

  const nexButton = (e: UIEvent) => {
    e?.stopPropagation();
    if (navigationType === 'time') {
      goToWeek('forward');
    } else {
      zoomInOrOut('in');
    }
    mixpanel.track(mixpanelEvents.dateDropdown[navigationType === 'time' ? 'nextWeek' : 'zoomIn']);
  };

  const prevButton = (e: UIEvent) => {
    e?.stopPropagation();
    if (navigationType === 'time') {
      goToWeek('backward');
    } else {
      zoomInOrOut('out');
    }
    mixpanel.track(mixpanelEvents.dateDropdown[navigationType === 'time' ? 'prevWeek' : 'zoomOut']);
  };

  useEffect(() => {
    const onRender = debounce(() => {
      let start: Date = gantt.config.start_date;
      let end: Date = gantt.config.end_date;
      if (!start || !end) {
        const {start_date, end_date} = gantt.getSubtaskDates();
        start = start_date;
        end = end_date;
      }
      setDateRange(`${formatDate(start, 'MMM D')} — ${formatDate(subtract(end, 1), 'MMM D')}`);
    }, 500);

    const event = gantt.attachEvent('onGanttRender', onRender, undefined);
    onRender();

    return () => {
      gantt.detachEvent(event);
      // Cancel debounce function to avoid leading or trailing invocation that cause memory leaks
      onRender.cancel();
    };
  }, [gantt]);

  return (
    <Dropdown
      className={cn(s.dateDropdown, className)}
      color="second"
      header={{
        title: t('gantt:toolbar.date.title', 'Dates'),
      }}
      footer={
        <>
          <CtrlChipGroup title={t('gantt:toolbar.date.weeks.title', 'Weeks Ahead')}>
            {weekOptions?.map(({value, title}) => {
              return (
                <CtrlChip
                  key={`${value}|${title}`}
                  name="schedWeeks"
                  label={title}
                  value={value}
                  onChange={onChangeWeeks}
                  checked={parseInt(schedWeeks) === value}
                />
              );
            })}
          </CtrlChipGroup>
          <CtrlCheck
            className={s.showPastDue}
            fieldType="checkbox"
            label={t('gantt:toolbar.date.showPastDue', 'Show Past Due Activities')}
            labelSize="s"
          >
            <input checked={!!queryParams.showPastDue} type="checkbox" onChange={toggleShowPastDue} />
          </CtrlCheck>
          <div></div>
          <CtrlButton
            className={cn(s.dateDropdownButton, s.dateDropdownButton_reset)}
            onClick={() => reset({newState: {schedWeeks: null, schedEndFirst: null, showPastDue: false}})}
          >
            {t('filters:dropdown.resetFilters', 'Reset filters')}
          </CtrlButton>
        </>
      }
      toggleElement={
        <CtrlDates
          active={active}
          onClick={onOpenDateFilter}
          prevButton={
            <CtrlButton
              color="clear"
              size="xs"
              icon={navigationType === 'time' ? 'chevron-left' : 'zoom-out'}
              disabled={navigationType === 'scale' && isDisabledZoomOut}
              iconOnly={true}
              onClick={prevButton}
            >
              {t('filters:dropdown.prev_button')}
            </CtrlButton>
          }
          nextButton={
            <CtrlButton
              color="clear"
              size="xs"
              icon={navigationType === 'time' ? 'chevron-right' : 'zoom-in'}
              disabled={navigationType === 'scale' && isDisabledZoomIn}
              iconOnly={true}
              onClick={nexButton}
            >
              {t('filters:dropdown.next_button')}
            </CtrlButton>
          }
          text={dateRange}
        />
      }
    >
      <DatePicker inline isClearable={false} dateFormat="dd/MM/yy" onChange={onChangeDate} selected={selectedDate} />
    </Dropdown>
  );
};
export default DateDropdown;
