import dayjs, {ConfigType, Dayjs} from 'dayjs';

const WEEKS_BEFORE_CURRENT = 1;

export function getDateRange(weeksBefore: number, weeksAfter: number, date: Date, extraWeeks: 1 | 0 = 1) {
  const today = dayjs(date);

  return [
    extraWeeks > 0 ? today.subtract(weeksBefore, 'week').day(extraWeeks).startOf('day') : today,
    today
      .add(weeksAfter + extraWeeks, 'week')
      .day(1)
      .startOf('day'),
  ] as [Dayjs, Dayjs];
}

export const getFilterDateRange = (from?: ConfigType, weeks = 3) => {
  if (!from) return null;

  const start = dayjs(from).startOf('day');
  if (weeks === 0) return [start.toDate(), start.endOf('day').toDate()];
  return getDateRange(WEEKS_BEFORE_CURRENT, weeks - WEEKS_BEFORE_CURRENT, start.toDate())?.map((date) => date.toDate());
};

export const getVisualFilterDateRange = (from?: ConfigType, weeks = 1) => {
  if (!from || !dayjs(from).isValid() || weeks < 0) return null;

  const start = dayjs(from).startOf('day');
  if (weeks === 0) return [start.toDate(), start.endOf('day').toDate()];
  return getDateRange(0, weeks, start.toDate(), 0)?.map((date) => date.toDate());
};
