import {TaskStatusType} from 'shared/models/task/taskStatus';
import {CompanyWorkerStatus, WorkerStatus} from 'shared/models/worker';

const WorkerStatusMapping: Record<string, [WorkerStatus, CompanyWorkerStatus][]> = {
  invited: [
    ['invited', 'invited'],
    ['invited', 'active'],
    ['open', 'invited'],
  ],
  removed: [
    ['invited', 'removed'],
    ['open', 'removed'],
    [TaskStatusType.closed, 'removed'],
  ],
  active: [['open', 'active']],
  closed: [
    [TaskStatusType.closed, 'invited'],
    [TaskStatusType.closed, 'active'],
  ],
};

export function getCompanyWorkerStatus(companyWorkerStatus: string, workerStatus: string) {
  return Object.keys(WorkerStatusMapping).find((status) =>
    WorkerStatusMapping[status].some((statuses) => statuses[0] === workerStatus && statuses[1] === companyWorkerStatus),
  );
}
