import * as Sentry from '@sentry/react';
import {Field, Formik, FormikProps, FormikValues} from 'formik';
import {FC, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {Link} from 'react-router-dom';
import {toast} from 'react-toastify';
import {object, string} from 'yup';

import FirebaseService from 'services/Firebase';
import {useAuth} from 'shared/components/AuthUserProvider';
import Button from 'shared/components/Button';
import EmailInput from 'shared/components/CoreForm/EmailInput';
import FormControl from 'shared/components/CoreNewUI/FormControl/FormControl';
import Loader from 'shared/components/Loader';
import MetaTags from 'shared/components/MetaTags';
import ZetOnboardingContainer from 'shared/components/OnboardingLayout/ZetOnboardingContainer';
import {isFirebaseAuthError} from 'shared/constants/firebase';
import {useLocalizedRoutes} from 'shared/constants/routes';
import {extractAxiosError, isAxiosError} from 'shared/helpers/axios';
import {useScrollToTopOnMount} from 'shared/hooks/core/useScrollToTopOnMount';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useLandingStyles} from 'shared/hooks/useLandingStyles';
import OnboardingLayout from 'shared/layout/base/OnboardingLayout';

type ForgotPasswordForm = {emailAddress: string};

const validationSchema = object({
  emailAddress: string().email('Must be a valid email address').required('This field is required'),
});

const ForgotPassword: FC = () => {
  const history = useHistory();
  const {user} = useAuth();
  const formikRef = useRef<FormikProps<ForgotPasswordForm>>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const {mixpanel} = useAnalyticsService({publicPage: true});
  const routes = useLocalizedRoutes();
  const mixpanelEvents = mixpanel.events.forgotPassword;

  useScrollToTopOnMount();
  useLandingStyles();

  useEffect(() => {
    if (user && !user.isAnonymous) {
      history.push(routes.projects);
    }
  }, [user]);

  const onSendEmailClick = (e) => {
    e.preventDefault();
    if (isSubmitted) {
      mixpanel.track(mixpanelEvents.buttons.resetEmailSent, {'User Email': formikRef.current.values.emailAddress});
      return history.push(routes.logIn);
    }
    formikRef.current.submitForm();
  };

  const submitForm = async ({emailAddress}: FormikValues) => {
    setIsLoading(true);
    const mixpanelMeta = {'User Email': emailAddress};
    try {
      await FirebaseService.sendPasswordResetEmail(emailAddress);
      mixpanel.track(mixpanelEvents.buttons.sendResetEmail, mixpanelMeta);
      setIsSubmitted(true);
    } catch (e) {
      Sentry.captureException(e);
      if (isFirebaseAuthError(e) && e.code === 'auth/user-not-found') {
        mixpanel.track(mixpanelEvents.buttons.emailNotFound, mixpanelMeta);
        toast(
          "This email address was not found. Please, enter a registered email address or Sign Up if you don't have an account.",
        );
      } else {
        if (isAxiosError(e)) {
          toast(extractAxiosError(e));
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onboardingCopy = useMemo(() => {
    if (isSubmitted) {
      const emailAddress = formikRef.current.values.emailAddress;
      return {
        title: 'Email Sent',
        description: (
          <>
            An email was sent to <b>{emailAddress}</b>. Please click the Reset Password button in the email.
          </>
        ),
      };
    }

    return {
      title: 'Forgot Password',
      description: 'Enter your email address and we’ll send an email so you can reset your password.',
    };
  }, [isSubmitted]);

  const metaTags = useMemo(() => {
    return {
      title: 'Forgot Password',
      description: isSubmitted
        ? 'An email has been sent to reset your password. Check your inbox!'
        : "We'll send you an email to reset password",
      keywords:
        'Construction Productivity, Task Management, Crew Management, Jobsite, Foreman, Project Delivery, Construction Management, Collaboration, Construction Technology, Construction software',
    };
  }, [isSubmitted]);

  return (
    <>
      <MetaTags title={metaTags.title} description={metaTags.description} keywords={metaTags.keywords} />
      <OnboardingLayout formClassName="form-onboarding-z--without-nav form-onboarding-z--without-footer">
        <ZetOnboardingContainer title={onboardingCopy.title} description={onboardingCopy.description}>
          <Formik<ForgotPasswordForm>
            initialValues={{emailAddress: ''}}
            onSubmit={submitForm}
            innerRef={formikRef}
            validationSchema={validationSchema}
          >
            {({isSubmitting}) => (
              <>
                {!isSubmitted && (
                  <div className="form-onboarding-z__item">
                    <div className="form-onboarding-z__item">
                      <FormControl name="emailAddress" label="Email Address">
                        <Field>{({field}) => <EmailInput placeholder="Enter Email Address" {...field} />}</Field>
                      </FormControl>
                    </div>
                  </div>
                )}
                <div className="form-onboarding-z__item form-onboarding-z__item--actions">
                  <Button onClick={onSendEmailClick} disabled={isSubmitting} data-cy="submit_btn">
                    {!isSubmitted ? 'Send Reset Password Email' : 'Back to Log In'}
                  </Button>
                </div>
                {!isSubmitted && (
                  <div className="form-onboarding-z__item">
                    <div className="form-onboarding-z__text">
                      Go back to{' '}
                      <Link
                        to={routes.logIn}
                        onClick={() =>
                          mixpanel.track(mixpanelEvents.buttons.backToLogin, {
                            'User Email': formikRef.current.values.emailAddress || '',
                          })
                        }
                      >
                        Log In
                      </Link>
                    </div>
                  </div>
                )}
                {isLoading && <Loader />}
              </>
            )}
          </Formik>
        </ZetOnboardingContainer>
      </OnboardingLayout>
    </>
  );
};
export default ForgotPassword;
