import dayjs from 'dayjs';

import {GanttTask} from 'modules/Tasks/components/Gantt/types';
import {subtract} from 'shared/helpers/dates';

import {SAFE_STATUSES} from './constants';
import {TaskGanttModel} from 'shared/models/task/task';

export function getDrawTaskDuration(task: GanttTask) {
  if (task.start_date && task.end_date) {
    return fillDateRange(task.date_list);
  }
}

function fillDateRange(dateList: string[] = []) {
  const range: {
    date: Date;
    isFilled: boolean;
  }[] = [];

  const dayStart = 'T00:00';
  const dateSet = new Set(dateList.map((d) => +new Date(d + dayStart)));
  const iterDate = new Date(dateList[0] + dayStart);
  const lastDate = new Date(dateList[dateList.length - 1] + dayStart);
  while (iterDate <= lastDate) {
    range.push({
      date: new Date(iterDate),
      isFilled: dateSet.has(+iterDate),
    });
    iterDate.setDate(iterDate.getDate() + 1);
  }

  return range;
}

export function extendWorkDateRange(dateList: string[], newDate: Date) {
  const workDates = dateList.filter(Boolean);
  const newDateString = dayjs(newDate).format('YYYY-MM-DD');
  if (workDates.includes(newDateString)) {
    return workDates.filter((date) => date !== newDateString);
  } else {
    workDates.push(newDateString);
    return workDates.sort((a, b) => dayjs(a).diff(b));
  }
}

export function validateDueDate(task: TaskGanttModel) {
  const today = dayjs();
  return today.isAfter(task.end_date, 'day') && !SAFE_STATUSES.includes(task.status);
}

export function parseDateWith2DigitYear(date: string): Date {
  // date comes from input in 'YYYY-MM-DD' format
  if (!date) return null;
  const [year, month, day] = date.split('-');
  const currYear = new Date().getFullYear();
  const upperYear = (currYear + 49) % 100;
  let resultYear = +year;
  if (resultYear <= 99) {
    if (resultYear <= upperYear) {
      resultYear += 2000;
    } else {
      resultYear += 1900;
    }
  }
  const result = new Date(resultYear, Number(month) - 1, Number(day));
  return dayjs(result).isValid() ? result : null;
}

export function getFocusTodayDate() {
  const today = new Date();
  return subtract(today, 1, 'week').toDate();
}
