import {FC} from 'react';

import CoreSelect from 'shared/components/CoreForm/Select/Select';
import TaskStatusSelect from 'shared/components/CoreForm/Select/TaskStatusSelect';
import {SelectProps} from 'shared/components/CoreForm/Select/types';
import WorkerStatusSelect from 'shared/components/CoreForm/Select/WorkerStatusSelect';

const CoreStatusSelectField: FC<SelectProps> & {
  Task: typeof TaskStatusSelect;
  Worker: typeof WorkerStatusSelect;
} = (props) => {
  return <CoreSelect {...props} />;
};

CoreStatusSelectField.Task = TaskStatusSelect;
CoreStatusSelectField.Worker = WorkerStatusSelect;

export default CoreStatusSelectField;
