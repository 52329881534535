import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {CompanyModel} from 'shared/models/company';
import {Worker} from 'shared/models/worker';

type OnboardingStore = {
  isLoading: boolean;
  currentStep: number;
  worker: Worker;
  company: CompanyModel;
  signupInProgress: boolean;
};

const initialState: OnboardingStore = {
  worker: null,
  company: null,
  isLoading: false,
  currentStep: 1,
  signupInProgress: false,
};

const onboardingSlice = createSlice({
  initialState,
  name: 'onboarding',
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setWorker: (state, action: PayloadAction<Worker>) => {
      state.worker = action.payload;
    },
    setCompany: (state, action: PayloadAction<CompanyModel>) => {
      state.company = action.payload;
    },
    setSignupInProgress: (state, action: PayloadAction<boolean>) => {
      state.signupInProgress = action.payload;
    },
    reset() {
      return initialState;
    },
  },
});

export default onboardingSlice.reducer;
export const {setCurrentStep, setCompany, setWorker, setIsLoading, setSignupInProgress, reset} =
  onboardingSlice.actions;
