import cn from 'classnames';
import {cloneElement, forwardRef, isValidElement, ReactNode} from 'react';

import s from './CtrlCard.module.scss';

type CtrlCardProps = {
  className?: string;
  check?: ReactNode;
  badge?: string;
  titleField?: ReactNode;
  actions?: ReactNode;
  children: ReactNode[] | ReactNode;
};

const CtrlCard = forwardRef<HTMLDivElement, CtrlCardProps>(
  ({className, check, badge, titleField, actions, children}, ref) => {
    return (
      <div className={cn(s.ctrlCard, className)} ref={ref}>
        <div className={s.ctrlCard__header}>
          {isValidElement(check) &&
            cloneElement(check, {
              ...check.props,
              className: cn(check.props.className, s.ctrlCard__check),
            })}
          <div className={s.ctrlCard__titleGroup}>
            {badge && <div className={s.ctrlCard__id}>{badge}</div>}
            {isValidElement(titleField) &&
              cloneElement(titleField, {
                ...titleField.props,
                className: cn(titleField.props.className, s.ctrlCard__titleField),
              })}
          </div>
          {isValidElement(actions) &&
            cloneElement(actions, {
              ...actions.props,
              className: cn(actions.props.className, s.ctrlCard__actions),
            })}
        </div>
        <div className={s.ctrlCard__body}>{children}</div>
      </div>
    );
  },
);

export default CtrlCard;
