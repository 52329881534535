import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {PROPLAN_MAX_RANGE_VALUE} from 'modules/CompanyProfile/SubscriptionModal/utils/constants';
import {parseNumberAsPrice} from 'modules/CompanyProfile/SubscriptionModal/utils/helpers';
import {FormattedProPlanTiers, ProPlan, ProPlanTier} from 'shared/models/checkout';

export function useFormattedProPlanTiers(proPlan: ProPlan, ver: 'full' | 'short' = 'full') {
  const {t} = useTranslation('common');

  const IntervalAbbr = {
    month: t('tiers.interval.month', 'mo'),
    year: t('tiers.interval.year', 'y'),
    week: t('tiers.interval.week', 'week'),
  };

  const getSeatRangeStr = (prev: ProPlanTier, cur: ProPlanTier) => {
    if (prev) {
      if (+prev.upTo < PROPLAN_MAX_RANGE_VALUE) {
        return `${+prev.upTo + 1}` + `${!cur.upTo ? '+' : ' \u2014 ' + cur.upTo}`;
      }
      return `${PROPLAN_MAX_RANGE_VALUE + 1}+`;
    }
    return `1 ${!cur.upTo ? '+' : ' \u2014 ' + cur.upTo}`;
  };

  return useMemo(() => {
    const res = (proPlan?.tiers ? [...proPlan.tiers] : [])
      .sort((a, b) => a.flatAmountDecimal - b.flatAmountDecimal)
      .reduce((acc, cur, index, tiers) => {
        const prev = index === 0 ? null : tiers[index - 1];
        return acc.concat({
          seatRange: getSeatRangeStr(prev, cur),
          formattedUnitPrice: `${parseNumberAsPrice(cur.unitAmountDecimal / 100)}/${IntervalAbbr[proPlan.interval]}`,
          formattedFlatPrice: cur.flatAmountDecimal
            ? `${parseNumberAsPrice(cur.flatAmountDecimal / 100)}/${IntervalAbbr[proPlan.interval]}`
            : ' \u2014 ',
          ...cur,
        });
      }, [] as FormattedProPlanTiers[]);

    return ver === 'full' ? res : res.filter((p) => +p.upTo <= PROPLAN_MAX_RANGE_VALUE);
  }, [proPlan, ver]);
}
