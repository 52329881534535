import cn from 'classnames';
import {cloneElement, isValidElement, ReactNode} from 'react';

import s from './CommentsAction.module.scss';

type CommentsActionProps = {
  className?: string;
  workerName: string;
  actionText: string;
  status?: ReactNode;
};

const CommentsAction = ({className, workerName, actionText, status}: CommentsActionProps) => {
  return (
    <div className={cn(s.CommentsAction, className)}>
      <span className={s.CommentsAction__workerName}>{workerName}</span>
      <span className={s.CommentsAction__actionText}>{actionText}</span>

      {isValidElement(status) &&
        cloneElement(status, {
          ...status.props,
          className: cn(status.props.className, s.CommentsAction__status),
        })}
    </div>
  );
};

export default CommentsAction;
