// eslint-disable @typescript-eslint/no-explicit-any
export function throttle<F extends (...args: any[]) => any>(fn: F, wait: number) {
  let isCalled = false;

  return function (...args: Parameters<F>) {
    if (!isCalled) {
      fn(...args);
      isCalled = true;
      setTimeout(function () {
        isCalled = false;
      }, wait);
    }
  };
}
