import {GanttStatic} from 'dhtmlx-gantt';
import {useRef} from 'react';

import {GANTT_COLUMNS_NAMES} from 'modules/Tasks/components/Gantt/utils/constants';
import {GanttEventNameUnion, GanttGridEventNames} from 'modules/Tasks/components/Gantt/utils/gantt';
import {isValidViewMode} from 'modules/Tasks/utils/functions';
import {GANTT_PREFERENCES_KEY, TasksViewMode} from 'shared/constants/common';
import {useLocalStorage} from 'shared/hooks/useLocalStorage';

import {EventProvider, EventStore} from '../utils/eventStore';
import {resetFrozenColumnsOffset} from '../utils/functions';

type Column = {name: GANTT_COLUMNS_NAMES; width: number};
type ColumnOrder = {[key in GANTT_COLUMNS_NAMES]: number};
export type GanttLayoutSettings = {
  divider?: number;
  columns: Column[];
  columnOrder: ColumnOrder;
};

const DISABLED_COLUMN_NAME_FOR_REORDERING = [
  GANTT_COLUMNS_NAMES.checkbox,
  GANTT_COLUMNS_NAMES.rownumber,
  GANTT_COLUMNS_NAMES.uniqueId,
  GANTT_COLUMNS_NAMES.name,
  GANTT_COLUMNS_NAMES.customAdd,
];

const END_COLUMNS_INDEX = 1000;
export const DEFAULT_GRID_WIDTH = 700;

export function useTasksActiveTabStore(projectId: string) {
  const [getActiveTab, setActiveTab] = useLocalStorage<TasksViewMode | ''>({
    key: GANTT_PREFERENCES_KEY,
    path: `byProject.${projectId}.activeTab`,
    defaultValue: '',
    enabled: !!projectId,
  });
  function updateStoredActiveTab(value: TasksViewMode) {
    if (value && isValidViewMode(value)) {
      setActiveTab(value);
    }
  }

  const getStoredActiveTab = () => {
    const stored = getActiveTab();
    if (TasksViewMode[stored]) {
      return stored;
    }
    return TasksViewMode.lookahead;
  };

  return [getStoredActiveTab, updateStoredActiveTab] as const;
}

interface UseGanttLayoutSettingsParams {
  gantt: GanttStatic;
  eventStore?: EventStore<GanttEventNameUnion>;
  trackColumnsWidth?: boolean;
  trackGridWidth?: boolean;
}

export default function useGanttLayoutSettings({
  gantt,
  eventStore,
  trackColumnsWidth = true,
  trackGridWidth = true,
}: UseGanttLayoutSettingsParams) {
  const gridEventsStore = useRef(new EventStore<GanttGridEventNames>());
  const [getLayoutSettings, setLayoutSettings] = useLocalStorage<GanttLayoutSettings>({
    key: GANTT_PREFERENCES_KEY,
    path: `${gantt.name}.layout`,
    defaultValue: {divider: DEFAULT_GRID_WIDTH, columns: [], columnOrder: null},
    enabled: !!gantt.name,
  });

  const [getActiveTab, setActiveTab] = useLocalStorage<TasksViewMode | ''>({
    key: GANTT_PREFERENCES_KEY,
    path: 'activeTab',
    defaultValue: '',
  });

  function isDisabledColumn(colName: GANTT_COLUMNS_NAMES) {
    return DISABLED_COLUMN_NAME_FOR_REORDERING.includes(colName);
  }

  function trackGridLayoutAndColumns(): () => void {
    const grid = gantt.$ui.getView('grid') as EventProvider<GanttGridEventNames>;
    gridEventsStore.current.setEventProvider(grid);

    trackGridWidth &&
      eventStore.attach('onGridResizeEnd', (currentValue, nextValue) => {
        updateDivider(nextValue);
        resetFrozenColumnsOffset();
        return true;
      });

    trackColumnsWidth &&
      eventStore.attach('onColumnResizeEnd', (columnNumber, column, width) => {
        updateVisibilityColumns(column.name, width);
        return true;
      });

    gridEventsStore.current.attach('onBeforeColumnDragStart', ({draggedColumn: {name}}) => !isDisabledColumn(name));
    gridEventsStore.current.attach('onColumnDragMove', ({targetColumn: {name}}) => !isDisabledColumn(name));
    gridEventsStore.current.attach('onBeforeColumnReorder', ({targetColumn: {name}}) => !isDisabledColumn(name));
    gridEventsStore.current.attach('onAfterColumnReorder', () => updateColumnsOrder());

    return () => {
      gridEventsStore.current.detachAll();
    };
  }

  function checkLayoutSettings() {
    if (getLayoutSettings()) {
      applyLayoutSettings();
    }
  }

  function applyLayoutSettings() {
    const prevConfig = getLayoutSettings();
    if (trackColumnsWidth && prevConfig?.columns?.length) {
      gantt.config.columns.forEach((column) => {
        const _column = prevConfig.columns.find((col) => col.name === column.name);
        if (_column?.width) {
          column.width = _column.width;
        }
      });
    }

    if (trackGridWidth && prevConfig?.divider) {
      gantt.config.layout.cols[0].width = prevConfig.divider;
    }

    if (prevConfig?.columnOrder) {
      const columnsWithOrder = gantt.config.columns.map((col) => {
        const order =
          typeof prevConfig?.columnOrder[col.name] === 'number'
            ? prevConfig?.columnOrder[col.name]
            : gantt.config.columns.length;
        return {...col, order};
      });
      gantt.config.columns = columnsWithOrder.sort((a, b) => (a.order > b.order ? 1 : -1));
    }
  }

  function updateDivider(value: number) {
    if (value) {
      setLayoutSettings({...getLayoutSettings(), divider: value});
    }
  }

  const updateVisibilityColumns = (name: string, width: number) => {
    if (name && width) {
      const prevConfig = getLayoutSettings();
      const prev = prevConfig.columns.find((c) => c.name === name);
      if (prev) {
        prev.width = width;
      } else {
        prevConfig.columns = prevConfig.columns.concat({name: name as GANTT_COLUMNS_NAMES, width});
      }
      setLayoutSettings(prevConfig);
    }
  };

  const updateColumnsOrder = () => {
    const prevConfig = getLayoutSettings();

    if (!prevConfig.columnOrder) {
      prevConfig.columnOrder = {} as ColumnOrder;
    }
    gantt.config.columns.forEach((col, index) => {
      prevConfig.columnOrder[col.name] = index;
    });
    // customAdd must be at the very end
    prevConfig.columnOrder[GANTT_COLUMNS_NAMES.customAdd] = END_COLUMNS_INDEX;
    setLayoutSettings(prevConfig);
  };

  function updateActiveTab(value: TasksViewMode) {
    if (value) {
      setActiveTab(value);
    }
  }

  return {
    checkUserConfig: checkLayoutSettings,
    updateDivider,
    updateColumns: updateVisibilityColumns,
    updateActiveTab,
    trackGridLayoutAndColumns,
    config: getLayoutSettings(),
    prevActiveTab: getActiveTab(),
  };
}
