import {GanttStatic} from 'dhtmlx-gantt';
import {getDayScale} from './config';
import {GanttZoomLevels} from './constants';

function quarterScaleTemplate(date) {
  return `Q${Math.floor(date.getMonth() / 3) + 1}`;
}

export function configureZoomLevels(gantt: GanttStatic, projectId: string): void {
  const prevZoom = gantt.ext.zoom.getCurrentLevel();
  const zoomConfig = {
    activeLevelIndex: prevZoom,
    levels: [
      {
        name: GanttZoomLevels.DAY,
        min_column_width: gantt.config.min_column_width,
        scales: getDayScale(gantt, projectId),
      },
      {
        name: GanttZoomLevels.WEEK,
        scales: [
          {unit: 'month', step: 1, format: '%F, %Y'},
          {
            unit: 'week',
            step: 1,
            format: function (date) {
              const dateToFunc = gantt.date.date_to_str('%d %M');
              const endDate = gantt.date.add(date, 6, 'day');
              return dateToFunc(endDate);
            },
          },
        ],
      },
      {
        name: GanttZoomLevels.MONTH,
        min_column_width: gantt.config.min_column_width,
        scales: [
          {unit: 'year', format: '%Y'},
          {unit: 'month', format: '%M'},
        ],
      },
      {
        name: GanttZoomLevels.QUARTER,
        min_column_width: gantt.config.min_column_width,
        scales: [
          {unit: 'year', format: '%Y'},
          {
            unit: 'quarter',
            step: 1,
            format: quarterScaleTemplate,
          },
          {unit: 'month', step: 1, format: '%M'},
        ],
      },
      {
        name: GanttZoomLevels.YEAR,
        min_column_width: gantt.config.min_column_width,
        scales: [
          {unit: 'year', step: 1, format: '%Y'},
          {
            unit: 'quarter',
            step: 1,
            format: quarterScaleTemplate,
          },
        ],
      },
    ],
  };
  gantt.ext.zoom.init(zoomConfig);
  if (prevZoom !== undefined) {
    setTimeout(() => {
      gantt.ext.zoom.setLevel(prevZoom);
    });
  }
}
