/* eslint-disable @typescript-eslint/naming-convention */
import cn from 'classnames';
import {GanttStatic} from 'dhtmlx-gantt';
import {forwardRef, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';

import {TasksLocationState} from 'modules/Tasks/types/location';
import {updateSearchQuery} from 'modules/Tasks/utils/updateSearchQuery';
import Button from 'shared/components/Button';
import Icon from 'shared/components/Icon';
import {HSpacer} from 'shared/components/Spacer/HSpacer';
import {CompletionUnits} from 'shared/constants/completionUnits';
import {formatDate} from 'shared/helpers/dates';
import {useProjectWorkers} from 'shared/hooks/useProjectWorkers';
import {TaskActiveTab} from 'shared/models/task/const';

import {IconsMap} from '../../../../constants/icons';
import ActualManpowerCounter from '../ActualManpowerCounter';
import ProgressSlider from '../ProgressSlider';

import styles from './dailyCard.module.scss';

type LastWorker = {
  date?: string;
  value?: string | number;
  worker_id?: string;
};

export interface DailyCardUpdates {
  completionUnit: CompletionUnits;
  dailyCompletionAmount: number;
  dailyLabor: number;
  dateTag: Date;
  lastAvgLabor?: LastWorker;
  lastProgress?: LastWorker;
}

interface DailyCardProps {
  completionTarget: string;
  cumulativeDailyCompletion: number;
  feedback: DailyCardUpdates;
  gantt?: GanttStatic;
  handleUpdate: (updates: DailyCardUpdates) => void;
  index: number;
  onOpenComments: () => void;
  readonlyMode: boolean;
  taskId: string;
}

const DailyCard = forwardRef<HTMLDivElement, DailyCardProps>(
  (
    {
      completionTarget,
      cumulativeDailyCompletion,
      feedback,
      gantt,
      handleUpdate,
      index,
      onOpenComments: onClose,
      readonlyMode,
      taskId,
    }: DailyCardProps,
    ref,
  ) => {
    const {t} = useTranslation('dailies');
    const history = useHistory<TasksLocationState>();
    const today = new Date();
    const isToday = today.getDate() === feedback.dateTag.getDate() && today.getMonth() === feedback.dateTag.getMonth();
    const greaterThanToday = feedback.dateTag.getTime() > today.getTime();
    const {projectWorkers, isLoading: areWorkersLoading} = useProjectWorkers();
    const lastAvgWorker =
      !areWorkersLoading && projectWorkers.find((worker) => worker.workerFull.id === feedback.lastAvgLabor?.worker_id);
    const lastProgressWorker =
      !areWorkersLoading && projectWorkers.find((worker) => worker.workerFull.id === feedback.lastProgress?.worker_id);

    const containerStyle = cn({
      [styles.card]: true,
      [styles.text]: true,
      [styles.not_active]: greaterThanToday,
    });

    const dateStyle = cn({
      [styles.row]: true,
      [styles.date]: true,
      [styles.bold]: isToday,
      [styles.not_active]: greaterThanToday,
    });

    const notActiveStyle = cn({
      [styles.not_active]: greaterThanToday,
    });

    const hasHistory = (!!feedback?.lastAvgLabor?.value || !!feedback?.lastProgress?.value) && !!gantt;

    const openActivityComments = useCallback(() => {
      const searchParams = updateSearchQuery(history.location.search, {activeTab: TaskActiveTab.chat});
      history.push({
        pathname: history.location.pathname,
        search: searchParams,
        state: {taskId, activeTab: TaskActiveTab.chat, progress: true},
      });
      onClose();
    }, [history, onClose, taskId]);

    return (
      <div className={containerStyle} ref={ref}>
        {/* date */}
        <section className={notActiveStyle}>
          <div className={dateStyle}>
            <span>{feedback.dateTag.toLocaleDateString('en-US', {weekday: 'short'})}</span>
            <HSpacer />
            <span>{formatDate(feedback.dateTag, 'M/D/YY')}</span>
            <HSpacer />
            {hasHistory ? (
              <Button icon={<Icon name={IconsMap.history} />} iconOnly onClick={openActivityComments} />
            ) : null}
          </div>
        </section>
        <div className={styles.section}>
          {/* reported labor text */}
          <section className={notActiveStyle}>
            <p className={styles.row}>
              <span>
                <Icon width={15} height={15} name={IconsMap.user_hardhat} />
              </span>
              <HSpacer />
              <span>{t('progress_report.card.report_labor')}</span>
            </p>
            {!!feedback?.lastAvgLabor?.worker_id ? (
              <div className={cn(styles.row, styles.element)}>
                <p className={styles.box_text}>{lastAvgWorker?.workerFull?.fullName || ''}</p>
                <div className={styles.large_horizontal_divider}></div>
                <p>{feedback.lastAvgLabor.value}</p>
              </div>
            ) : (
              <div className={cn(styles.row, styles.element)}>
                <p className={styles.box_text}>{t('progress_report.card.none')}</p>
              </div>
            )}
          </section>
          {/* daily labor counter */}
          <section className={notActiveStyle}>
            <p className={styles.row}>
              <span>
                <Icon width={15} height={15} name={IconsMap.user_hardhat} />
              </span>
              <HSpacer />
              <span>{t('progress_report.card.labor')}</span>
            </p>
            <ActualManpowerCounter
              count={feedback.dailyLabor}
              disabled={greaterThanToday}
              index={index}
              handleChangeCount={(dailyLabor) => handleUpdate({...feedback, dailyLabor})}
              readOnlyMode={readonlyMode}
            />
          </section>
        </div>
        <div className={styles.separator} />
        <div className={styles.section}>
          {/* last reported progress text */}
          <section className={notActiveStyle}>
            <p className={styles.row}>
              <span>
                <Icon width={15} height={15} name={IconsMap.technology} />
              </span>
              <HSpacer />
              <span>{t('progress_report.card.last_progress')}</span>
            </p>
            {!!feedback.lastProgress?.worker_id ? (
              <div className={cn(styles.row, styles.element)}>
                <p>{lastProgressWorker?.workerFull?.fullName}</p>
                <div className={styles.horizontal_divider}></div>
                <p>{feedback.lastProgress.value}</p>
              </div>
            ) : (
              <div className={cn(styles.row, styles.element)}>
                <p className={styles.box_text}>{t('progress_report.card.none')}</p>
              </div>
            )}
          </section>
          {/* progress counter */}
          <section className={notActiveStyle}>
            <p className={styles.row}>
              <span>
                <Icon width={15} height={15} name="technology" />
              </span>
              <HSpacer />
              {t('progress_report.card.progress')}
            </p>
            <ProgressSlider
              completion={{
                completionAmount: feedback.dailyCompletionAmount,
                completionTarget: parseInt(completionTarget),
                completionUnit: feedback.completionUnit,
              }}
              cumulativeDailyCompletion={cumulativeDailyCompletion}
              disabled={greaterThanToday}
              handleChange={(dailyCompletionAmount) => handleUpdate({...feedback, dailyCompletionAmount})}
              index={index}
              readonlyMode={readonlyMode}
            />
          </section>
        </div>
      </div>
    );
  },
);

export default DailyCard;

DailyCard.displayName = 'DailyCard';
