/* eslint-disable @typescript-eslint/no-explicit-any */

import {useRef} from 'react';

export interface EventProvider<Events extends string> {
  attachEvent: (eventName: Events, handler: (...args: any[]) => any, options?: any) => string;
  detachEvent: (eventId: string) => void;
}

export class EventStore<Events extends string> {
  private eventStore = new Set<string>();

  constructor(private eventProvider?: EventProvider<Events>) {}

  setEventProvider(eventProvider?: EventProvider<Events>) {
    if (this.eventProvider !== eventProvider) {
      this.detachAll();
    }
    this.eventProvider = eventProvider;
  }

  attach(name: Events, callback: (...args: any[]) => void) {
    const eventId = this.eventProvider.attachEvent(name, callback, null);
    this.eventStore.add(eventId);
    return eventId;
  }

  detach(eventId: string) {
    this.eventProvider.detachEvent(eventId);
    this.eventStore.delete(eventId);
  }

  detachAll() {
    for (const eventId of Array.from(this.eventStore)) {
      this.eventProvider.detachEvent(eventId);
    }
    this.eventStore.clear();
  }
}

export function useGanttEventStore<Events extends string>(eventProvider?: EventProvider<Events>) {
  return useRef(new EventStore<Events>(eventProvider)).current;
}
