import {FC} from 'react';
import {createPortal} from 'react-dom';

import NewConfirmation from 'shared/components/Confirmation/NewConfirmation';

import Confirmation, {ConfirmationProps} from './Confirmation';

type Props = {
  visible: boolean;
  onClose: () => void;
  useNewLayout?: boolean;
} & ConfirmationProps;

const ConfirmationPopup: FC<Props> = ({
  visible,
  onClose,
  onAccept,
  onReject,
  useNewLayout = false,
  ...confirmProps
}) => {
  const container = document.body;
  if (!visible || !container) return null;
  const actionWithClose = (cb: () => void) => () => {
    onClose();
    cb();
  };

  const ConfirmationComponent = useNewLayout ? NewConfirmation : Confirmation;

  return createPortal(
    <ConfirmationComponent
      {...confirmProps}
      onClose={onClose && onClose}
      onAccept={onAccept && actionWithClose(onAccept)}
      onReject={onReject && actionWithClose(onReject)}
    />,
    container,
  );
};
export default ConfirmationPopup;
