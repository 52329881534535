import cn from 'classnames';
import {FC, useMemo, useState} from 'react';
import {useInfiniteQuery, useQuery} from 'react-query';
import {useParams} from 'react-router';

import CompanyApi from 'api/company';
import ProjectsApi from 'api/projects';
import {useFilterContext} from 'modules/Tasks/components/Filters/FilterProvider';
import CtrlButton from 'shared/components/CoreNewUI/CtrlButton';
import CtrlCheck from 'shared/components/CoreNewUI/CtrlCheck/CtrlCheck';
import {AssigneesFilterParams} from 'shared/components/TaskAssigneersFilter/types';
import {QUERY_CACHE_KEYS} from 'shared/constants/queryCache';
import {filterDuplicatedCompanyWorkers} from 'shared/helpers/worker';
import {useAnalyticsService} from 'shared/hooks/useAnalyticsService';
import {useCompany} from 'shared/hooks/useCompany';
import {GroupMemberRole} from 'shared/models/task/const';
import {TaskAssignees, TaskAssigneeWorker} from 'shared/models/task/member';
import {CompanyWorker, WorkerTrade} from 'shared/models/worker';

import Member from '../../Member/Member';
import MembersAdd from '../../MembersAdd/MembersAdd';
import MembersList from '../../MembersList/MembersList';
import s from '../../MembersList/MembersList.module.scss';
import {AssignmentState} from '../utils/constants';
import {companyWorkerToTaskAssignee} from '../utils/functions';

import {useAssigneesTabContext} from './AssigneesTabContext';

const initialFilterParams = {
  searchText: '',
  orgList: [],
};

const defaultSortParams = {
  field: 'fullName',
  order: 'ASC',
};

const ONE_WORKER = 1;
const PAGE_SIZE = 1000;

type Props = {
  title: string;
  onClose: () => void;
  isResponsibleSearch?: boolean;
  isMemberList?: boolean;
  taskAssignees: TaskAssignees[];
  updateSelectedWorkers: (selectedWorkers: TaskAssignees[]) => void;
  onMemberSelect: (members: TaskAssigneeWorker[]) => void;
};

const SearchWorkers: FC<Props> = ({
  title,
  onClose,
  updateSelectedWorkers,
  taskAssignees,
  isResponsibleSearch,
  isMemberList,
  onMemberSelect,
}: Props) => {
  const company = useCompany();
  const {projectId} = useParams<{projectId: string}>();
  const {viewMode} = useFilterContext();

  const {mixpanel} = useAnalyticsService({extraMeta: {projectId, viewMode}});
  const mixpanelEvents = mixpanel.events.task;

  const [assigneesFilter, setAssigneesFilter] = useState<AssigneesFilterParams>(initialFilterParams);
  const [total, setTotal] = useState<number>(null);
  const {watchers, responsible, updateWatchers, setAssignmentState} = useAssigneesTabContext();

  const onChange = (e) => {
    setAssigneesFilter({orgList: [], searchText: e?.target?.value});
  };

  const {data: orgsName} = useQuery(
    QUERY_CACHE_KEYS.companySubcontractors(company.id),
    () => {
      return CompanyApi.getCompanyOrgs(company.id);
    },
    {
      select: (orgs) => orgs.map((org) => org.group.name),
      enabled: !!company?.id,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );

  // TODO: add loading on scroll down
  const {data: fetchedWorkers} = useInfiniteQuery(
    ['companyWorkers', projectId, assigneesFilter],
    ({pageParam = 0}) => {
      return ProjectsApi.getProjectWorkers(
        projectId,
        {
          filterParams: {
            wildcard: assigneesFilter.searchText,
            orgList: assigneesFilter.orgList,
            blendedStatus: ['active', 'invited'],
          },
          sortField: defaultSortParams.field,
          sortOrder: defaultSortParams.order,
          offset: pageParam * PAGE_SIZE,
          limit: PAGE_SIZE,
        },
        'response',
      ).then((res) => {
        return res.data;
      });
    },
    {
      enabled: !!company?.id,
      refetchOnWindowFocus: false,
      refetchOnMount: true,
    },
  );

  const workers = useMemo(() => {
    const cworkers = fetchedWorkers?.pages.reduce((prev, cur) => prev.concat(cur), []) || [];
    const filteredWorkers = filterDuplicatedCompanyWorkers(cworkers).map((worker) => worker);
    setTotal(filteredWorkers.length);
    return filteredWorkers;
  }, [fetchedWorkers]);

  const onSelectResponsible = (worker: CompanyWorker) => {
    mixpanel.track(mixpanelEvents.actions.selectResponsible);
    const selectedWorker = companyWorkerToTaskAssignee(worker, GroupMemberRole.responsible);
    const preparedWatchers = watchers
      .filter((w) => w.memberId !== worker.workerId)
      .map((w) => ({workerId: w.memberId, memberRole: GroupMemberRole.assignee}));
    const filteredWatchers = watchers.filter((w) => w.memberId !== worker.workerId);
    updateSelectedWorkers([selectedWorker]);
    updateWatchers([...filteredWatchers, {...selectedWorker, memberRole: GroupMemberRole.assignee}]);
    onMemberSelect([{...selectedWorker}, ...preparedWatchers]);
  };

  const onSelectWatcher = (worker: CompanyWorker, checked: boolean) => {
    mixpanel.track(mixpanelEvents.actions.toggleSelectTaskWatcher(!checked ? 'Select' : 'Unselect'));
    const selectedResponsible = responsible[0] ? [{...responsible[0], workerId: responsible[0].memberId}] : [];
    if (!checked) {
      const selectedWorker = companyWorkerToTaskAssignee(worker, GroupMemberRole.assignee);
      const preparedWatchers = watchers
        .filter((w) => w.memberId !== responsible[0]?.memberId)
        .map((w) => ({workerId: w.memberId, memberRole: GroupMemberRole.assignee}));
      updateSelectedWorkers([...watchers, selectedWorker]);
      onMemberSelect([...selectedResponsible, selectedWorker, ...preparedWatchers]);
    } else {
      if (taskAssignees.length === ONE_WORKER) return;
      const filteredWatchers = watchers.reduce((acc, w) => {
        if (w.memberId !== worker.workerId && w.memberId !== responsible[0]?.memberId) {
          acc.push({workerId: w.memberId, memberRole: GroupMemberRole.assignee});
        }
        return acc;
      }, []);
      const filteredSelectedWorkers = watchers.filter((w) => w.memberId !== worker.workerId);
      onMemberSelect([...selectedResponsible, ...filteredWatchers]);
      updateSelectedWorkers(filteredSelectedWorkers);
    }
  };

  const selectedAll = useMemo(() => {
    return total === taskAssignees.length;
  }, [total, taskAssignees]);

  const selectAll = () => {
    mixpanel.track(mixpanelEvents.buttons.selectAllAssignees);

    const workerForSelect = [];
    const selectedResponsible = responsible[0] ? [{...responsible[0], workerId: responsible[0].memberId}] : [];
    workers.forEach((w) => {
      const isWorkerUncheck = !taskAssignees.find((m) => m.memberId === w.workerId);
      if (isWorkerUncheck) {
        workerForSelect.push(companyWorkerToTaskAssignee(w, GroupMemberRole.assignee));
      }
    });
    const preparedWatchers = workers
      .filter((w) => w.workerId !== responsible[0]?.memberId)
      .map((w) => ({workerId: w.workerId, memberRole: GroupMemberRole.assignee}));
    onMemberSelect([...selectedResponsible, ...preparedWatchers]);
    updateSelectedWorkers([...taskAssignees, ...workerForSelect]);
  };

  const deselectAll = () => {
    mixpanel.track(mixpanelEvents.buttons.unselectAllAssignees);

    const selectedResponsible = responsible[0] ? [{...responsible[0], workerId: responsible[0].memberId}] : [];
    onMemberSelect([...selectedResponsible]);
    updateSelectedWorkers([{...responsible[0], memberRole: GroupMemberRole.assignee}]);
  };

  const getAction = (worker: CompanyWorker, name: string) => {
    const isActive = !!taskAssignees.find((w) => w.memberId === worker.workerId);
    if (isResponsibleSearch) {
      return (
        <CtrlButton
          color="second"
          size="s"
          icon="responsible"
          active={isActive}
          iconOnly
          circleView
          onClick={() => onSelectResponsible(worker)}
        >
          Make Responsible
        </CtrlButton>
      );
    } else {
      return (
        <CtrlCheck
          className={cn({[`${s.MembersList_disabled}`]: worker.workerId === responsible[0]?.memberId})}
          fieldType="checkbox"
          label={`Select ${name}`}
          labelIsHidden
        >
          <input
            type="checkbox"
            disabled={worker.workerId === responsible[0]?.memberId}
            checked={isActive}
            onClick={() => onSelectWatcher(worker, isActive)}
          />
        </CtrlCheck>
      );
    }
  };

  const onAfterSelectOrg = (org: string) => {
    setAssigneesFilter({orgList: [org], searchText: ''});
  };

  return (
    <>
      <MembersAdd
        orgsName={orgsName}
        title={title}
        onClose={onClose}
        onChange={onChange}
        onAfterSelectOrg={onAfterSelectOrg}
        inviteMember={() =>
          mixpanel.trackWithAction(
            () => setAssignmentState(AssignmentState.inviteMember),
            mixpanelEvents.buttons.inviteMemberButton,
          )
        }
      />
      <MembersList
        isMemberList={isMemberList}
        view="search"
        membersCount={total}
        selectAll={selectAll}
        selectedAll={selectedAll}
        deselectAll={deselectAll}
      >
        <>
          {workers.map((worker) => (
            <Member
              key={worker.id}
              className={s.MembersList__item}
              memberName={worker.workerFull.fullName || worker.workerFull.email}
              memberTrade={worker.workerFull.trade as WorkerTrade}
              memberOrgs={worker.orgs}
              memberProfilePicUrl={worker.workerFull.profilePicUrl}
              action={getAction(worker, worker.workerFull.fullName)}
            />
          ))}
        </>
      </MembersList>
    </>
  );
};

export default SearchWorkers;
